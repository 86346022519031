<template>
    <div
        v-if="langs"
        class="functionality-popup"
    >
        <div class="functionality-popup__title">
            {{ $t('h_doc_func', 'Editor functionality') }}
        </div>
        <div class="functionality-popup__row">
            {{ langs.id_p1 }}
        </div>
        <div class="functionality-popup__row">
            {{ langs.id_p2 }}
        </div>
        <div class="functionality-popup__row">
            <div class="functionality-popup__function">
                <img src="@/assets/img/document/1.jpg" />
            </div>
            <p>
                {{ langs.id_p3_1 }}
            </p>
        </div>
        <div class="functionality-popup__row">
            <div class="functionality-popup__function flex">
                <img src="@/assets/img/document/2.jpg" />
                <img src="@/assets/img/document/3.jpg" />
            </div>
            <p>
                {{ langs.id_p3_2 }}
            </p>
        </div>
        <div class="functionality-popup__row">
            <div class="functionality-popup__function">
                <img src="@/assets/img/document/4.jpg" />
            </div>
            <p>
                {{ langs.id_p4 }}
            </p>
        </div>
        <div class="functionality-popup__row">
            <p>
                {{ langs.id_p5 }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/5.jpg" />
            </div>
            <p>
                {{ langs.id_format }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/6.jpg" />
            </div>
            <p>
                {{ langs.id_font }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/7.jpg" />
            </div>
            <p>
                {{ langs.id_size }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/8.jpg" />
            </div>
            <p>
                {{ langs.id_color }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/9.jpg" />
            </div>
            <p>
                {{ langs.id_bold }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/10.jpg" />
            </div>
            <p>
                {{ langs.id_italic }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/11.jpg" />
            </div>
            <p>
                {{ langs.id_underline }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/12.jpg" />
            </div>
            <p>
                {{ langs.id_align_l }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/13.jpg" />
            </div>
            <p>
                {{ langs.id_align_c }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/14.jpg" />
            </div>
            <p>
                {{ langs.id_align_r }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/15.jpg" />
            </div>
            <p>
                {{ langs.id_align_j }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/16.jpg" />
            </div>
            <p>
                {{ langs.id_list_number }}
            </p>
        </div>
        <div class="functionality-popup__row format">
            <div class="functionality-popup__function format">
                <img src="@/assets/img/document/17.jpg" />
            </div>
            <p>
                {{ langs.id_list_bullet }}
            </p>
        </div>
        <div class="functionality-popup__row">
            <p>
                {{ langs.id_p6 }}
            </p>
        </div>
        <div class="functionality-popup__row">
            <div class="functionality-popup__function">
                <img src="@/assets/img/document/18.jpg" />
            </div>
            <p>
                {{ langs.id_p7 }}
            </p>
        </div>
        <div class="functionality-popup__row">
            <div class="functionality-popup__function">
                <img src="@/assets/img/document/19.jpg" />
            </div>
            <p>
                {{ langs.id_p8 }}
            </p>
        </div>
        <div class="functionality-popup__row">
            <div class="functionality-popup__function">
                <img src="@/assets/img/document/20.jpg" />
            </div>
            <p>
                {{ langs.id_p9 }}
            </p>
        </div>
        <div class="functionality-popup__row">
            <div class="functionality-popup__function">
                <img src="@/assets/img/document/21.jpg" />
            </div>
            <p>
                {{ langs.id_p10 }}
            </p>
        </div>
        <div class="functionality-popup__row">
            <div class="functionality-popup__function">
                <img src="@/assets/img/document/22.jpg" />
            </div>
            <p>
                {{ langs.id_p11 }}
            </p>
        </div>
        <h4>
            {{ langs.id_surveys }}
        </h4>
        <div class="functionality-popup__row">
            <div class="functionality-popup__function">
                <img src="@/assets/img/document/23.png" />
            </div>
            <p>
                {{ langs.id_p12 }}
            </p>
        </div>
    </div>
</template>

<script>
    import { get } from '@/utils/http-helper';

    const GET_LANG = '/lang/get-instruction';

    export default {
        name: 'functionality-popup',

        props: {
            isReview: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            langs: null,
        }),

        computed: {
            lang() {
                return this.$store.getters['platform/platform'].lang;
            },
        },

        async mounted() {
            const response = await get(GET_LANG, { type: 'document', lang: this.lang });
            this.langs = response.data.tokens;
        },
    };
</script>

<style scoped lang="scss">
    .functionality-popup{
        &__title{
            margin-bottom: 2.3rem;

            font-size: to_rem(18px);
            color: var(--platform-accent-color);
        }
        &__row{
            display: flex;
            align-items: center;

            margin-bottom: 1.5rem;

            line-height: 1.4;
            &.format {
                margin-bottom: 0.1rem;
            }
            .functionality-popup__function{
                width: 6%;
                .app-tool-btn{
                    margin: 0;
                }
                &.flex {
                    display: flex;

                    width: 12%;
                }
                &.format {
                    width: 10%;
                }
            }
            p{
                width: 90%;
            }
            &.button{
                .functionality-popup__function{
                    width: 26%;
                    margin-right: 1.3%;
                }
                p{
                    width: 73%;
                }
            }
            &.three{
                .functionality-popup__function{
                    display: flex;

                    width: 17%;
                    margin-right: 1.3%;
                    .app-tool-btn{
                        margin-right: 0.5rem;
                    }
                }
                p{
                    width: 82%;
                }
            }
            &.width{
                .functionality-popup__function{
                    width: 24%;
                    margin-right: 1.3%;
                }
                p{
                    width: 73%;
                }
            }
            &.color{
                .functionality-popup__function{
                    margin-right: 2%;
                }
            }
        }
        .leave{
            width: 2.125rem;
            height: 2.125rem;
            object-fit: contain;
        }
        @media screen and (max-width: 1023px){
            &__row {
                &.button {
                    .functionality-popup__function {
                        width: 28%;
                    }
                }
            }
        }
        @media screen and (max-width: 767px){
            &__row {
                .functionality-popup__function {
                    width: 15%;
                }
                &.button {
                    flex-direction: column;
                    .functionality-popup__function {
                        width: 67%;
                        margin: 0 auto 1rem;
                        .app-button {
                            margin: 0 auto;
                        }
                    }
                    p {
                        width: 100%;
                    }
                }
                &.three {
                    .functionality-popup__function {
                        width: 40%;
                    }
                }
                &.width {
                    flex-direction: column;
                    .functionality-popup__function {
                        width: 40%;
                    }
                }
            }
        }
    }
</style>
