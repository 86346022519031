import store from '@/store';
import router from '@/router';

import { GP, socketInstance } from '@/utils/sockets-helper';

const NAME_SPACE = 'time';
const TIME_INFO = 'info';
const ACTIVITY = 'activity';
const INACTIVE = 'inactive';
const DISCONNECT = 'disconnect';
const LOGOUT = 'logout';
const CHANGE_TOPIC = 'change-topic';
const JoinVideoCall = 'join-videocall';

const generateSocketPath = new GP(NAME_SPACE);

let timeSocket = null;
let canSendActivity = true;

export const initTimeSocket = (role_id) => {
    timeSocket = socketInstance(NAME_SPACE, {}, changeTopic);

    timeSocket.on(generateSocketPath.generate(TIME_INFO), data => {
        store.dispatch('auth/changeTime', data.current_time);
    });

    timeSocket.on(generateSocketPath.generate(INACTIVE), () => {
        store.dispatch('auth/toggleTimerWarning', true);
    });

    timeSocket.on(generateSocketPath.generate(DISCONNECT), async () => {
        logoutTopicTimer();
        await store.dispatch('auth/logout');
        router.push({ name: 'login', params: { platform: router.app._route.params.platform } });
        store.dispatch('auth/toggleTimerWarning', false);
    });
    if (role_id === 6 || role_id === 5) {
        window.addEventListener('mousemove', touchHandler);
        window.addEventListener('scroll', touchHandler);
        window.addEventListener('touchmove', touchHandler);
        window.addEventListener('focus', changeTopic);
        window.addEventListener('load', changeTopic);
        window.addEventListener('blur', blurTopic);
    }
};
export const videoWatching = (watching = true) => {
    if (watching) {
        touchHandler();
    }
};

function blurTopic() {
    changeTopicTimer(0);
}

function touchHandler() {
    if (!timeSocket) return;
    if (canSendActivity) {
        canSendActivity = false;
        setTimeout(() => {
            canSendActivity = true;
        }, 5000);
        timeSocket.emit(generateSocketPath.generate(ACTIVITY));
        store.dispatch('auth/toggleTimerWarning', false);
    }
}

function changeTopic() {
    const route = router.currentRoute.name;
    if (route === 'dashboard') {
        changeTopicTimer(store.getters['dashboard/topicId']);
    } else if (route === 'stickersBoard' || route === 'workshop' || route === 'document-review' || route === 'editor') {
        changeTopicTimer(router.app._route.query.id);
    }
}


export const toogleTab = (status) => {
    if (timeSocket) {
        timeSocket.emit(generateSocketPath.generate(JoinVideoCall), { status });
    }
};

export const changeTopicTimer = (id) => {
    if (timeSocket && id !== undefined) {
        timeSocket.emit(generateSocketPath.generate(CHANGE_TOPIC), { topic_id: id });
    }
    if (id > 0) {
        toogleTab(0);
    }
};

export const logoutTopicTimer = () => {
    if (timeSocket) {
        timeSocket.emit(generateSocketPath.generate(LOGOUT));
        timeSocket.destroy();
        timeSocket = null;
        window.removeEventListener('mousemove', touchHandler);
        window.removeEventListener('scroll', touchHandler);
        window.removeEventListener('touchmove', touchHandler);
        window.removeEventListener('focus', changeTopic);
        window.removeEventListener('load', changeTopic);
        window.removeEventListener('blur', blurTopic);
    }
};
