<template>
    <div
        class="editor"
        :class="{disable: disableAll}"
    >
        <div class="left">
            <div class="head">
                <div class="name">
                    <input
                        v-model="documentInfo.title"
                        type="text"
                        @blur="renamePage"
                    />
                </div>
                <div
                    v-if="actionUserName"
                    class="action"
                >
                    {{ actionUserName }} печатает
                </div>
                <div class="pages">
                    <div
                        class="app-tool-btn"
                        title="Add vote"
                        @click="openVotePopup"
                    >
                        <AddVoteIcon />
                    </div>
                </div>
            </div>
            <div class="editor__container">
                <ckeditor
                    v-if="user"
                    ref="ckeditor"
                    v-model="documentInfo.content"
                    :config="editorConfig"
                    :read-only="editorReadOnly"
                    @input="inputEvent"
                />
            </div>
            <div class="bottom">
                <div
                    class="app-tool-btn pre accent"
                    :title="$t('previous', 'Previews')"
                    :class="{disabled: currentPage === 1}"
                    @click="switchPage(currentPage-1)"
                >
                    <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                </div>
                <form
                    class="pages"
                    @submit.prevent="switchPage(+pageInputValue)"
                >
                    <span>
                        {{ currentPage }}/{{ documentInfo.total_page }}
                    </span>
                    <input
                        v-model="pageInputValue"
                        class="app-input white"
                        type="number"
                        :placeholder="$t('go_to', 'Go to')"
                    />
                    <button
                        type="submit"
                        class="app-tool-btn go accent"
                        title="Go to"
                    >
                        {{ $t('go', 'Go') }}
                    </button>
                </form>
                <div
                    class="app-tool-btn next accent"
                    :title="$t('next', 'Next')"
                    :class="{disabled: currentPage === documentInfo.total_page}"
                    @click="switchPage(currentPage+1)"
                >
                    <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                </div>
            </div>
        </div>

        <appPopup name="add-new-editor-page">
            <add-new-page
                :socket="socket"
                :name-space="$options.surveysPaths.NAME_SPACE"
                @createPage="createPage"
            />
        </appPopup>
        <voting
            :surveys="surveys"
            :all-answered="allAnswered"
            @deleteSurvey="deleteSurvey"
            @answer="answerSurvey"
            @translate="translate"
        />
        <app-popup name="add-vote-popup">
            <add-vote-popup
                :socket="socket"
                :paths="$options.surveysPaths"
                @addSurvey="addSurvey"
                @editedSurvey="editedSurvey"
            />
        </app-popup>
        <app-popup name="workshopFunctionalityPopup">
            <functionalityPopup />
        </app-popup>
    </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue';
import appPopup from '@/components/user/app-popup';
import addNewPage from '@/components/user/pages/editor/add-new-page';
import addVotePopup from '@/components/user/pages/workshop/add-vote-popup';
import voting from '@/components/user/pages/workshop/voting';
import functionalityPopup from '@/components/user/pages/editor/functionality-popup';
import AddVoteIcon from '@/assets/img/icons/add_vote_icon.svg?inline';

import { get, post } from '@/utils/http-helper';
import { socketInstance, GP } from '@/utils/sockets-helper';
import surveys from '@/utils/mixins/surveys';

const NAME_SPACE = 'document';
const GET_DOCUMENT_INFO = '/document/get';
const GET_DOCUMENT_PAGE = '/document/get-page';
const DELETE_IMAGE = '/files/delete-file';
const CREATE_NEW_PAGE = 'page-create';
const SWITCH_PAGE = 'page-switch';
const BLOCK_PAGE = 'editor-block';
const UNBLOCK_PAGE = 'editor-unblock';
const UPDATE_CONTENT = 'page-update';
const RENAME_PAGE = 'page-rename';
const TRANSLATE = '/document/translate';

const generateSocketPath = new GP(NAME_SPACE);

export default {
    name: 'editor',

    surveysPaths: {
        NAME_SPACE: 'document',
        CREATE_SURVEY: 'survey-create',
        UPDATE_SURVEY: 'survey-update',
    },

    components: {
        ckeditor: CKEditor.component,
        appPopup,
        addNewPage,
        voting,
        addVotePopup,
        functionalityPopup,
        AddVoteIcon,
    },

    mixins: [surveys(NAME_SPACE)],

    data: () => ({
        socket: null,
        currentPage: 1,
        documentInfo: {},
        surveys: null,
        editorReadOnly: false,
        oldEditorImgs: [],
        actionUserName: '',
        pageInputValue: '',
        allAnswered: false,
    }),

    computed: {
        topicId() {
            return this.$route.query.id;
        },
        eventId() {
            return this.$route.query.event_id;
        },
        user() {
            return this.$store.getters['auth/userInfo'];
        },
        editorConfig() {
            return {
                height: window.innerHeight * 0.65,
                extraPlugins: 'simage, lite, openlink',
                resize_enabled: false,
                toolbar: [
                    {
                        name: 'document',
                        items: ['NewPage', 'Save', 'Format', 'Font', 'FontSize', 'TextColor'],
                    },
                    {
                        name: 'basicstyles',
                        items: ['Bold', 'Italic', 'Underline', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                    },
                    {
                        name: 'paragraph',
                        items: ['NumberedList', 'BulletedList', '-', 'Undo', 'Redo'],
                    },
                    {
                        name: 'insert',
                        items: ['SImage', 'Voting', 'Link', 'Smiley', 'Table', 'ToggleVideocall', 'lite-toggleshow'],
                    },
                ],
                lite: {
                    tooltipTemplate: '%a %u',
                    contextMenu: false,
                    userName: `${ this.user?.firstName } ${ this.user?.lastName }`,
                    userId: this.user.id,
                    ignoreSelectors: 'img',
                },
            };
        },
        disableAll() {
            const mode = +this.$store.getters['auth/userInfo'].role.mode;
            return this.user?.role?.id !== 5 && this.user?.role?.id !== 6 || mode === 0;
        },
    },

    mounted() {
        this.initSocket();
        this.initCkeditorPlugins();
        this.getDocumentInfo();
    },

    methods: {
        initCkeditorPlugins() {
            /* eslint-disable */
            const that = this;
            CKEDITOR.plugins.registered['save'] =
                {
                    init: (editor) => {
                        let command = editor.addCommand('save',
                            {
                                modes: {wysiwyg: 1, source: 1},
                                exec: () => {
                                    if (this.disableAll) {
                                        const ckeditor = this.$refs.ckeditor.instance;
                                        ckeditor.plugins.lite.findPlugin(ckeditor).acceptAll({include: [this.user.id]});
                                    }
                                },
                            },
                        );
                        let commandNewPage = editor.addCommand('NewPage',
                            {
                                modes: {wysiwyg: 1, source: 1},
                                exec: function () {
                                    that.$store.dispatch('togglePopup', {popupName: 'add-new-editor-page'});
                                    setTimeout(() => {
                                        document.querySelector('iframe').blur();
                                    });
                                },
                            },
                        );
                        editor.ui.addButton('Save', {
                            label: that.$t('save', 'Save'),
                            command: 'save'
                        });
                        editor.ui.addButton('NewPage', {
                            label: that.$t('new_page', 'New page'),
                            command: 'NewPage'
                        });

                        if (this.$route.query.page) {
                            this.switchPage(+this.$route.query.page);
                        }
                    },
                };
            /* eslint-enable */
        },
        async getDocumentInfo() {
            const response = await get(GET_DOCUMENT_INFO, { topic_id: this.topicId, event_id: this.eventId });
            if (response.status) {
                this.documentInfo = response.data;
                this.surveys = response.data.surveys;
                this.allAnswered = !!response.data?.is_all_answered;
                this.setOldEditorImgs();
            }
        },
        async getPage() {
            const response = await get(GET_DOCUMENT_PAGE, {
                topic_id: this.topicId,
                page: this.currentPage,
                event_id: this.eventId,
            });
            if (response.status) {
                this.documentInfo = { ...this.documentInfo, ...response.data };
                this.surveys = response.data.surveys;
                this.allAnswered = !!response.data?.is_all_answered;
                this.setOldEditorImgs();
            }
        },
        initSocket() {
            this.socket = socketInstance(NAME_SPACE, { room: `room-${this.topicId}` });
            this.socket.on(generateSocketPath.generate(UPDATE_CONTENT), data => {
                if (data.success) {
                    const iframe = document.querySelector('iframe');
                    const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
                    const scroll = innerDoc.documentElement.scrollTop;
                    this.documentInfo.content = data.content;

                    const creditor = this.$refs.ckeditor.instance;
                    const litePlugin = creditor.plugins.lite.findPlugin(creditor);
                    const liteState = litePlugin.isVisible();

                    setTimeout(() => {
                        litePlugin.toggleShow(liteState);
                        innerDoc.documentElement.scrollTop = scroll;
                    }, 50);
                }
            });
            this.socket.on(generateSocketPath.generate(BLOCK_PAGE), data => {
                if (data.success) {
                    this.editorReadOnly = true;
                    this.actionUserName = data.name;
                }
            });
            this.socket.on(generateSocketPath.generate(UNBLOCK_PAGE), data => {
                if (data.success) {
                    this.editorReadOnly = false;
                    this.actionUserName = '';
                }
            });
            this.socket.on(generateSocketPath.generate(CREATE_NEW_PAGE), data => {
                if (data.success) {
                    this.createPage();
                }
            });
            this.socket.on(generateSocketPath.generate(RENAME_PAGE), data => {
                if (data.success) {
                    this.documentInfo.title = data.title;
                }
            });
            this.subscribeSocketEvents();
        },
        createPage() {
            this.documentInfo.total_page++;
        },
        switchPage(num) {
            if (!num) return;
            num < 1 ? num = this.pageInputValue = 1 : null;
            if (num > this.documentInfo?.total_page) {
                num = this.pageInputValue = this.documentInfo?.total_page;
            }
            this.socketSwitchPage(num);
            this.pageInputValue = '';
            if (+this.$route.query.page !== +num) {
                this.$router.replace({ query: { ...this.$route.query, page: num } });
            }
        },
        socketSwitchPage(page) {
            this.socketTogglePageStatus(true);
            this.socket.emit(generateSocketPath.generate(SWITCH_PAGE), { page: page }, data => {
                if (data.success) {
                    this.currentPage = page;
                    this.getPage();
                }
            });
        },
        socketTogglePageStatus(status) {
            if (status) {
                this.socket.emit(generateSocketPath.generate(BLOCK_PAGE), {}, () => {
                });
            } else {
                this.socket.emit(generateSocketPath.generate(UNBLOCK_PAGE), {}, () => {
                });
            }
        },
        inputEvent() {
            this.socketTogglePageStatus(true);
            clearTimeout(window.unblockTimeOut);
            window.unblockTimeOut = setTimeout(() => {
                this.socketTogglePageStatus(false);
                this.socket.emit(generateSocketPath.generate(UPDATE_CONTENT), { content: this.documentInfo.content });
            }, 1500);
            this.checkDeleteImg();
        },
        setOldEditorImgs() {
            const imgsArr = Array.from(new DOMParser().parseFromString(this.documentInfo.content, 'text/html')
                .querySelectorAll('img'))
                .map(img => img.getAttribute('src'));
            this.oldEditorImgs = imgsArr;
        },
        checkDeleteImg() {
            const imgsArr = Array.from(new DOMParser().parseFromString(this.documentInfo.content, 'text/html')
                .querySelectorAll('img'))
                .map(img => img.getAttribute('src'));
            if (this.oldEditorImgs.length > imgsArr.length) {
                this.oldEditorImgs.forEach(i => {
                    if (!imgsArr.includes(i)) {
                        post(DELETE_IMAGE, { name: i });
                    }
                });
            }
            this.setOldEditorImgs();
        },
        renamePage() {
            this.socket.emit(generateSocketPath.generate(RENAME_PAGE), { title: this.documentInfo.title });
        },

        openVotePopup() {
            this.$store.dispatch('togglePopup', { popupName: 'add-vote-popup' });
        },

        async translate(lang) {
            if (lang) {
                const response = await post(TRANSLATE, {
                    topic_id: +this.topicId,
                    page: +this.currentPage,
                    lang,
                });
                if (response.status) {
                    this.surveys = this.surveys.map(c => {
                        const survey = response.data.surveys.find(t => t.id === c.id);
                        c.name = survey?.name || c.name;
                        c.options = survey?.options || c.options;
                        return c;
                    });
                }
            } else {
                this.getPage();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.editor {
    display: flex;
    justify-content: center;

    min-height: 80vh;
    margin-bottom: 2rem;
    padding-top: 1.8rem;

    &__container {
        border: 20px solid var(--platform-main-color);
        border-top: none;
        border-bottom: none;
    }

    &::v-deep {
        [role=presentation] {
            z-index: 999 !important;
        }
    }
}

.go {
    width: auto;
    min-width: 2.5rem;
}

.name {
    input {
        font-size: to_rem(24px);
        color: #fff;

        background: transparent;
        border: none;
    }
}

.app-tool-btn {
    &::v-deep {
        svg {
            path {
                fill: var(--platform-main-color);
            }
        }
    }
}

.left {
    width: 1150px;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0.5rem 1.85rem;

        font-size: to_rem(20px);
        color: $mainTextColor;

        background: var(--platform-main-color);

        .pages {
            display: flex;
            align-items: center;

            .app-tool-btn {
                margin: 0;
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;

        padding: 0.5rem 0.7125rem;

        background: var(--platform-main-color);

        .pages {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: to_rem(14px);
            color: $mainTextColor;

            input {
                width: 4.5rem;
                margin: 0 0 0 0.5rem;

                text-align: center;
            }

            button {
                border: none;
            }
        }
    }
}

.right {
    width: 25%;

    .head {
        padding: .8rem 1.25rem;

        background: $mainBlue;
    }

    .content {
        padding: 3rem 1rem;

        background: white;
        border: 1px solid $gray;
        border-top: none;

        .app-voting {
            margin-bottom: 1rem;
        }
    }
}

@include mobile_or_P {
    .editor {
        flex-direction: column;

        padding: 0 1.2rem;

        .left {
            width: 100%;

            .head {
                padding: 0.9rem 1.35rem;

                font-size: 1rem;
            }
        }

        &__container {
            overflow: auto;

            &::v-deep {
                .cke_contents {
                    overflow: auto;
                }

                iframe {
                    width: 891px !important;
                }
            }
        }

        .right {
            width: 100%;
            margin-top: 1rem;

            .content {
                display: flex;
                justify-content: space-between;

                padding: 1.1rem 3.6rem;

                .app-voting {
                    width: 45%;
                }
            }
        }
    }
}

@include razr767 {
    .editor {
        padding: 0;

        .left {
            .head {
                padding: 1rem 1.8rem;

                font-size: 18px;

                .name {
                    width: 5rem;

                    input {
                        width: 100%;
                    }
                }
            }
        }

        &__container {
            border-width: 10px;

            &::v-deep {
                .cke_contents {
                    height: 400px !important;
                }
            }
        }

        &::v-deep {
            .cke_toolbar {
                float: initial;
            }
        }

        .right {
            .content {
                flex-direction: column;

                padding: 1rem 0.5rem;

                .app-voting {
                    width: 100%;
                }
            }
        }
    }
}

</style>
