<template>
    <div class="disposer-app-editor">
        <div
            v-if="title"
            class="disposer-app-topic-title"
        >
            {{ title }}
        </div>
        <app-ckeditor
            ref="ckeditor"
            v-model="state"
            :config="editorConfig"
        />
    </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue';

export default {
    name: 'app-editor',

    components: {
        appCkeditor: CKEditor.component,
    },

    props: {
        value: {
            type: null,
            required: true,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
    },

    data: () => ({
        editorConfig: {
            language: 'en',
            width: '650',
            height: '200',
            extraPlugins: 'simage, openlink',
            startupFocus: true,

            resize_enabled: true,
            resize_maxHeight: 500,
            toolbar: [
                {
                    name: 'document',
                    items: ['Format', 'Font', 'FontSize', 'TextColor', 'BGColor'],
                },
                {
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
                },
                {
                    name: 'paragraph',
                    items: ['NumberedList', 'BulletedList', '-', 'Undo', 'Redo', 'RemoveFormat'],
                },
                {
                    name: 'insert',
                    items: ['SImage', 'Voting', 'Link', 'Smiley', 'ToggleVideocall', 'lite-toggleshow'],
                },
            ],
        },
    }),

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style scoped lang="scss">
</style>
