<template>
    <div class="table-survey-component">
        <div class="table-survey-component__name app-topic-title">
            {{ table.name }}
            <div
                class="app-tool-btn"
                @click="openFullScreen"
            >
                <FullScreenIcon />
            </div>
        </div>
        <table-survey
            :table="sortedTablePosition"
            :question-id="questionId"
        />
        <app-popup
            ref="tablePopup"
            :name="`table-survey-popup-${table.id}`"
            large
        >
            <div class="app-popup__title">
                {{ table.name }}
            </div>
            <table-survey
                :table="mappingTable"
                :question-id="questionId"
            />
        </app-popup>
    </div>
</template>

<script>
import appPopup from '@/components/user/app-popup';
import TableSurvey from '@/components/user/pages/dashboard/table-survey';
import FullScreenIcon from '@/assets/img/icons/full_screen_icon.svg?inline';

export default {
    name: 'table-survey-component',

    components: {
        TableSurvey,
        appPopup,
        FullScreenIcon,
    },

    props: {
        table: {
            type: Object,
            default: () => ({}),
        },
        questionId: {
            type: [String, Number],
            default: 0,
        },
    },

    computed: {
        mappingTable() {
            const table = this.table;
            table.rows = table.rows.map(r => {
                const values = {};
                table.columns.forEach(c => {
                   const answer = c.answers.find(a => a.row_id === r.id);
                   if (answer.value && !Array.isArray(answer.value)) {
                       if (c.type === 1 || c.type === 2) {
                           values[c.id] = {
                               id: answer.value,
                               name: c.options?.find(o => o.id === answer.value)?.name,
                           };
                       } else {
                           values[c.id] = answer.value;
                       }
                   } else if (answer.value) {
                       values[c.id] = answer.value.map(v => ({
                           id: v,
                           name: c.options?.find(o => o.id === v)?.name,
                       }));
                   }
                });
                this.$set(r, 'values', values);
                return r;
            });
            return table;
        },
        sortedTablePosition() {
            return { ...this.table , columns:[...this.table.columns].sort((a,b) => a.position - b.position) };
        },
    },
    methods: {
        openFullScreen() {
            this.$store.dispatch('togglePopup', { popupName: `table-survey-popup-${this.table.id}` });
        },
        hidePopup(){
            this.isSent = true;
            this.$refs.tablePopup.closePopup();
        },
    },
};
</script>

<style scoped lang="scss">
.table-survey-component {
    &__name {
        display: flex;
        justify-content: space-between;

        font-size: 19px;
        color: #fff;

        .app-tool-btn {
            margin-left: auto;

            background-color: white;
        }

        svg {
            width: 25px;

            &::v-deep {
                path {
                    fill: var(--platform-main-color);
                }
            }
        }
    }

    @include mobile_or_P {
        .app-tool-btn {
            display: none;
        }
    }
}
</style>
