<template>
    <div class="disposer-edit-event">
        <div class="header">
            <div class="disposer-app-title">
                <span v-if="eventId">
                    Edit
                </span>
                <span v-else>
                    Add
                </span>
                Event
            </div>
            <div class="back">
                <app-button
                    tag="router-link"
                    :to="{name: 'events'}"
                >
                    Back to events
                </app-button>
            </div>
        </div>
        <div class="row row-event-tz">
            <div class="event-input">
                <label
                    class="disposer-app-label"
                >
                    Event
                    <input
                        v-model="event.name"
                        type="text"
                        class="disposer-app-input"
                    />
                    <span
                        v-if="$v.event.name.$dirty && !$v.event.name.required"
                        class="app-input-error"
                    >
                        Enter event name
                    </span>
                    <span
                        v-if="requestErrors.name"
                        class="app-input-error"
                    >
                        {{ requestErrors.name[0] }}
                    </span>
                </label>
            </div>
            <div
                v-if="timeZones"
                class="time-zone-input"
            >
                <div class="disposer-app-label">
                    Time zone
                    <app-select
                        v-model="event.timezone"
                        :option-select="timeZones"
                        :label="'name'"
                    />
                    <span
                        v-if="$v.event.timezone.$dirty && !$v.event.timezone.required"
                        class="app-input-error"
                    >
                        Select time zone
                    </span>
                    <span
                        v-if="requestErrors.timezone"
                        class="app-input-error"
                    >
                        {{ requestErrors.timezone[0] }}
                    </span>
                </div>
            </div>
        </div>
        <div class="date-preparation">
            <div class="disposer-app-topic-title">
                Date Preparation of Event
            </div>
            <div class="info-icons">
                <img
                    v-tooltip.right-start="$options.texts.datePrepIconMsg"
                    src="@/assets/img/icons/disposer/info-icon.png"
                    class="info-icon"
                />
            </div>
        </div>
        <div class="row row-date-time">
            <div>
                <app-date-picker
                    v-model="event.startDate"
                    title="Event Start date"
                    mode="date"
                    :required-error="$v.event.startDate.$dirty && !$v.event.startDate.required"
                />
                <app-date-picker
                    v-model="event.startTime"
                    title="Event Start time"
                    mode="time"
                    :required-error="$v.event.startTime.$dirty && !$v.event.startTime.required"
                />
                <span
                    v-if="requestErrors.start_date"
                    class="app-input-error"
                >
                    {{ requestErrors.start_date[0] }}
                </span>
            </div>
            <div>
                <app-date-picker
                    v-model="event.endDate"
                    title="Event End date"
                    mode="date"
                    :required-error="$v.event.endDate.$dirty && !$v.event.endDate.required"
                />
                <app-date-picker
                    v-model="event.endTime"
                    title="Event End time"
                    mode="time"
                    :required-error="$v.event.endTime.$dirty && !$v.event.endTime.required"
                />
                <span
                    v-if="requestErrors.end_date"
                    class="app-input-error"
                >
                    {{ requestErrors.end_date[0] }}
                </span>
            </div>
        </div>
        <div class="footer">
            <div class="footer-btns">
                <app-button
                    class="save-btn red"
                    @click="saveEvent"
                >
                    Save
                </app-button>
                <app-button
                    class="delete-btn"
                    :data-disable="!eventId"
                    @click="toggleCopyBlock"
                >
                    Copy content
                </app-button>
            </div>
            <div class="info-icons">
                <img
                    v-tooltip.right-start="$options.texts.copyIconMsg"
                    src="@/assets/img/icons/disposer/info-icon.png"
                    class="info-icon"
                />
            </div>
        </div>
        <div
            v-if="copyIsShow && eventsShort"
            class="copy"
        >
            <div class="target">
                <div class="disposer-app-topic-title">
                    Source project
                </div>
                <label class="disposer-app-label">
                    <app-select
                        v-model="copySettings.sourceEvent"
                        :option-select="eventsShort"
                        label="name"
                    />
                    <span
                        v-if="$v.copySettings.sourceEvent.$dirty && !$v.copySettings.sourceEvent.required"
                        class="app-input-error"
                    >
                        Select source event
                    </span>
                </label>
                <div class="settings">
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="copySettings.agenda"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Agenda
                    </label>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="copySettings.company"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Company
                    </label>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="copySettings.library"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Library
                    </label>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="copySettings.topics"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Topics
                    </label>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="copySettings.users"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Users
                    </label>
                </div>
                <div class="row btns">
                    <app-button @click="copyContent">
                        Copy
                    </app-button>
                    <app-button
                        v-if="copyFileUrl"
                        tag="a"
                        :href="copyFileUrl"
                        target="_blank"
                    >
                        Open participants passwords
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import appButton from '@/components/disposer/app/app-button';
    import appSelect from '@/components/disposer/app/app-select';
    import appDatePicker from '@/components/disposer/app/app-date-picker';

    import { datePrepIconMsg, copyIconMsg } from '@/utils/disposer/icons-text-helper.js';
    import { post } from '@/utils/http-helper';
    import { uniteTime } from '@/utils/disposer/time-format-helper';
    import { generateFileUrl } from '@/utils/http-helper';
    import { required } from 'vuelidate/lib/validators';
    import { createEvent, changeEvent, copyEvent } from '@/utils/disposer/notifications-text-helper';

    const EVENT_CREATE = '/event/create';
    const EVENT_UPDATE = '/event/update';
    const COPY_CONTENT = '/event/copy';

    export default {
        name: 'disposer-edit-event',

        components: {
            appButton,
            appSelect,
            appDatePicker,
        },

        texts: {
            datePrepIconMsg,
            copyIconMsg,
        },

        data: () => ({
            event: {
              name: '',
              startDate: '',
              startTime: '',
              endDate: '',
              endTime: '',
              timezone: null,
            },
            copySettings: {
              sourceEvent: null,
              agenda: 1,
              company: 1,
              library: 1,
              topics: 1,
              users: 1,
            },
            requestErrors: {},
            copyIsShow: false,
            copyFileUrl: '',
        }),

        validations: {
            event: {
              name: {
                required,
              },
              startDate: {
                required,
              },
              startTime: {
                required,
              },
              endDate: {
                required,
              },
              endTime: {
                required,
              },
              timezone: {
                required,
              },
            },
            copySettings: {
              sourceEvent: {
                required,
              },
            },
        },

        computed: {
            events() {
                return this.$store.getters['disposerEvents/get'];
            },
            timeZones() {
              return this.$store.getters['disposerEvents/getTimeZones'];
            },
            eventId() {
              return +this.$route.params.id;
            },
            editedEvent() {
              return this.$store.getters['disposerEvents/getEventInfo'];
            },
            eventsShort() {
              return this.$store.getters['disposerEvents/getEventsShort'].filter(e => +e.id !== +this.eventId);
            },
        },

        created() {
            !this.timeZones ? this.$store.dispatch('disposerEvents/getTimeZones'): null;
            if (this.eventId) {
                this.getEventInfo();
            }
        },

        methods: {
            async saveEvent() {
                if (this.$v.event.$invalid) {
                    this.$v.event.$touch();
                    return;
                }
                const newEvent = {
                    name: this.event.name,
                    timezone_id: this.event.timezone.id,
                    start_date: uniteTime(this.event.startDate, this.event.startTime),
                    end_date: uniteTime(this.event.endDate, this.event.endTime),
                };
                this.eventId? newEvent.id = this.eventId: null;

                this.$awn.async(post(this.eventId? EVENT_UPDATE: EVENT_CREATE, newEvent),
                response => {
                  if (response.status) {
                    this.$router.push({ name: 'edit-event', params: { id: response.data.id } });
                    this.requestErrors = {};
                    this.eventId ? this.$awn.success(createEvent) : this.$awn.success(changeEvent);
                  } else  {
                    this.requestErrors = response.errors;
                  }
                });

            },

            async getEventInfo() {
                await this.$store.dispatch('disposerEvents/getEventInfo', this.eventId);
                this.event.name = this.editedEvent.name;
                this.event.timezone = this.editedEvent.timezone;
                this.event.startDate = new Date(this.editedEvent.start_date);
                this.event.startTime = new Date(this.editedEvent.start_date);
                this.event.endDate = new Date(this.editedEvent.end_date);
                this.event.endTime = new Date(this.editedEvent.end_date);
            },

            async toggleCopyBlock() {
                this.copyIsShow = !this.copyIsShow;
                if (this.copyIsShow) {
                  await this.$store.dispatch('disposerEvents/getShort');
                }
            },

            copyContent() {
              if (this.$v.copySettings.$invalid) {
                this.$v.copySettings.$touch();
                return;
              }
              const obj = {
                source: this.copySettings.sourceEvent.id,
                target: this.eventId,
                params: {
                  agenda: +this.copySettings.agenda,
                  company: +this.copySettings.company,
                  library: +this.copySettings.library,
                  topics: +this.copySettings.topics,
                  users: +this.copySettings.users,
                },
              };
                this.$awn.async(post(COPY_CONTENT, obj), response => {
                    if (response.status) {
                      this.copyFileUrl = generateFileUrl(response.data.file);
                      this.$awn.success(copyEvent);
                    }
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .disposer-edit-event {
        .settings {
            margin-top: 1rem;
            .disposer-app-checkbox {
                margin-bottom: 0.5rem;
            }
        }
        .header {
            display: flex;
            justify-content: space-between;

            width: to_rem(350px);
            .app-button {
                padding: 15px;
            }
        }
        .row {
            display: flex;
            justify-content: space-between;

            padding-bottom: 30px;
            &.btns {
                justify-content: flex-start;
                .app-button {
                    margin-right: 1rem;
                }
            }
        }
        .row-event-tz {
            width: to_rem(705px);
            .event-input {
                width: to_rem(255px);
            }
            .time-zone-input {
                width: to_rem(375px);
            }
        }
        .date-preparation {
            display: flex;
            .disposer-app-topic-title{
                width: to_rem(190px);
            }
        }
        .row-date-time {
            width: to_rem(810px);
            &>div {
                position: relative;

                display: flex;
                justify-content: space-between;

                width: 45.5%;
                .disposer-app-label {
                    margin-bottom: 0;
                }
            }
        }
        .app-date-picker {
            width: to_rem(150px);
        }
        .footer {
            display: flex;
            &-btns {
                display: flex;
                justify-content: space-between;

                width: 320px;
                .save-btn,
                .delete-btn {
                    width: 100px;
                    height: 35px;
                }
                .delete-btn {
                    width: 195px;
                }
            }
            .info-icons {
                margin-top: 7px;
                margin-left: 10px;
            }
        }
        .copy {
            margin-top: 1rem;
        }
    }
</style>
