<template>
    <div class="disposer-app-topic-format">
        <div class="main">
            <div class="header">
                <div class="disposer-app-topic-title">
                    Select type of polling questions
                </div>
                <app-select
                    v-model="question.type"
                    :option-select="typeQuestion"
                />
            </div>

            <div
                v-if="question.type"
                class="content"
            >
                <div class="content-multi">
                    <div class="inputs">
                        <div
                            v-if="question.type.id !== 5 && question.type.id !== 6"
                            class="row"
                        >
                            <div class="question-wrapper">
                                <div class="question">
                                    <label class="disposer-app-label">
                                        Question
                                        <input
                                            v-model="question.name"
                                            type="text"
                                            maxlength="1000"
                                            class="disposer-app-input title-input"
                                        />
                                        <span
                                            v-if="$v.question.name.$dirty && !$v.question.name.required"
                                            class="app-input-error"
                                        >
                                            Enter question
                                        </span>
                                    </label>
                                </div>

                                <div class="answer-options">
                                    <h5>Answer Options</h5>
                                    <div
                                        v-for="(option, index) of question.options"
                                        :key="index"
                                        class="answer-options-input-icon"
                                    >
                                        <div class="answer-options-input">
                                            <label class="disposer-app-label">
                                                <input
                                                    v-model="option.name"
                                                    type="text"
                                                    class="disposer-app-input title-input"
                                                />
                                            </label>
                                        </div>

                                        <img
                                            v-if="question.options.length !== 1"
                                            src="@/assets/img/icons/disposer/delete-icon.png"
                                            @click="deleteAnswerOptions(index)"
                                        />

                                        <i
                                            v-if="index === question.options.length-1"
                                            class="fa fa-plus-circle"
                                            @click="addAnswerOptions"
                                        ></i>
                                    </div>
                                </div>

                                <div class="max">
                                    <div
                                        v-if="question.type.label === 'Rating'"
                                        class="scale-value"
                                    >
                                        <label
                                            class="disposer-app-label"
                                        >
                                            Max value of scale
                                            <vue-numeric-input
                                                v-model="question.max_scale"
                                                :min="1"
                                            />
                                        </label>
                                        <div class="table-survey__dropdown-section">
                                            <label class="disposer-app-checkbox">
                                                <input
                                                    v-model="question.is_rate_unique"
                                                    type="checkbox"
                                                />
                                                <div class="box"></div>
                                                Value can be used only once
                                            </label>
                                        </div>
                                    </div>

                                    <div
                                        v-if="question.type.label === 'Multiple choice'"
                                        class="responses-number"
                                    >
                                        <label class="disposer-app-label">
                                            Max number of responses
                                            <vue-numeric-input
                                                v-model="question.max_response"
                                                :min="1"
                                                :max="question.options.length"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <template v-if="question.type.id === 4 && isCurrentUserCreator">
                                <question-reset
                                    @reset-question="resetQuestion"
                                />
                            </template>
                        </div>
                        <template v-else-if="question.type.id === 5">
                            <label class="disposer-app-label">
                                Question name
                                <input
                                    v-model="question.name"
                                    type="text"
                                    class="disposer-app-input title-input"
                                />
                                <span
                                    v-if="$v.question.name.$dirty && !$v.question.name.required"
                                    class="app-input-error"
                                >
                                    Enter question
                                </span>
                            </label>
                            <div
                                v-for="testQuestion in testQuestions"
                                :key="testQuestion.id"
                                class="row test"
                            >
                                <div class="question-wrapper">
                                    <div class="question">
                                        <label class="disposer-app-label">
                                            Question
                                            <input
                                                v-model="testQuestion.name"
                                                type="text"
                                                class="disposer-app-input title-input"
                                            />
                                        </label>
                                        <img
                                            src="@/assets/img/icons/disposer/delete-icon.png"
                                            @click="deleteTestQuestion(testQuestion.id)"
                                        />
                                    </div>

                                    <div class="answer-options">
                                        <h5>Answer Options</h5>
                                        <div
                                            v-for="(option, index) of testQuestion.options"
                                            :key="index"
                                            class="answer-options-input-icon"
                                        >
                                            <div class="answer-options-input">
                                                <label class="disposer-app-label">
                                                    <input
                                                        v-model="option.name"
                                                        type="text"
                                                        class="disposer-app-input title-input"
                                                    />
                                                </label>
                                            </div>

                                            <img
                                                v-if="testQuestion.options.length !== 1"
                                                src="@/assets/img/icons/disposer/delete-icon.png"
                                                @click="deleteTestAnswerOptions(testQuestion.id, index)"
                                            />

                                            <i
                                                v-if="index === testQuestion.options.length-1"
                                                class="fa fa-plus-circle"
                                                @click="addTestAnswerOptions(testQuestion.id)"
                                            ></i>
                                        </div>
                                    </div>

                                    <div class="answer-options">
                                        <h5>
                                            Is the answer correct
                                        </h5>

                                        <div
                                            v-for="(option, index) of testQuestion.options"
                                            :key="index"
                                            class="answer-options-checkbox"
                                        >
                                            <label class="disposer-app-checkbox">
                                                <input
                                                    v-model="option.is_answer"
                                                    type="checkbox"
                                                />
                                                <div class="box"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <question-reset
                                    v-if="isCurrentUserCreator"
                                    :question-id="testQuestion.id"
                                    @reset-question="resetQuestion"
                                />
                            </div>
                        </template>
                        <table-survey
                            v-else
                            ref="tableSurvey"
                            :survey="question"
                            @updateTopicInfo="$emit('updateTopicInfo')"
                        />
                        <div
                            v-if="question.type.id === 5"
                            class="btns"
                        >
                            <div class="btn">
                                <app-button @click="addQuestion">
                                    Add question
                                </app-button>
                            </div>
                        </div>
                        <app-dates-row
                            :start-date-error="questionErrors && questionErrors.start_date"
                            :end-date-error="questionErrors && questionErrors.end_date"
                        >
                            <template v-slot:startDate>
                                <app-date-picker
                                    v-model="question.startDate"
                                    title="Start date"
                                    mode="date"
                                    :required-error="$v.question.startDate.$dirty && !$v.question.startDate.required"
                                />
                                <app-date-picker
                                    v-model="question.startTime"
                                    title="Start time"
                                    mode="time"
                                    :required-error="$v.question.startTime.$dirty && !$v.question.startTime.required"
                                />
                            </template>
                            <template v-slot:endDate>
                                <app-date-picker
                                    v-model="question.endDate"
                                    title="End date"
                                    mode="date"
                                    :required-error="$v.question.endDate.$dirty && !$v.question.endDate.required"
                                />
                                <app-date-picker
                                    v-model="question.endTime"
                                    title="End time"
                                    mode="time"
                                    :required-error="$v.question.endTime.$dirty && !$v.question.endTime.required"
                                />
                            </template>
                        </app-dates-row>
                    </div>
                    <div
                        v-if="question.type.id !== 4 && isCurrentUserCreator"
                        class="checkboxes-wrap"
                    >
                        <question-reset
                            @reset-question="resetQuestion"
                        />
                        <div class="checkboxes-list">
                            <div class="checkbox">
                                <label class="disposer-app-checkbox">
                                    <input
                                        v-model="question.need_comment"
                                        type="checkbox"
                                        name="updateMethod"
                                    />
                                    <div class="box"></div>
                                    Need comment
                                </label>
                            </div>
                            <div
                                v-if="question.type.id !== 4"
                                class="checkbox"
                            >
                                <label class="disposer-app-checkbox">
                                    <input
                                        v-model="question.show_result"
                                        type="checkbox"
                                        name="updateMethod"
                                    />
                                    <div class="box"></div>
                                    Show result in participant dashboard
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="btns">
                        <div class="btn">
                            <app-button
                                class="red"
                                @click="saveSurvey"
                            >
                                Save
                            </app-button>
                        </div>

                        <div class="btn">
                            <app-button @click="deleteSurvey(question.id)">
                                Delete
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appSelect from '@/components/disposer/app/app-select';
import appButton from '@/components/disposer/app/app-button';
import appDatePicker from '@/components/disposer/app/app-date-picker';
import appDatesRow from '@/components/disposer/app/app-dates-row';
import tableSurvey from '@/components/disposer/pages/topic/table-survey';
import questionReset from './question-reset';
import VueNumericInput from 'vue-numeric-input';

import { uniteTime } from '@/utils/disposer/time-format-helper';
import {
    createdSurvey,
    updatedSurvey,
    deletedSurvey,
    resetQuestion,
} from '@/utils/disposer/notifications-text-helper';
import { post } from '@/utils/http-helper';
import { required } from 'vuelidate/lib/validators';

const CREATE_SURVEY = '/topic/create-survey';
const DELETE_SURVEY = '/topic/delete-survey';
const UPDATE_SURVEY = '/topic/update-survey';
const RESET_QUESTION = '/topic/revert-survey';

const questionTemplate = (id) => ({
    id,
    name: '',
    options: [{
        name: '',
        is_answer: 0,
    }],
});

export default {
    name: 'app-topic-format',

    components: {
        appSelect,
        appButton,
        appDatePicker,
        VueNumericInput,
        appDatesRow,
        tableSurvey,
        questionReset,
    },

    props: {
        topicId: {
            type: Number,
            default: null,
        },
        questionId: {
            type: Number,
            default: null,
        },
        survey: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        question: {
            name: '',
            options: [{
                name: '',
            }],
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            need_comment: false,
            show_result: false,
            type: null,
            max_response: 1,
            max_scale: 1,
            is_rate_unique: false,
        },
        testQuestions: [{ ...questionTemplate(Math.random()) }],

        questionErrors: [],
        typeQuestion: [
            {
                label: 'Single choice',
                id: 1,
            },
            {
                label: 'Multiple choice',
                id: 2,
            },
            {
                label: 'Rating',
                id: 3,
            },
            {
                label: 'Comment to each option',
                id: 4,
            },
            {
                label: 'Test',
                id: 5,
            },
            {
                label: 'Table',
                id: 6,
            },
        ],
    }),

    computed: {
        topic() {
            return this.$store.getters['disposerTopics/getTopic'];
        },

        isCurrentUserCreator() {
            return !!this.topic.is_creator;
        },
    },

    mounted() {
        this.setInfo();
    },

    validations: {
        question: {
            name: {
                required,
            },
            startDate: {
                required,
            },
            startTime: {
                required,
            },
            endDate: {
                required,
            },
            endTime: {
                required,
            },
        },
    },

    methods: {
        setInfo() {
            if (this.survey.id >= 1) {
                this.question = { ...this.survey };
                this.question.type = this.typeQuestion.find(q => q.id === this.survey.type);
                this.survey.options?.length ? this.question.options = this.survey.options : null;
                this.question.startDate = new Date(this.survey.start_date);
                this.question.startTime = new Date(this.survey.start_date);
                this.question.endDate = new Date(this.survey.end_date);
                this.question.endTime = new Date(this.survey.end_date);
            } else {
                this.question.startDate = new Date(this.topic.start_date);
                this.question.startTime = new Date(this.topic.start_date);
                this.question.endDate = new Date(this.topic.end_date);
                this.question.endTime = new Date(this.topic.end_date);
                this.question.id = this.survey.id;
            }

            if (this.survey.type === 5) {
                this.testQuestions = this.survey.questions;
            }
        },

        resetQuestion(resetInfo) {
            const data = {
                topic_id: this.topicId,
                survey_id: this.survey.id,
            };
            if (resetInfo.allUsers) {
                data.user_id = '';
            } else {
                data.user_id = resetInfo.user.id;
            }
            if (resetInfo.questionId) {
                data.question_id = resetInfo.questionId;
            }
            this.$awn.async(post(RESET_QUESTION, data), response => {
                if (response.status) {
                    this.$awn.success(resetQuestion);
                }
            });
        },

        addAnswerOptions() {
            this.question.options.push({
                name: '',
            });
            this.question.max_response = this.question.options.length;
        },

        deleteAnswerOptions(index) {
            this.question.options.splice(index, 1);
            this.question.max_response = this.question.options.length;
        },

        addTestAnswerOptions(id) {
            const question = this.testQuestions.find(q => q.id === id);
            question.options.push(questionTemplate(Math.random()));
        },

        deleteTestAnswerOptions(id, index) {
            const question = this.testQuestions.find(q => q.id === id);
            question.options = question.options.filter((o, idx) => idx !== index);
        },

        addQuestion() {
            this.testQuestions.push(questionTemplate(Math.random()));
        },

        deleteTestQuestion(id) {
            this.testQuestions = this.testQuestions.filter(q => q.id !== id);
        },

        async saveSurvey() {
            if (this.question.type.id === 6) {
                this.questionErrors = await this.$refs.tableSurvey.submit({
                    start_date: uniteTime(this.question.startDate, this.question.startTime),
                    end_date: uniteTime(this.question.endDate, this.question.endTime),
                    topic_id: this.topicId,
                    question_id: this.questionId,
                    type: 6,
                });
                return;
            }
            if (this.question.type.id === 5) {
                this.saveTest();
                return;
            }
            if (this.$v.question.$invalid) {
                this.$v.question.$touch();
                return;
            }
            const survey = {
                topic_id: this.topicId,
                question_id: this.questionId,
                name: this.question.name,
                type: this.question.type.id,
                start_date: uniteTime(this.question.startDate, this.question.startTime),
                end_date: uniteTime(this.question.endDate, this.question.endTime),
                need_comment: +this.question.need_comment,
                show_result: +this.question.show_result,
                max_response: +this.question.max_response,
                max_scale: +this.question.max_scale,
                options: [],
                is_rate_unique: this.question.is_rate_unique ? 1 : 0,
            };

            if (this.question.id > 1) {
                survey.id = this.question.id;
                survey.options = this.question.options;
                this.$awn.async(post(UPDATE_SURVEY, survey), response => {
                    if (response.status) {
                        this.questionErrors = [];
                        this.$awn.success(updatedSurvey);
                    } else {
                        this.questionErrors = response.errors;
                    }
                });

            } else {
                this.question.options.forEach(option => {
                    option.name ? survey.options.push(option.name) : null;
                });

                this.$awn.async(post(CREATE_SURVEY, survey), response => {
                    if (response.status) {
                        this.questionErrors = [];
                        this.$emit('updateTopicInfo');
                        this.$awn.success(createdSurvey);
                    } else {
                        this.questionErrors = response.errors;
                    }
                });
            }
        },
        deleteSurvey(id) {
            if (id >= 1) {
                this.$awn.async(post(DELETE_SURVEY, {
                    id: id,
                    topic_id: this.topicId,
                }), response => {
                    if (response.status) {
                        this.$emit('deleteSurvey', id);
                        this.$awn.success(deletedSurvey);
                    }
                });
            } else {
                this.$emit('deleteSurvey', id);
            }
        },
        saveTest() {
            if (this.$v.question.$invalid) {
                this.$v.question.$touch();
                return;
            }

            if (!this.testQuestions.every(q => q.options.some(o => o.is_answer))) {
                this.$awn.alert('Each question must have at least one answer option');
                return;
            }

            const test = {
                topic_id: this.topicId,
                question_id: this.questionId,
                name: this.question.name,
                type: this.question.type.id,
                start_date: uniteTime(this.question.startDate, this.question.startTime),
                end_date: uniteTime(this.question.endDate, this.question.endTime),
            };
            test.questions = this.testQuestions.map(q => {
                if (q.id < 1) {
                    delete q.id;
                }
                q.options = q.options.map(o => {
                    if (o.id < 1) {
                        delete o.id;
                    }
                    return o;
                });
                return q;
            });

            test.questions.forEach(q => {
                q.options.forEach(o => o.is_answer = +o.is_answer);
            });
            if (this.question.id > 1) {
                test.id = this.question.id;
                test.options = this.question.options;
                this.$awn.async(post(UPDATE_SURVEY, test), response => {
                    if (response.status) {
                        this.questionErrors = [];
                        this.$awn.success(updatedSurvey);
                    } else {
                        this.questionErrors = response.errors;
                    }
                });
            } else {
                this.$awn.async(post(CREATE_SURVEY, test), response => {
                    if (response.status) {
                        this.questionErrors = [];
                        this.$emit('updateTopicInfo');
                        this.$awn.success(createdSurvey);
                    } else {
                        this.questionErrors = response.errors;
                    }
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.question-row {
    border-bottom: 1px solid transparent;
}

.app-dates-row {
    margin-top: 1rem;
}

.disposer-app-topic-format {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    margin-bottom: 10px;
    padding: 20px;

    background-color: #fdfcfc;

    .header {
        margin-bottom: 1rem;
    }

    .fa-plus-circle {
        margin-top: 1rem;
        margin-left: 0.6rem;

        font-size: 1.2rem;

        cursor: pointer;
    }

    .test {
        .question {
            display: flex;
            align-items: center;

            img {
                margin-top: 0.5rem;
                margin-left: 1rem;

                cursor: pointer;
            }
        }
    }

    .content-multi {
        display: flex;
        justify-content: space-between;

        .inputs {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            width: 1200px;
            max-width: 76%;

            .app-button {
                width: 7.25rem;
                margin-bottom: 1rem;
                padding: 0;
            }

            .row {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                width: 100%;
                max-width: 1200px;
                padding-top: 20px;

                .question-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    width: 900px;
                }

                &.test {
                    width: 100%;
                    max-width: 1200px;
                }

                &.row-date-time {
                    & > div {
                        position: relative;

                        display: flex;
                        justify-content: space-between;

                        width: 45.5%;

                        .disposer-app-label {
                            width: to_rem(150px);
                        }
                    }
                }


                .max {
                    width: 200px;

                    label {
                        width: 200px;
                    }

                    .scale-value,
                    .responses-number {
                        width: 40px;
                    }
                }

                .answer-options {
                    width: 305px;

                    .answer-options-checkbox{
                        height: 2.1875rem;
                        margin-top: 1rem;

                        .disposer-app-checkbox{
                            margin-top: 0;
                        }
                    }
                    .answer-options-input-icon {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }

                    .disposer-app-label {
                        margin-bottom: 0;
                    }

                    img {
                        width: 15px;
                        height: 15px;
                        margin-top: 1rem;
                        margin-left: 10px;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .disposer-app-checkbox {
                        margin-top: 1.5rem;

                        &:not(:last-of-type) {
                            margin-bottom: 1.95rem;
                        }
                    }
                }

                .question,
                .answer-options {
                    width: 260px;
                }

                .start-date,
                .start-time,
                .end-date,
                .end-time {
                    width: 150px;
                }
            }
        }

        .vue-numeric-input {
            box-sizing: border-box;

            height: 100%;
            margin-top: 1rem;

            .numeric-input {
                height: 100%;
                padding-left: 2.8rem;
            }

            .btn {
                width: 1.9rem;
            }
        }
    }

    .reset {
        &::v-deep {
            min-width: 220px;
            .app-select {
                margin-top: 1rem;
                margin-bottom: .5rem;
            }

            .reset__checkbox {
                height: 24px;
                margin-bottom: .5rem;
            }

            .app-button {
                height: 30px;
                margin-bottom: 2rem;
            }
        }
    }

    .checkboxes-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 250px;
        padding-top: 20px;
        padding-left: 20px;

        .checkboxes-list {
            margin-top: auto;
        }

        .checkbox {
            width: 220px;
            height: 30px;
            margin-bottom: 20px;
        }
    }

    .btns {
        display: flex;
        justify-content: space-between;

        width: 220px;
        margin-top: 20px;

        .btn {
            .app-button {
                width: to_rem(100px);
                height: 30px;
            }
        }
    }
}
</style>
