<template>
    <div class="loader-line"></div>
</template>

<script>
export default {
    name: 'line-loader',
};
</script>

<style scoped lang="scss">
.loader-line {
    position: relative;

    width: 100px;
    height: 3px;
    overflow: hidden;

    background-color: #ddd;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.loader-line:before {
    content: "";
    position: absolute;
    left: -50%;

    width: 40%;
    height: 3px;

    background-color: #d4a0f2;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;

        width: 80%;
    }
    100% {
        left: 100%;
        
        width: 100%;
    }
}
</style>
