<template>
    <div class="app-audios-gallery">
        <div
            v-for="audio of audiosArr"
            :key="audio.id"
            class="audio-wrapper"
        >
            <div class="title">
                <audioIcon />
                {{ audio.name }}
            </div>
            <img
                v-if="audio.preview"
                class="preview"
                :src="generateFileUrl(audio.preview)"
            />
            <audio
                class="audio-player"
                :src="generateFileUrl(audio.file)"
                controls
                @playing="sendAnalytic(4, audio.id)"
            >
                Your browser does not support the audio element.
            </audio>
            <p>
                {{ audio.description }}
            </p>
        </div>
    </div>
</template>

<script>
import audioIcon from '@/assets/img/icons/audio_icon.svg?inline';

import { generateFileUrl } from '@/utils/http-helper';
import { sendClick } from '@/utils/user/clicks-analytic';

export default {
    name: 'app-audios-gallery',

    components: {
        audioIcon,
    },

    props: {
        audios: {
            type: Array,
            default: null,
        },
    },

    data: () => ({
        audiosArr: [],
        activeaudio: null,
    }),

    computed: {
        topicId() {
            if (this.$route.name === 'dashboard') {
                return this.$store.getters['dashboard/topicId'];
            } else {
                return null;
            }
        },
        partId() {
            if (this.$route.name === 'dashboard') {
                return 5;
            } else if (this.$route.name === 'e-library') {
                return 3;
            } else if (this.$route.name === 'company') {
                return 1;
            } else {
                return null;
            }
        },
    },

    mounted() {
        this.audiosArr = this.audios?.map((audio) => {
            audio.played = false;
            return audio;
        });
    },

    methods: {
        generateFileUrl(file) {
            return generateFileUrl(file);
        },

        sendAnalytic(type, id) {
            sendClick(this.partId, type, id, this.topicId);
        },
    },
};
</script>

<style scoped lang="scss">
.app-audios-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 1rem 1.75rem;

    font-size: to_rem(16px);
    color: $copyGray;

    background: white;

    &.column {
        flex-direction: column;

        padding: 0.5rem;

        .audio-wrapper {
            width: 100%;
        }
    }

    .audio-wrapper {
        width: 49%;

        &:not(:last-of-type) {
            margin-bottom: 1.4rem;
        }
    }

    img, audio {
        display: block;

        width: 100%;
    }

    .audio-player {
        margin-top: 10px;
    }

    .preview {

        height: auto;

        object-fit: contain;

        background: black;
    }


    .title {
        display: flex;
        align-items: center;
        word-break: break-word;

        margin-bottom: 1.3rem;

        color: var(--platform-accent-color);

        svg {
            width: 2rem;
            min-width: 31px;
            margin-right: 0.4rem;
        }
    }

    svg {
        path:not([data-fill="no-fill"]) {
            fill: var(--platform-accent-color);
        }
    }

    p {
        margin-top: 1.1rem;
        padding-left: 0.2rem;
    }
}

@include mobile_or_P {
    .app-audios-gallery {
        padding: 1.85rem 1.3rem;

        p {
            margin-top: 0.6rem;

            line-height: 1.25;
        }

        .audio {
            margin-bottom: 1rem;

            & > img {
                width: 100%;
            }
        }
    }
}

@include razr767 {
    .app-audios-gallery {
        flex-direction: column;

        padding: 1.45rem 1.9rem;

        .audio-wrapper {
            width: 100%;
        }

        .audio {
            width: 100%;
            max-height: 13rem;
            margin-bottom: 1.5rem;
            object-fit: contain;

            audio {
                max-height: 13rem;
            }
        }

        .title {
            font-size: 14px;

            img {
                width: 2.8rem;
                margin-right: 0.5rem;
            }
        }

        p {
            margin-top: 0.4rem;
            padding-left: 0;

            font-size: 12px;
            line-height: 1.2;
        }
    }
}
</style>
