<template>
    <div class="guidance__videos row">
        <div
            v-if="guidanceVideos.overview"
            class="card"
        >
            <div class="title">
                {{ $t('guid_overview', 'Virtual Platform Overview') }} - {{ $t('video', 'video') }}
            </div>
            <div
                class="video"
                @click.stop="openVideo(guidanceVideos.overview)"
            >
                <video
                    :src="generateFileUrl(guidanceVideos.overview.src)"
                    @click.prevent
                ></video>
                <img
                    v-if="guidanceVideos.overview.poster"
                    class="preview"
                    :src="generateFileUrl(guidanceVideos.overview.poster)"
                />
                <div class="play">
                    <YouTubeIcon />
                </div>
            </div>
        </div>

        <div
            v-if="guidanceVideos.dashboard"
            class="card"
        >
            <div class="title">
                {{ $t('guid_dashboard', 'Virtual Platform Dashboard') }} - {{ $t('video', 'video') }}
            </div>
            <div
                class="video"
                @click.stop="openVideo(guidanceVideos.dashboard)"
            >
                <video
                    :src="generateFileUrl(guidanceVideos.dashboard.src)"
                    @click.prevent
                ></video>
                <img
                    v-if="guidanceVideos.dashboard.poster"
                    class="preview"
                    :src="generateFileUrl(guidanceVideos.dashboard.poster)"
                />
                <div class="play">
                    <YouTubeIcon />
                </div>
            </div>
        </div>

        <div
            v-if="guidanceVideos.videocall"
            class="card"
        >
            <div class="title">
                {{ $t('guid_videocall', 'Video Call') }} - {{ $t('video', 'video') }}
            </div>
            <div
                class="video"
                @click.stop="openVideo(guidanceVideos.videocall)"
            >
                <video
                    :src="generateFileUrl(guidanceVideos.videocall.src)"
                    @click.prevent
                ></video>
                <img
                    v-if="guidanceVideos.videocall.poster"
                    class="preview"
                    :src="generateFileUrl(guidanceVideos.videocall.poster)"
                />
                <div class="play">
                    <YouTubeIcon />
                </div>
            </div>
        </div>

        <div
            v-if="guidanceVideos.review"
            class="card"
        >
            <div class="title">
                {{ $t('guid_review', 'Document Review') }} - {{ $t('video', 'video') }}
            </div>
            <div
                class="video"
                @click.stop="openVideo(guidanceVideos.review)"
            >
                <video
                    :src="generateFileUrl(guidanceVideos.review.src)"
                    @click.prevent
                ></video>
                <img
                    v-if="guidanceVideos.review.poster"
                    class="preview"
                    :src="generateFileUrl(guidanceVideos.review.poster)"
                />
                <div class="play">
                    <YouTubeIcon />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import YouTubeIcon from '@/assets/img/icons/youTube_icon.svg?inline';

import { generateFileUrlBase } from '@/utils/http-helper';

export default {
    name: 'guidance-videos',

    components: {
        YouTubeIcon,
    },

    props: {
        guidanceVideos: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        guidance() {
            return this.$store.getters.getGuidance;
        },

        guidanceDocs() {
            return this.guidance.docs;
        },
    },

    created() {
        !this.guidance ? this.$store.dispatch('getGuidance') : null;
    },

    methods: {
        generateFileUrl(file) {
            return generateFileUrlBase(file);
        },

        openVideo(video) {
            const data = {
                file: generateFileUrlBase(video.src),
                secured: 1,
                preview: generateFileUrlBase(video.poster),
                noPathGenerate: 1,
            };
            this.$emit('openVideo', data);
        },
    },
};
</script>

<style scoped lang="scss">

.card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;

    width: 13rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 0.5rem 0;

    text-decoration: none;

    border: 1px solid $gray;

    &:hover {
        svg {
            transform: scale(1.1);
        }
    }

    svg {
        height: 3.8rem;
        margin-bottom: 1.9rem;

        transition: transform .3s;

        .cls-1 {
            stroke: var(--platform-main-color);
        }
        .cls-1, .st1, .cls-3 {
            fill: var(--platform-main-color);
        }
        .cls-2 {
            fill: white;
        }
    }

    p {
        width: 100%;

        font-size: to_rem(14px);
        text-align: center;
        color: #000;
    }

    .video {
        position: relative;

        display: block;

        width: 100%;
        height: 8rem;

        cursor: pointer;

        video {
            position: relative;
            z-index: 0;

            width: 100%;
            height: 100%;
            object-fit: contain;

            background: black;
        }

        .preview {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 10;

            width: 100%;
            height: 100%;

            object-fit: contain;

            background: black;
        }

        .play {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;

            width: 4rem;

            transform: translate(-50%, -50%);

            svg {
                width: 100%;
                margin-bottom: 0;

                fill: var(--platform-accent-color);
            }
        }
    }
}

</style>
