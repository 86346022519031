<template>
    <div class="disposer-app-upload-audio">
        <div class="upload-header">
            <div class="upload-current-file">
                <span
                    v-if="$v.audio.file.$dirty && !$v.audio.file.required"
                    class="file-error app-input-error"
                >
                    Choose a file
                </span>
                <span
                    v-if="errors.file"
                    class="file-error app-input-error"
                >
                    {{ errors.file[0] }}
                </span>
            </div>
        </div>

        <div class="upload-header">
            <label
                class="disposer-app-label"
            >
                Sorting order &nbsp;
                <vue-numeric-input
                    v-model="audio.number"
                    :min="0"
                />
            </label>
        </div>

        <div class="upload-main">
            <div class="choose-audio-cover">
                Choose audio cover
            </div>

            <div class="audio-cover-wrap">
                <img
                    v-if="audio.coverUrl || preview"
                    class="audio-cover"
                    :src="audio.coverUrl || preview"
                />
                <app-file-input
                    class="mini"
                    :load-file="loadCover"
                    accept="image/*"
                >
                    Choose
                </app-file-input>
            </div>
        </div>

        <div class="upload-footer">
            <div class="upload-checkboxes">
                <div>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="audio.secured"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Secured view-only format
                    </label>
                </div>
            </div>

            <div class="upload-name">
                <label
                    class="disposer-app-label"
                >
                    Name of audio
                    <a
                        v-if="audio.openLink || generateUrl(audio.file, true)"
                        target="_blank"
                        :href="audio.openLink || generateUrl(audio.file, true)"
                    >
                        <i
                            class="fa fa-external-link"
                            title="Open audio"
                        ></i>
                    </a>
                    <input
                        v-model="audio.name"
                        type="text"
                        class="disposer-app-input"
                    />
                </label>
                <label
                    class="disposer-app-label"
                >
                    Description of audio
                    <input
                        v-model="audio.description"
                        type="text"
                        class="disposer-app-input"
                    />
                </label>
            </div>

            <div class="upload-btns">
                <div class="upload-choose">
                    <div class="title">
                        Choose file
                    </div>

                    <div class="info-icons">
                        <img
                            src="@/assets/img/icons/disposer/info-icon.png"
                            class="info-icon"
                        />
                    </div>
                </div>

                <div class="row">
                    <app-file-input
                        class="upload-btn mini"
                        :load-file="loadAudio"
                        accept="audio/mpeg,audio/x-wav,application/ogg"
                    >
                        Choose
                        <div class="name">
                            {{ audio.fileName || audio.native }}
                        </div>
                    </app-file-input>
                    <app-button
                        class="upload-btn mini red"
                        @click="saveAudio"
                    >
                        Save
                    </app-button>
                    <app-button
                        class="upload-btn mini"
                        @click="deleteAudio"
                    >
                        Delete
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appButton from '@/components/disposer/app/app-button';
import appFileInput from '@/components/disposer/app/app-file-input';

import { defaultConfirmSettings } from '@/utils/disposer/notifications-text-helper';
import { post } from '@/utils/http-helper';
import { parseImg } from '@/utils/disposer/parse-files-helper';
import { required } from 'vuelidate/lib/validators';
import generateUrl from '@/utils/mixins/generate_url';

import VueNumericInput from 'vue-numeric-input';

export default {
    name: 'app-upload-audio',
    components: {
        appButton,
        appFileInput,
        VueNumericInput,
    },
    mixins: [generateUrl],
    props: {
        audio: {
            type: Object,
            default: () => {
            },
        },
        paths: {
            type: Object,
            default: () => {
            },
        },
        topicId: {
            type: Number,
            default: null,
        },
        eventId: {
            type: Number,
            default: null,
        },
        section: {
            type: Object,
            default: null,
        },
        questionId: {
            type: Number,
            default: null,
        },
        type: {
            type: Number,
            default: null,
        },
    },

    data: () => ({
        errors: {},
    }),

    computed: {
        preview() {
            return this.generateUrl(this.audio.preview, ' ');
        },
    },

    validations: {
        audio: {
            file: {
                required,
            },
        },
    },

    methods: {
        loadAudio(audio) {
            this.audio.file = audio;
            this.audio.fileName = audio.name;
            if (!this.audio.name) {
                this.audio.name = audio.name;
            }
            this.audio.openLink = window.URL.createObjectURL(audio);
        },
        async loadCover(img) {
            this.audio.cover = img;
            this.audio.coverUrl = await parseImg(img);
        },
        async saveAudio() {
            if (this.$v.audio.$invalid) {
                this.$v.audio.$touch();
                return;
            }
            const fd = new FormData();
            if (this.audio.number === undefined) {
                this.audio.number = 0;
            }
            fd.append('object', 'audio');
            fd.append('name', this.audio.name);
            fd.append('description', this.audio.description);
            fd.append('secured', +this.audio.secured);
            fd.append('file', this.audio.file);
            fd.append('preview', this.audio.cover);
            fd.append('id', this.audio.id);
            this.type ? fd.append('type', this.type) : fd.append('type', this.audio.type);
            fd.append('number', this.audio.number);
            this.topicId ? fd.append('topic_id', this.topicId) : fd.append('event_id', this.eventId);
            this.section ? fd.append('section_id', this.section.id) : null;
            this.questionId ? fd.append('question_id', this.questionId) : null;

            this.$awn.async(post(this.audio.id >= 1 ? this.paths.UPDATE_OBJECT : this.paths.SAVE_OBJECT, fd, true), response => {
                if (response.status) {
                    this.audio.id = response.data.id;
                } else {
                    this.errors = response.errors;
                }
            });

        },
        async deleteAudio() {
            this.$confirm({
                ...defaultConfirmSettings,
                callback: confirm => {
                    if (confirm) {
                        if (this.audio.id >= 1) {
                            this.$awn.async(post(this.paths.DELETE_OBJECT, {
                                topic_id: this.topicId,
                                event_id: this.eventId,
                                id: this.audio.id,
                                object: 'audio',
                            }), response => {
                                if (response.status) {
                                    this.$emit('delete', this.audio.id, this.section);
                                }
                            });
                        } else {
                            this.$emit('delete', this.audio.id, this.section);
                        }
                    }
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-app-upload-audio {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .disposer-app-input {
        width: to_rem(270px);
    }

    .upload-main {
        .choose-audio-cover {
            margin-bottom: 20px;
        }

        .audio-cover-wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            width: to_rem(440px);
            margin-bottom: 2rem;

            img {
                width: 100%;
                margin-right: 1rem;
            }

            .upload-btn {
                margin: 0;
            }

            .audio-cover {
                border: 1px solid $gray;
            }
        }
    }

    .upload-footer {
        display: flex;
        justify-content: space-between;

        .upload-checkboxes {
            margin-right: 1rem;
        }

        .upload-name {
            margin-right: 1rem;
        }
    }

    .upload-current-file {
        position: relative;

        margin-bottom: 1rem;

        color: grey;
    }

    .row {
        display: flex;
    }

    .upload-btns {
        .upload-choose {
            display: flex;

            .info-icons {
                margin-left: 10px;
            }
        }

        label {
            position: relative;
        }

        .name {
            position: absolute;
            top: 120%;
            left: 0;

            width: 250px;

            color: black;
        }
    }

    .upload-btn {
        margin-top: 0.75rem;
        margin-right: 1rem;
    }
}
</style>
