<template>
    <div
        id="app"
        :style="{
            '--platform-main-color': platformMainColor,
            '--platform-accent-color': platformAccentColor,
        }"
    >
        <appLoader v-if="!loadedStatus" />

        <component
            :is="layout"
            v-else
        >
            <keep-alive include="dashboard">
                <router-view />
            </keep-alive>
        </component>

        <timer-warning v-if="openTimerWarning" />

        <vue-confirm-dialog />

        <plug />
    </div>
</template>

<script>
import defaultLayout from '@/components/user/layouts/default-layout';
import loginLayout from '@/components/user/layouts/login-layout';
import HomeLayout from '@/components/user/layouts/home-layout';
import stickersLayout from '@/components/user/layouts/stickers-layout';
import workshopLayout from '@/components/user/layouts/workshop-layout';
import reviewLayout from '@/components/user/layouts/review-layout';
import viewLayout from '@/components/user/layouts/view-layout';
import editorLayout from '@/components/user/layouts/editor-layout';
import errorLayout from '@/components/user/layouts/error-layout';
import timerWarning from '@/components/user/layouts/timer-warning';
import plug from '@/components/user/plug/';
import appLoader from '@/components/user/app/app-loader';

import disposerDefaultLayout from '@/components/disposer/layouts/default-layout';

import generateUrl from '@/utils/mixins/generate_url';

export default {
    components: {
        defaultLayout,
        loginLayout,
        stickersLayout,
        workshopLayout,
        reviewLayout,
        viewLayout,
        editorLayout,
        errorLayout,
        disposerDefaultLayout,
        timerWarning,
        plug,
        appLoader,
        HomeLayout,
    },
    mixins: [generateUrl],
    data: () => ({
        newFavicon: '',
    }),
    computed: {
        layout() {
            return this.$route.meta.layout || 'default-layout';
        },

        openTimerWarning() {
            return this.$store.getters['auth/openTimerWarning'];
        },

        platformMainColor() {
            return this.$store.getters['platform/platformMainColor'] || '#0f9bdc';
        },

        platformAccentColor() {
            return this.$store.getters['platform/platformAccentColor'] || '#0f9bdc';
        },

        loadedStatus() {
            return this.$store.getters['auth/loadedStatus'];
        },

        platformFavicon(){
            return this.$store.getters['platform/platformFavicon'];
        },
        platformTitle(){
            return this.$store.getters['platform/platformTitle'];
        },
    },
    watch: {
        platformFavicon(){
            this.newFavicon = this.platformFavicon;
            let favicon = document.querySelector(`head link[rel="icon"]`);
            if (favicon != null) favicon.setAttribute('href', this.generateUrl(this.newFavicon, ''));
        },
        platformTitle(){
            if (this.platformTitle){
                document.title = this.platformTitle;
            }
        },
    },
};
</script>

<style scoped lang="scss">
#app {
    display: flex;
    flex-direction: column;

    min-height: 100vh;

    background: $mainBackgroundColor;
}
</style>
