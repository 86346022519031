<template>
    <div class="disposer-agenda">
        <eventNav />
        <div class="disposer-app-title">
            Agenda
        </div>

        <app-section>
            <div class="disposer-app-topic-title">Custom title:</div>
            <div class="agenda-title">
                <span v-if="!isEditRepresentativeTitle">
                    {{ agenda && agenda.customize.objectives }}
                </span>
                <input
                    v-else
                    v-model="agenda.customize.objectives"
                    class="disposer-app-input agenda-title-edit"
                    type="text"
                />
                <app-actions
                    :edit="toggleEditRepresentativeTitle"
                    :back="removeRepresentativeTitle"
                    :save="saveRepresentativeTitle"
                />
            </div>
        </app-section>

        <app-section :save="saveObjectives">
            <div class="disposer-app-topic-title">
                Objectives
            </div>
            <app-editor
                v-model="objectives"
                class="section-editor"
            />
        </app-section>

        <app-section>
            <div class="disposer-app-topic-title">
                Upload Documents
            </div>
            <app-upload-documents
                v-for="document in documents"
                :key="document.id"
                :document="document"
                :event-id="eventId"
                :paths="$options.objectActionsPaths"
                @delete="deleteDocument"
            />
            <app-button
                class="narrow"
                @click="addDocument"
            >
                Add document
            </app-button>
        </app-section>

        <div class="agenda-moderator">
            <div class="disposer-app-topic-title">
                Moderator
            </div>
            <div class="row">
                <div class="col external">
                    <p>
                        External
                    </p>
                    <label
                        v-for="moderator in externalModerators"
                        :key="moderator.id"
                        class="disposer-app-checkbox"
                    >
                        <input
                            v-model="moderator.status"
                            type="checkbox"
                            @change="changeModeratorStatus(moderator, 6)"
                        />
                        <div class="box"></div>
                        {{ moderator.name }}
                    </label>
                </div>
                <div class="col internal">
                    <p>
                        Internal
                    </p>
                    <label
                        v-for="moderator in internalModerators"
                        :key="moderator.id"
                        class="disposer-app-checkbox"
                    >
                        <input
                            v-model="moderator.status"
                            type="checkbox"
                            @change="changeModeratorStatus(moderator, 5)"
                        />
                        <div class="box"></div>
                        {{ moderator.name }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import eventNav from '@/components/disposer/pages/event/event-nav.vue';
    import appSection from '@/components/disposer/app/app-section';
    import appEditor from '@/components/disposer/app/app-editor';
    import appButton from '@/components/disposer/app/app-button';
    import appActions from '@/components/disposer/app/app-actions';
    import appUploadDocuments from '@/components/disposer/app/app-upload-documents';

    import { post } from '@/utils/http-helper';
    import { saveAgendaTitle, saveObjectives, changedParticipantStatus } from '@/utils/disposer/notifications-text-helper';
    import { DOCUMENT_TEMPLATE } from '@/utils/disposer/objects-templates';

    const defaultRepresentativeTitle = 'Agenda';

    const UPDATE_OBJECTIVES = '/agenda/update-field';
    const UPDATE_MODERATOR = '/agenda/update-moderator';

    export default {
        name: 'disposer-agenda',

        components: {
            eventNav,
            appSection,
            appEditor,
            appUploadDocuments,
            appButton,
            appActions,
        },

        objectActionsPaths: {
            SAVE_OBJECT: '/agenda/create-object',
            UPDATE_OBJECT: '/agenda/update-object',
            DELETE_OBJECT: '/agenda/delete-object',
        },

        data: () => ({
            objectives: '',
            documents: [],
            isEditRepresentativeTitle: false,
        }),

        computed: {
            agenda() {
                return this.$store.getters['disposerAgenda/get'];
            },
            eventId() {
                return +this.$route.params.id;
            },
            internalModerators() {
                return this.agenda?.moderators?.internal;
            },
            externalModerators() {
                return this.agenda?.moderators?.external;
            },
        },

        async created() {
            await this.$store.dispatch('disposerAgenda/get', this.eventId);
            !this.agenda.customize.objectives ? this.agenda.customize.objectives = defaultRepresentativeTitle : '';
            this.objectives = this.agenda.objectives;
            this.documents = this.agenda.documents;
        },

        methods: {
            toggleEditRepresentativeTitle() {
                this.isEditRepresentativeTitle = !this.isEditRepresentativeTitle;
            },
            removeRepresentativeTitle() {
                this.agenda.customize.objectives = defaultRepresentativeTitle;
                this.saveRepresentativeTitle();
            },
            saveRepresentativeTitle() {
                const fd = new FormData();
                fd.append('platform_id', this.platform_id);
                fd.append('event_id', this.eventId);
                fd.append('field', 'objectives');
                fd.append('value', this.agenda.customize.objectives);
                this.$awn.async(post('interface/update', fd, true), response => {
                    if (response.status) {
                        this.$awn.success(saveAgendaTitle);
                    }
                });
                this.isEditRepresentativeTitle = false;
            },
            async saveObjectives() {
                this.$awn.async(post(UPDATE_OBJECTIVES, {
                  event_id: this.eventId,
                  field: 'objectives',
                  value: this.objectives,
                }), response => {
                  if (response.status) {
                    this.$awn.success(saveObjectives);
                  }
                });
            },
            addDocument() {
                this.documents.push(DOCUMENT_TEMPLATE(Math.random()));
            },
            deleteDocument(id) {
                this.documents = this.documents.filter(d => d.id !== id);
            },
            async changeModeratorStatus(moderator, type) {
              this.$awn.async(post(UPDATE_MODERATOR, {
                event_id: this.eventId,
                type: type,
                user_id: moderator.id,
                status: +moderator.status,
              }), response => {
                if (response.status) {
                  this.$awn.success(changedParticipantStatus);
                }
              });
            },
        },
    };
</script>

<style scoped lang="scss">
    .disposer-agenda {
        .agenda-moderator {
            margin-top: 45px;
        }
        .row {
            display: flex;
        }
        .col {
            margin-right: 1rem;
            p {
                margin-bottom: 1rem;
            }
            .disposer-app-checkbox {
                margin-bottom: 0.5rem;
            }
        }
    }
    .agenda-title {
        display: flex;
        align-items: center;
        .app-actions{
            margin-left: 10px;
        }
    }
    .disposer-app-input.agenda-title-edit{
        margin-top: 0;
    }
</style>
