<template>
    <div
        v-if="langs"
        class="functionality-popup"
    >
        <div class="functionality-popup__title">
            {{ $t('h_sticker_func', 'Stickers functionality') }}
        </div>
        <h3>
            {{ langs.is_s_title }}
        </h3>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/1.png" />
            </div>
            <p>
                {{ langs.is_s_p1 }}
            </p>
        </div>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/2.png" />
            </div>
            <p>
                {{ langs.is_s_p2 }}
            </p>
        </div>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/3.png" />
            </div>
            <p>
                {{ langs.is_s_p3 }}
            </p>
        </div>
        <div class="row">
            <p>
                {{ langs.is_s_p4 }}
            </p>
        </div>
        <div class="row">
            <p>
                {{ langs.is_s_p5 }}
            </p>
        </div>
        <div class="row">
            <p>
                {{ langs.is_s_p6 }}
            </p>
        </div>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/4.png" />
            </div>
            <p>
                {{ langs.is_s_p7 }}
            </p>
        </div>
        <h3>
            {{ langs.is_a_title }}
        </h3>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/5.png" />
            </div>
            <p>
                {{ langs.is_a_p1 }}
            </p>
        </div>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/6.png" />
            </div>
            <p>
                {{ langs.is_a_p2 }}
            </p>
        </div>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/7.png" />
            </div>
            <p>
                {{ langs.is_a_p3 }}
            </p>
        </div>
        <h3>
            {{ langs.is_p_title }}
        </h3>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/8.png" />
            </div>
            <p>
                {{ langs.is_p_p1 }}
            </p>
        </div>
        <div class="row">
            <div class="left flex">
                <img src="@/assets/img/stickers/9.png" />
                <img src="@/assets/img/stickers/10.png" />
            </div>
            <p>
                {{ langs.is_p_p2 }}
            </p>
        </div>
        <h3>
            {{ langs.is_m_title }}
        </h3>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/11.png" />
            </div>
            <p>
                {{ langs.is_m_p1 }}
            </p>
        </div>
        <div class="row">
            <div class="left">
                <img src="@/assets/img/stickers/12.png" />
            </div>
            <p>
                {{ langs.is_m_p2 }}
            </p>
        </div>
    </div>
</template>

<script>
    import { get } from '@/utils/http-helper';

    const GET_LANG = '/lang/get-instruction';

    export default {
    name: 'functionality-popup',

    data: () => ({
      langs: null,
    }),

    computed: {
      lang() {
        return this.$store.getters['platform/platform'].lang;
      },
    },

    async mounted() {
      const response = await get(GET_LANG, { type: 'sticker', lang: this.lang });
      this.langs = response.data.tokens;
    },
  };
</script>

<style scoped lang="scss">
    .functionality-popup {
        &__title {
            margin-bottom: 1.5rem;

            font-size: to_rem(18px);
            color: var(--platform-accent-color);
        }
        h3 {
            margin-bottom: 1rem;

            font-size: to_rem(16px);
        }
        .row {
            display: flex;

            margin-bottom: 0.5rem;
            .left {
                margin-right: 1rem;
                &.flex {
                    display: flex;
                }
            }
        }
    }
</style>
