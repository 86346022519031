<template>
    <div
        v-if="activePopup"
        class="app-popup"
        :class="{large}"
    >
        <div
            class="app-popup__container"
        >
            <div
                class="app-popup__scroll-content"
                @click.stop
            >
                <slot></slot>
            </div>
            <div
                class="app-popup__closer"
                @click.stop="closePopup"
            >
                <slot name="close-icon">
                    <i class="fa fa-times"></i>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { videoWatching } from '@/utils/user/time-socket';

export default {
    name: 'app-popup',

    directives: {
        clickOutside: vClickOutside.directive,
    },

    props: {
        name: {
            type: String,
            default: null,
        },
        large: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        timerID: null,
    }),
    computed: {
        activePopup() {
            if (this.name) {
                return this.$store.getters.activePopup === this.name;
            } else {
                return this.$store.getters.activePopup;
            }
        },
    },
    watch: {
        activePopup: function () {
            if (this.activePopup) {
                this.activityStop();
            }
        },
    },
    methods: {
        closePopup() {
            this.$emit('close');
            this.$store.dispatch('togglePopup', { popupName: null });
            this.activityRun();
        },
        activityStop() {
            this.timerID = setInterval(videoWatching, 30000);
            // console.log('activity stopped...');
        },
        activityRun() {
            clearInterval(this.timerID);
            // console.log('activity is running...');
        },
    },
};
</script>

<style scoped lang="scss">
.app-popup {
    $root: &;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999999;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: auto;

    background: rgba(0, 0, 0, .2);

    &.large {
        #{$root}__container {
            width: fit-content;
            max-width: 80vw;
        }

        #{$root}__scroll-content {
            overflow: visible;
        }
    }

    &__title {
        max-width: 97%;
    }

    &__container {
        position: relative;

        width: 48rem;
        max-height: 80vh;
        padding: 1rem 0 1rem 1rem;

        background: white;
        border: 14px solid var(--platform-main-color);
        border-radius: 0.5rem;
    }

    &__scroll-content {
        max-height: calc(80vh - 2rem - 14px);
        padding-right: 1rem;
        padding-bottom: 1rem;
        overflow: auto;
    }

    &__closer {
        position: absolute;
        top: 1rem;
        right: 2rem;

        color: $gray;

        cursor: pointer;
    }
}

.app-popup--green-life {
    .app-popup {
        &__container {
            width: 34rem;
            padding: 2rem 0 0 2rem;

            border: none;
            border-radius: to_rem(13px);
        }
    }
}

@include mobile_or_P {
    .app-popup {
        .head {
            letter-spacing: -0.03rem;
        }

        &__container {
            width: 89%;
            padding: 1.5rem 0.8rem 0.8rem;

            border-width: 10px;
        }

        &__title {
            margin-bottom: 1.3rem;

            font-size: 1.15rem;
        }

        &__message {
            margin-bottom: 0.8rem;
        }

        &__closer {
            top: 0.5rem;
            right: 0.5rem;
        }
    }

    .app-popup--green-life {
        .app-popup {
            &__container {
                padding: 3rem 0 0 2rem;
            }

            &__closer {
                position: absolute;
                top: .7rem;
                right: .7rem;
            }
        }
    }
}
</style>
