<template>
    <div
        class="app-voting"
        :class="{disabled}"
    >
        <div class="app-voting__head">
            <div class="app-voting__title">
                {{ vote.author }}: {{ vote.name }}
            </div>
            <div
                v-if="self"
                class="app-voting__actions"
            >
                <div
                    v-if="!vote.answers.total"
                    class="edit"
                >
                    <i
                        class="fa fa-pencil green"
                        @click="editSurvey"
                    ></i>
                    <i
                        class="fa fa-trash-o red"
                        @click="removeSurvey(vote)"
                    ></i>
                </div>
            </div>
        </div>
        <div
            v-if="self && !vote.is_answered"
            class="app-voting__tabs"
        >
            <div
                v-if="!vote.is_answered"
                class="app-voting__tab"
                :class="{active: !showRes}"
                @click="showResults = false"
            >
                {{ $t('voting', 'Voting') }}
            </div>
            <div
                v-if="vote.answers"
                class="app-voting__tab"
                :class="{active: showRes}"
                @click="showResults = true"
            >
                {{ $t('result', 'Results') }}
            </div>
        </div>
        <div
            v-if="!showRes"
            class="app-voting__answers"
        >
            <template v-if="type===1">
                <label
                    v-for="option in vote.options"
                    :key="option.id"
                    class="app-radio"
                >
                    <input
                        v-model="vote.value"
                        type="radio"
                        name="updateMethod"
                        :value="option.id"
                    />
                    <div class="box"></div>
                    {{ option.name }}
                </label>
            </template>
            <template v-else-if="type===2">
                <label
                    v-for="option in vote.options"
                    :key="option.id"
                    class="app-checkbox"
                >
                    <input
                        v-model="option.value"
                        type="checkbox"
                        name="updateMethod"
                    />
                    <div class="box"></div>
                    {{ option.name }}
                </label>
            </template>
            <app-button
                class="accent"
                @click="answer"
            >
                {{ $t('vote', 'Vote') }}
            </app-button>
        </div>
        <div
            v-else
            class="app-voting__results"
        >
            <div
                v-for="answer in vote.options"
                :key="answer.id"
                class="app-voting__result-row"
            >
                <div class="answer">
                    {{ answer.name }}
                </div>
                <div class="right">
                    <img
                        v-if="selfAnswer(answer)"
                        src="@/assets/img/icons/ok_icon.png"
                    />
                    {{ answersPer(answer) }}%
                </div>
                <div
                    class="progress"
                    :style="{width: `${answersPer(answer)}%`}"
                ></div>
            </div>
            <div class="count">
                {{ totalAnswers }} {{ $t('votes_many', 'Voting') }}
            </div>
        </div>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';

export default {
    name: 'app-voting',

    components: {
        appButton,
    },

    props: {
        vote: {
            type: Object,
            default: null,
        },
        type: {
            type: Number,
            default: 1,
        },
    },

    data: () => ({
        showResults: false,
    }),

    computed: {
        self() {
            return this.vote.user_id === this.$store.getters['auth/userInfo'].id;
        },

        showRes() {
            return this.vote.is_answered || this.showResults;
        },

        totalAnswers() {
            return this.vote.answers?.total || 0;
        },

        disabled() {
            const role = this.$store.getters['auth/userInfo']?.role.id;
            return role !== 6;
        },
    },

    methods: {
        answersPer(answer) {
            const value = this.vote.answers?.values?.find(v => v.id === answer.id);
            return value?.value || 0;
        },

        selfAnswer(answer) {
            const self = this.vote.answers?.self?.find(s => s.id === answer.id);
            return self;
        },

        editSurvey() {
            const survey = {
                id: this.vote.id,
                question: this.vote.name,
                options: this.vote.options.slice(),
                isMultiple: undefined,
            };
            this.$store.dispatch('togglePopup', {
                popupName: 'add-vote-popup',
                options: { survey: { ...survey } },
            });
        },

        removeSurvey(vote) {
            this.$emit('deleteSurvey', vote);
        },

        answer() {
            let options = [];
            if (this.vote.type === 1) {
                options = [{ id: this.vote.value }];
            } else {
                options = this.vote.options.reduce((newObj, o) => {
                    o.value ? newObj.push({ id: o.id }) : null;
                    return [...newObj];
                }, []);
            }
            const answer = {
                survey_id: this.vote.id,
                options: options,
            };
            this.$emit('answer', answer);
        },
    },

};
</script>

<style scoped lang="scss">
.app-voting {
    padding: .7rem;

    background: white;
    border: 5px solid $lightGray;

    &.disabled {
        .app-voting__answers {
            pointer-events: none;
        }
    }

    &.small {
        padding: 1.4rem 0.7rem;

        border: 5px solid $gray;
        border-radius: 0;

        .app-voting {
            &__title {
                font-size: to_rem(14px);
                font-weight: 700;
            }

            &__tabs {
                margin: 1.05rem 0.7rem 0 auto;

                font-size: to_rem(14px);
            }

            &__tab {
                height: 1.6rem;
                margin: 0 0rem;
                padding: 0 0.7rem;

                color: $gray;

                border-color: black;
                border-radius: 0;
            }

            &__results {
                margin-top: 1rem;
            }

            &__result-row {
                height: 1.6rem;

                font-size: to_rem(14px);
            }

            &__answers {
                label {
                    margin-bottom: 0.3rem;
                }
            }
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;

        i {
            margin: 0 0.3rem;

            cursor: pointer;
        }

        .red {
            color: $red;
        }

        .green {
            color: $green;
        }
    }

    &__title {
        max-width: 95%;
        margin-bottom: 1rem;

        font-size: to_rem(18px);
    }

    &__tabs {
        display: flex;

        width: fit-content;
        margin: 0 2rem 0 auto;

        font-size: to_rem(18px);
        color: $copyGray;

    }

    &__tab {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 2rem;
        margin: 0 .2rem;
        padding: 0 .8rem;

        border-radius: .5rem;

        cursor: pointer;

        &:hover {
            color: black;
        }

        &.active {
            color: var(--platform-accent-color);

            border: 1px solid var(--platform-accent-color);
        }
    }

    &__answers {
        label {
            min-height: 2rem;
            margin-bottom: 1rem;
        }
    }

    &__results {
        margin-top: 0.5rem;
        margin-right: 2rem;
    }

    &__result-row {
        position: relative;
        z-index: 1;

        display: flex;
        justify-content: space-between;
        align-items: center;

        min-height: 2rem;
        margin-bottom: 1rem;
        padding: 0.4rem 0;
        overflow: hidden;

        background: $gray;
        border-radius: .5rem;

        .answer {
            padding-left: .5rem;

            color: $mainTextColor;
        }

        .right {
            padding-right: .5rem;
        }

        .progress {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: -1;

            background: var(--platform-accent-color);
        }
    }

    .count {
        font-size: to_rem(13px);
        color: $copyGray;
    }

    .app-button {
        margin: 0 auto;
    }
}

@include mobile_or_P {
    .app-voting {
        padding: 1rem 0.7rem;

        .edit {
            font-size: 1.3rem;
        }

        &__head {
            i {
                margin: 0 0.4rem;
            }
        }

        &__tabs {
            margin: 0.7rem 3.1rem 0 auto;
        }

        &__result-row {
            height: 2.5rem;
        }

        &.small {
            padding: .7rem;
        }
    }
}

@include razr767 {
    .app-voting {
        padding: 1.4rem 1rem;

        border-radius: 1rem;

        &__title {
            font-size: 16px;
        }

        &__head {
            i {
                margin: 0 0 0 1rem;
            }
        }

        .edit {
            font-size: 20px;
        }

        &__tabs {
            margin: 0.5rem 0 0 auto;
        }

        &__tab {
            height: 2.3rem;
            margin: 0;
            padding: 0 1rem;

            font-size: 16px;

            &:last-of-type {
                margin-left: .5rem;
            }
        }

        &__answers {
            margin-top: 0.8rem;

            label {
                margin-bottom: 2.3rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .app-button {
                margin: 1rem 0 0 auto;
            }
        }

        &__result-row {
            height: 2.5rem;
            margin-bottom: 1.4rem;

            font-size: 16px;

            .right {
                padding-right: 1rem;
            }
        }

        &__results {
            margin-top: 1.5rem;
        }

        .count {
            font-size: 13px;
        }

        &.small {
            .app-voting {
                &__title {
                    font-size: 16px;
                }

                &__tab {
                    height: 2.3rem;
                    margin: 0;
                    padding: 0 1rem;
                }

                &__result-row {
                    height: 2.5rem;

                    font-size: 16px;
                }
            }
        }
    }
}
</style>
