<template>
    <div
        v-click-outside="toggleColorPicker.bind(null, false)"
        title="Color pen"
        class="app-color-picker"
        :style="{background: value}"
        @click="toggleColorPicker(true)"
    >
        <compact
            v-if="isOpen"
            :value="value"
            @input="updateColor"
        />
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside';
    import { Compact } from 'vue-color';

    export default {
        name: 'app-color-picker',

        components: {
            Compact,
        },

        directives: {
            clickOutside: vClickOutside.directive,
        },

        props: {
            value: {
                type: String,
                default: '',
            },
        },

        data: ()=>({
            isOpen: false,
        }),

        methods: {
            toggleColorPicker(stage){
                this.isOpen = stage;
            },

            updateColor(color){
                this.$emit('input', color.hex);
                setTimeout( () => this.isOpen = false );
            },
        },
    };
</script>

<style scoped lang="scss">

    .app-color-picker{
        position: relative;

        width: 2rem;
        height: 2rem;

        border-radius: 50%;

        cursor: pointer;
        .vc-compact{
            position: absolute;
            top: 110%;
            left: 50%;
            z-index: 99999999;

            transform: translateX(-50%);
        }
    }
</style>
