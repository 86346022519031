<template>
    <div class="reset">
        <h5>Reset for user</h5>
        <app-select
            v-model="resetInfo.user"
            class="app-select--white"
            :disabled="resetInfo.allUsers"
            :option-select="externalParticipants"
            label="name"
            searchable
        />
        <div class="reset__checkbox">
            <label class="disposer-app-checkbox">
                <input
                    v-model="resetInfo.allUsers"
                    type="checkbox"
                    name="updateMethod"
                />
                <div class="box"></div>
                For all users
            </label>
        </div>
        <app-button
            class="red"
            :data-disable="!resetInfo.user && !resetInfo.allUsers"
            @click="resetQuestion()"
        >
            Reset
        </app-button>
    </div>
</template>

<script>
import appSelect from '@/components/disposer/app/app-select';
import appButton from '@/components/disposer/app/app-button';

export default {
    name: 'question-reset',

    components: {
        appSelect,
        appButton,
    },

    props: {
        questionId: {
            type: Number,
            default: null,
        },
    },

    data: () => ({
        resetInfo: {
            allUsers: false,
            user: null,
        },
    }),

    computed: {
        externalParticipants() {
            return this.$store.getters['disposerTopics/getExternalParticipants'];
        },
    },

    methods: {
        resetQuestion() {
            if (this.questionId) {
                this.resetInfo.questionId = this.questionId;
            }
            this.$emit('reset-question', this.resetInfo);
        },
    },
};
</script>