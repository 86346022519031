<template>
    <div class="app-information">
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'app-information',
    };
</script>

<style scoped lang="scss">
    .app-information{
        padding: 1.9rem;

        background: white;

        .app-topic-title {
            margin-bottom: 1rem;
        }

        .content{
            padding: 1rem .9rem;

            font-size: to_rem(16px);
            color: $copyGray;

            border: 5px solid $lightGray;
        }

        .head{
            display: flex;
            align-items: center;

            margin-bottom: .6rem;

            font-size: to_rem(16px);
            font-weight: 700;
            color: black;
            svg {
                width: 1.7rem;
                height: 1.7rem;
                margin-right: 0.15rem;

                circle {
                    fill: var(--platform-accent-color);
                }
            }
        }
    }
    @include mobile_or_P{
        .app-information{
            padding: 0.7rem 0.4rem;
            .content{
                padding: 1rem 0.6rem;

                line-height: 1.5;
            }
            .head{
                margin-bottom: .3rem;
            }
        }
    }

    @include razr767{
        .app-information{
            .content{
                padding: 1.1rem 1rem;

                font-size: 1.015rem;
            }
            .head{
                margin-bottom: 0.8rem;

                font-size: 1.175rem;
                img{
                    width: 2.3rem;
                    height: 2.3rem;
                    margin-right: 0.35rem;
                }
            }
            p{
                line-height: 1.55;
            }
        }
    }
</style>
