import { get } from '@/utils/http-helper';

const GET = '/library/view';

export default {
  namespaced: true,
  state: {
    library: null,
  },
  actions: {
    async get({ commit }, eventId) {
      const library = await get(GET, { event_id: eventId });
      commit('updateLibrary', library.data);
    },
  },
  mutations: {
    updateLibrary(state, library) {
      state.library = library;
    },
  },
  getters: {
    get: state => state.library,
  },
};
