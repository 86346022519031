import { GP } from '@/utils/sockets-helper';

const CREATE_SURVEY = 'survey-create';
const UPDATE_SURVEY = 'survey-update';
const REMOVE_SURVEY = 'survey-remove';
const ANSWER_SURVEY = 'survey-answer';

const surveys = (NAME_SPACE) => {
    return {
        data: ()=> ({
            generateSocketPath: null,
            allAnswered: false,
        }),
        created() {
            this.generateSocketPath = new GP(NAME_SPACE);
        },
        methods: {
            subscribeSocketEvents() {
                this.socket.on(this.generateSocketPath.generate(CREATE_SURVEY), data => {
                    if (data.success) {
                        data.answers = null;
                        data.is_answered = 0;
                        this.surveys.push(data);
                        this.checkAllAnswers();
                    }
                });
                this.socket.on(this.generateSocketPath.generate(UPDATE_SURVEY), data => {
                    if (data.success) {
                        this.editedSurvey(data);
                        this.checkAllAnswers();
                    }
                });
                this.socket.on(this.generateSocketPath.generate(REMOVE_SURVEY), data => {
                    if (data.success) {
                        this.surveys = this.surveys.filter(s => s.id !== data.id);
                        this.checkAllAnswers();
                    }
                });
                this.socket.on(this.generateSocketPath.generate(ANSWER_SURVEY), data => {
                    if (data.success) {
                        const survey = this.surveys.find((s)=> s.id === data.survey_id);
                        survey.answers = { ...survey.answers, ...data.answers };
                        this.checkAllAnswers();
                    }
                });
            },
            addSurvey(survey) {
                this.surveys.push(survey);
                this.$store.dispatch('togglePopup', { popupName: null });
                this.checkAllAnswers();
            },
            deleteSurvey(survey) {
                this.socket.emit(this.generateSocketPath.generate(REMOVE_SURVEY), {
                    survey_id: survey.id,
                }, data => {
                    if (data.success) {
                        this.surveys = this.surveys.filter(s => s.id !== survey.id);
                        this.checkAllAnswers();
                    }
                });
            },
            editedSurvey(data, id) {
                const surveys = [...this.surveys];
                let idx = id;
                if (!id) {
                    idx = data.id;
                }
                const survey = surveys.find(s => s.id === idx);
                survey.name = data.name;
                survey.options = data.options;
                this.surveys = [...surveys];
                this.$store.dispatch('togglePopup', { popupName: null });
            },
            answerSurvey(answer) {
                this.socket.emit(this.generateSocketPath.generate(ANSWER_SURVEY), answer, data => {
                    if (data.success) {
                        const survey = this.surveys.find((s)=> s.id === answer.survey_id);
                        survey.is_answered = 1;
                        survey.answers = data.answers;
                        survey.answers.self = data.answers.values;
                        this.checkAllAnswers();
                    }
                });
            },
            checkAllAnswers() {
                this.allAnswered = !this.surveys.find(s => !s.is_answered);
            },
        },
    };
};

export default surveys;
