<template>
    <div
        v-if="langs"
        class="workshopFunctionality-popup"
    >
        <div class="workshopFunctionality-popup__title">
            {{ $t('h_workshop_func', 'Workshop functionality') }}
        </div>
        <template v-if="!isReview">
            <div class="workshopFunctionality-popup__row button">
                <div class="workshopFunctionality-popup__function">
                    <app-button class="small accent">
                        {{ $t('h_workshop_func', 'Workshop functionality') }}
                    </app-button>
                </div>
                <p>
                    {{ langs.iw_btn_func }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row button">
                <div class="workshopFunctionality-popup__function">
                    <app-button class="small accent">
                        {{ $t('h_workshop_close', 'Finish Workshop') }}
                    </app-button>
                </div>
                <p>
                    {{ langs.iw_btn_finish }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row button">
                <div class="workshopFunctionality-popup__function">
                    <app-button class="small accent">
                        {{ $t('h_topic_context_video', 'Join Workshop Session') }}
                    </app-button>
                </div>
                <p>
                    {{ langs.iw_join_context }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row button">
                <div class="workshopFunctionality-popup__function">
                    <app-button class="small accent">
                        {{ $t('h_topic_main_video', 'Join Main Session') }}
                    </app-button>
                </div>
                <p>
                    {{ langs.iw_join_main }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div
                    class="app-tool-btn pre transparent leave"
                >
                    <img src="@/assets/img/icons/leave_icon.png" />
                </div>
                <p>
                    {{ langs.iw_video_leave }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn accent">
                        <img src="@/assets/img/icons/tools/cursor_icon.svg" />
                    </div>
                </div>
                <p>
                    {{ langs.iw_moderator }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn accent">
                        <img src="@/assets/img/icons/tools/clear_icon.svg" />
                    </div>
                </div>
                <p>
                    {{ langs.iw_hide }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn accent">
                        <i class="fa fa-pencil"></i>
                    </div>
                </div>
                <p>
                    {{ langs.iw_pen }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row three">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn">
                        <img src="@/assets/img/icons/tools/check_icon.svg" />
                    </div>
                    <div class="app-tool-btn">
                        <img src="@/assets/img/icons/tools/cross_icon.svg" />
                    </div>
                    <div class="app-tool-btn">
                        <img src="@/assets/img/icons/tools/question_icon.svg" />
                    </div>
                </div>
                <p>
                    {{ langs.iw_labels }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn blue">
                        <img src="@/assets/img/icons/tools/arrow-top_icon.svg" />
                    </div>
                </div>
                <p>
                    {{ langs.iw_arrow }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn blue">
                        <img src="@/assets/img/icons/tools/line_icon.svg" />
                    </div>
                </div>
                <p>
                    {{ langs.iw_line }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row width">
                <div class="workshopFunctionality-popup__function">
                    <img src="@/assets/img/icons/width.jpg" />
                </div>
                <p>
                    {{ langs.iw_width }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row color">
                <div class="workshopFunctionality-popup__function">
                    <img src="@/assets/img/icons/color.png" />
                </div>
                <p>
                    {{ langs.iw_color }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn blue">
                        <i class="fa fa-undo"></i>
                    </div>
                </div>
                <p>
                    {{ langs.iw_undo }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn blue">
                        <i class="fa fa-repeat"></i>
                    </div>
                </div>
                <p>
                    {{ langs.iw_redo }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn blue">
                        <i class="fa fa-search-plus"></i>
                    </div>
                </div>
                <p>
                    {{ langs.iw_zoom_p }}
                </p>
            </div>
            <div class="workshopFunctionality-popup__row">
                <div class="workshopFunctionality-popup__function">
                    <div class="app-tool-btn blue">
                        <i class="fa fa-search-minus"></i>
                    </div>
                </div>
                <p>
                    {{ langs.iw_zoom_m }}
                </p>
            </div>
        </template>
        <h4>
            {{ langs.iw_mark_title }}
        </h4>
        <ul>
            <li>
                {{ langs.iw_mark_p1 }}
            </li>
            <li>
                {{ langs.iw_mark_p2 }}
            </li>
            <li>
                {{ langs.iw_mark_p3 }}
            </li>
            <li>
                {{ langs.iw_mark_p4 }}
            </li>
            <li>
                {{ langs.iw_mark_p5 }}
            </li>
        </ul>
    </div>
</template>

<script>
    import appButton from '@/components/user/app/app-button';

    import { get } from '@/utils/http-helper';

    const GET_LANG = '/lang/get-instruction';

    export default {
        name: 'workshop-functionality-popup',

        components: {
            appButton,
        },

        props: {
            isReview: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            langs: null,
        }),

        computed: {
            lang() {
                return this.$store.getters['platform/platform'].lang;
            },
        },

        async mounted() {
            const response = await get(GET_LANG, { type: 'workshop', lang: this.lang });
            this.langs = response.data.tokens;
        },
    };
</script>

<style scoped lang="scss">
    .workshopFunctionality-popup{
        &__title{
            margin-bottom: 2.3rem;

            font-size: to_rem(18px);
            color: var(--platform-accent-color);
        }
        &__row{
            display: flex;
            align-items: center;

            margin-bottom: 1.5rem;

            line-height: 1.4;
            .workshopFunctionality-popup__function{
                width: 6%;
                .app-tool-btn{
                    margin: 0;
                }
            }
            p{
                width: 90%;
            }
            &.button{
                .workshopFunctionality-popup__function{
                    width: 26%;
                    margin-right: 1.3%;
                }
                p{
                    width: 73%;
                }
            }
            &.three{
                .workshopFunctionality-popup__function{
                    display: flex;

                    width: 17%;
                    margin-right: 1.3%;
                    .app-tool-btn{
                        margin-right: 0.5rem;
                    }
                }
                p{
                    width: 82%;
                }
            }
            &.width{
                .workshopFunctionality-popup__function{
                    width: 24%;
                    margin-right: 1.3%;
                }
                p{
                    width: 73%;
                }
            }
            &.color{
                .workshopFunctionality-popup__function{
                    margin-right: 2%;
                }
            }
        }
        .leave{
            width: 2.125rem;
            height: 2.125rem;
            object-fit: contain;
        }
        @media screen and (max-width: 1023px){
            &__row {
                &.button {
                    .workshopFunctionality-popup__function {
                        width: 28%;
                    }
                }
            }
        }
        @media screen and (max-width: 767px){
            &__row {
                .workshopFunctionality-popup__function {
                    width: 15%;
                }
                &.button {
                    flex-direction: column;
                    .workshopFunctionality-popup__function {
                        width: 67%;
                        margin: 0 auto 1rem;
                        .app-button {
                            margin: 0 auto;
                        }
                    }
                    p {
                        width: 100%;
                    }
                }
                &.three {
                    .workshopFunctionality-popup__function {
                        width: 40%;
                    }
                }
                &.width {
                    flex-direction: column;
                    .workshopFunctionality-popup__function {
                        width: 40%;
                    }
                }
            }
        }
    }
</style>
