<template>
    <div
        ref="correspondencePopup"
        class="correspondence-popup"
    >
        <div class="correspondence-popup__title">
            {{ $t('view_message', 'View Message') }}
        </div>
        <div
            v-for="(message, index) of chat.messages"
            :key="index"
            class="correspondence-popup__message"
        >
            <div class="head">
                {{ message.name }}
                <span>
                    {{ message.created_at }}
                </span>
            </div>
            <p>
                {{ message.text }}
            </p>
        </div>
        <form @submit.prevent="sendMessage">
            <textarea
                v-model="messageText"
                class="app-input"
                @keyup="checkSend"
            ></textarea>
            <app-button
                class="narrow"
                tag="button"
                type="submit"
            >
                {{ $t('send', 'Send') }}
            </app-button>
        </form>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';

import { GP } from '@/utils/sockets-helper';

const NAME_SPACE = 'message';
const MESSAGE_READ = 'dialog-read';
const MESSAGE_CREATE = 'message-create';
const TOGGLE_CHAT = 'dialog-toggle';
const generateSocketPath = new GP(NAME_SPACE);

export default {
    name: 'correspondence-popup',

    components: {
        appButton,
    },

    props: {
        chat: {
            type: Object,
            default: null,
        },
        socket: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        messageText: '',
    }),

    mounted() {
        this.socket.on(generateSocketPath.generate(MESSAGE_CREATE), this.readChat);
        this.readChat();
        this.socket.emit(generateSocketPath.generate(TOGGLE_CHAT), {
            dialog_id: +this.chat.id,
        });
        this.scrollDown();
    },

    beforeDestroy() {
        this.socket.removeListener(generateSocketPath.generate(MESSAGE_CREATE), this.readChat);
        this.socket.emit(generateSocketPath.generate(TOGGLE_CHAT), {
            dialog_id: 0,
        });
    },

    methods: {
        readChat() {
            this.socket.emit(generateSocketPath.generate(MESSAGE_READ), {
                dialog_id: +this.chat.id,
            }, data => {
                this.$store.dispatch('auth/changeUnreadCount', -data.count);
                this.$store.dispatch('readChat', this.chat.id);
            });
        },
        checkSend(e) {
            if (e.which == 13 && !e.shiftKey) {
                this.sendMessage();
            }
        },
        sendMessage() {
            if (!this.messageText) return;
            this.socket.emit(generateSocketPath.generate(MESSAGE_CREATE), {
                dialog_id: +this.chat.id,
                user_id: +this.chat.user_id,
                text: this.messageText,
            }, data => {
                this.$store.dispatch('updateActiveChat', data);
                this.$nextTick(() => {
                    this.scrollDown();
                });
            });
            this.messageText = '';
        },
        scrollDown() {
            const scrollContainer = document.querySelector('.app-popup__scroll-content');
            scrollContainer.scrollTo({
                top: this.$refs.correspondencePopup.offsetHeight,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.correspondence-popup {
    form {
        padding: 2px;
    }

    &__title {
        position: absolute;
        top: 0.7rem;

        width: 90%;

        font-size: to_rem(20px);
        color: $mainDarkBlue;

        background: white;
    }

    &__message {
        margin-bottom: 1.4rem;

        &:nth-of-type(2) {
            margin-top: 2rem;
        }
    }

    .head {
        display: flex;
        align-items: center;

        font-size: to_rem(16px);
        font-weight: 700;
        color: $mainDarkBlue;

        span {
            margin: 0 0.2rem;

            font-size: to_rem(12px);
            font-weight: 400;
            color: $gray;
        }
    }

    p {
        margin-top: 0.1rem;

        line-height: 1.25;
        color: $copyGray;
    }

    textarea {
        height: 3rem;
        margin-bottom: 1rem;

        resize: none;
    }

    .app-button {
        margin-left: auto;
    }
}

</style>
