<template>
    <div
        v-if="dashboard !== null"
        class="dashboard"
    >
        <!-- <div class="page-title">
            {{ $t('dashboard', 'Dashboard') }}
        </div> -->
        <div
            v-if="showDashboard"
            class="content"
        >
            <div class="left">
                <app-topic>
                    <div
                        v-if="dashboard.status"
                        class="app-topic__description"
                    >
                        {{ $t('dash_curr_topic', 'Current Topic for Discussion') }}
                    </div>
                    <div
                        v-else
                        class="app-topic__description"
                    >
                        {{ $t('dash_curr_topic_in', 'Current Topic for Discussion') }}
                    </div>

                    <div class="app-topic__question">
                        {{ dashboard.name }}
                    </div>
                    <div class="app-topic__description">
                        {{ $t('dash_remain_time', 'Remaining Time') }}:
                    </div>
                    <app-progress-bar :progress="dashboard.progress_elapsed" />
                    <div class="app-topic__time">
                        {{ dashboard.elapsed_at }}
                    </div>
                    <p v-html="dashboard.intro"></p>

                    <br v-if="showVideos" />
                    <app-videos-gallery
                        v-if="showVideos"
                        :key="dashboard.id"
                        class="column"
                        :videos="dashboard.intro_content.videos"
                    />
                    <br v-if="showAudios" />
                    <app-audios-gallery
                        v-if="showAudios"
                        :key="dashboard.id + 1"
                        class="column"
                        :audios="dashboard.intro_content.audios"
                    />
                    <br v-if="showDocuments" />
                    <template v-if="showDocuments">
                        <app-resources
                            v-for="document of dashboard.intro_content.documents"
                            :key="document.id"
                            class="resource"
                        >
                            <a
                                v-if="!document.secured"
                                :href="generateFileUrl(document.file)"
                                target="_blank"
                                @click="sendAnalytic(1 ,document.id)"
                            >
                                <PDFIcon />
                                {{ document.name }}
                            </a>
                            <router-link
                                v-else
                                :to="{ name:'view', query:{ document_id: document.id, part: 'dashboard' } }"
                                target="_blank"
                                @click.native="sendAnalytic(1 ,document.id)"
                            >
                                <PDFIcon />
                                {{ document.name }}
                            </router-link>
                            <p>
                                {{ document.description }}
                            </p>
                        </app-resources>
                    </template>
                    <br v-if="showVideos || showAudios || showDocuments" />

                    <p v-html="dashboard.intro_after"></p>
                    <app-language-select
                        v-if="showTranslating"
                        @change="translateTopic"
                    />
                </app-topic>
                <div
                    v-if="dashboard.format !== 1 && checkReviewDocumentInQustions()"
                    class="join-block"
                >
                    <app-button
                        v-if="dashboard.format === 2"
                        class="accent"
                        :data-disable="!topicIsActive || !dashboard.video_link || disableJoinBtn"
                        @click="joinToVideo"
                    >
                        <i class="fa fa-video-camera"></i>
                        {{ $t('dash_videocall', 'Join Video call') }}
                    </app-button>
                    <app-button
                        v-if="dashboard.format === 3"
                        tag="router-link"
                        target="_blank"
                        class="accent"
                        :data-disable="!topicIsActive"
                        :to="{name: 'document-review', query: {id: topicId, event_id: eventId}}"
                    >
                        <i class="fa fa-eye"></i>
                        {{ $t('dash_review', 'Join Review') }}
                    </app-button>
                    <app-button
                        v-if="dashboard.format === 4"
                        tag="router-link"
                        target="_blank"
                        class="accent"
                        :data-disable="!topicIsActive"
                        :to="{name: 'workshop', query: {id: topicId, event_id: eventId}}"
                    >
                        <i class="fa fa-pencil-square-o"></i>
                        {{ $t('dash_workshop', 'Join Workshop') }}
                    </app-button>
                    <app-button
                        v-if="dashboard.format === 5"
                        tag="router-link"
                        target="_blank"
                        class="accent"
                        :data-disable="!topicIsActive"
                        :to="{name: 'editor', query: {id: topicId, event_id: eventId}}"
                    >
                        <i class="fa fa-file-text"></i>
                        {{ $t('dash_document', 'Join Document') }}
                    </app-button>
                    <app-button
                        v-if="dashboard.format === 6"
                        tag="router-link"
                        target="_blank"
                        class="accent"
                        :data-disable="!topicIsActive"
                        :to="{name: 'stickersBoard', query: {id: topicId, event_id: eventId}}"
                    >
                        <i class="fa fa-sticky-note"></i>
                        {{ $t('dash_stickers', 'Join Meeting board') }}
                    </app-button>
                </div>

                <zoom-mtg
                    v-show="dashboard.format === 2 && showZoomMtgBlock"
                    ref="zoomMtgBlock"
                    @endMeeting="closeVideoCall"
                />
                <div
                    v-for="section in dashboard.sections"
                    :key="section.id"
                    class=""
                >
                    <div
                        v-if="section.questions || !dashboard.hide_tool"
                        class="questions-block"
                    >
                        <template>
                            <div
                                v-if="!section.is_hidden"
                                class=""
                            >
                                <div
                                    class="meaning-block__title section"
                                    :class="section.is_hidden ? 'close' : 'open'"
                                    @click="section.is_hidden = !section.is_hidden"
                                >
                                    {{ section.title }}
                                    <div class="question__open-button">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                                <div
                                    v-for="(question, questionIndex) in section.questions"
                                    :key="question.id"
                                    class="meaning-block"
                                >
                                    <div
                                        v-if="!hiddenQuestions.includes(questionIndex)"
                                        class="question"
                                        :class="{open: questionId===question.id}"
                                    >
                                        <div
                                            class="question-info"
                                            @click="toggleQuestion($event, question.id)"
                                        >
                                            <div class="question__short-info">
                                                <div
                                                    v-if="question.is_override_title"
                                                    class="question__title"
                                                >
                                                    <div class="number">
                                                        {{ question.title || '' }}
                                                    </div>
                                                </div>
                                                <div
                                                    v-else
                                                    class="question__title"
                                                >
                                                    <div class="number">
                                                        {{ $t('dash_question', 'Question') }}
                                                        {{ questionIndex + 1 }}
                                                    </div>
                                                </div>
                                                <div class="question__text">
                                                    <div
                                                        class=""
                                                        v-html="question.name"
                                                    ></div>
                                                    <div
                                                        class="view"
                                                        v-html="question.intro"
                                                    ></div>
                                                </div>
                                                <div class="question__actions-block">
                                                    <notificationIcon
                                                        v-if="question.is_unread"
                                                        class="question__action question__notification"
                                                    />
                                                    <img
                                                        v-if="isShowDone(question)"
                                                        src="@/assets/img/icons/done_icon.svg"
                                                        class="question__action question__done"
                                                    />
                                                    <img
                                                        v-if="question.is_private"
                                                        class="private-comment-icon"
                                                        src="@/assets/img/icons/Private_question_icon.svg"
                                                        alt="Иконка приватного вопроса"
                                                    />
                                                    <div class="question__open-button">
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="questionId===question.id"
                                            class="question-details"
                                        >
                                            <div
                                                v-if="!question.hide_tool || question.format === 3"
                                                class="meaning-block__actions"
                                            >
                                                <div
                                                    v-if="!question.hide_tool"
                                                    class="row"
                                                >
                                                    <app-button
                                                        class="narrow"
                                                        :data-disable="!enabledActions"
                                                        @click="openPopup(0, 0, question.id)"
                                                    >
                                                        <img
                                                            src="@/assets/img/icons/reply_icon.svg"
                                                        />
                                                        {{ $t('dash_reply', 'Reply') }}
                                                    </app-button>
                                                    <app-button
                                                        class="narrow purple"
                                                        :data-disable="!enabledActions"
                                                        @click="toggleRecorder"
                                                    >
                                                        <img
                                                            src="@/assets/img/icons/voice_icon.svg"
                                                        />
                                                        {{
                                                            $t('dash_reply_voice', 'Reply via Voice')
                                                        }}
                                                    </app-button>
                                                    <app-button
                                                        class="narrow"
                                                        :data-disable="!enabledActions"
                                                        @click="openPopup(0, 1, question.id)"
                                                    >
                                                        <img
                                                            src="@/assets/img/icons/question-cloud_icon.svg"
                                                        />
                                                        {{
                                                            $t('dash_reply_all', 'Question to All')
                                                        }}
                                                    </app-button>
                                                    <app-file-input
                                                        class="narrow"
                                                        :load-file="uploadCommentDocument"
                                                        :data-disable="!enabledActions"
                                                    >
                                                        <img
                                                            src="@/assets/img/icons/upload_icon.svg"
                                                        />
                                                        {{
                                                            $t('dash_reply_doc', 'Upload Documents')
                                                        }}
                                                    </app-file-input>
                                                    <app-gpt
                                                        :question-id="question.id"
                                                        :name="'textPanel'"
                                                    />
                                                </div>
                                                <div
                                                    v-if="!!question.is_file_uploaded"
                                                    class="row"
                                                >
                                                    <app-button
                                                        tag="router-link"
                                                        target="_blank"
                                                        class="accent"
                                                        :data-disable="!topicIsActive"
                                                        :to="{name: 'document-review', query: {id: topicId, event_id: eventId, question_id: question.id}}"
                                                    >
                                                        <i class="fa fa-eye"></i>
                                                        {{ $t('dash_review', 'Join Review') }}
                                                    </app-button>
                                                </div>
                                            </div>
                                            <div class="question-files">
                                                <app-audio-recorder
                                                    v-if="recorderIsActive"
                                                    :load-voice="uploadVoiceComment"
                                                    @close="toggleRecorder"
                                                />
                                                <app-videos-gallery
                                                    v-if="showVideoOnTopic(question)"
                                                    :key="dashboard.id"
                                                    class="column"
                                                    :videos="question.videos"
                                                />
                                                <app-audios-gallery
                                                    v-if="showAudioOnTopic(question)"
                                                    :key="dashboard.id + 1"
                                                    class="column"
                                                    :audios="question.audios"
                                                />
                                                <template v-if="showDocumentsOnTopic(question)">
                                                    <app-resources
                                                        v-for="document of question.documents"
                                                        :key="document.id "
                                                        class="resource"
                                                    >
                                                        <a
                                                            v-if="!document.secured"
                                                            :href="generateFileUrl(document.file)"
                                                            target="_blank"
                                                            @click="sendAnalytic(1 ,document.id)"
                                                        >
                                                            <PDFIcon />
                                                            {{ document.name }}
                                                        </a>
                                                        <router-link
                                                            v-else
                                                            :to="{ name:'view', query:{ document_id: document.id, part: 'dashboard' } }"
                                                            target="_blank"
                                                            @click.native="sendAnalytic(1 ,document.id)"
                                                        >
                                                            <PDFIcon />
                                                            {{ document.name }}
                                                        </router-link>
                                                        <p>
                                                            {{ document.description }}
                                                        </p>
                                                    </app-resources>
                                                </template>
                                            </div>
                                            <br v-if="showVideoOnTopic || showDocumentsOnTopic || showAudioOnTopic" />
                                            <app-comments
                                                v-if="showComments(question) && !question.hide_tool"
                                                :comments="question.comments"
                                                :question-id="question.id"
                                                :topic-is-active="enabledActions"
                                                @like="likeComment"
                                                @comment="openPopup"
                                                @edit="editPopup"
                                                @toggleCommentState="toggleCommentState"
                                            />

                                            <template v-if="question.surveys.length">
                                                <div class="app-topic-title">
                                                    {{ $t('dash_survey', 'Survey') }}
                                                </div>
                                                <div
                                                    class="survey"
                                                    :disabled="!enabledActions"
                                                >
                                                    <template v-for="survey in question.surveys">
                                                        <app-survey-question
                                                            v-if="survey.type !== 6"
                                                            :key="survey.id"
                                                            :survey="survey"
                                                            :socket="socket"
                                                        />
                                                        <table-survey-component
                                                            v-else
                                                            :key="survey.id"
                                                            :table="survey"
                                                            :question-id="question.id"
                                                        />
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="meaning-block__title section"
                                :class="section.is_hidden ? 'close' : 'open'"
                                @click="section.is_hidden = !section.is_hidden"
                            >
                                {{ section.title }}
                                <div class="question__open-button">
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </template>
                        <div
                            v-if="!dashboard.hide_tool && !section.is_hidden"
                            class="meaning-block"
                        >
                            <div class="meaning-block__title">
                                {{ $t('dash_curr_disc', 'Current Discussion') }}
                            </div>
                            <div class="meaning-block__actions">
                                <div class="row">
                                    <app-button
                                        class="narrow"
                                        :data-disable="!enabledActions"
                                        @click="openPopup(0)"
                                    >
                                        <img src="@/assets/img/icons/reply_icon.svg" />
                                        {{ $t('dash_reply', 'Reply') }}
                                    </app-button>
                                    <app-button
                                        class="narrow purple"
                                        :data-disable="!enabledActions"
                                        @click="toggleRecorder"
                                    >
                                        <img src="@/assets/img/icons/voice_icon.svg" />
                                        {{ $t('dash_reply_voice', 'Reply via Voice') }}
                                    </app-button>
                                    <app-button
                                        class="narrow"
                                        :data-disable="!enabledActions"
                                        @click="openPopup(0, 1)"
                                    >
                                        <img src="@/assets/img/icons/question-cloud_icon.svg" />
                                        {{ $t('dash_reply_all', 'Question to All') }}
                                    </app-button>
                                    <app-file-input
                                        class="narrow"
                                        :load-file="uploadCommentDocument"
                                        :data-disable="!enabledActions"
                                    >
                                        <img src="@/assets/img/icons/upload_icon.svg" />
                                        {{ $t('dash_reply_doc', 'Upload Documents') }}
                                    </app-file-input>
                                </div>
                            </div>
                            <app-audio-recorder
                                v-if="recorderIsActive"
                                :load-voice="uploadVoiceComment"
                                @close="toggleRecorder"
                            />
                            <app-comments
                                v-if="dashboard.comments && dashboard.comments.length"
                                :comments="dashboard.comments"
                                :topic-is-active="enabledActions"
                                @like="likeComment"
                                @comment="openPopup"
                                @edit="editPopup"
                                @toggleCommentState="toggleCommentState"
                            />
                        </div>
                    </div>
                </div>
                <template v-if="showSurveys">
                    <div class="app-topic-title">
                        {{ $t('dash_survey', 'Survey') }}
                    </div>
                    <div
                        class="survey"
                        :disabled="!enabledActions"
                    >
                        <template v-for="survey in dashboard.surveys">
                            <app-survey-question
                                v-if="survey.type !== 6"
                                :key="survey.id"
                                :survey="survey"
                                :socket="socket"
                            />
                            <table-survey-component
                                v-else
                                :key="survey.id"
                                :table="survey"
                                :class="{'event-none': survey.is_answered}"
                            />
                        </template>
                    </div>
                </template>
            </div>
            <sidebar
                :key="dashboard.id"
                :dashboard="dashboard"
            />
        </div>
        <template v-else-if="dashboard.source === 1">
            <e-library />
        </template>
        <template v-else-if="dashboard.source === 2">
            <company />
        </template>
        <template v-else>
            <div
                v-if="agenda && agenda.topics"
                class="dashboard-agenda"
            >
                <div class="app-topic-title">
                    {{ $t('dash_agenda_topics', 'Topics') }}
                </div>

                <div class="topics-list">
                    <div
                        v-for="topic of agenda.topics"
                        :key="topic.id"
                        class="topic"
                    >
                        <format-icons
                            :format="topic.format"
                            :is-conf="topic.conf"
                        />
                        <a @click.prevent="toTopic(topic)">
                            {{ topic.name }}
                        </a>
                        <p>
                            <span>
                                {{ $t('dash_timeline', 'Timeline') }}:
                            </span>
                            {{ topic.start_date }} - {{ topic.end_date }} {{
                                agenda.event.timezone
                            }}
                        </p>
                        <hr />
                    </div>
                </div>
            </div>
        </template>
        <app-popup name="commentPopup">
            <comment-popup
                :paths="$options.commentsPaths"
                :socket="socket"
                @createComment="createComment"
                @editComment="editComment"
            />
        </app-popup>
    </div>
</template>

<script>
import appTopic from '@/components/user/app/app-topic';
import appProgressBar from '@/components/user/app/app-progressBar';
import appButton from '@/components/user/app/app-button';
import appFileInput from '@/components/user/app/app-file-input';
import appAudioRecorder from '@/components/user/app/app-audio-recorder';
import appLanguageSelect from '@/components/user/app/app-language-select';
import appComments from '@/components/user/app/app-comments';
import appSurveyQuestion from '@/components/user/app/app-survey-question';
import tableSurveyComponent from '@/components/user/pages/dashboard/table-survey-component';
import formatIcons from '@/components/user/pages/agenda/format-icons';
import zoomMtg from '@/components/user/pages/dashboard/zoomMtg';
import notificationIcon from '@/assets/img/icons/notification_icon.svg?inline';
import appGpt from '@/components/user/app/app-gpt';

import sidebar from '@/components/user/pages/dashboard/sidebar';
import appPopup from '@/components/user/app-popup';
import commentPopup from '@/components/user/app/app-comment-popup';

import ELibrary from '@/views/user/e-library';
import company from '@/views/user/company';

import { GP, socketInstance } from '@/utils/sockets-helper';
import { generateFileUrl, post } from '@/utils/http-helper';
import { changeTopicTimer, toogleTab } from '@/utils/user/time-socket';

import appVideosGallery from '@/components/user/app/app-videos-gallery';
import appAudiosGallery from '@/components/user/app/app-audios-gallery';
import appResources from '@/components/user/app/app-resources';
import PDFIcon from '@/assets/img/icons/pdf_icon.svg?inline';
import { sendClick } from '@/utils/user/clicks-analytic';

const UPLOAD_DOCUMENT = '/dashboard/upload-document';
const UPLOAD_VOICE = '/dashboard/upload-voice';
const READ_QUESTION = '/dashboard/read-messages';
const JOIN_TO_VIDEO = '/dashboard/join-videocall';

const NAME_SPACE = 'dashboard';
const CREATE_TEXT = 'comment-create-text';
const COMMENT_LIKE = 'comment-like';
const COMMENT_UNLIKE = 'comment-unlike';
const COMMENT_CREATE_FILE = 'comment-create-file';
const COMMENT_CREATE_AUDIO = 'comment-create-audio';
const COMMENT_REMOVE = 'comment-remove';
const COMMENT_EDIT = 'comment-edit';
const COMMENT_RESTORE = 'comment-restore';
const SURVEY_ANSWER = 'survey-answer';

const generateSocketPath = new GP(NAME_SPACE);

export default {
    name: 'dashboard',

    components: {
        appTopic,
        appProgressBar,
        appButton,
        appFileInput,
        appAudioRecorder,
        sidebar,
        appLanguageSelect,
        appComments,
        appSurveyQuestion,
        appPopup,
        commentPopup,
        formatIcons,
        tableSurveyComponent,
        zoomMtg,
        notificationIcon,
        ELibrary,
        company,
        appGpt,
        appVideosGallery,
        appAudiosGallery,
        appResources,
        PDFIcon,
    },

    commentsPaths: {
        NAME_SPACE: 'dashboard',
        CREATE_TEXT: 'comment-create-text',
        EDIT_TEXT: 'comment-edit',
    },

    data: () => ({
        language: null,
        socket: null,
        recorderIsActive: false,
        statTimer: null,
        lang: null,
        showZoomMtgBlock: false,
        disableJoinBtn: false,
        hiddenQuestions: [],
    }),

    computed: {
        dashboard() {
            return this.$store.getters['dashboard/get'];
        },
        id() {
            return this.$route.query.id;
        },
        eventId() {
            return this.$route.query.event_id;
        },
        questionId() {
            return +this.$route.query.question;
        },
        topicId() {
            return this.$store.getters['dashboard/topicId'];
        },
        showSurveys() {
            return this.dashboard.surveys?.length;
        },
        showDashboard() {
            return this.dashboard && this.dashboard.id;
        },
        showDocuments() {
            return this.dashboard.intro_content?.documents?.length;
        },
        showVideos() {
            return this.dashboard.intro_content?.videos?.length;
        },
        showAudios() {
            return this.dashboard.intro_content?.audios?.length;
        },
        agenda() {
            return this.$store.getters['dashboard/getAgenda'];
        },
        topicIsActive() {
            return +this.dashboard.status;
        },
        isUser() {
            const id = +this.$store.getters['auth/userRole']?.id;
            const mode = +this.$store.getters['auth/userRole']?.mode;
            return (id === 5 && mode !== 0) || id === 6;
        },
        enabledActions() {
            return !!(this.isUser && this.topicIsActive);
        },
        showTranslating() {
            return this.$store.getters['platform/platform'].settings.translating;
        },
    },

    watch: {
        id(value, oldValue) {
            if (+value !== +oldValue) {
                this.getDashboardInfo();
            }
        },
    },

    async created() {
        await this.getDashboardInfo();
        this.initStatTimer();
    },

    activated() {
        changeTopicTimer(this.topicId);
        this.initStatTimer();
    },

    deactivated() {
        this.socket.destroy();
        clearInterval(this.statTimer);
        this.statTimer = null;
    },

    methods: {
        sendAnalytic(type, id) {
            sendClick(5, type, id, this.topicId);
        },

        checkReviewDocumentInQustions() {
            return !this.dashboard.questions?.some(item => item.format === 3);
        },

        generateFileUrl(file) {
            return generateFileUrl(file);
        },

        toTopic(event) {
            this.$router.push({ name: 'dashboard', query: { id: event.id, event_id: this.eventId } });
        },

        isShowDone(question) {
            return !!question.is_answered;
        },

        checkStatusDone() {
            this.statusDone = true;
        },

        showDocumentsOnTopic(question) {
            return !!question?.documents?.length;
        },
        showAudioOnTopic(question) {
            return !!question?.audios?.length;
        },
        showVideoOnTopic(question) {
            return !!question?.videos?.length;
        },

        async getDashboardRequest() {
            const params = {
                id: this.id ? this.id : null,
                eventId: this.eventId ? this.eventId : null,
            };
            await this.$store.dispatch('dashboard/get', params);
        },

        async getDashboardInfo() {
            await this.getDashboardRequest();
            if (this.showDashboard) {
                this.initialSockets();
                changeTopicTimer(this.topicId);
            } else {
                await this.$store.dispatch('dashboard/getAgenda', this.eventId ? this.eventId : null);
            }

            this.showZoomMtgBlock = this.dashboard.video_link?.includes('zoom');
            // Инициализация состояния скрытия вопросов
            this.dashboard.questions.forEach((question, index) => {
                if (question.is_hide_section) {
                    this.hiddenQuestions.push(index);
                }
            });
        },

        initStatTimer() {
            clearInterval(this.statTimer);
            this.statTimer = setInterval(async () => {
                this.$store.dispatch('dashboard/getStat', {
                    id: this.topicId,
                    event_id: this.eventId,
                });
            }, 30000);
        },

        showComments(question) {
            return question.comments?.length;
        },
        initialSockets() {
            this.socket = socketInstance(NAME_SPACE, { room: `room-${this.topicId}` });
            this.socket.on(generateSocketPath.generate(CREATE_TEXT), data => {
                this.$store.dispatch('dashboard/createComment', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_LIKE), data => {
                this.$store.dispatch('dashboard/changeLikesCount', {
                    id: data.comment_id,
                    up: true,
                    questionId: data.question_id,
                });
            });
            this.socket.on(generateSocketPath.generate(COMMENT_UNLIKE), data => {
                this.$store.dispatch('dashboard/changeLikesCount', {
                    id: data.comment_id,
                    up: false,
                    questionId: data.question_id,
                });
            });
            this.socket.on(generateSocketPath.generate(COMMENT_CREATE_FILE), data => {
                this.$store.dispatch('dashboard/createComment', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_CREATE_AUDIO), data => {
                this.$store.dispatch('dashboard/createComment', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_EDIT), data => {
                this.$store.dispatch('dashboard/editComment', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_REMOVE), data => {
                data.deleted = 1;
                this.$store.dispatch('dashboard/toggleCommentState', data);
            });
            this.socket.on(generateSocketPath.generate(COMMENT_RESTORE), data => {
                data.deleted = 0;
                this.$store.dispatch('dashboard/toggleCommentState', data);
            });
            this.socket.on(generateSocketPath.generate(SURVEY_ANSWER), data => {
                if (data.success) {
                    this.$store.dispatch('dashboard/answerSurvey', data);
                }
            });
        },
        likeComment(id, liked, questionId) {
            this.socket.emit(generateSocketPath.generate(liked ? COMMENT_UNLIKE : COMMENT_LIKE), {
                comment_id: id,
                question_id: questionId,
            }, data => {
                if (data.success) {
                    this.$store.dispatch('dashboard/changeLikesCount', {
                        id,
                        up: !liked,
                        self: true,
                        questionId,
                    });
                }
            });
        },
        toggleCommentState(id, deleted) {
            this.socket.emit(generateSocketPath.generate(deleted ? COMMENT_RESTORE : COMMENT_REMOVE), {
                comment_id: id,
            }, data => {
                if (data.success) {
                    data.deleted = +!deleted;
                    this.$store.dispatch('dashboard/toggleCommentState', data);
                }
            });
        },
        async toggleQuestion(e, id) {
            this.translateTopic(this.lang);
            const routeObj = {
                query: { ...this.$route.query },
            };
            if (this.questionId !== id) {
                await this.getDashboardRequest();
                routeObj.query.question = id;
                post(READ_QUESTION, { question_id: +id });
                this.$store.dispatch('dashboard/readQuestion', { id, status: false });
            } else {
                delete routeObj.query.question;
            }
            this.$router.push(routeObj);

            setTimeout(() => {
                if (this.questionId && (e.target.getBoundingClientRect().top < 0 || e.target.getBoundingClientRect().top > window.innerHeight)) {
                    window.scrollTo({
                        top: e.target.getBoundingClientRect().top + window.pageYOffset - 70,
                    });
                }
            }, 100);
        },

        openPopup(parentId = 0, toAll = 0, questionId = 0) {
            const options = {
                topicId: this.topicId,
                parentId,
                toAll,
                questionId,
            };
            this.$store.dispatch('togglePopup', { popupName: 'commentPopup', options });
        },
        editPopup(commentId = 0, questionId = 0, commentContent = '') {
            const options = {
                commentId,
                questionId,
                commentContent,
                editing: true,
            };
            this.$store.dispatch('togglePopup', { popupName: 'commentPopup', options });
        },
        async uploadCommentDocument(document) {
            let formData = new FormData();
            formData.append('topic_id', this.topicId);
            formData.append('file', document);
            this.questionId ? formData.append('question_id', this.questionId) : null;
            const loadStatus = await post(UPLOAD_DOCUMENT, formData, true);
            if (loadStatus.status === 1) {
                this.$store.dispatch('dashboard/createComment', loadStatus.data);
                this.socket.emit(generateSocketPath.generate(COMMENT_CREATE_FILE), {
                    comment_id: loadStatus.data.id,
                });
            }
        },
        toggleRecorder() {
            this.recorderIsActive = !this.recorderIsActive;
        },
        async uploadVoiceComment(voice, name) {
            let formData = new FormData();
            formData.append('topic_id', this.topicId);
            formData.append('file', voice, name);
            this.questionId ? formData.append('question_id', this.questionId) : null;

            const loadStatus = await post(UPLOAD_VOICE, formData, true);

            if (loadStatus.status === 1) {
                this.$store.dispatch('dashboard/createComment', loadStatus.data);
                this.socket.emit(generateSocketPath.generate(COMMENT_CREATE_AUDIO), {
                    comment_id: loadStatus.data.id,
                });
            }
        },
        translateTopic(lang) {
            if (lang) {
                this.lang = lang;
                this.$store.commit('dashboard/updateLang', lang);
                this.$store.dispatch('dashboard/translateTopic', {
                    topic_id: this.topicId,
                    lang,
                });
            } else {
                this.$store.dispatch('dashboard/get', {
                    id: this.id ? this.id : null,
                    eventId: this.eventId ? this.eventId : null,
                });
            }
        },
        createComment(data) {
            this.$store.dispatch('dashboard/createComment', data);
        },
        editComment(data) {
            this.$store.dispatch('dashboard/editComment', data);
        },

        joinToVideo() {
            const link = this.dashboard.video_link;
            const target = this.dashboard.video_target;
            const isZoom = link.includes('zoom');
            if (isZoom && target) {
                this.startZoomMtg();
            } else {
                window.open(link, '_blank').focus();
                toogleTab(1);
            }

            if (this.isUser) {
                post(JOIN_TO_VIDEO, {
                    topic_id: this.topicId,
                });
            }
        },

        startZoomMtg() {
            this.disableJoinBtn = true;
            this.$refs.zoomMtgBlock.joinMeeting(this.dashboard.video_link);
        },

        closeVideoCall() {
            this.disableJoinBtn = false;
        },
    },

};
</script>

<style scoped lang="scss">
.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
        width: calc(100% - 18.4rem);
        padding-right: 1rem;
    }

    .join-block {
        display: flex;
        justify-content: center;

        margin-top: .8rem;
        padding: 2.8rem 0;

        background: var(--platform-main-color);
    }

    .questions-block {
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0.9rem 0.7rem;

        background: var(--platform-main-color);
    }

    .question-info {
        cursor: pointer;
    }

    .meaning-block {
        &__title {
            margin-bottom: 0.8rem;
            display: flex;
            align-items: center;
            font-size: to_rem(20px);
            text-align: center;
            color: $mainTextColor;

            &-title {
                margin-left: auto;
            }

            .question__open-button {
                margin-left: auto;

                span {
                    background: $mainTextColor;
                }

                span:nth-of-type(2) {
                    display: none;
                }
            }
        }

        &__title.close {
            .question__open-button {
                span:nth-of-type(2) {
                    display: block;
                }
            }
        }

        &__actions {
            display: flex;
            flex-direction: column;
            align-items: center;

            margin-bottom: 1.2rem;
            padding: 1.85rem 0;

            background: var(--platform-accent-color);

            .row {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            .app-button {
                margin-bottom: .6rem;

                &:not(:last-child) {
                    margin-right: .3rem;
                }
            }

            .accent {
                background: var(--platform-main-color);
            }
        }

        .app-comments {
            max-height: 40rem;
        }
    }

    .app-language-select {
        position: absolute;
        top: 0;
        right: 0;
    }

    .topics-list {
        padding: 1.7rem 1.8rem;

        background: white;

        .topic {
            margin-bottom: 1.1rem;

            &:last-of-type {
                hr {
                    display: none;
                }
            }

            &.new {
                position: relative;

                padding-left: 3.7rem;

                &:before {
                    @include bef_aft;
                    width: 3rem;
                    height: 3rem;

                    background: url("~@/assets/img/icons/new-topic_icon.png") no-repeat center center/contain;
                }
            }

            a {
                display: block;

                margin-bottom: 0.3rem;

                font-size: to_rem(24px);
                color: var(--platform-accent-color);
                text-decoration: none;
                font-style: italic;

                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            p {
                font-size: to_rem(14px);
                font-weight: 700;

                span {
                    font-weight: 400;
                }
            }

            hr {
                margin: 1.7rem 0 0;

                border: none;
                border-top: 1px solid $lightGray;
            }
        }

        .app-labels-imgs {
            margin-bottom: 0.6rem;
        }
    }

    .section.open {

        .question__open-button {
            span:nth-of-type(2) {
                display: none !important;
            }
        }
    }

    .section.close {

        .question__open-button {
            span:nth-of-type(2) {
                display: block !important;
            }
        }
    }

    .question {
        margin-bottom: 1.2rem;

        &.open {
            .question__open-button {
                span:nth-of-type(2) {
                    display: none;
                }
            }
        }

        &-info {
            position: relative;

            margin-bottom: 1.2rem;
            padding: .55rem .4rem 1.7rem;

            background: white;
        }

        &__description {
            margin-bottom: 1.6rem;

            color: $darkGray;

            h2 {
                font-size: to_rem(20px);
                font-weight: 400;
            }

            h3 {
                width: 88%;
                margin-top: .8rem;

                font-size: to_rem(13px);
                font-weight: 400;
                text-transform: uppercase;

                span {
                    text-transform: none;
                }
            }

            p {
                width: 88%;
                margin-top: 0.4rem;

                font-size: to_rem(13px);
                line-height: 1.5;
            }

            img {
                display: block;

                width: 88%;
                margin: 2.3rem 0;
            }
        }

        &__short-info {
            position: relative;

            padding: .9rem 1.1rem 0;

            &::v-deep {
                img {
                    max-width: 100%;
                }
            }
        }

        &__title {
            font-size: to_rem(14px);
            font-weight: 700;

            &::v-deep {
                .number {
                    margin-bottom: 0.5rem;

                    color: var(--platform-accent-color);
                }
            }
        }

        &__text {
            margin-top: 0.5rem;

            font-size: to_rem(14px);
            font-weight: 700;
        }

        &__actions-block {
            position: absolute;
            top: -.2em;
            right: 1.25rem;

            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        &__open-button {
            position: relative;

            width: 0.9rem;
            height: 0.9rem;
            margin-top: 0.4rem;

            cursor: pointer;

            span {
                position: absolute;
                top: 50%;
                left: 50%;

                display: block;

                width: 100%;
                height: 2px;

                background: var(--platform-accent-color);

                transform: translate(-50%, -50%);

                &:nth-of-type(2) {
                    transform: translate(-50%, -50%) rotate(90deg);
                }
            }
        }

        &__action {
            height: 1.8rem;
            margin-right: .9rem;

            cursor: pointer;
        }

        &__notification {
            width: 1.7rem;

            &::v-deep {
                path, circle {
                    fill: white;
                    stroke: var(--platform-accent-color);
                    stroke-width: 15;
                }

                circle {
                    fill: red !important;
                }

                circle:nth-last-of-type(2) {
                    fill: var(--platform-accent-color) !important;
                }
            }
        }

        &__done {
            width: 3.9rem;
        }
    }

    .view {
        font-size: initial;
        font-weight: initial;

        * {
            font-size: initial;
            font-weight: initial;
        }

        &::v-deep img {
            max-width: 100%;

            object-fit: contain;
        }
    }

    .private-comment-icon {
        width: 1rem;
        height: 1rem;
        margin-top: 0.4rem;
        margin-right: 8px;
    }
}

.survey {
    background: #fff;
    border-top: none;

    &[disabled] {
        pointer-events: none;

        &::v-deep {
            .app-tool-btn, .app-popup, .table-survey__table-wrapper {
                pointer-events: all;
            }

            .table-survey {
                textarea, .checkbox, .table-survey__dropdown {
                    pointer-events: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
    .content .left {
        width: 33rem;
    }
}

@include mobile_or_P {
    .content {
        padding-right: 0.5rem;

        .left {
            width: 100%;
            padding-right: 0.5rem;
        }

        .app-topic {
            margin-left: .7rem;
        }

        .questions-block {
            margin-left: 0.7rem;
            padding: 1rem .7rem;
        }

        .meaning-block {
            &__title {
                margin-bottom: 0.95rem;
            }

            &__actions {
                margin-bottom: 0.65rem;
                padding: .5rem;

                .app-button {
                    margin: 0.5rem 0.2rem !important;
                }
            }

            .app-language-select {
                margin-top: 0.5rem;
            }

            .app-comments {
                max-height: 30rem;
            }
        }

        .question {

            &__short-info {
                padding: 0.85rem 0.4rem 0;
            }

            &__actions-block {
                right: 0.25rem;
            }
        }
    }
}

@include razr767 {
    .content {
        flex-direction: column;

        margin-left: 0;
        padding-right: 0;

        .left {
            padding-right: 0;
        }

        .app-topic {
            margin: 0 0.85rem;
        }

        .questions-block {
            margin-left: 0;
        }

        .question {
            &-info {
                margin-bottom: 0;
                padding: 0.8rem 1.1rem 1.5rem;
            }

            &__description {
                h3 {
                    font-size: 1.104rem;
                    line-height: 1.8;
                }

                p {
                    width: 100%;

                    font-size: 1.104rem;
                    line-height: 1.75;
                }

                img {
                    width: 100%;
                }
            }

            &__title {
                font-size: 1.05rem;
            }

            &__short-info {
                padding: 0.85rem 0.6rem 0;
            }

            &__text {
                margin-top: 0.7rem;

                font-size: 1.01902rem;
            }
        }

        .meaning-block {
            &__actions {
                margin-bottom: 0;
                padding: 1.4rem 0;

                .row {
                    justify-content: center;
                }
            }
        }
    }
}

.event-none {
    pointer-events: none;
}

</style>
