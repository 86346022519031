export const DOCUMENT_TEMPLATE = (id, type = 1) => ({
    id: id,
    type: type,
    secured: false,
    watermark: false,
    name: '',
    file: null,
    openLink: '',
});

export const VIDEO_TEMPLATE = (id, type = 1) => ({
    id: id,
    type: type,
    secured: false,
    name: '',
    file: null,
    cover: null,
    coverUrl: '',
    openLink: '',
    preview: null,
    description: '',
});

export const AUDIO_TEMPLATE = (id, type = 1) => ({
    id: id,
    type: type,
    secured: false,
    name: '',
    file: null,
    cover: null,
    coverUrl: '',
    openLink: '',
    preview: null,
    description: '',
});

export const LINK_TEMPLATE = (id) => ({
  id: id,
  link: '',
  description: '',
});
