import { get } from '@/utils/http-helper';
import router from '@/router';

const GET = '/sticker/get';
const GET_PAGE = '/sticker/get-page';

export default {
    namespaced: true,

    state: {
        stickers: null,
    },

    actions: {
        async get({ commit }, topicId) {
            const stickers = await get(GET, {
                topic_id: topicId,
                event_id: router.app._route.query.event_id,
            });
            if (stickers.status) {
                commit('updateStickers', stickers.data);
            }
        },
        async getPage({ commit }, { topicId, page }) {
            const stickers = await get(GET_PAGE, {
                topic_id: topicId,
                page,
                event_id: router.app._route.query.event_id,
            });
            if (stickers.status) {
                commit('updatePageStickers', stickers.data);
            }
        },
        renamePage({ commit }, title) {
            commit('renamePage', title);
        },
    },

    mutations: {
        updateStickers(state, stickers) {
            state.stickers = stickers;
        },
        updatePageStickers(state, stickers) {
            state.stickers = { ...state.stickers, ...stickers };
        },
        renamePage(state, title) {
            state.stickers.title = title;
        },
    },
    getters: {
        get: state => state.stickers,
    },
};
