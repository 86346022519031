<template>
    <div class="disposer-sidebar">
        <nav>
            <router-link :to="{name: 'managers'}">
                Project Managers
            </router-link>
            <router-link :to="{name: 'interface'}">
                Interface
            </router-link>
            <router-link :to="{name: 'events'}">
                Events
            </router-link>
            <router-link :to="{name: 'disposer-video-guidance'}">
                Video guidance
            </router-link>
        </nav>
    </div>
</template>

<script>
    export default {
        name: 'disposer-sidebar',
    };
</script>

<style scoped lang="scss">
.disposer-sidebar {
    background: $dark;

    nav {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        padding: 2.1rem 0;

        font-size: 16px;
    }

    a {
        position: relative;

        display: flex;
        align-items: center;

        width: 16rem;
        min-height: 2.9rem;
        padding: .8rem 3rem .8rem 3.2rem;

        color: white;
        text-decoration: none;

        border-left: 3px solid transparent;

        &.router-link-active {
            background: #1e272c;
            border-color: $copyGray;
        }

        &:before {
            content: '';
            position: absolute;
            top: 1rem;
            left: 1.7rem;

            width: 0.5rem;
            height: 0.5rem;

            border: 2px solid white;
            border-radius: 50%;
        }
    }

    @media screen and (max-width: 1280px) {
        a {
            width: 18rem;
        }
    }
}
</style>
