<template>
    <div class="disposer-app-upload-documents">
        <div class="upload-header">
            <div class="upload-current-file">
                <span
                    v-if="$v.document.file.$dirty && !$v.document.file.required"
                    class="file-error app-input-error"
                >
                    Choose a file
                </span>
                <span
                    v-if="errors && errors.document"
                    class="file-error app-input-error"
                >
                    {{ errors.document[0] }}
                </span>
            </div>
        </div>

        <div class="upload-header">
            <label
                class="disposer-app-label"
            >
                Sorting order &nbsp;
                <vue-numeric-input
                    v-model="document.number"
                    :min="0"
                />
            </label>
        </div>

        <div
            v-if="platform"
            class="upload-main"
        >
            <div class="upload-checkboxes">
                <div>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="document.secured"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Secured view-only format
                    </label>
                    <label
                        v-if="platform.settings.watermarks && document.secured"
                        class="disposer-app-checkbox"
                    >
                        <input
                            v-model="document.watermark"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Watermarks
                    </label>
                </div>
            </div>
            <div class="upload-name">
                <label
                    class="disposer-app-label"
                >
                    Name of document
                    <a
                        v-if="document.openLink || generateUrl(document.file, ' ')"
                        target="_blank"
                        :href="document.openLink || generateUrl(document.file, ' ')"
                    >
                        <i
                            class="fa fa-external-link"
                            title="Open document"
                        ></i>
                    </a>
                    <input
                        v-model="document.name"
                        type="text"
                        class="disposer-app-input"
                    />
                </label>
            </div>
            <div class="upload-document">
                <label
                    class="disposer-app-label"
                >
                    Document Description
                    <input
                        v-model="document.description"
                        type="text"
                        class="disposer-app-input"
                    />
                </label>
            </div>
            <div class="upload-btns">
                <div class="upload-choose">
                    <div class="title">
                        Choose file
                    </div>
                    <div class="info-icons">
                        <img
                            v-tooltip.top-end="$options.texts.chooseDocumentIconMsg"
                            src="@/assets/img/icons/disposer/info-icon.png"
                            class="info-icon"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="fileName">
                        {{ fileName || document.native }}
                    </div>
                    <app-file-input
                        class="upload-btn mini"
                        accept=".pdf,.ptt,.pptx,.doc,.docx"
                        :load-file="loadDocument"
                    >
                        Choose
                    </app-file-input>
                    <app-button
                        class="upload-btn mini red"
                        @click="saveDocument"
                    >
                        Save
                    </app-button>
                    <app-button
                        class="upload-btn mini"
                        @click="deleteDocument"
                    >
                        Delete
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appButton from '@/components/disposer/app/app-button';
import appFileInput from '@/components/disposer/app/app-file-input';

import { chooseDocumentIconMsg } from '@/utils/disposer/icons-text-helper.js';
import {
    defaultConfirmSettings,
    deletedDocument,
    saveDocument,
} from '@/utils/disposer/notifications-text-helper';
import { post } from '@/utils/http-helper';
import { required } from 'vuelidate/lib/validators';
import generateUrl from '@/utils/mixins/generate_url';

import VueNumericInput from 'vue-numeric-input';

export default {
    name: 'app-upload-documents',

    components: {
        appButton,
        appFileInput,
        VueNumericInput,
    },

    mixins: [generateUrl],

    props: {
        document: {
            type: Object,
            default: () => {
            },
        },
        paths: {
            type: Object,
            default: () => {
            },
        },
        topicId: {
            type: Number,
            default: null,
        },
        questionId: {
            type: Number,
            default: null,
        },
        type: {
            type: Number,
            default: null,
        },
        eventId: {
            type: Number,
            default: null,
        },
        section: {
            type: Object,
            default: null,
        },
    },

    texts: {
        chooseDocumentIconMsg,
    },

    data: () => ({
        errors: {},
        fileName: '',
    }),

    validations: {
        document: {
            file: {
                required,
            },
        },
    },

    computed: {
        platform() {
            return this.$store.getters['platform/platform'];
        },
    },

    methods: {
        loadDocument(document) {
            this.document.file = document;
            this.fileName = document.name;
            !this.document.name ? this.document.name = document.name : null;
            this.document.openLink = window.URL.createObjectURL(document);
        },
        async saveDocument() {
            if (this.$v.document.$invalid) {
                this.$v.document.$touch();
                return;
            }
            const fd = new FormData();
            if (this.document.number === undefined) {
                this.document.number = 0;
            }
            fd.append('object', 'document');
            fd.append('name', this.document.name);
            fd.append('description', this.document.description || '');
            fd.append('watermark', +this.document.watermark);
            fd.append('secured', +this.document.secured);
            fd.append('id', this.document.id);
            this.type ? fd.append('type', this.type) : fd.append('type', this.document.type);
            fd.append('number', this.document.number);
            this.document.id < 1 ? fd.append('file', this.document.file) : null;
            this.topicId ? fd.append('topic_id', this.topicId) : fd.append('event_id', this.eventId);
            this.section ? fd.append('section_id', this.section.id) : null;
            this.questionId ? fd.append('question_id', this.questionId) : null;

            this.$awn.async(post(this.document.id > 1 ? this.paths.UPDATE_OBJECT : this.paths.SAVE_OBJECT, fd, true), response => {
                if (response.status) {
                    this.document.id = response.data.id;
                    this.document.native = response.data.native;
                    this.$awn.success(saveDocument);
                } else {
                    this.errors = response.errors;
                }
            });
        },
        async deleteDocument() {
            this.$confirm({
                ...defaultConfirmSettings,
                callback: confirm => {
                    if (confirm) {
                        if (this.document.id >= 1) {
                            this.$awn.async(post(this.paths.DELETE_OBJECT, {
                                topic_id: this.topicId,
                                event_id: this.eventId,
                                id: this.document.id,
                                object: 'document',
                            }), response => {
                                if (response.status) {
                                    this.$emit('delete', this.document.id, this.section);
                                    this.$awn.success(deletedDocument);
                                }
                            });
                        } else {
                            this.$emit('delete', this.document.id, this.section);
                        }
                    }
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-app-upload-documents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: to_rem(770px);

    .disposer-app-input {
        width: to_rem(200px);
    }

    .upload-main {
        display: flex;
        justify-content: space-between;

        .upload-checkboxes {
            display: flex;
            flex-direction: column;
            justify-content: center;

            label {
                width: to_rem(200px);

                font-size: 14px;
                color: grey;
            }

            input {
                margin: 0;
                padding: 0;
            }

            .disposer-app-checkbox {
                margin-bottom: 10px;
            }
        }

        .disposer-app-label {
            margin-left: 1rem;
        }
    }

    .upload-current-file {
        position: relative;

        margin-bottom: 1rem;

        color: grey;
    }

    .upload-btns {
        margin-left: 1rem;

        .row {
            position: relative;

            display: flex;

            .fileName {
                position: absolute;
                top: 3.3rem;
                left: 0;

                max-width: 100%;
                overflow: hidden;

                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .upload-choose {
            display: flex;

            .info-icon {
                margin-left: 10px;
            }
        }

        .upload-btn {
            margin-top: 0.75rem;
            margin-right: 1rem;
        }
    }
}
</style>
