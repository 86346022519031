<template>
    <appWorkshop />
</template>

<script>
  import appWorkshop from '@/components/user/app/app-workshop';

  export default  {
    name: 'workshop',
    components: {
      appWorkshop,
    },
  };
</script>
