<template>
    <div class="participants-chat">
        <div
            class="participants-chat__label"
            @click="toggleChat(true)"
        >
            <img src="@/assets/img/icons/chat.svg" />
            <span class="forDesktop">
                {{ $t('chat', 'Chat') }}
            </span>
            <div
                v-if="unreadCount"
                class="count"
            >
                {{ unreadCount }}
            </div>
        </div>
        <vue-resizable
            v-show="isActive"
            class="participants-chat__window"
            :drag-selector="dragSelectors"
            :min-width="300"
            :min-height="408"
            :width="300"
            :height="408"
            :max-width="700"
            :max-height="700"
            :fit-parent="true"
        >
            <div class="head">
                {{ $t('chat', 'Chat') }}
                <div
                    class="closer"
                    @click="toggleChat(false)"
                >
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div
                class="messages"
                @scroll="readMessages"
            >
                <scroll-loader
                    :loader-method="getMessages"
                    :loader-disable="!messages.length || lastMessage"
                />
                <div
                    v-for="message in showingMessages"
                    :key="message.id"
                    :data-read="message.status"
                    :data-id="message.id"
                >
                    <div
                        v-if="+message.user_id === +userId"
                        class="my-message"
                    >
                        {{ message.content }}
                    </div>
                    <div
                        v-else
                        class="other-message"
                    >
                        <div class="left">
                            <img src="@/assets/img/icons/chat.svg" />
                        </div>
                        <div class="right">
                            <div class="name">
                                {{ message.name }}
                            </div>
                            <div class="message">
                                {{ message.content }}
                            </div>
                            <div class="time">
                                {{ message.date }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form
                class="participants-chat__message-input"
                @submit.prevent="sendMessage"
            >
                <textarea
                    v-model="newMessage"
                    :placeholder="$t('message', 'Message')"
                    @keyup="checkSend"
                ></textarea>
                <app-button
                    tag="button"
                    type="submit"
                    class="purple narrow"
                >
                    {{ $t('send', 'Send') }}
                </app-button>
            </form>
        </vue-resizable>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';
import VueResizable from 'vue-resizable';

import { GP } from '@/utils/sockets-helper';
import { get } from '@/utils/http-helper';

const GET_CHAT = '/workshop/get-chat';
const NAME_SPACE = 'workshop';
const CREATE_MESSAGE = 'chat-create';
const TOGGLE_CHAT = 'chat-toggle';
const READ_CHAT = 'chat-read';
const CHAT_NOTIFICATION = 'chat-notification';
const generateSocketPath = new GP(NAME_SPACE);

export default {
    name: 'participants-chat',
    components: {
        VueResizable,
        appButton,
    },

    props: {
        socket: {
            type: Object,
            default: null,
        },
        page: {
            type: Number,
            default: null,
        },
        unreadCount: {
            type: Number,
            default: 0,
        },
    },

    data: () => ({
        messages: [],
        isActive: false,
        newMessage: '',
        lastMessage: false,
        readObj: [],
    }),

    computed: {
        topicId() {
            return this.$route.query.id;
        },
        userId() {
            return this.$store.getters['auth/userInfo'].id;
        },
        dragSelectors() {
            if (window.isMobileOrTablet) {
                return '';
            }
            return '.head';
        },
        showingMessages() {
            return [...this.messages].reverse();
        },
    },

    created() {
        this.initSocket();
    },

    methods: {
        initSocket() {
            this.socket.on(generateSocketPath.generate(CREATE_MESSAGE), data => {
                if (data.success) {
                    this.messages.unshift(data);
                    this.$emit('read', this.unreadCount + 1);
                }
            });
            this.socket.on(generateSocketPath.generate(CHAT_NOTIFICATION), data => {
                if (data.success) {
                    this.$emit('read', this.unreadCount + data.inc);
                }
            });
        },
        async toggleChat(status) {
            this.isActive = status;
            if (this.isActive && !this.messages.length) {
                const messages = await get(GET_CHAT, { topic_id: this.topicId });
                this.messages = [...this.messages, ...messages.data.messages];
            }
            this.socket.emit(generateSocketPath.generate(TOGGLE_CHAT), { state: +this.isActive });
            const messagesContainer = document.querySelector('.participants-chat .messages');
            this.$nextTick(() => {
                this.readMessages();
                messagesContainer.scrollTo({ top: messagesContainer.scrollHeight });
            });
        },
        async getMessages() {
            const messageId = this.messages[this.messages.length - 1]?.id;
            const messages = await get(GET_CHAT, { topic_id: this.topicId, message_id: messageId });
            if (messages.data.messages.length) {
                const oldLength = this.messages.length;
                this.messages = [...this.messages, ...messages.data.messages];
                if (this.messages.length - oldLength < 25) {
                    this.lastMessage = true;
                }
            } else {
                this.lastMessage = true;
            }
        },
        sendMessage() {
            if (this.newMessage.trim()) {
                this.socket.emit(generateSocketPath.generate(CREATE_MESSAGE), { content: this.newMessage }, data => {
                    if (data.success) {
                        this.messages.unshift(data);
                        this.newMessage = '';
                    }
                });
            }
        },
        readMessages() {
            const messages = document.querySelectorAll('.participants-chat .messages > div');
            const messagesContainer = document.querySelector('.participants-chat .messages');
            messages.forEach((message) => {
                if (message.offsetTop - messagesContainer.scrollTop < messagesContainer.offsetHeight) {
                    const readStatus = message.getAttribute('data-read');
                    const messageId = message.getAttribute('data-id');
                    if (readStatus === '0') {
                        this.readObj.push(+messageId);
                        this.messages.find(m => +m.id === +messageId).status = 1;
                    }
                }
            });
            clearTimeout(window.readChatMessagesT);
            window.readChatMessagesT = setTimeout(() => {
                this.socket.emit(generateSocketPath.generate(READ_CHAT), { ids: this.readObj }, data => {
                    if (data.success) {
                        this.$emit('read', this.unreadCount - data.count);
                        this.readObj = [];
                    }
                });
            }, 100);
        },
        checkSend(e) {
            if (e.which == 13 && !e.shiftKey) {
                e.preventDefault();
                this.sendMessage();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.participants-chat {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100000;

    pointer-events: none;

    & > div {
        pointer-events: all;
    }

    &__label {
        position: absolute;
        top: 4.9rem;
        right: 3.7rem;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 8rem;
        height: 2.9rem;

        font-size: to_rem(18px);
        color: #fff;

        background: $mainBlue;
        border-radius: 0 0 0.5rem 0.5rem;

        cursor: pointer;

        img {
            width: 1.75rem;
            margin-right: 0.5rem;

            transform: scaleX(-1);
        }

        .count {
            position: absolute;
            top: -.4rem;
            right: -.4rem;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 1.8rem;
            height: 1.8rem;

            background: $red;
            border-radius: 50%;
        }
    }

    &__window {
        position: absolute;
        top: 27rem;
        right: -8.8rem;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 18.9rem;
        padding-bottom: 1px;
        overflow: hidden;

        background: #fff;
        border-radius: .5rem .5rem 0 0;

        &::v-deep {
            &.vdr.active:before {
                display: none;
            }
        }

        .head {
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;

            height: 2.8rem;

            font-size: to_rem(18px);
            color: #fff;

            background: $mainBlue;

            cursor: move;

            .closer {
                position: absolute;
                top: 50%;
                right: 0.6rem;

                width: 1rem;
                height: 1rem;

                transform: translateY(-50%);
                cursor: pointer;

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    display: block;

                    width: 100%;
                    height: 2px;

                    background: #fff;
                    border-radius: 1px;

                    transform: translate(-50%, -50%) rotate(45deg);

                    &:nth-of-type(2) {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
            }
        }

        .messages {
            height: calc(100% - 4.7rem - 2.8rem);
            padding: 1.1rem 0.6rem;
            overflow-y: scroll;

            border-left: 1px solid $gray;

            & > div {
                max-width: 87%;
                margin-bottom: 0.8rem;
            }

            .my-message {
                width: fit-content;
                margin-left: auto;
                padding: 0.4rem 0.5rem;

                font-size: to_rem(14px);
                color: #fff;

                background: $mainBlue;
            }

            .other-message {
                display: flex;
                align-items: center;

                .left {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    width: 2.6rem;
                    height: 2.6rem;
                    margin-right: 0.4rem;

                    background: $mainBlue;
                    border-radius: 50%;
                }

                .right {
                    width: 80%;
                }

                .name {
                    font-size: to_rem(12px);
                    font-weight: 700;
                    color: $gray;
                    letter-spacing: -0.02rem;
                }

                .message {
                    padding: 0.5rem;

                    font-size: to_rem(14px);

                    background: $lightGray;
                }

                .time {
                    margin-top: 0.4rem;

                    font-size: to_rem(8px);
                    text-align: center;
                    color: $gray;
                }

                img {
                    width: 1.5rem;

                    transform: scaleX(-1);
                }
            }
        }
    }

    &__message-input {
        position: relative;

        height: 4.7rem;

        textarea {
            width: 100%;
            height: 100%;
            padding: 0.9rem 5.5rem 0.9rem 0.9rem;

            border: 1px solid $gray;
            outline: none;

            resize: none;

            &::placeholder {
                color: $gray;
            }
        }

        .app-button {
            position: absolute;
            top: 1.1rem;
            right: 1.1rem;

            min-width: 4rem;
            height: 2.8rem;
        }
    }

    @media screen and (max-width: 1023px) {
        z-index: 100000000;
        &__label {
            position: fixed;
            top: auto;
            right: 2rem;
            bottom: 2rem;

            width: 4rem;
            height: 4rem;

            border-radius: 50%;

            img {
                margin-right: 0;
            }

            .count {
                right: -0.8rem;
            }
        }
        &__window {
            position: fixed;
            top: auto !important;
            right: 0;
            bottom: 0;
            left: auto !important;
        }
    }
}
</style>
