<template>
    <div class="disposer-interface">
        <div class="disposer-app-title">
            Interface
        </div>
        <div class="bg-login-page-topic topic-wrap">
            <div class="disposer-app-topic-title">
                Backgroung login page
            </div>
            <div class="icons-content-flex">
                <img
                    :src="backgroundLpUrl"
                    alt="*****"
                    class="background-lp interface-image"
                />
                <div class="actions">
                    <app-actions
                        :edit="() => editFields('background_lp_mode')"
                        :trash="() => deleteFields('background_lp')"
                    />
                    <div
                        v-if="background_lp_mode"
                        class="btns-wrap"
                    >
                        <div class="edit-image">
                            <label
                                class="choose-label"
                                for="choose-input"
                            >
                                Choose Image
                            </label>
                            <input
                                id="choose-input"
                                ref="backgroundLpFile"
                                type="file"
                                class="choose-input-file"
                                @change="() => chooseFile('backgroundLpFile', 'backgroundLpUrl')"
                            />
                        </div>
                        <div class="upload-image">
                            <label
                                class="upload-label"
                                for="upload-input"
                            >
                                Upload Image
                            </label>
                            <input
                                id="upload-input"
                                class="upload-input-file"
                                @click="() => uploadFile('background_lp', 'backgroundLpFile')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="title-login-topic topic-wrap">
            <label
                for="title-input"
                class="disposer-app-label"
            >
                Title login page
            </label>
            <div class="icons-content-flex">
                <input
                    id="title-input"
                    :value="interfacee.title_lp | htmlToString"
                    type="text"
                    :disabled="!title_lp_mode"
                    class="disposer-app-input title-lp"
                    @focus="() => showModal('title_subject')"
                />
                <app-actions
                    :edit="() => editFields('title_lp_mode')"
                    :trash="() => deleteFields('title_lp')"
                    :save="() => updateFields('title_lp', interfacee.title_lp)"
                />
            </div>
        </div>
        <div class="company-topic topic-wrap">
            <div class="disposer-app-topic-title">
                Company logo
            </div>
            <div class="icons-content-flex">
                <img
                    v-if="companyLogoUrl"
                    :src="companyLogoUrl"
                    alt="*****"
                    class="company-logo interface-image"
                />
                <div class="actions">
                    <app-actions
                        :edit="() => editFields('company_logo_mode')"
                        :trash="() => deleteFields('company_logo')"
                    />
                    <div
                        v-if="company_logo_mode"
                        class="btns-wrap"
                    >
                        <div class="edit-image">
                            <label
                                class="choose-label"
                            >
                                Choose Image
                                <input
                                    ref="companyLogoFile"
                                    type="file"
                                    class="choose-input-file"
                                    @change="() => chooseFile('companyLogoFile', 'companyLogoUrl')"
                                />
                            </label>
                        </div>
                        <div class="upload-image">
                            <label
                                class="upload-label"
                            >
                                Upload Image
                                <input
                                    class="upload-input-file"
                                    @click="() => uploadFile('company_logo', 'companyLogoFile')"
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns-color-topic topic-wrap">
            <div class="disposer-app-topic-title">
                Color Customization
            </div>
            <div class="btns-color-topic__row">
                <div class="btns-color-topic__item">
                    <div class="btns-color-topic__name">
                        Main
                    </div>
                    <div class="icons-content-flex">
                        <div
                            class="btns-color"
                            :style="{ backgroundColor: interfacee.buttons_color_main }"
                        >
                        </div>
                        <div class="actions">
                            <app-actions
                                :edit="() => editFields('buttons_color_main_mode')"
                            />

                            <div
                                v-if="buttons_color_main_mode"
                                class="choose-btns-color"
                            >
                                <div
                                    v-click-outside="() => {closeColorPicker('main')}"
                                    class="choose-color"
                                >
                                    <label @click="toggleColorPicker('main')">
                                        Choose color
                                    </label>
                                    <Chrome
                                        v-if="showMainColorPicker"
                                        :value="interfacee.buttons_color_main"
                                        @input="updateColor($event, 'buttons_color_main')"
                                    />
                                </div>
                                <div class="upload-color">
                                    <label @click="() => updateFields('buttons_color_main', interfacee.buttons_color_main)">
                                        Update color
                                    </label>
                                </div>
                                <div class="upload-color">
                                    <label @click="toDefaultColor('buttons_color_main', '#0f9bdc')">
                                        Default color
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btns-color-topic__item">
                    <div class="btns-color-topic__name">
                        Accent
                    </div>
                    <div class="icons-content-flex">
                        <div
                            class="btns-color"
                            :style="{ backgroundColor: interfacee.buttons_color_accent }"
                        >
                        </div>
                        <div class="actions">
                            <app-actions
                                :edit="() => editFields('buttons_color_accent_mode')"
                            />

                            <div
                                v-if="buttons_color_accent_mode"
                                class="choose-btns-color"
                            >
                                <div
                                    v-click-outside="() => {closeColorPicker('accent')}"
                                    class="choose-color"
                                >
                                    <label @click="toggleColorPicker('accent')">
                                        Choose color
                                    </label>
                                    <Chrome
                                        v-if="showAccentColorPicker"
                                        :value="interfacee.buttons_color_accent"
                                        @input="updateColor($event, 'buttons_color_accent')"
                                    />
                                </div>
                                <div class="upload-color">
                                    <label>
                                        Update color
                                        <input
                                            class="upload-input-file"
                                            @click="() => updateFields('buttons_color_accent', interfacee.buttons_color_accent)"
                                        />
                                    </label>
                                </div>
                                <div class="upload-color">
                                    <label @click="toDefaultColor('buttons_color_accent', '#255C9B')">
                                        Default color
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btns-color-topic__item">
                    <div class="btns-color-topic__name">
                        LogIn Frame Color
                    </div>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="interfacee.login_frame"
                            type="radio"
                            value="1"
                            @change="updateFields('login_frame', interfacee.login_frame)"
                        />
                        <div class="box"></div>
                        Main
                    </label>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="interfacee.login_frame"
                            type="radio"
                            value="2"
                            @change="updateFields('login_frame', interfacee.login_frame)"
                        />
                        <div class="box"></div>
                        Accent
                    </label>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="interfacee.login_frame"
                            type="radio"
                            value="3"
                            @change="updateFields('login_frame', interfacee.login_frame)"
                        />
                        <div class="box"></div>
                        Transparent
                    </label>
                </div>
            </div>
        </div>
        <div class="support-topic topic-wrap">
            <label
                class="disposer-app-label"
            >
                Support E-mail
            </label>
            <div class="icons-content-flex">
                <input
                    id="email-input"
                    v-model="interfacee.support_email"
                    type="text"
                    :disabled="!support_email_mode"
                    class="disposer-app-input"
                />
                <app-actions
                    :edit="() => editFields('support_email_mode')"
                    :save="() => updateFields('support_email', interfacee.support_email)"
                />
            </div>
        </div>
        <div class="support-topic topic-wrap">
            <label
                for="phone-input"
                class="disposer-app-label"
            >
                Support phone number
            </label>
            <div class="icons-content-flex">
                <input
                    id="phone-input"
                    v-model="interfacee.support_phone"
                    type="text"
                    :disabled="!support_phone_mode"
                    class="disposer-app-input"
                />
                <app-actions
                    :edit="() => editFields('support_phone_mode')"
                    :save="() => updateFields('support_phone', interfacee.support_phone)"
                />
            </div>
        </div>
        <div class="footer-topic topic-wrap">
            <label
                for="footer-textarea"
                class="disposer-app-label"
            >
                Footer
            </label>
            <p class="footer-topic-parag">
                To change the text, contact your administrator
            </p>
            <textarea
                id="footer-textarea"
                type="text"
                class="disposer-app-input"
                :disabled="true"
                :placeholder="interfacee.footer_content"
            >
            </textarea>
        </div>
        <div class="company-topic topic-wrap">
            <div class="disposer-app-topic-title">
                Footer logo
            </div>
            <div class="icons-content-flex">
                <img
                    v-if="footerLogoUrl"
                    :src="footerLogoUrl"
                    alt="*****"
                    class="company-logo interface-image"
                />
                <div class="actions">
                    <app-actions
                        :edit="() => editFields('footer_logo_mode')"
                        :trash="() => deleteFields('footer_logo')"
                    />
                    <div
                        v-if="footer_logo_mode"
                        class="btns-wrap"
                    >
                        <div class="edit-image">
                            <label
                                class="choose-label"
                            >
                                Choose Image
                                <input
                                    ref="footerLogoFile"
                                    type="file"
                                    class="choose-input-file"
                                    @change="() => chooseFile('footerLogoFile', 'footerLogoUrl')"
                                />
                            </label>
                        </div>
                        <div class="upload-image">
                            <label
                                class="upload-label"
                            >
                                Upload Image
                                <input
                                    class="upload-input-file"
                                    @click="() => uploadFile('footer_logo', 'footerLogoFile')"
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="menu"
            class="configure-topic topic-wrap"
        >
            <div class="disposer-app-topic-title">
                Configure the platform
            </div>
            <div
                v-for="checkbox in checkboxes"
                :key="checkbox.name"
                class="configure-topic-checkboxes"
            >
                <label
                    class="disposer-app-checkbox"
                    :data-disabled="Boolean(checkbox.disabled)"
                >
                    <input
                        v-model="checkbox.checked"
                        type="checkbox"
                        :disabled="Boolean(checkbox.disabled)"
                        @change="updateCheckboxFields(checkbox)"
                    />
                    <div class="box"></div>
                    <template v-if="editedMenuField !== checkbox.field">
                        {{ menu[checkbox.field.slice(0, -1) + 't'] || checkbox.name }}
                    </template>
                    <template v-else>
                        <input
                            v-model="editMenuValue"
                            class="disposer-app-input"
                            type="text"
                        />
                    </template>
                </label>
                <app-actions
                    :edit="() => toggleEditMenuField(checkbox.field)"
                    :back="() => restoreMenuField(checkbox.field)"
                    :save="() => saveMenuField(checkbox.field)"
                />
            </div>
        </div>
        <div class="disclaimers-topic topic-wrap">
            <div class="disposer-app-topic-title">
                Disclaimer
            </div>
            <div
                v-for="(disclaimer, idx) in disclaimers"
                :key="disclaimer.id"
                class="disclaimers-topic-inputs"
            >
                <div
                    class="icons-content-flex"
                >
                    <textarea
                        type="text"
                        :value="disclaimer.value | htmlToString"
                        :disabled="!disclaimer.edit_mode"
                        class="disposer-app-input"
                        @focus="() => showModal('disclaimer', idx)"
                    >
                    </textarea>
                    <app-actions
                        v-if="!disclaimer.is_permanent"
                        :edit="() => editDisclaimerFields(idx)"
                        :trash="() => deleteDisclaimerFields(disclaimer.id)"
                        :save="() => {
                            add_disclaimer_mode ? createDisclaimer(disclaimer, idx) : updateDisclaimerFields(disclaimer, idx)
                        }"
                    />
                </div>
            </div>
            <app-button
                v-if="!add_disclaimer_mode"
                @click="addDisclaimer"
            >
                ADD
            </app-button>
        </div>
        <div class="notifications-topic topic-wrap">
            <div class="disposer-app-topic-title">
                Notification
            </div>

            <div class="notifications-topic-parags">
                <p
                    v-for="(notification, idx) in $options.texts.notificationsTitle"
                    :key="idx"
                    class="notifications-topic-parag mg-bt-20"
                    v-html="notification"
                ></p>
            </div>
        </div>
        <div class="settings">
            <ul class="settings-ul">
                <li
                    v-for="(notification, idx) in notifications"
                    :key="notification.name"
                >
                    <div class="disposer-app-topic-title">
                        {{ notification.name }}
                    </div>
                    <div class="icons-content-flex">
                        <div class="email-subject">
                            <label
                                class="disposer-app-label"
                            >
                                Email Subject
                                <input
                                    :value="notification.values[0].value"
                                    :disabled="!notification.values[0].edit_mode || isModalVisible"
                                    type="text"
                                    class="disposer-app-input"
                                    @focus="() => showModal('email_subject', idx)"
                                />
                            </label>
                        </div>
                        <app-actions
                            :edit="() => editNotification(idx, 0)"
                            :back="() => restoreNotification(notification.values[0])"
                            :save="() => updateNotification(notification.values[0], idx, 0)"
                        />
                    </div>
                    <div class="icons-content-flex">
                        <div class="email-content">
                            <label
                                for="em-cont-txtarea"
                                class="disposer-app-label"
                            >
                                Email Content
                            </label>
                            <textarea
                                id="em-cont-txtarea"
                                :value="notification.values[1].value"
                                :disabled="!notification.values[1].edit_mode || isModalVisible"
                                type="text"
                                class="disposer-app-input"
                                @focus="() => showModal('email_content', idx)"
                            >
                            </textarea>
                        </div>
                        <app-actions
                            :edit="() => editNotification(idx, 1)"
                            :back="() => restoreNotification(notification.values[1])"
                            :save="() => updateNotification(notification.values[1], idx, 1)"
                        />
                    </div>
                    <div class="icons-content-flex">
                        <div class="sms">
                            <label
                                for="sms-txtarea"
                                class="disposer-app-label"
                            >
                                SMS
                            </label>
                            <textarea
                                id="sms-txtarea"
                                :value="notification.values[2].value"
                                :disabled="!notification.values[2].edit_mode || isModalVisible"
                                type="text"
                                class="disposer-app-input"
                                @focus="() => showModal('sms', idx)"
                            >
                            </textarea>
                        </div>
                        <app-actions
                            :edit="() => editNotification(idx, 2)"
                            :back="() => restoreNotification(notification.values[2])"
                            :save="() => updateNotification(notification.values[2], idx, 2)"
                        />
                    </div>
                </li>
            </ul>
        </div>
        <div class="footer-modal">
            <app-modal
                v-if="isModalVisible"
                @closeModal="closeModal"
                @saveHandler="saveEditorData"
            >
                <app-editor
                    v-model="editorData"
                    @editorDataHandler="getEditorData($event)"
                />
            </app-modal>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import appActions from '@/components/disposer/app/app-actions';
import appButton from '@/components/disposer/app/app-button';
import appModal from '@/components/disposer/app/app-modal';
import appEditor from '@/components/disposer/app/app-editor';
import { Chrome } from 'vue-color';

import htmlToString from '@/utils/filters/html-to-string';

import notificationsTitle from '@/utils/disposer/interface-text-helper.js';
import { post } from '@/utils/http-helper';
import { textUpdated } from '@/utils/disposer/notifications-text-helper';


import { mapActions } from 'vuex';

const UPDATE_TEXT = '/interface/update-text';

export default {
    name: 'disposer-managers',

    components: {
        appActions,
        appButton,
        appModal,
        appEditor,
        Chrome,
    },

    directives: {
        clickOutside: vClickOutside.directive,
    },

    filters: {
        htmlToString,
    },

    data: () => ({
        background_lp_mode: false,
        company_logo_mode: false,
        footer_logo_mode: false,
        title_lp_mode: false,

        buttons_color_main_mode: false,
        buttons_color_accent_mode: false,

        support_email_mode: false,
        support_phone_mode: false,

        add_disclaimer_mode: false,

        editorData: '',
        isModalVisible: false,
        idx_mode: -1,

        showMainColorPicker: false,
        showAccentColorPicker: false,
        interfacee: {},
        disclaimers: [],
        notifications: [],
        checkboxes: [],

        backgroundLpUrl: '',
        companyLogoUrl: '',
        footerLogoUrl: '',
        backgroundLpFile: '',
        companyLogoFile: '',

        menu: null,
        editedMenuField: '',
        editMenuValue: '',
    }),
    texts: {
        notificationsTitle,
    },
    computed: {
        platformInterface() {
            return this.$store.getters.platformInterface;
        },

        platformSettings() {
            return this.$store.getters['platform/platform'];
        },
    },
    watch: {
        platformInterface(newPlatformInterface) {
            this.interfacee = newPlatformInterface.interface;
            this.disclaimers = newPlatformInterface.disclaimers;
            this.notifications = newPlatformInterface.notifications;
            this.checkboxes = newPlatformInterface.interface.visibility;

            const baseURL = process.env.VUE_APP_BASE_URL + '/';

            this.backgroundLpUrl = baseURL + this.interfacee?.background_lp;
            this.companyLogoUrl = this.interfacee?.company_logo ? baseURL + this.interfacee?.company_logo : '';
            this.footerLogoUrl = this.interfacee?.footer_logo ? baseURL + this.interfacee?.footer_logo : '';
        },
    },
    async mounted() {
        await this.$store.dispatch('getPlatformInterface');

        this.menu = this.platformSettings.menu;
    },
    methods: {
        ...mapActions(
            [
                'getPlatformInterface',
                'uploadInterfaceImage',
                'updateInterfaceFields',
                'updateInterfaceCheckboxFields',
                'createInterfaceDisclaimerFields',
                'restoreInterfaceNotificationFields',
                'deleteInterfaceFields',
            ],
        ),
        toggleColorPicker(color) {
            if (color === 'main') {
                this.showMainColorPicker = !this.showMainColorPicker;
            } else {
                this.showAccentColorPicker = !this.showAccentColorPicker;
            }
        },
        closeColorPicker(color) {
            if (color === 'main') {
                this.showMainColorPicker = false;
            } else {
                this.showAccentColorPicker = false;
            }
        },
        updateColor(e, color) {
            this.interfacee[color] = e.hex;
        },
        showModal(v, idx) {
            this.isModalVisible = !this.isModalVisible;
            this.editedObject = v;
            this.idx_mode = idx;
            if (v === 'title_subject') {
                this.editorData = this.interfacee.title_lp;
            } else if (v === 'email_subject') {
                this.editorData = this.notifications[idx].values[0].value;
            } else if (v === 'email_content') {
                this.editorData = this.notifications[idx].values[1].value;
            } else if (v === 'sms') {
                this.editorData = this.notifications[idx].values[2].value;
            } else if (v === 'disclaimer') {
                this.editorData = this.disclaimers[idx].value;
            }
        },
        closeModal() {
            this.isModalVisible = !this.isModalVisible;
            this.editedObject = null;
            this.idx_mode = -1;
        },
        getEditorData(e) {
            this.editorData = e;
        },
        saveEditorData() {
            let idx = this.idx_mode;

            if (this.editedObject === 'title_subject') {
                this.interfacee.title_lp = this.editorData;
            } else if (this.editedObject === 'email_subject') {
                this.notifications[idx].values[0].value = this.editorData;
            } else if (this.editedObject === 'email_content') {
                this.notifications[idx].values[1].value = this.editorData;
            } else if (this.editedObject === 'sms') {
                this.notifications[idx].values[2].value = this.editorData;
            } else if (this.editedObject === 'disclaimer') {
                this.disclaimers[idx].value = this.editorData;
            }

            this.closeModal();
        },
        editFields(mode) {
            this.showColorPicker = false;
            this[mode] = !this[mode];
        },
        editDisclaimerFields(disclaimerIdx) {
            this.disclaimers[disclaimerIdx].edit_mode = !this.disclaimers[disclaimerIdx].edit_mode;
        },
        chooseFile(file, url) {
            const data = this.$refs[file];
            this[file] = data.files[0];
            this[url] = URL.createObjectURL(this[file]);
        },
        async uploadFile(field, file) {
            let formData = new FormData();

            formData.append('field', field);
            formData.append('image', this[file]);

            this[field + '_mode'] = false;
            await this.uploadInterfaceImage(formData);
        },
        async updateFields(field, value) {
            let data = {
                field,
                value,
            };
            await this.updateInterfaceFields(data);
            this[field + '_mode'] = false;
        },
        async updateDisclaimerFields({ value, id }, idx) {
            let data = {
                field: 'disclaimer',
                value,
                id,
            };
            this.disclaimers[idx].edit_mode = !this.disclaimers[idx].edit_mode;
            await this.updateInterfaceFields(data);
        },
        async updateCheckboxFields({ field, checked }) {
            let data = {
                value: [
                    {
                        'field': field,
                        'checked': Number(checked),
                    },
                ],
            };
            await this.updateInterfaceCheckboxFields(data);
        },
        addDisclaimer() {
            this.disclaimers.push({
                edit_mode: true,
            });
            this.add_disclaimer_mode = true;
        },
        async createDisclaimer({ value }, idx) {
            const data = {
                'platform_id': this.$store.getters['auth/platformId'],
                'field': 'disclaimer',
                'value': value,
            };
            await this.createInterfaceDisclaimerFields(data);
            this.disclaimers[idx].edit_mode = !this.disclaimers[idx].edit_mode;
            this.add_disclaimer_mode = false;
        },
        async deleteFields(field) {
            this.deleteInterfaceFields({ field });
            await this.getPlatformInterface();
        },
        async deleteDisclaimerFields(id) {
            this.deleteInterfaceFields({ field: 'disclaimer', id });
            await this.getPlatformInterface();
        },
        editNotification(idx, id) {
            this.notifications[idx].values[id].edit_mode = !this.notifications[idx].values[id].edit_mode;
        },
        async updateNotification({ field, value }, idx, id) {
            const data = {
                'field': 'notification',
                'name': field,
                'value': value,
            };
            await this.updateInterfaceFields(data);
            this.notifications[idx].values[id].edit_mode = false;
        },
        async restoreNotification({ field }) {
            await this.restoreInterfaceNotificationFields({ field });
            setTimeout(() => {
                this.getPlatformInterface();
            }, 100);
        },

        toDefaultColor(name, color) {
            this.interfacee[name] = color;
            this.updateFields(name, this.interfacee[name]);
        },

        toggleEditMenuField(field) {
            if (this.editedMenuField !== field) {
                this.editedMenuField = field;
                this.editMenuValue = this.menu[field.slice(0, -1) + 't'] || this.checkboxes.find(c => c.field === field).name;
            } else  {
                this.editedMenuField = '';
                this.editMenuValue = '';
            }
        },
        async saveMenuField(field) {
            await this.$awn.async(post(UPDATE_TEXT, {
                field: field.slice(0, -1) + 't',
                value: this.editMenuValue,
            }), response => {
                if (response.status) {
                    this.$awn.success(textUpdated);
                    this.editedMenuField = '';
                    this.menu[field.slice(0, -1) + 't'] = this.editMenuValue;
                    this.editMenuValue = '';
                }
            });
        },
        async restoreMenuField(field) {
            this.editMenuValue = '';
            await this.saveMenuField(field);
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-interface {
    .choose-color {
        position: relative;
        &::v-deep {
            .vc-chrome {
                position: absolute;
                top: calc(100% + 10px);
                z-index: 10;
            }
        }
    }

    .disposer-app-topic-title {
        width: 48rem;
    }

    .disposer-app-title {
        margin-bottom: 0;
    }

    input:disabled,
    textarea:disabled {
        background-color: #eee;
    }

    .topic-wrap {
        padding: 40px 0;

        border-bottom: 1px solid $gray;
    }

    .mg-bt-20 {
        margin-bottom: to_rem(20px);
    }

    .interface-image {
        width: 20rem;
    }

    .icons-content-flex {
        .btns-wrap {
            .choose-label,
            .upload-label {
                display: block;

                width: 130px;
                margin-top: 15px;
                padding: 5px;

                font-size: 1rem;
                text-align: center;
                color: white;

                background-color: #222d31;
                border-radius: 5px;

                cursor: pointer;
            }

            .choose-input-file,
            .upload-input-file {
                display: none;

                opacity: 0;
            }
        }
    }

    .title-login-topic {
        .disposer-app-input {
            width: to_rem(750px);

            &:disabled {
                background-color: #eee;
            }
        }
    }

    .btns-color-topic {
        .btns-color {
            width: to_rem(90px);
            height: to_rem(90px);
            margin-right: to_rem(20px);

            background-color: $mainBlue;
            border-radius: 1px;
        }

        .choose-btns-color {
            label {
                display: block;

                width: 130px;
                height: 30px;
                margin-top: 15px;
                padding: 5px;

                font-size: 1rem;
                text-align: center;
                color: white;

                background-color: #222d31;
                border-radius: 5px;

                cursor: pointer;
            }

            input {
                opacity: 0;
            }
        }

        &__row {
            display: flex;
        }

        &__item {
            margin-right: 20px;

            .disposer-app-checkbox {
                margin-bottom: 10px;
            }
        }

        &__name {
            margin-bottom: 10px;

            font-size: 13px;
            font-weight: 700;
        }
    }

    .support-topic {
        .disposer-app-input {
            width: to_rem(260px);
        }
    }

    .configure-topic {
        &-checkboxes {
            display: flex;
            align-items: center;

            margin-bottom: to_rem(10px);

            color: $gray;

            .app-actions {
                margin-left: 10px;
            }

            input[type="text"] {
                opacity: 1;

                pointer-events: all !important;
            }
        }
    }

    .footer-topic {
        &-parag {
            width: to_rem(630px);
            padding-bottom: to_rem(10px);
            padding-left: to_rem(10px);

            color: $gray;
        }

        .disposer-app-input {
            width: to_rem(750px);
            height: to_rem(55px);
        }
    }

    .disclaimers-topic {
        &-inputs {
            .disposer-app-input {
                width: to_rem(750px);
                height: to_rem(100px);
                margin-bottom: to_rem(20px);
            }
        }
    }

    .notifications-topic {
        &-parag {
            width: to_rem(630px);
            padding-left: to_rem(10px);

            color: $gray;
        }
    }

    .settings {
        label {
            display: block;

            margin-bottom: to_rem(10px);
        }

        &-ul {
            padding-left: to_rem(20px);
        }

        .icons-content-flex {
            margin-bottom: to_rem(20px);
        }

        .email-subject {
            .disposer-app-input {
                width: to_rem(750px);
            }
        }

        .email-content {
            .disposer-app-input {
                width: to_rem(750px);
                height: to_rem(150px);
            }
        }

        .sms {
            .disposer-app-input {
                width: to_rem(750px);
                height: to_rem(55px);
            }
        }
    }

    textarea {
        padding: 10px;

        resize: none;
    }

    .icons-content-flex {
        display: flex;

        img {
            padding-right: to_rem(20px);
        }

        .disposer-app-input {
            margin-right: to_rem(20px);
        }

        textarea {
            margin-right: to_rem(20px);
        }
    }
}
</style>
