import { get } from '@/utils/http-helper';

const GET = '/agenda/view';

export default {
  namespaced: true,
  state: {
    agenda: null,
  },
  actions: {
    async get({ commit }, eventId) {
      const agenda = await get(GET, { event_id: eventId });
      commit('updateAgenda', agenda.data);
    },
  },
  mutations: {
    updateAgenda(state, agenda) {
      state.agenda = agenda;
    },
  },
  getters: {
    get: state => state.agenda,
  },
};
