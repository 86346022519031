import Vue from 'vue';

import VueAWN from 'vue-awesome-notifications';

let options = {
    durations: {
        global: 2000,
        success: 1000,
    },

    minDurations: {
        async: 0,
    },
};

Vue.use(VueAWN, options);

import 'vue-awesome-notifications/dist/styles/style.css';
