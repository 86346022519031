<template>
    <div class="it-support">
        <!-- <div class="page-title">
            {{ $t('support', 'IT-support') }}
        </div> -->
        <div class="container">
            <p>
                {{ $t('sup_title', 'In case of any issue - please send the request to our IT support - we’ll contact you soon.') }}
            </p>
            <form @submit.prevent="createQuestion">
                <div class="inputs-wrapper">
                    <label :class="{error: $v.form.firstName.$dirty && !$v.form.firstName.required}">
                        <p>
                            {{ $t('sup_firstname', 'First Name') }}
                        </p>
                        <input
                            v-model="form.firstName"
                            class="app-input"
                            type="text"
                        />
                    </label>
                    <label :class="{error: $v.form.lastName.$dirty && !$v.form.lastName.required}">
                        <p>
                            {{ $t('sup_lastname', 'Last Name') }}
                        </p>
                        <input
                            v-model="form.lastName"
                            class="app-input"
                            type="text"
                        />
                    </label>
                    <label :class="{error: $v.form.email.$dirty && (!$v.form.email.required || !$v.form.email.email)}">
                        <p>
                            {{ $t('sup_email', 'Email Address') }}
                        </p>
                        <input
                            v-model="form.email"
                            class="app-input"
                            type="text"
                        />
                    </label>
                    <label :class="{error: $v.form.issue.$dirty && !$v.form.issue.required}">
                        <p>
                            {{ $t('sup_issue', 'Issue') }}
                        </p>
                        <textarea
                            v-model="form.issue"
                            class="app-input"
                        ></textarea>
                    </label>
                </div>
                <app-button
                    tag="button"
                    type="submit"
                    class="narrow accent"
                >
                    {{ $t('send', 'Send') }}
                </app-button>
            </form>
        </div>
    </div>
</template>

<script>
    import { post } from '@/utils/http-helper';
    import { required, email } from 'vuelidate/lib/validators';
    import appButton from '@/components/user/app/app-button';

    const CREATE_QUESTION = '/it-support/create';

    export default {
        name: 'it-support',

        components: {
            appButton,
        },

        data() {
          return {
            form: {
              firstName: '',
              lastName: '',
              email: '',
              issue: '',
            },
          };
        },

        validations: {
          form: {
            firstName: {
              required,
            },
            lastName: {
              required,
            },
            email: {
              email,
              required,
            },
            issue: {
              required,
            },
          },
        },

        computed: {
            platformId() {
                return this.$store.getters['platform/platform']?.id;
            },
        },

        mounted() {
            const user = this.$store.getters['auth/userInfo'];
            this.form = {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            };
        },

        methods: {
            createQuestion() {
              if (this.$v.$invalid) {
                this.$v.$touch();
                return;
              }
              const user = this.$store.getters['auth/userInfo'];
              const data = {
                ...this.form,
                event_id: user.event?.id,
                platform_id: this.platformId,
              };
              this.$awn.async(post(CREATE_QUESTION, data), response => {
                if (response.status) {
                  this.form = {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    issue: '',
                  };
                  this.$v.$reset();
                  this.$awn.success('Succsess');
                }
              });
            },
        },
    };
</script>

<style scoped lang="scss">
    .it-support{
        .container{
            padding: 1.2rem 1.9rem;

            background: white;
        }
        p{
            margin-bottom: 1.5rem;

            font-weight: 700;
            color: $copyGray;
        }
        .inputs-wrapper{
            margin-bottom: 1.4rem;
            padding: 1.5rem 3rem 1.5rem 1.5rem;

            font-size: to_rem(14px);

            border: 10px solid $lightGray;

            label{
                position: relative;

                display: flex;
                justify-content: space-between;

                margin-bottom: 1.45rem;
                &.error {
                    p {
                        color: red;
                    }
                }
            }
            p{
                display: flex;
                align-items: center;

                height: 1.8rem;
                margin-bottom: 0;
            }
            .app-input{
                width: 80%;
            }
            textarea{
                min-height: 2.8rem;
                padding-top: 0.5rem;

                resize: vertical;
            }
        }
        .app-button{
            margin: 0 auto;
        }
    }
    @include mobile_or_P{
        .it-support{
            .container{
                padding: 1.7rem 1.2rem;
            }
            .inputs-wrapper{
                padding: 1.3rem .9rem 1.5rem .9rem;
                .app-input{
                    width: 80.7%;
                }
            }
        }
    }

    @include razr767{
        .it-support{
            .container{
                padding: .8rem .5rem;
            }
            p{
                margin-bottom: 1.2rem;
                padding: 0 1.3rem;

                font-size: 14px;
            }
            .inputs-wrapper{
                padding: .9rem .9rem .2rem .9rem;

                border-width: 5px;
                label{
                    flex-direction: column;
                    p{
                        margin-bottom: 0.5rem;
                        padding: 0;
                    }
                }
                .app-input{
                    width: 100%;
                }
                textarea{
                    min-height: 4.8rem;
                }
            }
            .app-button{
                margin: 0 .5rem 0 auto;
            }
        }
    }
</style>
