<template>
    <div class="agenda">
        <!-- <div class="page-title">
            {{ $t('agenda', 'Agenda') }}
        </div>
        <div class="app-topic-title">
            {{ $t('agenda_topics', 'Topics') }}
        </div> -->
        <div class="buttons">
            <app-button
                class="big"
                :class="{ accent: topicsView === 1 }"
                @click="topicsView=1"
            >
                {{ $t('agenda_view_a', 'View the Agenda') }}
            </app-button>
            <app-button
                class="big"
                :class="{ accent: topicsView === 2 }"
                @click="topicsView=2"
            >
                {{ $t('agenda_calendar', 'Calendar') }}
            </app-button>
        </div>
        <div
            v-if="agenda.sections.length"
            class="mb-20"
        >
            <div
                v-for="(section, index) in agenda.sections"
                :key="section.id"
                class="participants__section"
            >
                <div
                    class="app-topic-title"
                    @click="section.collapsed = !section.collapsed"
                >
                    {{ section.title || '-' }}
                    <span
                        class="icon-collapse"
                        :class="{'collapsed' : section.collapsed}"
                    ></span>
                </div>
                <div
                    v-if="!agenda.sections[index].collapsed"
                    class="topics-list"
                >
                    <div
                        v-for="topic in agenda.sections[index].topics"
                        :key="topic.id"
                        class="topic"
                        :class="{new: topic.new}"
                    >
                        <format-icons
                            :format="topic.format"
                            :is-conf="topic.conf"
                        />
                        <router-link
                            :to="{ name: 'dashboard', query: { id: topic.id, event_id: eventId } }"
                        >
                            {{ topic.name }}
                        </router-link>
                        <p>
                            <span>
                                {{ $t('agenda_timeline', 'Timeline') }}:
                            </span>
                            {{ topic.start_date }} - {{ topic.end_date }} {{
                                agenda.event.timezone
                            }}
                        </p>
                        <hr />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class=""
        >
            <div
                v-if="topicsView===1 && agenda && agenda.topics"
                class="topics-list"
            >
                <div
                    v-for="topic of agenda.topics"
                    :key="topic.id"
                    class="topic"
                    :class="{new: topic.new}"
                >
                    <format-icons
                        :format="topic.format"
                        :is-conf="topic.conf"
                    />
                    <router-link
                        :to="{ name: 'dashboard', query: { id: topic.id, event_id: eventId } }"
                    >
                        {{ topic.name }}
                    </router-link>
                    <p>
                        <span>
                            {{ $t('agenda_timeline', 'Timeline') }}:
                        </span>
                        {{ topic.start_date }} - {{ topic.end_date }} {{ agenda.event.timezone }}
                    </p>
                    <hr />
                </div>
            </div>
        </div>

        <app-calendar v-if="topicsView===2 && agenda" />

        <template v-if="agenda && agenda.objectives">
            <div class="app-topic-title">
                {{ agendaRepresentativeTitle || $t('agenda_overall', 'Overall Agenda') }}
            </div>
            <div
                class="view"
                v-html="agenda.objectives"
            ></div>
        </template>

        <template v-if="agenda && agenda.documents && agenda.documents.length">
            <div class="app-topic-title">
                {{ $t('agenda_resources', 'Resources') }}
            </div>
            <div class="documents">
                <app-resources>
                    <div
                        v-for="document of agenda.documents"
                        :key="document.id"
                        class="resource"
                    >
                        <a
                            v-if="!document.secured"
                            :href="generateFileUrl(document.file)"
                            target="_blank"
                            @click="sendAnalytic(1 ,document.id)"
                        >
                            <PDFIcon />
                            {{ document.name }}
                        </a>
                        <router-link
                            v-else
                            :to="{ name:'view', query:{ document_id: document.id, part: 'agenda' } }"
                            target="_blank"
                            @click.native="sendAnalytic(1 ,document.id)"
                        >
                            <PDFIcon />
                            {{ document.name }}
                        </router-link>
                        <p>
                            {{ document.description }}
                        </p>
                    </div>
                </app-resources>
            </div>
        </template>
        <template v-if="showModerators">
            <div class="app-topic-title">
                {{ $t('agenda_chair', 'Information about Moderator') }}
            </div>
            <app-participants-information
                v-for="moderator of agenda.moderators"
                :key="moderator.id"
                :participant="moderator"
            />
        </template>
    </div>
</template>

<script>
import PDFIcon from '@/assets/img/icons/pdf_icon.svg?inline';

import appButton from '@/components/user/app/app-button';
import appParticipantsInformation from '@/components/user/app/app-participants-information';
import appCalendar from '@/components/user/pages/agenda/calendar';
import formatIcons from '@/components/user/pages/agenda/format-icons';
import appResources from '@/components/user/app/app-resources';

import { sendClick } from '@/utils/user/clicks-analytic';

import { generateFileUrl } from '@/utils/http-helper';

export default {
    name: 'agenda',

    components: {
        PDFIcon,
        appButton,
        appParticipantsInformation,
        appCalendar,
        formatIcons,
        appResources,
    },

    data: () => ({
        topicsView: 1,
    }),

    computed: {
        agenda() {
            return this.$store.getters.getAgenda;
        },
        showModerators() {
            return this.agenda?.moderators?.length;
        },
        eventId() {
            return this.$route.query.event_id;
        },
        agendaRepresentativeTitle() {
            return this.$store.getters['platform/platform']?.customize.agenda.objectives;
        },
    },

    async created() {
        await this.$store.dispatch('getAgenda', this.eventId);
    },

    methods: {
        sendAnalytic(type, id) {
            sendClick(2, type, id);
        },

        generateFileUrl(file) {
            return generateFileUrl(file);
        },
    },
};
</script>

<style scoped lang="scss">
.buttons {
    display: flex;
    justify-content: center;

    padding: 2.3rem 0;

    .app-button {
        &:not(:last-of-type) {
            margin-right: 6rem;
        }
    }
}

.view {
    padding: 1.8rem 1.75rem;

    background: white;
}

.topics-list {
    padding: 1.7rem 1.8rem;

    background: white;

    .topic {
        margin-bottom: 1.1rem;

        &:last-of-type {
            hr {
                display: none;
            }
        }

        &.new {
            position: relative;

            padding-left: 3.7rem;

            &:before {
                @include bef_aft;
                width: 3rem;
                height: 3rem;

                background: url("~@/assets/img/icons/new-topic_icon.svg") no-repeat center center/contain;
            }
        }

        a {
            display: block;

            margin-bottom: 0.3rem;

            font-size: to_rem(24px);
            color: var(--platform-accent-color);
            text-decoration: none;
            font-style: italic;
        }

        p {
            font-size: to_rem(14px);
            font-weight: 700;

            span {
                font-weight: 400;
            }
        }

        hr {
            margin: 1.7rem 0 0;

            border: none;
            border-top: 1px solid $lightGray;
        }
    }

    .app-labels-imgs {
        margin-bottom: 0.6rem;
    }
}

.documents {
    background: white;

    .document {
        &:not(:last-of-type) {
            margin-bottom: 1rem;
        }
    }

    a {
        position: relative;

        display: flex;
        align-items: center;

        margin-bottom: 0.6rem;

        font-size: to_rem(14px);
        color: var(--platform-accent-color);

        img {
            display: block;

            width: 1rem;
            margin-right: 0.5rem;
        }
    }

    p {
        font-size: to_rem(12px);
    }
}

@include mobile_or_P {
    .topics-list {
        .topic {
            margin-bottom: 1.3rem;
        }
    }
    .documents {
        padding: 1.2rem 2.5rem;
    }
}

@include razr767 {
    .buttons {
        flex-direction: column;
        align-items: center;

        padding: 2.3rem 0 1.1rem;

        .app-button {
            margin-bottom: .5rem;

            &:not(:last-of-type) {
                margin-right: 0;
            }
        }
    }
    .documents {
        padding: 1.5rem 1.5rem 1.5rem 3.5rem;

        a {
            margin-bottom: 0.8rem;

            font-size: 1.2rem;

            &:before {
                left: -1.9rem;

                width: 1.4rem;
                height: 1.3rem;
            }
        }

        p {
            font-size: 1rem;
            line-height: 1.2;
        }
    }
    .app-participants-information {
        margin-bottom: 1.5rem;
    }
}

.icon-collapse {
    position: relative;
    margin-left: 0;
    display: block;
    //width: 20px;
    //height: 10px;
    //background: url('../../../assets/img/icons/disposer/bottom-arrow_icon.svg') center center / contain no-repeat;
    transform: scale(1, -1);
    font-size: 150%;
    line-height: 0.5;
}

.icon-collapse:after {
    content: '−';
}

.icon-collapse.collapsed:after {
    //transform: scale(1, 1);
    content: '+'
}

.app-topic-title {
    justify-content: space-between;
    cursor: pointer;
}

.mb-20 {
    margin-bottom: 20px;
}
</style>
