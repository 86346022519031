import Vue from 'vue';

import { get, post } from '@/utils/http-helper';
import { imageLoaded, changeData } from '@/utils/disposer/notifications-text-helper';

const GET_PLATFORM_INTERFACE = '/interface/index';
const INTERFACE_UPDATE = '/interface/update';
const INTERFACE_UPDATE_VISIBILITY = '/interface/update-visibility';
const INTERFACE_DELETE = '/interface/delete';
const INTERFACE_CREATE = '/interface/create';
const INTERFACE_RESTORE = '/interface/restore';

export default {
    state: {
        platformInterface: {},
    },
    mutations: {
        initPlatformInterface(state, platformInterface) {
            state.platformInterface = Object.assign({}, platformInterface);
        },
    },
    actions: {
        async getPlatformInterface({ commit }) {
            const platformInterfaceResp = await get(`${GET_PLATFORM_INTERFACE}`);
            const platformInterface = platformInterfaceResp.data;
            platformInterface.disclaimers = platformInterface.disclaimers.map(disclaimer => {
                disclaimer.edit_mode = false;

                return disclaimer;
            });
            platformInterface.notifications = platformInterface.notifications.map(notification => {
                notification.values.map(notification_value => notification_value.edit_mode = false);

                return notification;
            });
            commit('initPlatformInterface', platformInterface);
        },
        //запросы для заглушки - никак не обрабатывается ответ
        async uploadInterfaceImage(_, formData) {
            Vue.prototype.$awn.async(post(`${INTERFACE_UPDATE}`, formData, true), imageLoaded);
        },
        async updateInterfaceFields(_, data) {
            Vue.prototype.$awn.async(post(`${INTERFACE_UPDATE}`, data), changeData);
        },
        async updateInterfaceCheckboxFields(_, data) {
            Vue.prototype.$awn.async(post(`${INTERFACE_UPDATE_VISIBILITY}`, data), changeData);
        },
        async deleteInterfaceFields(_, data) {
            Vue.prototype.$awn.async(post(`${INTERFACE_DELETE}`, data), changeData);
        },
        async createInterfaceDisclaimerFields(_, data) {
            Vue.prototype.$awn.async(post(`${INTERFACE_CREATE}`, data), changeData);
        },
        async restoreInterfaceNotificationFields(_, data) {
            Vue.prototype.$awn.async(post(`${INTERFACE_RESTORE}`, data), changeData);
        },
    },
    getters: {
        platformInterface: state => state.platformInterface,
    },
};
