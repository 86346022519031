<template>
    <component
        :is="tag"
        class="app-button"
        v-bind="attributes"
        v-on="listeners"
    >
        <slot></slot>
    </component>
</template>

<script>
    export default {
        name: 'disposer-app-button',
        props: {
            tag: {
                type: String,
                default: 'div',
            },
        },
        computed: {
            attributes() {
                return this.$attrs;
            },
            listeners() {
                return Object.assign(
                    {},
                    this.$listeners,
                );
            },
        },
    };
</script>

<style scoped lang="scss">
    .app-button{
        display: flex;
        justify-content: center;
        align-items: center;

        width: fit-content;
        padding: 0.86rem 2.5rem;

        font-size: to_rem(14px);
        color: $mainTextColor;
        text-decoration: none;

        background: $dark;
        border: none;
        border-radius: 0.2rem;

        cursor: pointer;
        transition: box-shadow .2s;

        user-select: none;

        &:hover{
            box-shadow: 3px 3px 3px rgb(0 0 0 / 40%)
        }

        &.narrow {
            width: to_rem(120px);
            padding: 0.86rem 0;
        }

        &.mini {
            width: 100px;
            height: 35px;
            padding: 0;
        }

        &.red {
            background: $red;
        }

        &[data-disable] {
            opacity: 0.7;

            pointer-events: none;
        }

    }
    img{
        width: 0.9rem;
        margin-right: 0.5rem;
    }

</style>
