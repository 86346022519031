const datePrepIconMsg = 'Please enter date and time when you start to upload event content. The platform will be activated. Active users will be able to login and check.';
const copyIconMsg =  'Click here to copy content.';
const descriptionIconMsg = 'This text will be displayed on the right side of Participant dashboard in „info“ box';
const chooseDocumentIconMsg = 'Please use only pdf for secured view-only format. Documents will be displayed in Agenda tab';
const chooseVideoIconMsg = 'Video will be displayed in E-Library tab';

export { datePrepIconMsg,  copyIconMsg, descriptionIconMsg, chooseDocumentIconMsg, chooseVideoIconMsg };

export default {
    descriptionIconMsg: 'This info is displayed in a blue field under participant’s first name and last name',
    quickInfoIconMsg: 'This info is displayed in „Description“ box of participant’s profile',
    participantMessageIconMsg: 'This message is displayed in „Message to participant“ box of participant’s profile',
    countryIconMsg: 'This info is displayed in final report',
    phoneCodeIconMsg: 'This info is not displayed in participant’s profile',
    phoneNumberIconMsg: 'This info is not displayed in participant’s profile',
};
