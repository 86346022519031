<template>
    <div
        ref="tableBlock"
        class="table-survey"
        :class="{'disable-btn': table.is_answered}"
    >
        <div class="table-survey__save">
            <div
                class="alert"
                :class="{'alert--visible': saveAlertVisible}"
            >
                Saved
            </div>
            <app-button
                tag="button"
                class="save-button accent"
                @click="sendTable"
            >
                {{ $t('submit', 'Submit') }}
            </app-button>
            <app-button
                tag="button"
                class="save-button accent"
                @click="disableBtnSave"
            >
                {{ $t('save', 'Save') }}
            </app-button>
        </div>
        <div
            ref="tableWrapper"
            class="table-survey__table-wrapper"
            :class="{'sticky-head': table.freeze_row, 'sticky-first-col': table.freeze_column}"
        >
            <div
                class="table-survey__table"
                :class="{'table-survey__table--disabled': isUserInternal}"
            >
                <div class="table-survey__table-tr table-survey__table-head ">
                    <div
                        v-for="col in table.columns"
                        :key="col.id"
                        class="table-survey__table-cell table-survey__table-th"
                        :class="{first: col.type===0}"
                    >
                        {{ col.name }}
                    </div>
                </div>
                <div
                    v-for="string in table.rows"
                    :id="'r'+string.id"
                    :key="string.id"
                    class="table-survey__table-tr"
                >
                    <div
                        v-for="col in table.columns"
                        :key="col.id"
                        class="table-survey__table-cell table-survey__table-td"
                        :class="{
                            first: col.type===0, input: col.type===4, 'tr_none_event': col.type===0 || !!table.is_answered,
                            ['row'+string.id+'col'+col.id]:true,
                            ['table-survey-type'+col.type]: true,
                        }"
                        @click="openDropdown($event, string, col)"
                    >
                        <span
                            v-if="col.type===0"
                            class="table-survey__pb "
                        >
                            {{ string.val }}
                        </span>
                        <template v-else-if="col.type===4">
                            <textarea-autosize
                                v-model="string.values[col.id]"
                                rows="1"
                                :min-height="36"
                                @blur.native="sendText(string.values[col.id], col.id, string.id)"
                                @keydown.enter.prevent.native="sendText(string.values[col.id], col.id, string.id)"
                            />
                            <div
                                class="dropdown__mini-btn"
                            >
                                <p
                                    @mousedown="saveInputValue(col.id)"
                                >
                                    {{ $t('save', 'Save') }}
                                </p>
                            </div>
                        </template>
                        <template v-else>
                            <span
                                v-if="col.type===1 && string.values[col.id]"
                                :class="'column'+string.id"
                                class="table-survey__pb"
                            >
                                {{ string.values[col.id].name }}
                            </span>
                            <span
                                v-if="col.type===2 && string.values[col.id]"
                                class="table-survey__pb"
                                :class="'column'+string.id"
                                :title="multiselectValue(string.values[col.id])"
                            >
                                {{ multiselectValue(string.values[col.id]) }}
                            </span>
                            <span
                                v-if="col.type===3 && string.values[col.id]"
                                class="center"
                                :class="'column'+string.id"
                            >
                                {{ string.values[col.id] }}
                            </span>

                            <ellipsis-icon v-if="col.type===1 || col.type===2" />
                            <select-icon v-if="col.type===3" />
                            <div
                                v-if="col.type===5"
                                :class="classesSingleCheckboxContainer(string.values[col.id], string, col)"
                                @click.stop
                            >
                                <div
                                    class="checkbox"
                                    :class="classesSingleCheckbox(string.values[col.id])"
                                    @click="selectSingleCheckbox(string.values[col.id], string, col)"
                                >
                                    <box-icon class="box" />
                                    <img src="@/assets/img/icons/check-icon.svg" />
                                </div>
                                <div class="dropdown__mini-btn">
                                    <p @click="saveSingleCheckbox(string.id, col.id)">
                                        {{ $t('save', 'Save') }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="activeDropdownCell"
            ref="dropdown"
            v-click-outside="openDropdown.bind(null, false)"
            :class="'table-survey__dropdown active-dropdown-cel-type' + activeDropdownCol.type"
            :style="{top: `${dropdownTop}px`, left: `${dropdownLeft}px`, width: `${dropdownW}px`}"
        >
            <template v-if="activeDropdownCol.type===1">
                <label
                    v-for="value in activeDropdownCol.options"
                    :key="value.id"
                    class="app-radio"
                >
                    <input
                        v-model="activeDropdownCell.values[activeDropdownCol.id]"
                        :value="value"
                        name="value"
                        type="radio"
                        @change="updateSelectSingle"
                    />
                    <div class="box"></div>
                    {{ value.name }}
                </label>
                <div
                    class="dropdown__mini-btn"
                >
                    <p
                        @click="selectSingle"
                    >
                        {{ $t('save', 'Save') }}
                    </p>
                </div>
            </template>
            <template v-if="activeDropdownCol.type===2">
                <label
                    v-for="value in activeDropdownCol.options"
                    :key="value.id"
                    class="app-checkbox"
                >
                    <input
                        name="value"
                        type="checkbox"
                        :checked="isCheckedMultiselectValue(value)"
                        @change="changeMultiSelect(value)"
                    />
                    <div class="box"></div>
                    {{ value.name }}
                </label>
                <div
                    class="dropdown__mini-btn"
                >
                    <p
                        @click="testCheckBox"
                    >
                        {{ $t('save', 'Save') }}
                    </p>
                </div>
            </template>
            <template v-if="activeDropdownCol.type===3">
                <div
                    v-for="option in activeDropdownCol.settings.max_scale"
                    :key="option"
                    class="table-survey__option"
                    :class="{active: activeDropdownCell.activeOptionId === option, disabled: checkAnswerIsUnique(option)}"
                    @click="selectOption(option)"
                >
                    {{ option }}
                </div>
                <div
                    class="dropdown__mini-btn"
                >
                    <p
                        @click="sendSelectRate"
                    >
                        {{ $t('save', 'Save') }}
                    </p>
                </div>
            </template>
        </div>

        <div
            v-if="isUserInternal"
            class="table-survey__total"
        >
            {{ totalAnswers }} {{ $t('dash_answers', 'Voting') }}
        </div>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';

import ellipsisIcon from '@/assets/img/icons/ellipsis_icon.svg?inline';
import selectIcon from '@/assets/img/icons/select_icon.svg?inline';
import boxIcon from '@/assets/img/icons/box_icon.svg?inline';

import vClickOutside from 'v-click-outside';
import { post } from '@/utils/http-helper';

export default {
    name: 'table-survey',

    components: {
        appButton,
        ellipsisIcon,
        selectIcon,
        boxIcon,
    },

    directives: {
        clickOutside: vClickOutside.directive,
    },

    props: {
        table: {
            type: Object,
            default: () => ({}),
        },
        questionId: {
            type: [String, Number],
            default: 0,
        },
    },

    data: () => ({
        activeDropdownCell: null,
        activeDropdownCol: null,
        dropdownLeft: 0,
        dropdownTop: 0,
        dropdownW: 0,
        saveAlertVisible: false,
        isAnswered: false,
        currentRate: null,
        currentTarget: null,
        currentCheckBox:[],
        singleCheckBoxSelectedIds: {},
    }),

    computed: {
        userRole() {
            return this.$store.getters['auth/userRole'];
        },

        totalAnswers() {
            return this.table.answers.total;
        },

        isUserInternal() {
            return this.userRole?.id === 5;
        },
        topicId() {
            return this.$store.getters['dashboard/topicId'];
        },
    },

    watch: {
        table: {
            handler() {
                this.table.columns.forEach(items=> {
                    if (items.type) {
                        items.answers.forEach(item => {
                            if (item.row_id) {
                                if (item.is_answered || this.table.is_answered) {
                                    this.$nextTick(()=> {
                                        document.querySelectorAll(`.row${item.row_id}col${items.id}`).forEach(td=> {
                                            td.classList.add('tr_none_event');
                                        });
                                    });
                                } else {
                                    this.$nextTick(()=> {
                                        document.querySelectorAll(`.row${item.row_id}col${items.id}`).forEach(td=> {
                                            td.classList.remove('tr_none_event');
                                        });
                                    });
                                }
                            }
                        });
                    }
                });
            },
            deep: true,
            immediate: true,
        },
    },

    mounted() {
        this.$refs.tableWrapper.addEventListener('scroll', () => {
            this.openDropdown(null, false);
        });
    },

    methods: {
        checkAnswerIsUnique(option) {
            if (this.activeDropdownCol.settings.is_rate_unique !== 1) return;
            let disabled = false;
            this.activeDropdownCol.answers.forEach((answer)=> {
                if (answer.value === option && answer.is_answered) {
                    disabled = true;
                }
            });
            return disabled;

        },
        openDropdown(e, string, col) {
            if (e) {
                this.currentTarget = e;
                const type = col?.type;

                if (!col || type === 0) {
                    this.activeDropdownCell = null;
                    this.activeDropdownCol = null;
                    this.currentRate = null;
                } if (type === 5) {
                    this.activeDropdownCell = string;
                    this.activeDropdownCol = col;
                    this.currentRate = null;
                } else {
                    this.activeDropdownCell = string;
                    this.activeDropdownCol = col;

                    const parent = e?.target.closest('.table-survey__table-td');
                    this.dropdownLeft = parent?.getBoundingClientRect().left - this.$refs.tableBlock.getBoundingClientRect().left;
                    this.dropdownTop = parent?.getBoundingClientRect().top - this.$refs.tableBlock.getBoundingClientRect().top;
                    this.dropdownW = parent?.offsetWidth - 1;

                    this.$nextTick(() => {
                        const bottomSpade = window.innerHeight - this.$refs.dropdown.getBoundingClientRect().bottom - 10;
                        if (bottomSpade<0) {
                            this.dropdownTop += bottomSpade;
                        }
                    });
                }
            } else {
                this.activeDropdownCell = null;
                this.activeDropdownCol = null;
                this.currentRate = null;
            }
        },
        testCheckBox() {
            const rowId = this.activeDropdownCell.id;
            const colId = this.activeDropdownCol.id;
            const value =  this.activeDropdownCell.values[this.activeDropdownCol.id];

            this.$nextTick(()=> {
                document.querySelectorAll(`.row${rowId}col${colId}`).forEach(td=> {
                    td.classList.add('tr_none_event');
                });
            });

            this.$store.commit('dashboard/updateStateMultiCheckbox', { rowId, colId,  value, questionId: this.questionId });
            this.sendAnswer(this.activeDropdownCell.values[this.activeDropdownCol.id].map(v => v.id));

            this.activeDropdownCell = null;
            this.activeDropdownCol = null;
        },

        checkboxChange(val) {
            const rowId = this.activeDropdownCell.id;
            const colId = this.activeDropdownCol.id;
            const value = val;

            this.$set(this.activeDropdownCell.values, colId, value);

            this.$store.commit('dashboard/editStateSingle', { rowId, colId,  value, questionId: this.questionId });
        },

        classSelectSingleCheckbox(string, col) {
            return 'row' + string?.id + 'col' + col?.id;
        },

        classesSingleCheckboxContainer(checked, string, col) {
            const id = this.classSelectSingleCheckbox(string, col);
            return {
                'single-checkbox': true,
                'single-checkbox_selected': this.singleCheckBoxSelectedIds[id],
            };
        },

        classesSingleCheckbox(checked) {
            return {
                checked,
            };
        },

        selectSingleCheckbox(value, string = null, col) {
            if (string && col) {
                this.activeDropdownCell = string;
                this.activeDropdownCol = col;

                const id = this.classSelectSingleCheckbox(string, col);
                const val = !value;

                this.singleCheckBoxSelectedIds[id] = !this.singleCheckBoxSelectedIds?.[id] ?? false;

                this.checkboxChange(val);
            }
        },

        saveSingleCheckbox(rowIdInner = null, colIdInner = null) {
            const rowId = rowIdInner ?? this.activeDropdownCell.id;
            const colId = colIdInner ?? this.activeDropdownCol.id;

            let value = this.activeDropdownCell.values[this.activeDropdownCol.id];

            if (!value)  {
                value = 0;
            }

            this.$store.commit('dashboard/updateStateSingle', { rowId, colId,  value, questionId: this.questionId });
            this.sendAnswer(value, colId, rowId);
        },

        selectSingle() {
            const rowId = this.activeDropdownCell.id;
            const colId = this.activeDropdownCol.id;
            const value = this.activeDropdownCell.values[this.activeDropdownCol.id]?.id;

            if (value === null || value === undefined) return;

            this.$nextTick(()=> {
                    document.querySelectorAll(`.row${rowId}col${colId}`).forEach(td=> {
                        td.classList.add('tr_none_event');
                    });
                });
            this.$store.commit('dashboard/updateStateSingle', { rowId, colId,  value, questionId: this.questionId });
            this.sendAnswer(this.activeDropdownCell.values[this.activeDropdownCol.id]?.id);
            this.activeDropdownCell = null;
            this.activeDropdownCol = null;
        },
        updateSelectSingle() {
            const rowId = this.activeDropdownCell.id;
            const colId = this.activeDropdownCol.id;
            const value = this.activeDropdownCell.values[this.activeDropdownCol.id]?.id;
            this.$store.commit('dashboard/editStateSingle', { rowId, colId,  value, questionId: this.questionId });
        },


        changeMultiSelect(value) {
            let values = this.activeDropdownCell.values[this.activeDropdownCol.id];
            if (!values) {
                values = [];
                this.$set(this.activeDropdownCell.values, this.activeDropdownCol.id, values);
            }
            if (this.isCheckedMultiselectValue(value)) {
                this.activeDropdownCell.values[this.activeDropdownCol.id] = values.filter(v => v.id !== value.id);
            } else {
                values.push(value);
            }

            const rowId = this.activeDropdownCell.id;
            const colId = this.activeDropdownCol.id;

            this.$store.commit('dashboard/editStateMultiCheckbox', {
                rowId,
                colId,
                value: this.activeDropdownCell.values[this.activeDropdownCol.id],
                questionId: this.questionId,
            });
        },

        isCheckedMultiselectValue(value) {
            return this.activeDropdownCell.values[this.activeDropdownCol.id]?.find(v => v.id === value.id);
        },

        multiselectValue(arr) {
            let t = arr.reduce((res, value, index) => {
                res += value.name;
                index !== arr.length-1 ? res += ', ' : '';
                return res;
            }, '');
            return t;
        },
        sendSelectRate() {
            if (!this.activeDropdownCell.values[this.activeDropdownCol.id]) return;
            const rowId = this.activeDropdownCell.id;
            const colId = this.activeDropdownCol.id;
            const value = this.activeDropdownCell.values[this.activeDropdownCol.id];
            this.$nextTick(()=> {
                document.querySelectorAll(`.row${rowId}col${colId}`).forEach(td=> {
                    td.classList.add('tr_none_event');
                });
            });
            this.$store.commit('dashboard/updateStateSingle', { rowId, colId,  value, questionId: this.questionId });
            this.sendAnswer(value);


            this.openDropdown(null, false);

        },


        async selectOption(option) {
            const rowId = this.activeDropdownCell.id;
            const colId = this.activeDropdownCol.id;
            const value = option;

            this.$store.commit('dashboard/editStateSingle', { rowId, colId,  value, questionId: this.questionId });

            if (this.activeDropdownCol.settings.is_rate_unique) {
                if (!this.activeDropdownCol.usedValues) {
                    this.activeDropdownCol.usedValues = [];
                }
            }

            if (this.activeDropdownCell.values[this.activeDropdownCol.id] !== option) {
                if (this.activeDropdownCol.settings.is_rate_unique) {
                    const oldCell = this.table.rows.find(s => s.values[this.activeDropdownCol.id] === option);
                    if (!oldCell) {
                        this.activeDropdownCol.usedValues?.push(option);
                    } else {
                        this.activeDropdownCol.usedValues = this.activeDropdownCol.usedValues.filter(v => v !== this.activeDropdownCell.values[this.activeDropdownCol.id]);
                    }
                    if (oldCell?.values[this.activeDropdownCol.id]) {
                        this.activeDropdownCol.answers.forEach((answer)=> {
                            if (answer.row_id === oldCell.id) {
                                answer.value = null;
                                answer.is_answered = false;
                            }
                        });
                        // await this.sendAnswer(null, this.activeDropdownCol?.id, oldCell.id);
                    }
                    delete oldCell?.values[this.activeDropdownCol.id];
                }
                this.$set(this.activeDropdownCell.values, this.activeDropdownCol.id, option);
                this.activeDropdownCell.activeOptionId = option;
            } else {
                delete this.activeDropdownCell.values[this.activeDropdownCol.id];
                this.activeDropdownCell.activeOptionId = null;
                if (this.activeDropdownCol.settings.is_rate_unique) {
                    this.activeDropdownCol.usedValues = this.activeDropdownCol.usedValues.filter(v => v !== option);
                }
            }
        },

        sendText(text, colId, rowId) {
            this.$store.commit('dashboard/editStateSingle', { rowId, colId,  value: text, questionId: this.questionId });
        },
        saveInputValue(col) {
            const rowId = this.activeDropdownCell.id;
            const colId = col;
            const value = this.activeDropdownCell.values[colId];

            if (value === null || value === undefined) return;

            this.$store.commit('dashboard/updateStateSingle', { rowId, colId,  value, questionId: this.questionId });
            this.sendAnswer(value, colId, rowId);
        },

        async sendAnswer(value=null, colId, rowId) {
            const obj = {
                survey_id: this.table.id,
                column_id: colId || this.activeDropdownCol?.id,
                row_id: rowId || this.activeDropdownCell?.id,
            };

            if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value || value === 0)) {
                obj.value = value;
                await post('/dashboard/answer-table', obj).then((resp) => this.showSaveAlert(resp));
            } else {
                await post('/dashboard/clear-table-cell', obj).then(() => this.showSaveAlert());
            }
            await this.getDashboardRequest();
        },

        async sendTable(){
            const obj = {
                survey_id: this.table.id,
            };
            this.disableBtnSave();
            await post('/dashboard/submit-table', obj);
            await this.getDashboardRequest();
        },

        async getDashboardRequest() {
            const params = {
                id: this.topicId,
                eventId: this.eventId ? this.eventId : null,
            };
            await this.$store.dispatch('dashboard/get', params);
        },

        hideTable(resp){
            if (resp && resp.status) {
                this.$refs.tableBlock.classList.add('disable-tbl');
            }
        },
        showSaveAlert(resp) {
            if (resp && resp.data.is_answered) {
                this.isAnswered = true;
                this.$refs.tableBlock.classList.add('disable-btn');
                // this.$emit('hideTable');
            }

            this.saveAlertVisible = true;
            setTimeout(() => {
                this.saveAlertVisible = false;
            }, 1500);
        },
        disableBtnSave() {
            const rows = this.table.rows;
            const columns = this.table.columns;

            columns.forEach(col => {
                if (col.type === 0) return;
                rows.forEach(row => {
                    col.answers.forEach(colAnswer => {
                        if (colAnswer.row_id ===  row.id) {
                            if (colAnswer.value === null || colAnswer.value === undefined) return;
                            if (col.type === 1) {
                                if (!colAnswer.is_answered) {
                                    this.sendAnswer(row.values[col.id].id, col.id, row.id); //single select
                                    this.$store.commit('dashboard/updateStateSingle',{
                                        rowId: row.id,
                                        colId: col.id,
                                        value: row.values[col.id].id,
                                        questionId: this.questionId,
                                    });
                                }
                            }

                            if (col.type === 2) {
                                col.answers.forEach(colAnswer => {
                                    if (colAnswer.row_id ===  row.id) {
                                        if (!colAnswer.is_answered) {
                                            this.sendAnswer(row.values[col.id].map(v => v.id), col.id, row.id);
                                            this.$store.commit('dashboard/updateStateMultiCheckbox',
                                                { rowId: row.id, colId: col.id,  value: row.values[col.id], questionId: this.questionId },
                                            );
                                        }
                                    }
                                }); // multi select
                            }
                            if (col.type === 3) {
                                if (!colAnswer.is_answered) {
                                    this.sendAnswer(row.values[col.id], col.id, row.id);  //select Rate
                                    this.$store.commit('dashboard/updateStateSingle',{
                                        rowId: row.id, colId: col.id, value: row.values[col.id], questionId: this.questionId,
                                    });
                                }
                            }
                            if (col.type === 4) {
                                if (!colAnswer.is_answered) {
                                    this.$store.commit('dashboard/updateStateSingle', {
                                        rowId: row.id, colId:  col.id,  value: row.values[col.id], questionId: this.questionId,
                                    });
                                    this.sendAnswer(row.values[col.id], col.id, row.id);
                                }
                            }
                            if (col.type === 5) {
                                col.answers.forEach(colAnswer => {
                                    if (colAnswer.row_id ===  row.id) {
                                        if (!colAnswer.is_answered) {
                                            this.sendAnswer(colAnswer.value, col.id, row.id);
                                            this.$store.commit('dashboard/updateStateSingle',
                                                { rowId: row.id, colId: col.id, value: colAnswer.value, questionId: this.questionId },
                                            );
                                        }
                                    }
                                });
                            }

                            this.$nextTick(()=> {
                                document.querySelectorAll(`.row${row.id}col${col.id}`).forEach(td=> {
                                    td.classList.add('tr_none_event');
                                });
                            });

                        }
                    });
                });
            });

            if (this.isAnswered) {
                this.$refs.tableBlock.classList.add('disable-btn');
            }
        },
    },
};

</script>

<style scoped lang="scss">
.table-survey {
    $root: &;

    position: relative;

    padding: 14px 10px 20px 20px;

    background: #fff;
    transition: opacity 0.3s ease;

    .single-checkbox {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        .checkbox {
            margin: 0;
        }

        &_selected {
            border-radius: 3px;
            outline: 2px solid var(--platform-accent-color);

            .dropdown__mini-btn {
                position: static;

                display: block;

                margin: 0;
                margin-left: 30px;
            }
        }
    }

    &.disable-btn  {
        pointer-events: none;
        .table-survey__save button{
            background-color: #c5c0c0;
        }
    }
    &.disable-tbl{
        pointer-events: none;
        opacity: 0.3;
    }

    &__save {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        margin-bottom: 12px;

        .alert {
            font-size: 14px;
            color: $green;

            opacity: 0;
            transition: opacity .3s ease;

            &--visible {
                opacity: 1;
            }
        }

        .save-button {
            //margin-left: auto;
            margin-left: 1rem;
        }
    }

    &__table {
        width: 100%;
        height: fit-content;

        &--disabled {
            pointer-events: none;
        }

        &-wrapper {
            display: flex;

            max-width: 100%;
            max-height: 463px;
            overflow: auto;

            @include custom_scroll;

            scrollbar-color: var(--platform-main-color) transparent;

            &::-webkit-scrollbar-thumb {
                background-color: var(--platform-main-color);
            }

            &.sticky-head {
                #{$root}__table-head {
                    position: sticky;
                    top: 0;
                    left: 0;
                    z-index: 10;
                }
            }

            &.sticky-first-col {
                #{$root}__table-td, #{$root}__table-th {
                    &.first {
                        position: sticky;
                        top: 0;
                        left: 0;
                        z-index: 2;
                    }
                }
            }
        }

        &-tr {
            position: relative;

            display: flex;
        }

        &-cell {
            width: 100%;
            min-width: 172px;
            min-height: 33px;
            padding: 10px 3px;

            &:not(:first-of-type) {
                border-left: none;
            }

            .center {
                margin: 0 auto;
            }
        }

        &-th {
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 13px;
            font-weight: 700;
            text-align: center;
            color: var(--platform-accent-color);

            background: $heavyLightGray;
            border: 1px solid var(--platform-main-color);
        }

        #{$root}__pb {
            width: 100%;
            padding: 5px 20px 5px 5px;
        }

        &-td {
            position: relative;

            display: flex;
            align-items: center;

            padding: 0;

            font-size: 12px;

            border: 1px solid $gainsborough;
            border-top: none;

            span {
                overflow: hidden;

                text-overflow: ellipsis;
            }

            &.first {
                font-size: 14px;

                // background: $heavyLightGray;
            }

            svg {
                position: absolute;
                right: 5px;
                z-index: 1;

                box-sizing: content-box;

                width: 13px;
                padding: 4px 0;

                // background-color: #fff;

                cursor: pointer;

                &:not(&.box) {
                    &::v-deep {
                        path {
                            fill: var(--platform-accent-color);
                        }
                    }
                }
            }

            .checkbox {
                &:focus ~ .dropdown__mini-btn{
                    display: flex;
                }

                position: relative;

                width: 16px;
                height: 16px;
                margin: 0 auto;

                &.checked {
                    img {
                        display: block;
                    }
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 45%;

                    display: none;

                    width: 55%;

                    transform: translate(-50%, -50%);

                    pointer-events: none;
                }

                svg {
                    position: static;

                    width: 100%;
                    height: 100%;
                    padding: 0;
                }

                &::v-deep {
                    path {
                        stroke: var(--platform-accent-color);
                    }
                }
            }

            &.input {
                padding: 0;
            }

            textarea {
                width: 100%;
                min-width: 100%;
                height: 100%;
                min-height: 100%;
                padding: 10px 86px 10px 5px;

                border: none;

                resize: none;
                &:focus ~ .dropdown__mini-btn{
                    display: flex;
                }
            }
            .dropdown__mini-btn {
                position: absolute;
                right: -8px;

                display: none;

                margin: 7px 0;
                p{
                    margin: 0 10px 0 auto;
                    padding: 7px 12px;

                    color: white;

                    background: var(--platform-accent-color);
                    border-radius: 3px;

                    cursor: pointer;
                }
            }
            &.tr_none_event {
                textarea{
                    padding: 10px 5px 10px 5px;

                    background: rgb(241, 241, 241);

                    pointer-events: none;
                }
                .dropdown__mini-btn {
                    display: none;
                }
            }
        }
    }

    &__dropdown {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 15;

        width: 171px;

        font-size: 12px;

        background: #fff;
        box-shadow: 0 10px 12px rgba(0, 0, 0, 0.15);

        label {
            width: 100%;
            padding: 5px 10px;

            font-size: 12px;

            &:hover {
                background-color: #F5F5F5;
            }

            .box {
                width: 14px;
                min-width: 14px;
                height: 14px;
                min-height: 14px;
                margin-right: 8px;

                border-color: var(--platform-accent-color);

                &:after {
                    top: 2px;
                    left: 2px;

                    width: 8px;
                    height: 8px;

                    background-color: var(--platform-main-color);
                }
            }

            &.app-checkbox {
                .box {
                    &:after {
                        background-color: transparent;
                    }
                }
            }
        }
        .dropdown__mini-btn {
            display: flex;

            margin: 7px 0;
            p{
                margin: 0 10px 0 auto;
                padding: 7px 12px;

                color: white;

                background: var(--platform-accent-color);
                border-radius: 3px;

                cursor: pointer;
            }
        }
    }

    &__option {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 29px;

        cursor: pointer;
        transition: background-color 0.2s;

        &.active {
            background-color: #cecece;
        }

        &:hover {
            background-color: #F5F5F5;
        }
        &.disabled {
            color: $gray;

            background-color: #F5F5F5;

            pointer-events: none;
        }
    }

    &__total {
        margin-top: 8px;

        font-size: 14px;
        color: $gray;
    }
}
.tr_none_event {
    background: rgba(135, 135, 135, 0.112);

    pointer-events: none;

    .single-checkbox_selected {
        outline: none;
    }
}
</style>
