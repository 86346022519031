<template>
    <div
        ref="paintBord"
        class="paint-bord"
    >
        <canvas
            ref="paintBordCnv"
            @mousedown="startPrint($event)"
        ></canvas>
    </div>
</template>

<script>
    import check from '@/assets/img/workshop/checkmark.svg';
    import close from '@/assets/img/workshop/close.svg';
    import question from '@/assets/img/workshop/question.svg';

    import { GP } from '@/utils/sockets-helper';

    const NAME_SPACE = 'workshop';
    const generateSocketPath = new GP(NAME_SPACE);
    const CREATE_OBJECT = 'object-create';
    const ZOOM = 'zoom-switch';
    const UNDO_OBJECT = 'object-undo';
    const REDO_OBJECT = 'object-redo';
    const arrowsAmendment = 15;

    export default {
        name: 'paint-bord',

        props: {
            objects:{
                type: Array,
                default: null,
            },
            tool:{
                type: Number,
                default: null,
            },
            elementWeight:{
                type: String,
                default: '2',
            },
            elementColor:{
                type: String,
                default: '#000000',
            },
            socket: {
                type: Object,
                default: null,
            },
            pdf: {
              type: HTMLCanvasElement,
              default: null,
            },
        },

        data: ()=> ({
            viewportWidth: '',
            cnv: null,
            ctx: null,
            zoom: 1,

            elements: [],
            bufferElements: [],
            printingElement: {},

            imgs:{
                check: null,
                cross: null,
                question: null,
            },

            scalingFactor: 1,
        }),

        computed: {
            userId() {
                return this.$store.getters['auth/userInfo'].id;
            },
        },

        watch: {
          objects() {
            this.elements = this.objects;
          },
        },

        mounted() {
            this.initSocket();
            this.elements = this.objects;
        },

        methods:{
            initCanvas(scale, viewportWidth){
                this.cnv = this.$refs.paintBordCnv;
                this.ctx = this.cnv.getContext('2d');
                this.cnv.width = this.$refs.paintBord.offsetWidth;
                this.cnv.height = this.$refs.paintBord.offsetHeight;

                this.imgs.check = new Image();
                this.imgs.check.src = check;
                this.imgs.cross = new Image();
                this.imgs.cross.src = close;
                this.imgs.question = new Image();
                this.imgs.question.src = question;

                this.imgs.question.onload = ()=>{
                    this.zoomCnv(scale, viewportWidth);
                };
            },
            initSocket() {
              this.socket.on(generateSocketPath.generate(CREATE_OBJECT), data => {
                const points = data.points.map(p => {
                  return {
                    x: p.x,
                    y: p.y,
                  };
                });
                this.elements.push({
                  id: data.id,
                  type: data.type,
                  points: points,
                  width: data.width,
                  color: data.color,
                  isShow: true,
                });
                this.draw();
              });
              this.socket.on(generateSocketPath.generate(UNDO_OBJECT), data => {
                if (data.success) {
                  const elements = [...this.elements];
                  const object = elements.find(o => +o.id === +data.id);
                  object.inBuffer = true;
                  this.elements = [...elements];
                  this.draw();
                }
              });
              this.socket.on(generateSocketPath.generate(REDO_OBJECT), data => {
                if (data.success) {
                  const elements = [...this.elements];
                  const object = elements.find(o => o.id === data.id);
                  if (object) {
                    object.inBuffer = false;
                  } else {
                    elements.push(data);
                  }
                  this.elements = [...elements];
                  this.draw();
                }
              });
            },

            zoomCnv(zoom=1, viewportWidth){
                //595.28 - эталонный документ формата А4 портрет
                !this.viewportWidth ? this.viewportWidth = viewportWidth : '';
                this.zoom = zoom * (this.viewportWidth / 595.28);
                this.socket.emit(generateSocketPath.generate(ZOOM), { zoom: zoom }, data => {
                  if (data.success) {
                    this.cnv.width = this.pdf.offsetWidth;
                    this.cnv.height = this.pdf.offsetHeight;
                    this.scalingFactor = this.cnv.width/1000/this.zoom;
                    this.draw();
                  }
                });
            },

            getCoords(element) {
                return {
                    x1: element.points[0].x*this.scalingFactor,
                    y1: element.points[0].y*this.scalingFactor,
                    x2: element.points[1].x*this.scalingFactor,
                    y2: element.points[1].y*this.scalingFactor,
                };
            },

            draw(){
                const ctx = this.ctx;
                ctx?.clearRect(0, 0, this.cnv.width, this.cnv.height);

                this.elements?.forEach((element)=>{
                    if (element.inBuffer || !element.isShow) return;
                    ctx.beginPath();
                    ctx.fillStyle = element.color;
                    ctx.strokeStyle = element.color;
                    ctx.lineWidth =  element.width*2*this.zoom;
                   if (element.type===6){
                       element.points.forEach((point)=>{
                           this.drawPolygon(point.x*this.scalingFactor, point.y*this.scalingFactor, element.width, ctx);
                       });
                       return;
                   }
                   if (element.type===1){
                        const { x1, y1, x2, y2 } = this.getCoords(element);
                        this.drawLine(x1, y1, x2, y2);
                        return;
                   }
                   if (element.type===2){
                        const { x1, y1, x2, y2 } = this.getCoords(element);
                        const { width } = element;
                        this.drawArrow(x1-width, y1, x2, y2, width);
                        return;
                   }
                    const { x, y } = element.points[0];
                    const { type } = element;
                    this.drawImg(x*this.scalingFactor, y*this.scalingFactor, type);
                });
            },

            createPrintingElement(type){
                const printingElement = {
                    type: type,
                    user_id: this.userId,
                };
                switch (type) {
                    case 6 : {
                        printingElement.width = this.elementWeight/this.scalingFactor;
                        printingElement.color = this.elementColor;
                        printingElement.points = [];
                        break;
                    }
                    case 1 : {
                        printingElement.width = this.elementWeight/this.scalingFactor;
                        printingElement.color = this.elementColor;
                        break;
                    }
                    case 2 : {
                        printingElement.width = this.elementWeight/this.scalingFactor;
                        printingElement.color = this.elementColor;
                        break;
                    }
                }

                this.printingElement = printingElement;
            },

            startPrint(e){
                if (!this.tool) return;

                switch (this.tool) {
                    case 6 : this.startPrintPolygon(e); break;
                    case 1: this.startPrintLine(e); break;
                    case 2: this.startPrintArrow(e); break;
                    default : this.startPrintImg(e); break;
                }
            },
            endPrint() {
              this.cnv.onmousemove = null;
              this.cnv.onmouseup = null;
              this.socketCreateObjectEvent(this.printingElement);
            },

            startPrintLine(e){
                this.createPrintingElement(1);

                this.printingElement.points = [{}, {}];

                const x = Math.round(e.offsetX/this.zoom);
                const y = Math.round(e.offsetY/this.zoom);
                this.printingElement.points[0].x = x/this.scalingFactor;
                this.printingElement.points[0].y = y/this.scalingFactor;
                this.cnv.onmousemove = (e) => this.printingLine(e, x, y);
                this.cnv.onmouseup = (e) => {
                    this.printingElement.points[1].x = Math.round(e.offsetX/this.zoom/this.scalingFactor);
                    this.printingElement.points[1].y = Math.round(e.offsetY/this.zoom/this.scalingFactor);
                    this.endPrint();
                };
            },
            printingLine(e, x1, y1){
                this.draw();
                this.ctx.beginPath();

                let x2 = e.offsetX/this.zoom;
                let y2 = e.offsetY/this.zoom;

                const { width, color } = this.printingElement;

                this.ctx.fillStyle = color;
                this.ctx.strokeStyle = color;
                this.ctx.lineWidth =  width*2*this.zoom;

                this.drawLine(x1, y1, x2, y2);
            },
            drawLine(x1, y1, x2, y2){
                const ctx = this.ctx;

                ctx.moveTo(x1*this.zoom, y1*this.zoom);
                ctx.lineTo(x2*this.zoom, y2*this.zoom);
                ctx.stroke();
            },

            startPrintPolygon(){
                this.ctx.beginPath();
                this.createPrintingElement(6);

                this.cnv.onmousemove = this.printingPolygon;

                this.cnv.onmouseup = () => {
                    this.ctx.beginPath();
                    this.endPrint();
                };
            },
            printingPolygon(e){
                const ctx = this.ctx;

                let x = Math.round(e.offsetX/this.zoom);
                let y = Math.round(e.offsetY/this.zoom);

                this.printingElement.points.push({
                    x: x/this.scalingFactor, y: y/this.scalingFactor,
                });

                const { width, color } = this.printingElement;

                this.ctx.fillStyle = color;
                this.ctx.strokeStyle = color;
                this.ctx.lineWidth =  width*2*this.zoom;

                this.drawPolygon(x, y, width, ctx);
            },
            drawPolygon(x, y, w, ctx){
                ctx.lineTo(x*this.zoom, y*this.zoom);
                ctx.stroke();

                ctx.beginPath();
                ctx.arc(x*this.zoom, y*this.zoom, w*this.zoom, 0, Math.PI*2);
                ctx.fill();

                ctx.beginPath();
                ctx.moveTo(x*this.zoom, y*this.zoom);
            },

            startPrintArrow(e){
                this.createPrintingElement(2);

                this.printingElement.points = [{}, {}];

                const x = Math.round(e.offsetX/this.zoom);
                const y = Math.round(e.offsetY/this.zoom);

                this.printingElement.points[0].x = x/this.scalingFactor;
                this.printingElement.points[0].y = y/this.scalingFactor;

                this.cnv.onmousemove = (e) => this.printingArrow(e, x, y);
                this.cnv.onmouseup = (e) => {
                    this.printingElement.points[1].x = Math.round(e.offsetX/this.zoom/this.scalingFactor);
                    this.printingElement.points[1].y = Math.round(e.offsetY/this.zoom/this.scalingFactor);
                    this.endPrint();
                };

            },
            printingArrow(e, x1, y1){
                this.draw();
                this.ctx.beginPath();

                let x2 = e.offsetX/this.zoom;
                let y2 = e.offsetY/this.zoom;

                const { width, color } = this.printingElement;

                this.ctx.fillStyle = color;
                this.ctx.strokeStyle = color;
                this.ctx.lineWidth =  width*2*this.zoom;

                this.drawArrow(x1 - width, y1, x2, y2, width);
            },
            drawArrow(x1, y1, x2, y2, width){
                const arrowSettings = [0, width, width*(-12), width, width*(-arrowsAmendment), width*5];

                const newArr = arrowSettings.map((item)=>{
                    return item*this.zoom;
                });

                this.ctx.beginPath();
                this.ctx.arrow(x1*this.zoom, y1*this.zoom, x2*this.zoom, y2*this.zoom, newArr);
                this.ctx.fill();
            },

            startPrintImg(){
                this.createPrintingElement(this.tool);

                this.cnv.onmouseup = (e) => {
                    this.printingElement.points = [{}];
                    const x = Math.round(e.offsetX/this.zoom);
                    const y = Math.round(e.offsetY/this.zoom);
                    this.printingElement.points[0].x = x/this.scalingFactor;
                    this.printingElement.points[0].y = y/this.scalingFactor;
                    this.cnv.onmouseup = null;
                    this.socketCreateObjectEvent(this.printingElement);
                    this.drawImg(x, y, this.tool);
                };
            },
            drawImg(x, y, tool){
                let img;
                switch (tool) {
                    case 3: img = this.imgs.check; break;
                    case 4: img = this.imgs.cross; break;
                    case 5: img = this.imgs.question; break;
                }
                let imgSize = 15*this.zoom;
                this.ctx.drawImage(img, x*this.zoom - imgSize/2, y*this.zoom - imgSize/2, imgSize, imgSize);
            },

            undoElement(){
                if (this.elements.length){
                    let elements = [...this.elements];
                    const object = elements.reverse().find(o => o.user_id === this.userId && !o.inBuffer);
                    if (object) {
                      object.inBuffer = true;
                      this.socket.emit(generateSocketPath.generate(UNDO_OBJECT), { id:  object.id }, data => {
                        if (data.success) {
                          elements = elements.reverse();
                          this.elements = [...elements];
                          this.draw();
                        }
                      });
                    }
                }
            },
            redoElement(){
              let elements = [...this.elements];
              const object = elements.find(o => o.user_id === this.userId && o.inBuffer);
              if (object) {
                this.socket.emit(generateSocketPath.generate(REDO_OBJECT), { id:  object.id }, data => {
                  if (data.success) {
                    object.inBuffer = false;
                    this.elements = elements;
                    this.draw();
                  }
                });
              }
            },

            socketCreateObjectEvent(obj) {
              const points = obj.points.map(p => {
                return {
                  x: Math.round(p.x),
                  y: Math.round(p.y),
                };
              });
              const newObj = {
                type: obj.type,
                width: Math.round(obj.width),
                color: obj.color,
                points: JSON.stringify(points),
              };
              this.socket.emit(generateSocketPath.generate(CREATE_OBJECT), newObj, data => {
                const elements = [...this.elements];
                this.printingElement.id = data.id;
                this.printingElement.isShow = true;
                elements.push(this.printingElement);
                this.printingElement = {};
                this.$emit('updateElements', elements);
              });
            },

        },
    };
</script>

<style scoped lang="scss">
    .paint-bord{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
    }
</style>
