import store from '@/store';
import router from '@/router';
import axios from 'axios';
import Vue from 'vue';

export const BASE_URL = process.env.VUE_APP_BASE_URL;

export const generate_Url = (mutableUrl, defaultImg) => {
    let url = require('@/assets/img/icons/favicon.png');
    mutableUrl ? url = `${BASE_URL}/${mutableUrl}` : null;
    if (defaultImg && !mutableUrl) {
        url = defaultImg;
    }
    if (defaultImg === ' ' && !mutableUrl) {
        url = null;
    }
    return url;
};
// Добавляем dev_token из cookies
const getDevToken = () => {
    const cookies = document.cookie.split('; ');
    const devTokenCookie = cookies.find(cookie => cookie.startsWith('dev_token='));
    return devTokenCookie ? devTokenCookie.split('=')[1] : null;
};

export const generateFileUrl = (file) => {
    const devToken = getDevToken();
    return `${BASE_URL}/files/cdn?file=${file}&token=${devToken}`;
};
export const generateUrlSubtitle = (file) => {
    return `${BASE_URL}/${file}`;
};

export const generateFileUrlBase = (file) => {
    return `${BASE_URL}/${file}`;
};

const getResource = async (url, data, method, isFile, noAlert) => {
    try {
        const apiClient = axios.create({
            baseURL: BASE_URL,
        });

        apiClient.interceptors.request.use((config) => {
            config.headers['Content-Type'] = 'application/json';

            if (store.getters['auth/accessToken']) {
                config.headers.Authorization = `${store.getters['auth/tokenType']} ${store.getters['auth/accessToken']}`;
            }

            if (isFile) {
                config.headers['Content-Type'] = 'multipart/form-data';
            }
            return config;
        }, (error) => Promise.resolve({ error }));

        apiClient.interceptors.response.use(
            response => {
                return response;
            },
            async error => {
                const { status } = error.response || {};

                if (status === 401) {
                    const refresh = await store.dispatch('auth/refreshToken');
                    if (refresh?.status) {
                        error.config.headers.Authorization = `${store.getters['auth/tokenType']} ${store.getters['auth/accessToken']}`;
                        return axios.request(error.config);
                    } else {
                        store.dispatch('auth/logout', true);
                        const startLocation = router.app._router.history._startLocation;
                        const platform = startLocation.substring(1, startLocation.indexOf('/', 1));
                        router.push({ name: 'login', params: { platform } });
                    }
                }

                return Promise.reject(error.response);
            },
        );

        let requestOmj = {
            url, method,
        };

        if (method === 'post') {
            requestOmj = { ...requestOmj, data };
        } else {
            requestOmj = { ...requestOmj, params: data };
        }

        const response = await apiClient(requestOmj);

        return response.data;
    } catch (e) {
        if (e.data?.status === 0 && !noAlert && e.config.url !== '/user/refresh-token' && e.status !== 401) {
            Vue.prototype.$awn.alert(e.data?.message);
        }
        return e.data;
    }

};

export const get = async (url, params, noAlert) => getResource(url, params, 'get', false, noAlert);
export const post = async (url, data, isFile = false, noAlert) => getResource(url, data, 'post', isFile, noAlert);

export default {
    base_url: BASE_URL,
};
