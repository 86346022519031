import { get, post } from '@/utils/http-helper.js';
import { set_cookie, get_cookie, delete_cookie } from '@/utils/cookie-helper';
import { disconnectMessageSocket } from '@/utils/user/message-socket';

const USER_LOGIN = '/user/login';
const USER_INFO = '/user/info';
const USER_LOGOUT = '/user/logout';
const REFRESH_TOKEN = '/user/refresh-token';
const GET_ACCEPT = '/platform/get-disclaimers';

export default {
    namespaced: true,
    state: {
        auth: {
            token:{
                token_type: 'Bearer',
                access_token: get_cookie('access-token') || '',
                refresh_token: get_cookie('refresh-token') || '',
            },
        },
        user: null,
        accept: null,
        currentTime: '',
        openTimerWarning: false,
        pageIsLoaded: false,
    },
    mutations: {
        setPageLoadedStatus(state, status) {
            state.pageIsLoaded = status;
        },

        accessTokens(state, { access_token, refresh_token, platform } ) {
            const platformName = platform?.platformName;
            state.auth.token.access_token = access_token;
            state.auth.token.refresh_token = refresh_token;
            access_token? set_cookie('access-token', access_token, `${platformName}`) : delete_cookie('access-token', `${platformName}`);
            access_token? set_cookie(`${platform?.platform.server}_token`, access_token, '', `.${location.hostname}`)
                : delete_cookie(`${platform?.platform.server}_token`, '/', `.${location.hostname}`);
            refresh_token? set_cookie('refresh-token', refresh_token, `${platformName}`) : delete_cookie('refresh-token', `${platformName}`);
        },
        updateUserInfo(state, user) {
            state.user = user;
        },
        updateAccept(state, accept) {
            state.accept = accept;
        },
        setCurrentTime(state, time) {
            state.currentTime = time;
        },
        toggleTimerWarning(state, status) {
            state.openTimerWarning = status;
        },
        changeUnreadCount(state, num) {
            const user = { ...state.user };
            user.messages.unread += num;
            state.user = { ...user };
        },
    },
    actions: {
        setPageLoadedStatus({ commit }, status) {
            commit('setPageLoadedStatus', status);
        },
        async login({ commit, rootState }, { email, password }) {
            const requestObj = {
                email,
                password,
                platform_id: rootState.platform.platform.id,
            };
            const authData = await post(USER_LOGIN, requestObj, false, true);
            if (authData?.data) {
                commit('accessTokens', { ...authData.data, platform: rootState.platform });
            }
            return authData;
        },

        async getUserInfo({ commit }, params) {
            const user = await get(USER_INFO, params, true);
            if (user?.data) {
                commit('updateUserInfo', user.data);
                commit('setCurrentTime', user.data.server_time);
            }
            return user;
        },

        changeUnreadCount({ commit }, num) {
            commit('changeUnreadCount', num);
        },

        async accept({ commit }) {
            const accept = await get(GET_ACCEPT);
            if (accept.data) {
                commit('updateAccept', accept.data);
            }
        },

        setTokens({ commit }, tokens) {
            commit('accessTokens', tokens);
        },

        async refreshToken({ commit, dispatch, state }) {
            const refreshData = await post(REFRESH_TOKEN, {
                refresh_token: state.auth.token.refresh_token,
            });
            if (refreshData?.data) {
                commit('accessTokens', refreshData.data);
            }
            if (!refreshData || !refreshData.status) {
                dispatch('logout', true);
            }
        },

        changeTime({ commit }, time) {
            commit('setCurrentTime', time);
        },

        toggleTimerWarning({ commit }, status) {
            commit('toggleTimerWarning', status);
        },

        async logout({ rootState, commit }, noSendPost) {
            if (!noSendPost) {
                await post(USER_LOGOUT);
            }
            delete_cookie('access-token', `/${rootState.platform.platformName}`);
            delete_cookie('refresh-token', `/${rootState.platform.platformName}`);
            commit('updateUserInfo', null);
            commit('accessTokens', { access_token: null, refresh_token: null });
            disconnectMessageSocket();
        },
    },

    getters: {
        userInfo: state => state.user,
        userRole: state => state.user?.role,
        accessToken: state => state.auth.token.access_token,
        refreshToken: state => state.auth.token.refresh_token,
        tokenType: state => state.auth.token.token_type,
        event: state => state.user.event,
        accept: state => state.accept,
        currentTime: state => state.currentTime,
        openTimerWarning: state => state.openTimerWarning,
        loadedStatus: state => state.pageIsLoaded,
    },
};
