<template>
    <div class="disposer-events">
        <div class="disposer-app-title">
            Events
        </div>

        <div
            v-if="events"
            class="events-table"
        >
            <div class="row-tr row-tr-head">
                <div class="number">
                    Number
                </div>

                <div class="event">
                    Event
                </div>

                <div class="start-date">
                    Start date
                </div>

                <div class="end-date">
                    End  date
                </div>

                <div class="time-zone">
                    Time zone
                </div>

                <div class="open-event">
                    Open
                </div>

                <div class="edit-delete">
                    Edit / Delete
                </div>
            </div>

            <div
                v-for="(event, index) in events"
                :key="event.id"
                class="row-tr"
            >
                <div class="number">
                    {{ index + 1 }}
                </div>

                <div class="event">
                    {{ event.name }}
                </div>

                <div class="start-date">
                    {{ event.start_date }}
                </div>

                <div class="end-date">
                    {{ event.end_date }}
                </div>

                <div class="time-zone">
                    {{ event.timezone.name }}
                </div>

                <div class="open-event">
                    <app-button
                        @click="openEvent(event)"
                    >
                        Open
                    </app-button>
                </div>

                <div class="edit-delete edit-delete-align">
                    <app-actions
                        :edit="() => editEvent(event)"
                        :trash="() => deleteEvent(event)"
                    />
                </div>
            </div>
        </div>

        <app-button
            class="add-event-btn"
            @click="editMode"
        >
            Add Event
        </app-button>
    </div>
</template>

<script>
    import appButton from '@/components/disposer/app/app-button';
    import appActions from '@/components/disposer/app/app-actions';

    import { post } from '@/utils/http-helper';
    import { deletedEvent } from '@/utils/disposer/notifications-text-helper';

    const DELETE_EVENT = '/event/delete';

    export default {
        name: 'disposer-events',

        components: {
            appButton,
            appActions,
        },

        computed: {
            events() {
                return this.$store.getters['disposerEvents/get'];
            },
        },

        created() {
            this.fetchEvent();
        },

        methods: {
            fetchEvent() {
                this.$store.dispatch('disposerEvents/get');
            },
            editMode() {
                this.$router.push({ name: 'add-event' });
            },
            editEvent(event) {
                this.$router.push({ name: 'edit-event', params: { id: event.id } });
            },
            async deleteEvent(event) {
                this.$awn.async(post(DELETE_EVENT, { id: event.id }), () => {
                  this.$awn.success(deletedEvent);
                  this.fetchEvent();
                });
            },
            openEvent(event) {
                 this.$router.push({ name: 'event-topics', params:{ id: event.id } });
            },
        },
    };
</script>

<style scoped lang="scss">
    .disposer-events {
        .events-table {
            padding-bottom: 20px;

            .row-tr.row-tr-head{
                background-color: #d0d1d2;
            }

            .row-tr {
                display: flex;
                justify-content: space-between;
                align-items: center;

                width: to_rem(1300px);
                //max-width: 78vw;
                height: auto;
                padding: 10px;

                background-color: white;
                border: 1px solid #d0d1d2;

                .number {
                    width: to_rem(50px);
                }

                .event {
                    width: to_rem(250px);
                }

                .start-date,
                .end-date {
                    width: to_rem(150px);
                }

                .open-event{
                    width: 5rem;

                    cursor: pointer;
                }

                .time-zone {
                    width: to_rem(125px);
                }

                .language {
                    width: to_rem(70px);
                }

                .edit-delete {
                    width: to_rem(90px);
                }
            }

            .edit-delete-align {
                display: flex;
                justify-content: flex-end;

                padding-right: 10px;
            }
        }

        .add-event-btn{
            width: 145px;
            height: 40px;
            margin-bottom: 10px;
        }
    }
</style>
