import { generate_Url } from '@/utils/http-helper';

const generateUrl = {
    methods: {
        generateUrl(url, defaultImg) {
            return generate_Url(url, defaultImg);
        },
    },
};

export default generateUrl;
