<template>
    <div class="disposer-default-layout">
        <div class="disposer-sidebar-wrap">
            <disposer-sidebar />
        </div>
        <div class="disposer-content-wrap">
            <div class="disposer-header-btns">
                <app-button
                    @click="disposerLogout"
                >
                    Logout
                </app-button>
            </div>
            <div class="disposer-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import disposerSidebar from '@/components/disposer/layouts/sidebar';
    import appButton from '@/components/disposer/app/app-button';

    export default {
        name: 'disposer-default-layout',
        components: {
            disposerSidebar,
            appButton,
        },
        methods: {
            async disposerLogout() {
                await this.$store.dispatch('auth/logout');
                this.$router.push({ name: 'login' });
            },
        },
    };
</script>

<style scoped lang="scss">
.disposer-default-layout{
    display: flex;

    min-height: 100vh;
    padding-right: 10px;

    background: #f9f5f5;

    .disposer-sidebar-wrap{
        flex: 1;

        background: $dark;

        .disposer-sidebar {
            min-width: to_rem(233px);
        }
    }

    .disposer-content-wrap{
        flex: 3;

        max-width: initial;
        padding: 0 0 30px;

        .disposer-header-btns {
            display: flex;
            justify-content: flex-end;

            padding-bottom: 20px;

            .app-button {
                margin-top: 10px;
                margin-right: 40px;
                padding: 10px;
            }
        }
        .disposer-content{
            max-width: to_rem(1300px);
            margin-left: 1rem;

            background: #f9f5f5;
        }
    }

    @media screen and (max-width: 1440px) {
        .disposer-sidebar-wrap {
            flex: initial;
        }
    }
}
</style>
