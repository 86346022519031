<template>
    <div class="audio-recorder">
        <div class="row">
            <div class="audio-recorder__timer">
                {{ timer }}
                <span v-if="recorder">{{ $t('dash_record_limit', 'maximum 1min') }}</span>
            </div>
            <app-button
                class="narrow accent"
                :data-disable="recorder"
                @click="startRecording"
            >
                <i class="fa fa-circle"></i>
                {{ $t('dash_record', 'RECORD') }}
            </app-button>
            <app-button
                class="narrow accent"
                :data-disable="!recorder"
                @click="pauseRecording"
            >
                <template v-if="recording">
                    <i class="fa fa-pause"></i>
                    {{ $t('dash_pause', 'PAUSE') }}
                </template>
                <template v-else>
                    <i class="fa fa-play"></i>
                    {{ $t('dash_resume', 'PLAY') }}
                </template>
            </app-button>
            <app-button
                class="narrow accent"
                :data-disable="!recorder"
                @click="stopRecording"
            >
                <i class="fa fa-stop"></i>
                {{ $t('dash_stop', 'STOP') }}
            </app-button>
            <audio
                v-if="recordedAudioUrl && !recording"
                :src="recordedAudioUrl"
                controlsList="nodownload"
                controls
            ></audio>
            <app-button
                v-if="recordedAudioUrl && !recording"
                class="narrow accent"
                @click="uploadVoice"
            >
                <template v-if="!isLoading">
                    <i class="fa fa-share"></i>
                    {{ $t('send', 'UPLOAD') }}
                </template>
                <line-loader v-else />
            </app-button>
        </div>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';
import LineLoader from '@/components/user/app/app-loader/line-loader';

export default {
    name: 'app-audio-recorder',

    components: {
      appButton,
      LineLoader,
    },

    props: {
        loadVoice: {
            type: Function,
            default: null,
        },
    },

    data: () => ({
      recorder: null,
      recording: false,
      gumStream: null,
      recordedAudioUrl: null,
      blob: null,
      audioName: null,

      time: null,
      timer: '00:00',
      timeTimeout: null,

      isLoading: false,
    }),

    beforeDestroy() {
      this.stopRecording();
    },

    methods: {
        startRecording() {
            this.recordedAudioUrl = null;
            this.blob = null;
            this.audioName = null;
            const constraints = { audio: true, video:false };
            navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                const AudioContext = window.AudioContext || window.webkitAudioContext;
                const audioContext = new AudioContext();
                this.gumStream = stream;
                const input = audioContext.createMediaStreamSource(stream);
                this.recorder = new window.Recorder(input,{ numChannels:1 });
                this.recorder.record();
                this.recording = true;
                this.startTimer();
            }).catch(function(err) {
                console.error(err);
            });
        },

        stopRecording() {
          this.recorder.stop();
          this.recording = false;
          this.removeTimer();
          this.gumStream.getAudioTracks()[0].stop();
          this.recorder.exportWAV(this.createDownloadLink);
          this.recorder = null;
        },

        createDownloadLink(blob) {
          this.blob = blob;
          this.audioName = new Date().toISOString() + '.wav';
          const URL = window.URL || window.webkitURL;
          this.recordedAudioUrl = URL.createObjectURL(blob);
        },

        pauseRecording() {
            if (this.recorder.recording){
                this.recorder.stop();
                this.recording = false;
                this.pauseTimer();
            } else {
                this.recorder.record();
                this.recording = true;
                this.resumeTimer();
            }
        },

        startTimer() {
            this.time = new Date();
            this.time.setHours(0,1,0);

            this.timeTimeout = 0;
            this.timerTick();
        },

        timerTick() {
            let m= this.time.getMinutes();
            let s= this.time.getSeconds();
            m= (m<10)?'0'+m: m;
            s= (s<10)? '0'+s : s;

            this.timer = `${m}:${s}`;

            this.time.setSeconds(this.time.getSeconds()-1);
            this.timeTimeout = setTimeout(this.timerTick, 1000);
            if (m==0 && s==0){ clearTimeout(this.timeTimeout);
                this.stopRecording();
            }
        },

        pauseTimer() {
            clearTimeout(this.timeTimeout);
        },

        resumeTimer() {
            this.timeTimeout = setTimeout(this.timerTick, 1000);
        },

        removeTimer() {
            clearTimeout(this.timeTimeout);
            this.timer = '00:00';
        },

        async uploadVoice() {
            this.isLoading = true;
            await this.loadVoice(this.blob, this.audioName);
            this.recordedAudioUrl = null;
            this.isLoading = false;
            this.$emit('close');
        },
    },
};
</script>

<style scoped lang="scss">
    .audio-recorder {
        padding: 1rem 0;
        .row {
            display: flex;
            align-items: center;

            height: 4rem;
        }
        .app-button {
            margin-left: 0.5rem;
        }
        &__timer {
            position: relative;

            color: white;
            span {
                position: absolute;
                top: 1.7rem;
                left: 0;

                width: 10rem;

                font-size: to_rem(11px);

                pointer-events: none;
            }
        }
        .app-button {
            height: 2rem;
        }
        audio {
            margin-left: 0.5rem;
        }
        @media screen and (max-width: 1023px){
            .row {
                flex-wrap: wrap;
                align-items: center;

                height: auto;
                .app-button {
                    margin: 1rem 0.3rem;
                    i {
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
</style>
