import Vue from 'vue';
import { get, post } from '@/utils/http-helper';
import { managerChanged, managerDeleted } from '@/utils/disposer/notifications-text-helper';

const GET_MANAGERS_LIST = '/manager/index?size=0';
const MANAGER_UPDATE = '/manager/update';
const MANAGER_DELETE = '/manager/delete';

export default {
    state: {
        managersList: {},
    },
    mutations: {
        initManagersList(state, { managers }){
            state.managersList = Object.assign({}, managers);
        },
    },
    actions: {
        async getManagersList({ commit } ) {
            const managers = await get(GET_MANAGERS_LIST).then(resp => resp.data);
            commit('initManagersList', { managers });
        },

        async updateManager({ dispatch }, updatedManager) {
            await Vue.prototype.$awn.async(post(`${MANAGER_UPDATE}`, updatedManager),
                async response => {
                    if (response.status) {
                        await dispatch('getManagersList');
                        await dispatch('auth/getUserInfo');
                        Vue.prototype.$awn.success(managerChanged);
                    }
                });
        },

        async deleteManager({ dispatch }, manager) {
            Vue.prototype.$awn.async(post(`${MANAGER_DELETE}`, { id : manager.id }), async () => {
                await dispatch('getManagersList');
                Vue.prototype.$awn.success(managerDeleted);
            });
        },
    },
    getters: {
        managersList: state => state.managersList,
    },
};
