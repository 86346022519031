import { get } from '@/utils/http-helper';

const GET_AGENDA = '/agenda/get';
const GET_PERIOD = '/agenda/get-period';
const GET_CALENDAR = '/agenda/get-period';

export default {
  state: {
    agenda: null,
  },
  actions: {
    async getAgenda({ commit }, eventId) {
        const agenda = await get(GET_AGENDA, { event_id: eventId });
        if (agenda?.status) {
          commit('updateAgenda', agenda.data);
        }
    },
    async getPeriod({ commit }, { eventId, period }) {
        const agenda = await get(GET_PERIOD, { event_id: eventId, period });
        if (agenda?.status) {
          commit('updateAgendaCalendar', agenda.data);
        }
    },
    async getCalendar({ commit }) {
      const calendar = await get(GET_CALENDAR);
      if (calendar.status) {
        commit('updateCalendar', calendar.data);
      }
    },
  },
  mutations: {
    updateAgenda(state, agenda) {
      state.agenda = agenda;
    },
    updateAgendaCalendar(state, calendar) {
        const agenda = { ...state.agenda };
        agenda.calendar = calendar.calendar;
        state.agenda = agenda;
    },
    updateCalendar(state, calendar) {
      state.agenda.calendar = calendar;
    },
  },
  getters: {
    getAgenda: state => state.agenda,
    getAgendaCalendar: state => state.agenda?.calendar,
    getToday: state => state.agenda?.today,
  },
};
