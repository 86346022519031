<template>
    <div class="disposer-add-edit-participants">
        <div
            class="main-modal"
        >
            <app-modal
                v-if="isModalVisible"
                @closeModal="closeModal"
                @saveHandler="saveEditorData"
            >
                <app-editor
                    v-model="editorData"
                />
            </app-modal>
        </div>
        <app-section :save="saveParticipant">
            <div class="header">
                <div class="title-back">
                    <div class="disposer-app-title">
                        Edit {{ pathPrefix }} Participants
                    </div>
                    <div class="back">
                        <app-button
                            tag="router-link"
                            :to="{name: `${pathPrefix}-participants`}"
                        >
                            Back to participants
                        </app-button>
                    </div>
                </div>
            </div>
            <div class="toggle-buttons">
                <div class="header-btns-left">
                    <app-button
                        class="mini"
                        :class="{inactive: !participant.status}"
                        @click="toggleStatus(1)"
                    >
                        Active
                    </app-button>

                    <app-button
                        class="mini"
                        :class="{inactive: participant.status}"
                        @click="toggleStatus(0)"
                    >
                        Inactive
                    </app-button>
                </div>
                <div
                    v-if="!isExternal"
                    class="header-btns-right"
                >
                    <app-button
                        class="mini"
                        :class="{inactive: !participant.mode}"
                        @click="toggleMode(1)"
                    >
                        Participantion
                    </app-button>

                    <app-button
                        class="mini"
                        :class="{inactive: participant.mode}"
                        @click="toggleMode(0)"
                    >
                        View only
                    </app-button>
                </div>
            </div>
            <div class="disposer-app-participants-info">
                <div class="participants-info-header">
                    <div class="row">
                        <div class="title">
                            <app-input
                                v-model="participant.title"
                                title="Title"
                            />
                        </div>
                        <div class="first-name">
                            <app-input
                                v-model="participant.firstName"
                                title="First Name*"
                                :errors="errors.firstName"
                                :required-error="$v.participant.firstName.$dirty && !$v.participant.firstName.required"
                            />
                        </div>
                        <div class="last-name">
                            <app-input
                                v-model="participant.lastName"
                                title="Last Name*"
                                :errors="errors.lastName"
                                :required-error="$v.participant.lastName.$dirty && !$v.participant.lastName.required"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="email">
                            <app-input
                                v-model="participant.email"
                                title="Email*"
                                name="participantEmail"
                                :errors="errors.email"
                                :required-error="$v.participant.email.$dirty && !$v.participant.email.required"
                                :extension-validations="[{validator: 'mail', text: 'Enter correct e-mail'}]"
                                :extension-errors="{mail: $v.participant.email.$dirty && !$v.participant.email.email}"
                            />
                        </div>
                        <div class="description">
                            <div class="wrap">
                                <app-input
                                    :value="participant.description | htmlToString"
                                    title="Description"
                                    :disabled="isModalVisible"
                                    @focus="showModal('description')"
                                />
                            </div>
                            <div class="info-icons">
                                <img
                                    v-tooltip.top-end="$options.texts.descriptionIconMsg"
                                    src="@/assets/img/icons/disposer/info-icon.png"
                                    class="info-icon"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="quick-info">
                            <div class="wrap">
                                <app-input
                                    :value="participant.quick_info | htmlToString"
                                    title="Quick Info"
                                    :disabled="isModalVisible"
                                    @focus="showModal('quick_info')"
                                />
                            </div>
                            <div class="info-icons">
                                <img
                                    v-tooltip.right-start="$options.texts.quickInfoIconMsg"
                                    src="@/assets/img/icons/disposer/info-icon.png"
                                    class="info-icon"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="quick-info">
                            <div class="wrap">
                                <app-input
                                    :value="participant.message | htmlToString"
                                    title="Message to participant"
                                    :disabled="isModalVisible"
                                    @focus="showModal('participant_message')"
                                />
                            </div>
                            <div class="info-icons">
                                <img
                                    v-tooltip.right-start="$options.texts.participantMessageIconMsg"
                                    src="@/assets/img/icons/disposer/info-icon.png"
                                    class="info-icon"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="country">
                            <div class="wrap">
                                <div class="disposer-app-topic-title">
                                    Country*
                                </div>
                                <app-select
                                    v-model="participant.country"
                                    :option-select="countries"
                                    label="name"
                                    searchable
                                />
                                <span
                                    v-if="$v.participant.country.$dirty && !$v.participant.country.required"
                                    class="file-error app-input-error"
                                >
                                    Select country
                                </span>
                            </div>
                            <div class="info-icons">
                                <img
                                    v-tooltip.top-end="$options.texts.countryIconMsg"
                                    src="@/assets/img/icons/disposer/info-icon.png"
                                    class="info-icon"
                                />
                            </div>
                        </div>
                        <div class="phone-code">
                            <div class="wrap">
                                <app-input
                                    v-model="participant.phone_code"
                                    title="Phone code"
                                />
                            </div>
                            <div class="info-icons">
                                <img
                                    v-tooltip.right-start="$options.texts.phoneCodeIconMsg"
                                    src="@/assets/img/icons/disposer/info-icon.png"
                                    class="info-icon"
                                />
                            </div>
                        </div>
                        <div class="phone-number">
                            <div class="wrap">
                                <app-input
                                    v-model="participant.phone"
                                    title="Phone number"
                                />
                            </div>
                            <div class="info-icons">
                                <img
                                    v-tooltip.right-start="$options.texts.phoneNumberIconMsg"
                                    src="@/assets/img/icons/disposer/info-icon.png"
                                    class="info-icon"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row-5">
                        <div class="photo">
                            <div class="disposer-app-topic-title">
                                Upload photo
                            </div>
                            <div class="photo-choose-flex">
                                <div class="photo-icon">
                                    <img :src="photo.url || participantPhoto" />
                                </div>
                                <div class="choose-btn">
                                    <app-file-input
                                        class="mini"
                                        :load-file="loadPhoto"
                                        accept="image/*"
                                    >
                                        Choose
                                    </app-file-input>
                                    <app-button
                                        class="mini"
                                        @click="deletePhoto"
                                    >
                                        Delete
                                    </app-button>
                                </div>
                            </div>
                        </div>
                        <div class="password">
                            <div class="password-first">
                                <app-input
                                    v-model="participant.password"
                                    title="Password"
                                    :errors="errors.password"
                                    :required-error="$v.participant.password.$dirty && !$v.participant.password.required"
                                    :extension-validations="[{validator: 'minLength', text: 'Password must be more 8 characters'}]"
                                    :extension-errors="{minLength: $v.participant.password.$dirty && !$v.participant.password.minLength}"
                                />
                            </div>
                            <div class="passworf-confirm">
                                <app-input
                                    v-model="participant.password_confirm"
                                    title="Confirm password"
                                    :errors="errors.password_confirm"
                                    :required-error="$v.participant.password_confirm.$dirty && !$v.participant.password_confirm.required"
                                    :extension-validations="[{validator: 'password', text: 'Passwords must match'}]"
                                    :extension-errors="{password: $v.participant.password_confirm.$dirty && !$v.participant.password_confirm.isConfirm && $v.participant.password_confirm.required}"
                                />
                            </div>
                        </div>
                        <div class="direct-msgs">
                            <label class="disposer-app-checkbox">
                                <input
                                    v-model="participant.allow_messages"
                                    type="checkbox"
                                    name="updateMethod"
                                />
                                <div class="box"></div>
                                Direct messages
                            </label>
                        </div>
                    </div>
                </div>
                <div class="disposer-app-topic-title">
                    Platform notifications
                </div>
                <div class="platform-notifications">
                    <div class="left">
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="participant.not1_start"
                                type="checkbox"
                            />
                            <div class="box"></div>
                            Start of new topic
                        </label>
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="participant.not2_reminder"
                                type="checkbox"
                            />
                            <div class="box"></div>
                            Reminder to perform the task
                        </label>
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="participant.not3_hour"
                                type="checkbox"
                            />
                            <div class="box"></div>
                            1 hour before video call start
                        </label>
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="participant.not4_thank"
                                type="checkbox"
                            />
                            <div class="box"></div>
                            Thank you email
                        </label>
                    </div>
                    <div class="right">
                        <p>Unread direct messages:</p>
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="participant.period"
                                type="radio"
                                :value="1"
                                @click="checkOverlap(1)"
                            />
                            <div class="box"></div>
                            1/day
                        </label>
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="participant.period"
                                type="radio"
                                :value="2"
                                @click="checkOverlap(2)"
                            />
                            <div class="box"></div>
                            1/week
                        </label>
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="participant.period"
                                type="radio"
                                :value="3"
                                @click="checkOverlap(3)"
                            />
                            <div class="box"></div>
                            3/week
                        </label>
                    </div>
                </div>
                <div class="disposer-app-topic-title">
                    Sections
                </div>
                <div class="sections">
                    <app-select
                        v-model="participant.section"
                        :option-select="sections"
                        label="name"
                        searchable
                    />
                </div>
                <div class="disposer-app-topic-title">
                    Topics
                </div>
                <div class="topics">
                    <div v-for="topic in topicsShort" :key="topic.id">
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="topic.checked"
                                type="checkbox"
                                name="updateMethod"
                                @change="changeTopicStatus($event, topic.id)"
                            />
                            <div class="box"></div>
                            {{ topic.name }}
                        </label>
                    </div>
                </div>
                <label
                    v-if="!isExternal"
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="participant.allow_gpt"
                        type="checkbox"
                        name="updateMethod"
                    />
                    <div class="box"></div>
                    Allow to see Chat GPT
                </label>
                <label
                    v-if="!isExternal"
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="participant.allow_report"
                        type="checkbox"
                        name="updateMethod"
                    />
                    <div class="box"></div>
                    Allow to print report
                </label>
                <label
                    v-else
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="participant.anonymizer"
                        type="checkbox"
                        name="updateMethod"
                    />
                    <div class="box"></div>
                    Would you like this user to participate anonymously
                </label>
                <label class="disposer-app-checkbox">
                    <input
                        v-model="participant.send_user"
                        type="checkbox"
                        name="updateMethod"
                    />
                    <div class="box"></div>
                    Send login details to the participant
                </label>
                <label class="disposer-app-checkbox">
                    <input
                        v-model="participant.send_admin"
                        type="checkbox"
                        name="updateMethod"
                    />
                    <div class="box"></div>
                    Send login details to the project manager
                </label>
            </div>
        </app-section>

        <app-section
            class="links"
            :class="{active: editParticipantId}"
        >
            <div class="disposer-app-topic-title">
                Links
            </div>
            <app-link
                v-for="link in participant.links"
                :key="link.id"
                :paths="$options.objectActionsPaths"
                :link="link"
                :user-id="editParticipantId"
                @delete="deleteLink"
            />
            <app-button @click="addLink">
                Add link
            </app-button>
        </app-section>
    </div>
</template>

<script>
    import appSection from '@/components/disposer/app/app-section';
    import appSelect from '@/components/disposer/app/app-select';
    import appLink from '@/components/disposer/app/app-link';
    import appButton from '@/components/disposer/app/app-button';
    import appInput from '@/components/disposer/app/app-input';
    import appFileInput from '@/components/disposer/app/app-file-input';
    import appModal from '@/components/disposer/app/app-modal';
    import appEditor from '@/components/disposer/app/app-editor';
    import texts from '@/utils/disposer/icons-text-helper.js';

    import htmlToString from '@/utils/filters/html-to-string';
    import { parseImg } from '@/utils/disposer/parse-files-helper';
    import { get, post } from '@/utils/http-helper';
    import {
      changedParticipantStatus,
      createPart,
      updatePart,
      changePartTopicStatus,
      changePartPhoto,
      deletePartPhoto,
      defaultConfirmSettings,
    } from '@/utils/disposer/notifications-text-helper';
    import { required, email, minLength } from 'vuelidate/lib/validators';
    import { LINK_TEMPLATE } from '@/utils/disposer/objects-templates';
    import generateUrl from '@/utils/mixins/generate_url';

    const CREATE_PARTICIPANT = '/participant/create';
    const UPDATE_PARTICIPANT = '/participant/update';
    const CHANGE_STATUS = '/participant/change-status';
    const UPDATE_PHOTO = '/participant/update-photo';
    const DELETE_PHOTO = '/participant/delete-photo';
    const UPDATE_TOPICS = '/participant/update-topics';
    const GENERATE_PASSWORD = '/security/gen-password';

    export default {
        name: 'add-edit-participants',

        components: {
            appSection,
            appLink,
            appButton,
            appInput,
            appFileInput,
            appSelect,
            appModal,
            appEditor,
        },

        filters: {
            htmlToString,
        },

        mixins: [generateUrl],

        texts: {
            ...texts,
        },

        objectActionsPaths: {
            SAVE_OBJECT: '/participant/create-link',
            UPDATE_OBJECT: '/participant/update-link',
            DELETE_OBJECT: '/participant/delete-link',
        },

        data: () => ({
            participant: {
              email: '',
              password: '',
              password_confirm: '',
              status: 1,
              firstName: '',
              lastName: '',
              title: '',
              description: '',
              quick_info: '',
              country_id: '',
              country: null,
              phone_code: '',
              phone: '',
              allow_messages: 0,
              not1_start: 0,
              not2_reminder: 0,
              not3_hour: 0,
              not4_thank: 0,
              not5_unread: 0,
              period: 0,
              mode: 1,
              anonymizer: 0,
                send_user: 0,
                send_admin: 0,
              allow_report: 1,
              section_id: '',
              section: null,
              topics: [],
              links: [],
              photo: null,
            },
            photo: {
              file: null,
              url: null,
            },
            errors: {},

            isModalVisible: false,
            isDescriptionModal: false,
            isQuickInfoModal: false,
            isParticipantMessageModal: false,
            editorData: '',
        }),

        validations: {
            participant: {
                email: {
                    required,
                    email,
                },
                firstName: {
                    required,
                },
                lastName: {
                    required,
                },
              country: {
                required,
              },
              password: {
                required(value) {
                  if (!this.editParticipantId) {
                    return !!value;
                  } else {
                    return true;
                  }
                },
                minLength: minLength(8),
              },
              password_confirm: {
                required(value) {
                  if (!this.editParticipantId) {
                    return !!value;
                  } else {
                    return true;
                  }
                },
                isConfirm() {
                    return this.participant.password === this.participant.password_confirm;
                },
              },
            },
        },

        computed: {
            eventId() {
                return +this.$route.params.id;
            },
            editParticipantId() {
              return +this.$route.params.participantId;
            },
            editParticipant() {
              return this.$store.getters['disposerParticipants/getEditParticipant'];
            },
            role() {
                return this.$route.meta.role;
            },
            isExternal() {
                return this.role === 6;
            },
            pathPrefix() {
                return this.isExternal? 'external': 'internal';
            },
            countries() {
                return this.$store.getters['disposerParticipants/getCountries'];
            },
            sections(){
                return this.$store.getters['disposerParticipants/getSectionsCompact'];
            },

            topicsShort() {
                if (this.participant.topics.length) {
                  return this.participant.topics;
                } else  {
                  return this.$store.getters['disposerTopics/getTopicsShort']?.map(t => {
                    if (!(t.checked || t.checked===0)) {
                      t.checked = 1;
                    }
                    return t;
                  });
                }
            },
            participantPhoto() {
                return this.generateUrl(this.participant.photo);
            },
        },

        async created() {
            this.$store.dispatch('disposerParticipants/getCountries');
            this.$store.dispatch('disposerParticipants/getSectionsCompact', { eventId: this.eventId, type: this.role });
            this.$store.dispatch('disposerTopics/getTopicsShort', { id: this.eventId });
            if (this.editParticipantId){
                await this.$store.dispatch('disposerParticipants/getParticipant', this.editParticipantId);
                this.participant = this.editParticipant;
            }
            this.generatePassword();
        },

        methods: {
            checkOverlap(num) {
                +this.participant.period === +num ? this.participant.period = 0 : null;
            },
            toggleStatus(status) {
              this.participant.status = status;
              if (this.editParticipantId) {
                this.$awn.async(post(CHANGE_STATUS, {
                  id: this.editParticipantId,
                  event_id: this.eventId,
                  status: +status,
                  type: this.role,
                }), response => {
                  if (response.status) {
                    this.$awn.success(changedParticipantStatus);
                  }
                });
              }
            },
            toggleMode(mode) {
              this.participant.mode = mode;
              if (this.editParticipantId) {
                this.saveParticipant();
              }
            },
            async loadPhoto(img) {
              this.photo.file = img;
              this.photo.url = await parseImg(img);
              if (this.editParticipantId) {
                await this.updatePhoto();
              }
            },
            async saveParticipant() {
                if (this.$v.participant.$invalid || this.$v.participant.country.$invalid) {
                    this.$v.participant.$touch();
                    this.$v.participant.country.$touch();
                    return;
                }
                const participant = { ...this.participant };
                participant.status =  +participant.status;
                participant.allow_messages =  +participant.allow_messages;
                participant.not1_start =  +participant.not1_start;
                participant.not2_reminder =  +participant.not2_reminder;
                participant.not3_hour =  +participant.not3_hour;
                participant.not4_thank =  +participant.not4_thank;
                participant.not5_unread =  +!!participant.period;
                participant.period =  +participant.period;
                participant.mode =  +participant.mode;
                participant.anonymizer =  +participant.anonymizer;
                participant.send_user =  +participant.send_user;
                participant.send_admin =  +participant.send_admin;
                participant.allow_report =  +participant.allow_report;
                participant.allow_gpt = participant.allow_gpt ? +participant.allow_gpt : 0;
                participant.role = this.role;
                participant.event_id = this.eventId;
                participant.country_id = this.participant.country?.id;
                participant.section_id = this.participant.section?.id;

                let url = CREATE_PARTICIPANT;
                let successMessage = createPart;
                if (this.editParticipantId) {
                  participant.id = this.editParticipantId;
                  url = UPDATE_PARTICIPANT;
                  successMessage = updatePart;
                } else  {
                  participant.topics = [];
                  this.topicsShort.forEach(t => t.checked? participant.topics.push(t.id): null);
                }
                this.$awn.async(post(url, participant), response => {
                    if (response.status) {
                        if (!this.editParticipantId) {
                            this.$router.push({ name: `edit-${this.pathPrefix}-participant`, params: { participantId: response.data.id } });
                        }
                        setTimeout(this.updatePhoto, 100);
                        this.errors = {};
                        this.$awn.success(successMessage);
                    } else {
                        this.errors = response.errors;
                    }
                });
            },
            async updatePhoto() {
                if (this.photo.file) {
                    const fd = new FormData;
                    fd.append('id', this.editParticipantId);
                    fd.append('photo', this.photo.file);
                    this.$awn.async(post(UPDATE_PHOTO, fd, true), response => {
                      if (response.status) {
                        this.participant.photo = response.data.photo;
                        this.$awn.success(changePartPhoto);
                      }
                    });
                }
            },
            async deletePhoto() {
              this.$confirm({
                ...defaultConfirmSettings,
                callback: confirm => {
                  if (confirm) {
                    this.$awn.async(post(DELETE_PHOTO, {
                      id: this.editParticipantId,
                    }), response => {
                      if (response.status) {
                        this.participant.photo = null;
                        this.$awn.success(deletePartPhoto);
                      }
                      this.photo.url = '';
                      this.photo.file = null;
                    });
                  }
                },
              });
            },
            changeTopicStatus(e, id) {
              if (this.editParticipantId) {
                this.$awn.async(post(UPDATE_TOPICS, {
                  topic_id: id,
                  user_id: this.editParticipantId,
                  action: +e.target.checked,
                }), response => {
                  if (response.status) {
                    this.$awn.success(changePartTopicStatus);
                  }
                });
              }
            },
            addLink() {
                this.participant.links.push({ ...LINK_TEMPLATE(Math.random()) });
            },
            deleteLink(id) {
              this.participant.links = this.participant.links.filter(p => p.id !== id);
            },

            async generatePassword() {
                if (!this.editParticipantId) {
                    const data = await get(GENERATE_PASSWORD);
                    this.participant.password = this.participant.password_confirm = data.data.password;
                }
            },

            showModal(v) {
                this.isModalVisible = true;
                if (v === 'description') {
                    this.editorData = this.participant.description;
                    this.isDescriptionModal = true;
                }
                if (v === 'quick_info') {
                    this.editorData = this.participant.quick_info;
                    this.isQuickInfoModal = true;
                }
                if (v === 'participant_message') {
                    this.editorData = this.participant.message;
                    this.isParticipantMessageModal = true;
                }
            },

            closeModal() {
                this.isModalVisible = false;
                this.isDescriptionModal = false;
                this.isQuickInfoModal = false;
            },

            saveEditorData() {
                this.isModalVisible = false;
                if (this.isDescriptionModal) {
                    this.participant.description = this.editorData;
                    this.isDescriptionModal = false;
                }
                if (this.isQuickInfoModal) {
                    this.participant.quick_info = this.editorData;
                    this.isQuickInfoModal = false;
                }
                if (this.isParticipantMessageModal) {
                    this.participant.message = this.editorData;
                    this.isParticipantMessageModal = false;
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .disposer-add-edit-participants {
        .header {
            display: flex;
            .title-back {
                display: flex;
                flex: 3.3;
                .app-button {
                    margin-left: 1rem;
                    padding: 15px;
                }
            }
        }
        .toggle-buttons {
            display: flex;
            align-items: center;

            margin-bottom: 1rem;
            .inactive {
                background-color: #d0d1d2;
            }
            .header-btns-left, .header-btns-right {
                display: flex;

                margin-right: 1rem;
                padding-top: 5px;
            }
        }
    }

    .photo-icon {
        width: to_rem(135px);
        height: to_rem(135px);
        img {
            width: 100%;
            height: 100%;
        }
    }

    .disposer-app-checkbox {
        margin-bottom: 0.5rem;
    }

    .platform-notifications {
        display: flex;

        margin-bottom: 1rem;
        .right {
            margin-left: 2rem;
        }
        p {
            margin-bottom: 0.5rem;

            font-size: 14px;
            color: $copyGray;
        }
    }

    .passworf-confirm {
        .disposer-app-label{
            margin-bottom: 0;
        }
    }

    .disposer-app-participants-info{
        .row {
            display: flex;
            justify-content: flex-start;

            padding-bottom: 20px;
            .info-icons {
                margin-top: 45px;
                margin-left: 10px;
            }
            .title,
            .first-name,
            .last-name,
            .email,
            .country,
            .phone-code,
            .phone-number{
                flex: 1;
            }
            .country {
                display: flex;
                flex: 0.7;

                margin-right: 1rem;
                .app-select {
                    .multiselect {
                        width: to_rem(265px);
                    }
                }
                .wrap {
                    position: relative;
                }
            }
            .phone-code {
                display: flex;
                flex: 0.43;
                .disposer-app-input {
                    width: to_rem(85px);
                }
            }
            .phone-number {
                display: flex;
                flex: 1.2;
            }
            .description {
                display: flex;
                flex: 2;
                .disposer-app-label {
                    width: to_rem(640px);
                }
            }
            .disposer-app-label, .title-input {
                width: to_rem(260px);
            }
            .phone-code {
                .disposer-app-label {
                    width: to_rem(100px);
                }
                .phone-code-input{
                    width: to_rem(75px);
                }
            }
            .description-input{
                width: to_rem(700px);
            }
            .quick-info {
                position: relative;

                display: flex;
                .disposer-app-label{
                    width: to_rem(760px);
                }
            }
            .country-input{
                width: to_rem(260px);
            }

            .phone-number-input{
                width: to_rem(260px);
            }
        }
        .row-5 {
            display: flex;
            justify-content: space-between;

            width: to_rem(870px);
            padding: 30px 0 45px;
            .photo {
                width: to_rem(520px);
            }
            .direct-msgs {
                padding-left: 10px;
            }
            .password{
                input {
                    width: 150px;
                }
            }
            .photo-choose-flex {
                display: flex;
                justify-content: space-between;

                width: to_rem(310px);
                .app-button {
                    margin-bottom: 1rem;
                }
            }
            .password-first {
                margin-bottom: 30px;
            }
            .direct-msgs {
                display: flex;
                align-items: flex-end;
            }
        }
        .topics {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            height: 300px;
            margin-bottom: 1rem;
            .disposer-app-checkbox {
                margin-right: 0.5rem;
            }
        }
    }
    .links {
        opacity: 0.5;

        pointer-events: none;
        &.active {
            opacity: 1;

            pointer-events: all;
        }
    }
    .sections{
        margin-bottom: 2rem;
    }
</style>
