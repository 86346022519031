<template>
    <footer>
        <a class="logo">
            <img :src="footerLogo" />
        </a>
        <div class="copy">
            {{ footerContent }}
            <div>
                <span @click="openGreenInitiativePopup">
                    <p>
                        <GreenInitiativeIcon class="copy__logo" />Thank you for saving our planet!
                    </p>
                </span>
            </div>
        </div>
        <div class="right">
            <a
                :href="`mailto:${supportEmail}`"
                class="mail"
            >
                <MailIcon />
                {{ supportEmail }}
            </a>
            <a
                :href="`tel:${phoneHref}`"
                class="phone"
            >
                <PhoneIcon />
                {{ supportPhone }}
            </a>
        </div>
        <app-popup
            name="greenInitiative"
            class="app-popup--green-life"
        >
            <green-initiative-popup />
            <template v-slot:close-icon>
                <img
                    src="@/assets/img/icons/close-green.svg"
                    alt="close"
                />
            </template>
        </app-popup>
    </footer>
</template>

<script>
import appPopup from '@/components/user/app-popup';
import GreenInitiativePopup from '@/components/user/layouts/footer/green-initiative-popup';
import GreenInitiativeIcon from '@/assets/img/icons/greenInitiativeFooterIcon.svg?inline';
import MailIcon from '@/assets/img/icons/mail-purple_icon.svg?inline';
import PhoneIcon from '@/assets/img/icons/phone_icon.svg?inline';

import generateUrl from '@/utils/mixins/generate_url';

export default {
    name: 'layout-footer',

    components: {
        appPopup,
        GreenInitiativePopup,
        GreenInitiativeIcon,
        MailIcon,
        PhoneIcon,
    },

    mixins: [generateUrl],

    computed: {
        platform() {
            return this.$store.getters['platform/platform'];
        },
        logo() {
            return this.generateUrl(this.platform?.interface?.company_logo, '');
        },
        phoneHref() {
            return this.platform?.interface?.support_phone.replaceAll('+', '').replaceAll(' ', '');
        },
        supportEmail() {
            return this.platform?.interface?.support_email;
        },
        supportPhone() {
            return this.platform?.interface?.support_phone;
        },
        footerContent() {
            return this.platform?.interface?.footer_content;
        },
        footerLogo() {
            return this.platform?.interface?.footer_logo ? this.generateUrl(this.platform?.interface?.footer_logo, '') : require('@/assets/img/logo.svg');
        },
    },

    methods: {
        openGreenInitiativePopup() {
            this.$store.dispatch('togglePopup', { popupName: 'greenInitiative' });
        },
    },
};
</script>

<style scoped lang="scss">
footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 8.5vh;
    margin-top: auto;
    padding: 0 0 0 0.2rem;

    background: white;
}

.logo {
    display: block;

    width: 9.5rem;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.copy {
    width: 46rem;

    font-size: to_rem(12px);
    text-align: center;
    color: $copyGray;

    &:hover {
        .copy__logo {
            path {
                fill: $darkGreen;
            }
        }

        span {
            color: $darkGreen;
        }
    }

    &__logo {
        margin: 0 10px;

        path {
            transition: fill .3s;
        }
    }

    span {
        display: inline-block;

        width: fit-content;

        font-weight: 600;
        vertical-align: middle;
        color: $lightGreen;
        text-decoration: underline;

        cursor: pointer;
        transition: color .3s;

        p {
            display: flex;
            align-items: center;

            width: fit-content;
        }

        img {
            margin: 0 10px;
        }
    }
}

.right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    padding: 0 .7rem;

    background: #f6f6f6;
}

a {
    margin: 0.2rem 0;

    font-size: to_rem(16px);
    color: var(--platform-accent-color);

    svg {
        width: 1rem;
        margin-right: 0.5rem;

        path {
            fill: var(--platform-accent-color);
        }
    }
}

@include mobile_or_P {
    footer {
        margin-top: auto;
        padding: 0 0 0 1.2rem;

        .logo {
            width: 9.6rem;
            margin-left: 0.8rem;
        }

        .copy {
            width: 21rem;
        }

        .right {
            justify-content: center;

            width: 14rem;
            height: 100%;


            background: $lightGray;

            svg {
                width: 1rem;
                margin: 0 .5rem 0 0;
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 12px;
            text-align: center;
        }
    }
}

@include razr767 {
    footer {
        flex-direction: column;

        height: auto;
        padding: 1rem 0 0;

        .logo {
            width: 13rem;
        }

        .copy {
            width: 88%;
            margin-top: 0.8rem;

            font-size: 1rem;
            line-height: 1.5;
        }

        .right {
            width: 100%;
            height: auto;
            margin-top: 1rem;
            padding: 1rem 0;

            font-size: 1.35rem;

            svg {
                width: 1.5rem;
                margin: 0 1rem 0 0;
            }
        }
    }
}
</style>
