<template>
    <div
        ref="chat"
        class="app-video-chat"
    ></div>
</template>

<script>
  import DailyIframe from '@daily-co/daily-js';

  export default {
    name: 'app-video-chat',

    props: {
        link: {
          type: String,
          default: '',
        },
    },

    data: () => ({
        chatFrame: null,
    }),

    computed: {
      userName() {
        return this.$store.getters['auth/userInfo'].firstName + this.$store.getters['auth/userInfo'].lastName;
      },
    },

    mounted() {
      this.initChat();
    },

    methods: {
        initChat() {
          if (!this.link) return;
          this.chatFrame = DailyIframe.createFrame(this.$refs.chat,
            {
              userName: this.userName,
            },
          );
          this.chatFrame.join({
              url: this.link,
              showLeaveButton: true,
          });
          this.chatFrame.on('left-meeting', this.leave);
        },
        leave() {
          this.chatFrame.destroy();
          this.$emit('close');
        },
    },
  };
</script>

<style scoped lang="scss">
    .app-video-chat {
        height: 30rem;
    }
</style>
