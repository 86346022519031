import { get } from '@/utils/http-helper';
import router from '@/router';

const GET = '/workshop/get';
const GET_REVIEW = '/review/get';

export default {
    namespaced: true,
    state: {
        workshop: null,
    },
    actions: {
        async get({ commit }, topicId) {
            const workshop = await get(GET, {
                topic_id: topicId,
                event_id: router.app._route.query.event_id,
            });
            if (workshop.status) {
                commit('updateWorkshop', workshop.data);
            }
        },
        async getReview({ commit }, topicId) {
            const workshop = await get(GET_REVIEW, {
                topic_id: topicId,
                event_id: router.app._route.query.event_id,
                question_id: router.app._route.query.question_id || 0,
            });
            if (workshop.status) {
                commit('updateWorkshop', workshop.data);
            }
        },
        async getPage({ commit }, { topicId, question_id, page, path }) {
            const workshop = await get(path, {
                topic_id: topicId,
                question_id,
                page,
                event_id: router.app._route.query.event_id,
            });
            if (workshop.status) {
                commit('updateWorkshop', workshop.data);
            }
        },
    },

    mutations: {
        updateWorkshop(state, workshop) {
            if (state.workshop) {
                state.workshop = { ...state.workshop, ...workshop };
            } else {
                state.workshop = workshop;
            }
        },
    },

    getters: {
        get: state => state.workshop,
    },
};
