<template>
    <appWorkshop :is-document-review="true" />
</template>

<script>
  import appWorkshop from '@/components/user/app/app-workshop';

  export default {
    name: 'document-review',
    components: {
      appWorkshop,
    },
  };
</script>

<style scoped lang="scss">

</style>
