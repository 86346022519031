<template>
    <div class="event-nav">
        <router-link :to="{name: 'event-topics'}">
            Event Topics
        </router-link>
        <router-link :to="{name: 'disposer-agenda'}">
            Agenda
        </router-link>
        <router-link :to="{name: 'disposer-e-library'}">
            E-Library
        </router-link>
        <router-link :to="{name: 'disposer-company'}">
            Company Info/Contacts
        </router-link>
        <router-link :to="{name: 'external-participants'}">
            External Participants
        </router-link>
        <router-link :to="{name: 'internal-participants'}">
            Internal Participants
        </router-link>
        <router-link :to="{name: 'reports'}">
            Reports
        </router-link>

        <app-button
            class="to-platform"
            tag="router-link"
            :to="{name: 'dashboard', query: {event_id: eventId}}"
            target="_blank"
        >
            Go to the platform
        </app-button>
    </div>
</template>

<script>
    import appButton from '@/components/disposer/app/app-button';

    export default {
        name: 'event-nav',

        components: {
          appButton,
        },

        computed: {
            eventId() {
                return +this.$route.params.id;
            },
        },
    };
</script>

<style scoped lang="scss">
    .event-nav{
        position: relative;

        display: flex;
        justify-content: space-between;

        margin-right: 3rem;
        margin-bottom: 1rem;
        a:not(.to-platform){
            display: block;

            margin-right: 0.5rem;
            padding: 0.5rem 1rem;

            font-size: to_rem(16px);
            color: #000;
            text-decoration: none;

            background: rgba(#222d30, 0.15);

            transition: background .3s;
            &:hover{
                background: rgba(#222d30, 0.2);
            }

            &.router-link-active{
                background: rgba(#222d30, 0.4);
            }
        }
        .to-platform {
            position: absolute;
            top: 150%;
            right: 2rem;
        }
    }
</style>
