<template>
    <div>
        <p class="disposer-app-lang-title">
            Choose language
        </p>
        <app-select
            v-model="language"
            class="disposer-app-select app-select--white"
            :option-select="languagesList"
            label="label"
            :placeholder="'Default'"
        />
        <app-button
            class="get-report"
            @click="$emit('getReport', {role: 6, roleName: 'external', language: languageValue})"
        >
            Get report
        </app-button>
    </div>
</template>

<script>
    import appButton from '@/components/disposer/app/app-button';
    import appSelect from '@/components/disposer/app/app-select';

    export default {
        name: 'app-reports-lang',
        components: {
            appButton,
            appSelect,
        },
        props: {
            languagesList: {
              type: Array,
              default: () => [],
            },
        },
        data: () => ({
            language: null,
        }),
        computed: {
            languageValue() {
                return this.language ? this.language.value : null;
            },
        },
    };
</script>

<style scoped lang="scss">
.disposer-app-lang-title {
    margin-top: 1rem;
}
</style>