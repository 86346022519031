<template>
    <div
        class="app-survey-question"
        :class="{'p-none': survey.is_answered}"
    >
        <div class="app-survey-question__question">
            {{ processedSurvey.name }}
        </div>
        <form
            v-if="!showResults"
            ref="surveyForm"
            class="form app-survey-question__answers"
            @submit.prevent
            @change="checkShowExplain"
        >
            <div class="app-survey-question__inputs">
                <template v-if="processedSurvey.type===1">
                    <label
                        v-for="option in processedSurvey.options"
                        :key="option.id"
                        class="app-radio"
                    >
                        <input
                            v-model="selectedRadioBtn"
                            :value="option.id"
                            name="radioSurvey"
                            type="radio"
                        />
                        <div class="box"></div>
                        {{ option.name }}
                    </label>
                </template>

                <template v-else-if="processedSurvey.type===2">
                    <label
                        v-for="option in processedSurvey.options"
                        :key="option.id"
                        class="app-checkbox"
                        :data-disabled="survey.is_answered || answersCount>processedSurvey.max_response && !option.inputValue"
                    >
                        <input
                            v-model="option.value"
                            type="checkbox"
                            :value="option.id"
                            @change="countAnswers"
                        />
                        <div class="box"></div>
                        {{ option.name }}
                    </label>
                    <div
                        v-if="processedSurvey.max_response"
                        class="max"
                    >
                        {{ $t('max_response', 'maximum number of responses') }}:
                        {{ survey.max_response }}
                    </div>
                </template>

                <template v-else-if="processedSurvey.type===3">
                    <label
                        v-for="option in processedSurvey.options"
                        :key="option.id"
                    >
                        <select
                            v-model="option.value"
                            :name="option.id"
                            @change="checkUniqueRate"
                        >
                            <option
                                value=""
                                selected
                                disabled
                                hidden
                            ></option>

                            <option
                                v-for="item in optionsRating"
                                :key="item.value"
                                :value="item.value"
                                :class="{disabled :item.disabled}"
                                :disabled="item.disabled"
                            >
                                {{ item.value }}
                            </option>
                        </select>
                        {{ option.name }}
                    </label>
                </template>

                <template v-else-if="processedSurvey.type===4">
                    <label
                        v-for="option in processedSurvey.options"
                        :key="option.id"
                        class="deployed"
                    >
                        {{ option.name }}
                        <input
                            v-model="option.value"
                            type="text"
                            class="app-input"
                            :name="option.id"
                        />
                    </label>
                </template>

                <template v-else-if="processedSurvey.type===5">
                    <div
                        v-for="question in processedSurvey.questions"
                        :key="question.id"
                        :class="{disabled : question.answers && question.answers.length || userRole.id === 5}"
                    >
                        <div class="question-name">
                            {{ question.name }}
                        </div>

                        <template v-if="question.type===1">
                            <label
                                v-for="option in question.options"
                                :key="option.id"
                                class="app-radio"
                                :class="{error: option.result===0, success: option.result}"
                            >
                                <input
                                    v-model="question.answerId"
                                    :value="option.id"
                                    :name="question.id"
                                    type="radio"
                                />
                                <div class="box"></div>
                                {{ option.name }}
                            </label>
                        </template>
                        <template v-else-if="question.type===2">
                            <label
                                v-for="option in question.options"
                                :key="option.id"
                                class="app-checkbox"
                                :class="{error: option.result===0, success: option.result}"
                            >
                                <input
                                    v-model="option.isAnswered"
                                    type="checkbox"
                                />
                                <div class="box"></div>
                                {{ option.name }}
                            </label>
                        </template>

                        <div
                            v-if="question.isRequiredError"
                            class="test-error"
                        >
                            {{ $t('field_req', 'Required') }}
                        </div>

                        <app-button
                            tag="button"
                            class="accent"
                            :class="{'disabled-btn': question.answers}"
                            @click="answerTheTest(question.id)"
                        >
                            {{ $t('submit', 'Submit') }}
                        </app-button>
                    </div>
                </template>

                <label
                    v-if="processedSurvey.need_comment && showExplain"
                    class="textarea-label"
                    :class="{error: explainError}"
                >
                    {{ $t('dash_answer_explain', 'Please explain your answer') }}:
                    <textarea class="app-input"></textarea>
                    <p
                        v-if="explainError"
                        class="app-input-error"
                    >
                        {{ $t('field_req', 'Required') }}
                    </p>
                </label>
            </div>
            <div class="surves-btn">
                <app-button
                    v-if="processedSurvey.type!==5"
                    tag="button"
                    class="accent"
                    :class="{'disabled-btn': survey.is_answered}"
                    @click="sendAnswer"
                >
                    {{ $t('submit', 'Submit') }}
                </app-button>
                <app-gpt
                    v-if="processedSurvey.type === 1 || processedSurvey.type === 2 || processedSurvey.type === 3 "
                    :name="'surveyPanel'"
                    :big-btn="true"
                    :surveys-id="survey.id"
                />
            </div>
        </form>
        <div
            v-else
            class="answers"
        >
            <div
                v-for="(answer, index) of survey.answers.values"
                :key="answer.id"
                class="answer"
                :class="{textAnswer: survey.type===4}"
            >
                <template v-if="survey.type===1 || survey.type===2">
                    <app-progress-bar :progress="+answer.value" />
                    <span class="percents">
                        ({{ answer.value }}%)
                    </span>
                    {{ survey.options[index].name }}
                </template>
                <template v-else-if="survey.type===3">
                    <span class="average">
                        {{ $t('average', 'average rating') }}: <span>{{ answer.value }}</span>
                    </span>
                    {{ survey.options[index].name }}
                </template>
                <template v-else-if="survey.type===4">
                    {{ survey.options[index].name }}
                    <div
                        v-for="(comment, index) in answer.comments"
                        :key="index"
                        class="average"
                    >
                        <b>{{ comment.name }}:</b>
                        {{ comment.content }}
                    </div>
                </template>
            </div>
            <div class="count">
                {{ survey.answers.total }} {{ $t('dash_answers', 'Voting') }}
            </div>
            <div
                v-if="survey.advanced"
                class="advanced"
            >
                <div
                    v-for="(message, index) in survey.advanced.comments"
                    :key="index"
                    class="message"
                >
                    <div class="name">
                        {{ message.name }}
                    </div>
                    <div class="text">
                        {{ message.content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';
import appProgressBar from '@/components/user/app/app-progressBar';
import appGpt from '@/components/user/app/app-gpt';

const NAME_SPACE = 'dashboard';
const SURVEY_ANSWER = 'survey-answer';
const ANSWER_TEST = 'dashboard/answer-test';

import { GP } from '@/utils/sockets-helper';
import { post } from '@/utils/http-helper';

const generateSocketPath = new GP(NAME_SPACE);

export default {
    name: 'app-survey-question',

    components: {
        appButton,
        appProgressBar,
        appGpt,
    },

    props: {
        survey: {
            type: Object,
            default: null,
        },
        socket: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        answersCount: 0,
        explainError: false,
        processedSurvey: {},
        showExplain: false,
        optionsRating: [],
        selectedRadioBtn: 0,
    }),

    computed: {
        showSurvey() {
            return (this.processedSurvey.answers && this.processedSurvey.show_result) || !this.processedSurvey.answers || this.userRole?.id !== 6;
        },

        showResults() {
            return this.processedSurvey.answers && this.userRole?.id !== 1 && this.userRole?.id !== 2 && this.userRole?.id !== 3 && this.userRole?.id !== 4;
        },

        userRole() {
            return this.$store.getters['auth/userRole'];
        },
        isUniqueRate () {
            return this.processedSurvey.is_rate_unique ? true : false;
        },
        topicId() {
            return this.$store.getters['dashboard/topicId'];
        },

    },

    watch: {
        survey: {
            handler() {
                this.setProcessedSurvey();
            },
            deep: true,
        },
    },

    mounted() {
        this.setProcessedSurvey();
    },

    methods: {
        checkUniqueRate() {
            if (!this.isUniqueRate) return;
            const inputs = this.$refs.surveyForm.elements;
            this.optionsRating.map(item => {
                item.disabled = false;
            });
            inputs.forEach(i => {
                if (i.nodeName === 'SELECT') {
                    if (i.value) {
                        this.optionsRating.map(item => {
                            if (item.value == i.value) {
                                item.disabled = true;
                            }
                        });
                    }
                }
            });
        },

        setProcessedSurvey() {
            if (this.survey.questions) {
                const survey = JSON.parse(JSON.stringify(this.survey));
                survey.questions?.forEach(question => {
                    question.answers?.forEach(answer => {
                        const option = question.options.find(o => +o.id === +answer.option_id);
                        option.result = answer.result;
                        option.isAnswered = answer.self;
                        if (!question.answerId && answer.self) {
                            this.$set(question, 'answerId', +option.id);
                        }

                    });
                });

                this.processedSurvey = survey;

            } else {
                this.processedSurvey = this.survey;
            }
            if ( this.processedSurvey.type === 3 ) {
               this.optionsRating =  Array.from({ length: this.processedSurvey.max_scale }, (_, i) => ({ value:  i + 1, disabled: false }));
            }


            if (this.processedSurvey.type === 1 ) {
                this.survey.options.forEach((item) => {
                    if (item.value) {
                        this.selectedRadioBtn = item.id;
                    }
                });
            }
        },
        checkShowExplain() {
            const obj = {
                survey_id: this.processedSurvey.id,
                options: [],
            };

            this.$refs.surveyForm.elements.forEach((i) => {
                if (this.processedSurvey.type === 1 || this.processedSurvey.type === 2) {
                    if (i.nodeName === 'INPUT' && i.checked) {
                        obj.options.push({
                            id: +i.value,
                        });
                    }
                } else {
                    if (i.value) {
                        obj.options.push({
                            id: +i.name,
                            value: i.value,
                        });
                    }
                }
            });

            this.showExplain = !!obj.options.length;
        },

        canSendAnswer() {
            let isValid = true;
            if (this.processedSurvey.type === 3) {
                const inputs = this.$refs.surveyForm.elements;
                inputs.forEach(i => {
                    if (i.nodeName === 'SELECT') {
                        if (!i.value) {
                            isValid = false;
                            i.classList.add('error');
                        } else {
                            i.classList.remove('error');
                        }
                    }
                });
            }
            return isValid;
        },

        sendAnswer() {
            const obj = {
                survey_id: this.processedSurvey.id,
                options: [],
            };

            if (!this.canSendAnswer()) return;

            this.$refs.surveyForm.elements.forEach((i) => {
                if (this.processedSurvey.type === 1 || this.processedSurvey.type === 2) {
                    if (i.nodeName === 'INPUT' && i.checked) {
                        obj.options.push({
                            id: +i.value,
                        });
                    }
                } else {
                    if (i.value && i.name) {
                        obj.options.push({
                            id: +i.name,
                            value: i.value,
                        });
                    }
                }
                if (i.nodeName === 'TEXTAREA' && i.value) {
                    obj.comment = i.value;
                } else if (i.nodeName === 'TEXTAREA' && !i.value) {
                    this.explainError = true;
                }
            });

            if (!obj.options.length) return;

            this.socket.emit(generateSocketPath.generate(SURVEY_ANSWER), obj,
                data => {
                    if (data.success) {
                        this.$store.dispatch('dashboard/answerSurvey', data);
                    }
                },
            );
            this.getDashboardRequest();
        },

        countAnswers(e) {
            e.target.checked ? this.answersCount++ : this.answersCount--;
        },


        async answerTheTest(questionId) {
            let question = this.processedSurvey.questions.find(q => q.id === questionId);

            if (this.disabledSendTest(question)) {
                this.$set(question, 'isRequiredError' , true);
                return;
            } else {
                this.$set(question, 'isRequiredError' , false);
            }

            const obj = {
                survey_id: this.processedSurvey.id,
                questions: [
                    {
                        question_id: questionId,
                        options: [],
                    },
                ],
            };
            if (question.type===1) {
                obj.questions[0].options = [question.answerId];
            } else if (question.type===2) {
                question.options.forEach(o => {
                    if (o.isAnswered) {
                        obj.questions[0].options.push(o.id);
                    }
                });
            }
            const response = await post(ANSWER_TEST, obj);

            if (response.status) {
                response.data.questions[0].options.forEach(o => {
                    this.$set(question, 'answers', [{}]);
                    const option = question.options.find(opt => +opt.id === o.option_id);
                    this.$set(option, 'result', o.result);
                });
            }
            await this.getDashboardRequest();
        },

        async getDashboardRequest() {
            const params = {
                id: this.topicId,
                eventId: this.eventId ? this.eventId : null,
            };
            await this.$store.dispatch('dashboard/get', params);
        },

        disabledSendTest(question) {
            let option = true;
            if (question.type === 1) {
                option = !question.answerId;
            } else {
                option = !question.options.find(o=>o.isAnswered);
            }
            return !!(question.answers && question.answers.length || option || this.userRole?.id === 5);
        },
    },
};
</script>

<style scoped lang="scss">
.app-survey-question {
    margin-top: 1.2rem;
    padding: 0.7rem 1.25rem 1.25rem;

    &__inputs {
        .disabled {
            pointer-events: none;
        }
    }

    .app-checkbox {
        width: 100%;
    }

    .question-name {
        margin-bottom: 1rem;
    }

    .test-error {
        font-size: to_rem(14px);
        color: $red;
    }

    &__question{
        font-size: to_rem(18px);
        color: var(--platform-accent-color);
        font-style: italic;
        word-break: break-word;
    }

    &__inputs, .answers {
        display: flex;
        flex-direction: column;

        margin-top: 1.3rem;
        padding: 2rem 1.2rem;

        border: 3px solid $lightGray;
    }

    .max {
        font-size: 12px;
        color: $gray;
    }

    .count {
        font-size: 14px;
        color: $gray;
    }

    .answer {
        display: flex;

        margin-bottom: 1rem;

        &.textAnswer {
            display: block;
        }

        .percents {
            width: 4rem;
            margin-right: 1rem;

            color: $gray;
        }

        .average {
            margin-right: 1rem;

            color: $gray;

            span {
                color: black;
            }
        }

        .app-progressBar {
            width: 10rem;
            margin-right: 0.5rem;
        }
    }

    .advanced {
        .message {
            margin-bottom: 1rem;

            font-size: to_rem(14px);

            &:nth-of-type(1) {
                margin-top: 0.5rem;
            }
        }

        .name {
            font-weight: 700;
        }
    }

    .question-name {
        font-weight: 700;
    }

    .app-radio, .app-checkbox {
        padding: 5px 5px;

        &.error {
            background: rgba($red, 0.4);
        }

        &.success {
            background: rgba($green, 0.4);
        }
    }

    label {
        margin-bottom: 1rem;

        font-size: to_rem(14px);
        color: $copyGray;

        display: flex;
        align-items: center;

        &.textarea-label {
            position: relative;

            flex-direction: column;
            align-items: flex-start;

            margin: 2.3rem 0 0;

            font-weight: 700;

            &.error {
                textarea {
                    border-color: $red;
                }
            }
        }

        &.deployed {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    textarea {
        width: 100%;
        height: 3.8rem;
        margin-top: 0.4rem;;

        resize: none;
    }

    input[type='text'] {
        margin-top: 1rem;
    }

    select {
        width: 2.3rem;
        min-width: 40px;
        margin-right: 1rem;

        color: $copyGray;

        border-color: $copyGray;

        & option.disabled {
            color: #a8a9ab;

            background-color: #F5F5F5;

            pointer-events: none;
        }
        &.error {
            border-color: $red;
        }
    }

    .app-button {
        margin-top: 1rem;
        margin-left: auto;
    }
    .surves-btn {
        display: flex;
        align-items: center;
    }

    .disabled-btn {
        pointer-events: none;
        background-color: #c5c0c0;
    }
}

.p-none {
    pointer-events: none;
}

@include mobile_or_P {
    .app-survey-question {
        &__inputs {
            padding: 1rem;
        }

        label {
            margin-bottom: 1rem;

            &.textarea-label {
                margin-top: 1rem;
            }
        }
    }
}
</style>
