<template>
    <div class="add-new-page">
        <div class="head">
            {{ $t('new_page', 'New chapter name') }}
        </div>
        <form @submit.prevent="addNewPage">
            <label class="column">
                <p>
                    {{ $t('title', 'Title') }}
                </p>
                <input
                    v-model="title"
                    type="text"
                    class="app-input"
                    :autofocus="false"
                />
            </label>
            <app-button tag="button">
                OK
            </app-button>
        </form>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';

import { GP } from '@/utils/sockets-helper';

const CREATE_NEW_PAGE = 'page-create';

export default {
    name: 'add-new-page',

    components: {
        appButton,
    },

    props: {
      socket: {
        type: Object,
        default: null,
      },
      nameSpace: {
        type: String,
        default: '',
      },
    },

    data: ()=>({
      title: '',
      generateSocketPath: null,
    }),

    mounted() {
        this.generateSocketPath =  new GP(this.nameSpace);
    },

    methods: {
        addNewPage() {
          this.socket.emit(this.generateSocketPath.generate(CREATE_NEW_PAGE), { title: this.title }, data => {
            if (data.success) {
              this.$emit('createPage');
              this.$store.dispatch('togglePopup', { popupName: null });
            }
          });
        },
    },
};
</script>

<style scoped lang="scss">
    form {
        margin: 1rem auto;
        input {
            margin: 1rem 0;
        }
    }
</style>
