<template>
    <div class="disposer-app-links">
        <div class="links-main">
            <div class="link-description">
                <label class="disposer-app-label">
                    Description
                    <input
                        v-model="link.description"
                        type="text"
                        class="disposer-app-input"
                    />
                </label>
            </div>
            <div class="link-name">
                <label class="disposer-app-label">
                    Link
                    <input
                        v-model="link.link"
                        type="text"
                        class="disposer-app-input"
                    />
                    <span
                        v-if="$v.link.link.$dirty && !$v.link.link.required"
                        class="app-input-error"
                    >
                        Enter url
                    </span>
                    <span
                        v-if="$v.link.link.$dirty && !$v.link.link.url"
                        class="app-input-error"
                    >
                        Url is invalid
                    </span>
                    <span
                        v-if="errors.link"
                        class="app-input-error"
                    >
                        {{ errors.link[0] }}
                    </span>
                </label>
            </div>

            <div class="link-btns">
                <app-button
                    class="mini red"
                    @click="saveLink"
                >
                    Save
                </app-button>
                <app-button
                    class="mini"
                    @click="deleteLink"
                >
                    Delete
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
    import appButton from '@/components/disposer/app/app-button';

    import { post } from '@/utils/http-helper';
    import { saveLink, updateLink, deleteLink, defaultConfirmSettings } from '@/utils/disposer/notifications-text-helper';
    import { required, url } from 'vuelidate/lib/validators';

    export default {
        name: 'app-link',

        components: {
            appButton,
        },

        props: {
            link: {
              type: Object,
              default: () => ({}),
            },
            paths: {
                type: Object,
                default: () => {},
            },
            eventId: {
                type: Number,
                default: null,
            },
            userId: {
                type: Number,
                default: null,
            },
            section: {
                type: Object,
                default: null,
            },
        },

        data: () => ({
            errors: {},
        }),

        validations: {
            link: {
                link: {
                    required,
                    url,
                },
            },
        },

        methods: {
            async saveLink() {
                if (this.$v.link.$invalid) {
                    this.$v.link.$touch();
                    return;
                }

                const fd = new FormData();
                fd.append('object', 'link');
                fd.append('link', this.link.link);
                fd.append('description', this.link.description);
                fd.append('id', this.link.id);
                this.section? fd.append('section_id', this.section.id) : null;
                this.userId? fd.append('user_id', this.userId) : fd.append('event_id', this.eventId);

                this.$awn.async(post(this.link.id>=1 ? this.paths.UPDATE_OBJECT :  this.paths.SAVE_OBJECT, fd, true), response => {
                  if (response.status) {
                    this.link.id>=1 ? this.$awn.success(updateLink) : this.$awn.success(saveLink) ;
                    this.link.id = response.data.id;
                  } else  {
                    this.errors = response.errors;
                  }
                });

            },
            async deleteLink() {
              this.$confirm({
                ...defaultConfirmSettings,
                callback: confirm => {
                  if (confirm) {
                    if (this.link.id>=1){
                      const obj = {
                        id: this.link.id,
                        object: 'link',
                      };
                      this.userId? obj.user_id = this.userId: obj.event_id = this.eventId;

                      this.$awn.async(post(this.paths.DELETE_OBJECT, obj), response => {
                        if (response.status) {
                          this.$emit('delete', this.link.id, this.section);
                          this.$awn.success(deleteLink);
                        }
                      });
                    } else  {
                      this.$emit('delete', this.link.id, this.section);
                    }
                  }
                },
              });
            },
        },
    };
</script>

<style scoped lang="scss">
    .disposer-app-links {
        margin-bottom: 1rem;
        padding: 1rem 0 0;

        .links-header {
            margin-bottom: 20px;
        }

        .links-main {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            width: 100%;

            .disposer-app-input {
                width: to_rem(330px);
                margin-right: 1rem;
            }
        }

        .link-btns {
            margin-left: auto;
            .app-button {
                margin-bottom: 1rem;
            }
        }

        .upload-current-file {
            color: grey;
        }
    }
</style>
