<template>
    <div class="editor-layout">
        <layout-header
            type="editor"
            class="all-info"
        />
        <div class="container">
            <slot></slot>
        </div>
        <layout-footer />
    </div>
</template>

<script>
    import LayoutHeader from '@/components/user/layouts/header';
    import LayoutFooter from '@/components/user/layouts/footer';

    export default {
        name: 'editor-layout',
        components: {
            LayoutHeader,
            LayoutFooter,
        },
    };
</script>

<style scoped lang="scss">
    .container{
        width: to_rem(1270px);
        margin: 0 auto;
    }
    @include mobile_or_P{
        .container{
            width: 100%;
            padding-top: 7rem;
        }
    }
    @include razr767{
        .container{
            padding-top: 14rem;
        }
    }
</style>
