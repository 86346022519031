<template>
    <div :class="{'survey-btn': bigBtn}">
        <div
            v-if="showBtnGpt"
            class="summary-text"
            @click="openMessagePopup"
        >
            <img src="@/assets/img/icons/gpt.svg" /> 
            Summary text
        </div>
        <app-popup
            v-if="popupName === nameActivePopup"
            :name="popupName"
        >
            <h2> Summary Text </h2>
           
            <div class="wrapper-summary">
                <div
                    v-if="!isLoaded"
                    class="summary-loading"
                >
                    <img src="@/assets/img/icons/gpt-black.svg" />
                </div>

                <div
                    v-else
                    class="summary-descp"
                >
                    <p>{{ summaryText }}</p>
                </div>
            </div>
        </app-popup>
    </div>
</template>

<script>
import appPopup from '@/components/user/app-popup';
import { post } from '@/utils/http-helper';

const GET_SUMMARY_TEXT = 'api/dashboard/summary-text';

export default {
    
    components: {
        appPopup,
    },

    props: {
        bigBtn: {
            type: Boolean,
            default: false,
        },
        questionId: {
            type: Number,
            default: 0,
        },
        surveysId: {
            type: Number,
            default: 0,
        },
        name: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLoaded: false,
            summaryText: '',
        };
    },

    computed: {
        dashboard() {
            return this.$store.getters['dashboard/get'];
        },
        user(){
            return this.$store.getters['auth/userInfo'];
        },
        profile() {
            return this.$store.getters['auth/userRole'];
        },
        platform() {
            return this.$store.getters['platform/platform'];
        },
        nameActivePopup() {
            return this.$store.getters.activePopup;
        },
        popupName() {
            if (this.surveysId) {
                return this.name + this.surveysId;
            }
            return this.name + this.questionId;
        },
        showBtnGpt() {
            if (this.platform.settings.chatgpt && (this.profile?.id <= 3 || this.profile?.id === 5) && this.user.allow_gpt) {
                return true;
            }
            return false;
        },
    },

    methods: {
        openMessagePopup() {
            this.isLoaded = false;
            this.getSummaryText();
            this.$store.dispatch('togglePopup', { popupName: this.popupName });
        },
        async getSummaryText() {
            const option = {
                'topic_id': this.dashboard.id,
                'question_id': this.questionId ? this.questionId : '',
                
            };
            if (this.surveysId) {
                option['survey_id'] = this.surveysId;
            }
            const response = await post(GET_SUMMARY_TEXT, option);
            this.summaryText = response.data.summary;
            this.isLoaded = true;
        },
    },
};
</script>

<style lang="scss">
.summary-text {
    display: flex;

    padding: 0.55rem 0.4rem;

    font-size: 0.875rem;
    color: white;
    
    background: var(--platform-main-color);
    border-radius: 0.2rem;

    cursor: pointer;
    filter: grayscale(1);

    pointer-events: auto;
    img {
        width: 0.9rem;
        margin: 0 3px 0 0;
    }
}
.survey-btn{
    .summary-text{
        margin-top: 1rem;
        margin-left: 15px;
        padding: 0.86rem 2.5rem;

        font-size: 0.875rem;
    }
}
.wrapper-summary {
    display: flex;

    min-height: 250px;
    .summary-loading {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;

        img {
            animation: gptRrotate 4s linear infinite;
        }
    }

    .summary-descp {
        margin-top: 25px;
    }
}


@keyframes gptRrotate {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(0.7);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}


@include mobile_or_P {
    .summary-text{
        margin: 0.5rem 0.2rem;
    }
    .survey-btn{
        .summary-text{
            margin: 0;
            margin-top: 1rem;
            margin-left: 15px;
        }
}   
}
</style>