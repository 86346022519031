import { get } from '@/utils/http-helper';

const GET_PROFILE = '/my-profile/view';
const GET_CHAT = '/my-profile/view-chat';
const GET_CHATS = '/my-profile/get-chats';

export default {
  state: {
    profile: null,
    activeChat: {
      id: null,
      messages: [],
    },
  },
  actions: {
    async getProfile({ commit }) {
      const profile = await get(GET_PROFILE);
      if (profile.status) {
        commit('updateProfile', profile.data);
      }
    },

    async viewChat({ commit }, chat) {
      const path = `${GET_CHAT}?dialog_id=${chat.id}`;
      const newChat = await get(path);
      if (newChat.status) {
        commit('updateChat', { chat, newChat: newChat.data });
      }
    },

    async GET_CHATS({ commit }, data) {
      const chats = await get(GET_CHATS, { ...data });
      if (chats.status) {
        commit('updateChats', chats);
      }
    },

    messageCreate({ commit, state }, data) {
      commit('updateChatList', data);

      if (+data.dialog_id === +state.activeChat.id) {
        commit('updateActiveChat', data);
      }
    },

    updateActiveChat({ commit }, data) {
      commit('updateChatList', data);
      commit('updateActiveChat', data);
    },

    readChat({ commit }, id) {
      commit('readChat', id);
    },

    deleteChats({ commit }, chats) {
      commit('deleteChats', chats);
    },

    unreadChats({ commit }, chats) {
      commit('unreadChats', chats);
    },
  },
  mutations: {
    updateProfile(state, profile) {
      state.profile = profile;
    },

    updateChat(state, { chat, newChat }) {
      state.activeChat.id = chat.id;
      state.activeChat.user_id = chat.user_id;
      state.activeChat.messages = newChat.messages;
    },

    updateChats(state, chats) {
        state.profile.chats = chats.data.chats;
    },

    updateChatList(state, data) {
      const message = state.profile?.chats?.chats?.find((chat) => +chat.id === +data.dialog_id || +chat.id === +data.recipient_id);
      if (message) {
        message.text = data.text;
        message.status = data.status;
      } else {
        state.profile?.chats?.chats?.unshift({
          date: data.created_at,
          name: data.author,
          status: 0,
          id: data.dialog_id,
          text: data.text,
        });
      }
    },

    updateActiveChat(state, data) {
      state.activeChat.messages.push({
        created_at: data.created_at,
        name: data.author,
        id: data.id,
        text: data.text,
      });
    },

    readChat(state, id) {
      const message = state.profile.chats.chats.find((chat) => {
        return +chat.id === +id;
      });
      message.status = 1;
    },

    deleteChats(state, chats) {
        let profile = state.profile;
        chats.forEach(c => {
            profile.chats.chats = profile.chats.chats.filter(chat => +chat.id !== +c);
        });
        state.profile = profile;
    },

    unreadChats(state, chats) {
        let profile = state.profile;
        chats.forEach(c => {
            const chat = profile.chats.chats.find(chat => +chat.id === +c);
            chat.status = 0;
        });
        state.profile = profile;
    },
  },
  getters: {
    getProfile: state => state.profile,
    getChat: state => state.activeChat,
  },
};
