<template>
    <div class="zoom-mtg" :class="{ fullScreen: isShowFullScreen }">
        <div class="zoom-mtg__container">
            <div v-if="isStartMtg" class="zoom-mtg__full-screen app-tool-btn" @click="toggleFullScreen">
                <img v-if="!isShowFullScreen" src="@/assets/img/icons/full_screen_icon.svg" />
                <img v-else src="@/assets/img/icons/low-screen_icon.svg" />
            </div>
            <div ref="zoomMtg" @dragstart.stop></div>
        </div>
    </div>
</template>

<script>
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';

import { get } from '@/utils/http-helper';
const GET_SIGNATURE = '/user/zoom-signature';

const client = ZoomMtgEmbedded.createClient();

export default {
    name: 'zoom-mtg',

    data: () => ({
        isShowFullScreen: false,
        isStartMtg: false,

        getCurrentMeetingInfoInterval: null,
        checkTranslationInterval: null,

        zoomFullScreenTxtOpen: '<span class="zoomBtnFullScreen">Open fullwindow<span>',
        zoomFullScreenTxtClose: '<span class="zoomBtnFullScreen">Collapse<span>',
    }),

    computed: {
        user() {
            return this.$store.getters['auth/userInfo'];
        },

        zoomApiKey() {
            return this.$store.getters['platform/platform'].settings.zoom_key;
        },

        languageFormat() {
            const lang = this.$store.getters['platform/platform'].lang;
            return `${lang}-${lang.toUpperCase()}`;
            //return `en-EN`;
        },
    },

    mounted() {
        this.initMeeting();
    },

    deactivated() {
        document.querySelectorAll('[role="dialog"]').forEach(d => { d.style.display = 'none'; });
    },

    activated() {
        document.querySelectorAll('[role="dialog"]').forEach(d => { d.style.display = 'block'; });
    },

    beforeDestroy() {
        client.leaveMeeting();
        window.clearInterval(this.checkTranslationInterval);
    },

    methods: {
        initMeeting() {
            console.log(this.zoomApiKey);
            client.leaveMeeting();
            client.init({
                zoomAppRoot: this.$refs.zoomMtg,
                language: this.languageFormat,
                customize: {
                    meetingInfo: [
                        'topic',
                        'host',
                        'mn',
                        'pwd',
                        'telPwd',
                        'invite',
                        'participant',
                        'dc',
                        'enctype',
                    ],
                },
            });
        },

        async joinMeeting(link) {
            const parseLInk = new URL(link);
            const id = parseLInk.pathname.split('/')[parseLInk.pathname.split('/').length - 1];
            const password = parseLInk.search.split('=')[1];

            this.initListeners();

            const response = await get(GET_SIGNATURE, {
                link_or_id: id,
                role: 0,
            });

            let zoomFullScreen = null;

            if (response.status) {
                client.join({
                    sdkKey: this.zoomApiKey,
                    signature: response.data.signature,
                    meetingNumber: id,
                    password: password,
                    userName: `${this.user?.firstName} ${this.user?.lastName}`,
                    userEmail: this.user?.email,

                }).then(() => {
                    setTimeout(() => {
                        this.isStartMtg = true;
                        this.getCurrentMeetingInfoInterval = setInterval(() => {
                            zoomFullScreen = document.querySelector('.zoom-mtg__full-screen');
                            if (zoomFullScreen && !zoomFullScreen.classList.contains('zoom-mtg__full-screen2')) {
                                zoomFullScreen.innerHTML = this.zoomFullScreenTxtOpen;
                                zoomFullScreen.classList.add('zoom-mtg__full-screen2');

                                document.querySelector('button[title="Audio"]').click();
                            }
                            const currentMeetingInfo = client.getCurrentMeetingInfo();
                            if (currentMeetingInfo?.isInMeeting) {
                                this.wasEnter = true;
                            } else if (this.wasEnter) {
                                this.endMeeting();
                            }
                        }, 1000);
                    }, 1000);
                }).catch((e) => {
                    console.error(e);
                    if (e.reason === 'Duplicated join operation') {
                        window.location.reload();
                    } else if (e.errorCode === 3707 || e.errorCode === 3008 || e.errorCode === 3712 || e.errorCode === 3620 || e.type === 'INVALID_OPERATION') {
                        this.endMeeting();
                        alert(e.reason);
                    }
                });
            }
        },

        endMeeting() {
            this.isStartMtg = false;
            this.closeFullScreen();

            this.$emit('endMeeting');
            this.wasEnter = false;
            window.clearInterval(this.getCurrentMeetingInfoInterval);
        },

        initListeners() {
            
        },

        toggleFullScreen() {
            this.isShowFullScreen = !this.isShowFullScreen;
            const zoomFullScreen = document.querySelector('.zoom-mtg__full-screen2');
            if (this.isShowFullScreen) {
                window.scrollTo({
                    top: this.$refs.zoomMtg.getBoundingClientRect().bottom + window.pageYOffset - window.innerHeight,
                });
                setTimeout(() => {
                    document.documentElement.style.overflow = 'hidden';
                });
                if (zoomFullScreen) {
                    zoomFullScreen.innerHTML = this.zoomFullScreenTxtClose;
                }
            } else {
                document.documentElement.style.overflow = 'auto';
                if (zoomFullScreen) {
                   zoomFullScreen.innerHTML = this.zoomFullScreenTxtOpen;
                }
            }
        },

        closeFullScreen() {
            if (this.isShowFullScreen) {
                this.isShowFullScreen = false;
        
            }
        },
    },
};
</script>

<style lang="scss">
.zoom-mtg__full-screen {
    display: none !important;
    color: black !important;
    width: auto !important;
    display: inline-block !important;
    background: none !important;
    text-align: right !important;

    &:hover {
        box-shadow: none !important;
    }

    img {
        display: none !important;
    }
}

.zoom-mtg__container>div:last-child {
    justify-content: center;
    align-items: center;
}

.zoom-mtg__full-screen2 {
    display: block !important;
    margin-bottom: 10px !important;

    .zoomBtnFullScreen {
        display: inline-block;
        color: black;
        background: white;
        border-radius: 10px;
        padding: 4px 9px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
        transition: 0.3s;

        &:hover {
            box-shadow: 1px 1px 8px rgba(0, 0, 0, 1);
        }
    }
}

.zmwebsdk-MuiPaper-root, .zmwebsdk-makeStyles-videoCustomize-1 {
    width: 100% !important;
}


.zmwebsdk-makeStyles-root-30:not(.zmwebsdk-makeStyles-rootInSharing-31) .zmwebsdk-makeStyles-wrap-49 {
    width: 100% !important;
}

.zoom-mtg {
    position: relative;
    z-index: 1;

    margin: 10px 0;

    #suspension-view-tabpanel-active:nth-child(3) {
        background: black;
    }
    
    #suspension-view-tabpanel-active:nth-child(3) > div[class*="zmwebsdk-makeStyles-root-"] {
        height: 300px !important;
    }

    [class*="zmwebsdk-makeStyles-rootInSharing-"] #suspension-view-tabpanel-active:nth-child(3) > div[class*="zmwebsdk-makeStyles-root-"] {
        height: 340px !important;
    }
    

    @media (max-width: 1023px) {
        [class*="zmwebsdk-makeStyles-rootInSharing-"] #suspension-view-tabpanel-active:nth-child(3) > div {
            flex-direction: column-reverse;
            height: 420px !important;
        }
        [class*="zmwebsdk-makeStyles-rootInSharing-"] #suspension-view-tabpanel-active > div > div:nth-child(1) {
            height: calc(100% - 90px);
            width: 100%;
        }
        [class*="zmwebsdk-makeStyles-rootInSharing-"] #suspension-view-tabpanel-active > div > div:nth-child(2) {
            height: 90px;
            width: 100%;
        }
    }
    @media (max-width: 767px) {
        #suspension-view-tabpanel-active:nth-child(3) > div[class*="zmwebsdk-makeStyles-root-"] {
            height: 270px !important;
        }

        [class*="zmwebsdk-makeStyles-rootInSharing-"] #suspension-view-tabpanel-active:nth-child(3) > div {
            height: 340px !important;
        }
    }

    &__full-screen {
        margin-bottom: 10px;
        margin-left: auto;

        background: white;
    }

    &.fullScreen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0;

        background: rgba(#000, 0.7);

        .zoom-mtg__container {
            display: flex;
            flex-direction: column;

            width: 95vw;
            margin: 0 auto;
        }

        #suspension-view-tabpanel-active:nth-child(3) > div[class*="zmwebsdk-makeStyles-root-"] {
            height: 80vh !important;
        }
      
        @media (max-width: 1023px) {
            #suspension-view-tabpanel-active:nth-child(3) > div[class*="zmwebsdk-makeStyles-root-"] {
                height: 55vh !important;  
            }
           
        }
        @media (max-width: 767px) {
            #suspension-view-tabpanel-active:nth-child(3) > div[class*="zmwebsdk-makeStyles-root-"] {
                height: 60vh !important; 
            }
            
        }
    }
}
[role="dialog"]:not(.cke_dialog_container),
[role="tooltip"],
[role="listbox"] {
    z-index: 3 !important;
    @media (max-width: 1023px) {
        top: 30px;
    }

}
[role="dialog"] {
    @media (max-width: 767px) {
        width: 100% !important;
        transform: none !important;
        top: auto !important;
        bottom: 0 !important;
        position: fixed !important;
    }
}
[role="dialog"] > [class*="zmwebsdk-makeStyles-root-"] {
    min-width: 320px;
}

[role="dialog"]:not([class]) {
    @media (max-width: 767px) {
        position: fixed !important;
        bottom: 0;
    }
}
</style>