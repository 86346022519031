<template>
    <div class="app-select">
        <multiselect
            v-model="state"
            :options="optionSelect"
            :searchable="searchable"
            :placeholder="placeholder"
            :label="label"
            :disabled="disabled"
            :allow-empty="allowEmpty"
        />
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'app-select',


        components: {
            Multiselect,
        },

        props: {
            value: {
                type: null,
                required: true,
                default: null,
            },

            label: {
                type: String,
                default: 'label',
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            searchable: {
                type: Boolean,
                required: false,
                default: false,
            },

            placeholder: {
                type: String,
                default: 'Choose',
            },

            optionSelect: {
                type: Array,
                default: null,
            },

            allowEmpty: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            state: {
                get() {
                    return this.value;
                },

                set(value) {
                    this.$emit('input', value);
                },
            },
        },
    };
</script>

<style scoped lang="scss">
    .app-select{
        &::v-deep{
            .multiselect{
                width: 23.5rem;
                min-height: to_rem(35px);

                cursor: pointer;

                &__tags{
                    display: flex;
                    align-items: center;

                    min-height: to_rem(35px);
                    padding: 0 0 0 0.5rem;

                    font-size: to_rem(14px);
                    color: #fff;

                    background: $dark;
                    border: none;
                }

                &__placeholder{
                    margin: 0;
                    padding: 0;

                    color: #fff;
                }

                &__select{
                    width: 1rem;
                    height: to_rem(35px);
                    margin-right: 0.5rem;
                    padding: 0;

                    background: url("~@/assets/img/icons/disposer/bottom-arrow_icon.svg") no-repeat center center/contain;

                    &:before{
                        display: none;
                    }
                }

                &__input, &__single{
                    display: flex;
                    align-items: center;

                    margin: 0;
                    padding: 0;

                    color: white;

                    background: transparent;

                    &::placeholder{
                        color: white;
                    }
                }
                &__option{
                    display: flex;
                    align-items: center;

                    min-height: to_rem(35px);
                    padding: 0 0 0 0.5rem;
                }

                &__option--highlight{
                    background: $dark;

                    &:after{
                        display: none;
                    }
                }

                &__option--selected{
                    color: white;

                    background: $gray;

                    &:after{
                        display: none;
                    }
                }
            }
        }

        &--white {
            &::v-deep{
                .multiselect{
                    width: 100%;
                    max-width: 16rem;
                    min-height: to_rem(24px);

                    cursor: pointer;

                    &__tags{
                        min-height: to_rem(24px);
                        padding: 0 0 0 0.5rem;

                        font-size: to_rem(14px);
                        color: #000;

                        background: $mainTextColor;
                        border: 1px solid #000;
                    }

                    &__placeholder{
                        color: #000;
                    }

                    &__select{
                        height: 100%;

                        background: url("~@/assets/img/icons/arrow-down-black.png") no-repeat center center/contain;

                        &:before{
                            display: none;
                        }
                    }

                    &__input, &__single{
                        color: #000;

                        background: transparent;

                        &::placeholder{
                            color: #000;
                        }
                    }
                    &__option{
                        min-height: to_rem(24px);
                        padding: 0 0 0 0.5rem;
                    }
                }
            }
        }
    }
</style>
