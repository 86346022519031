import { get } from '@/utils/http-helper';

const GET_PARTICIPANTS = '/participant/get';

export default {
  state: {
    participants: null,
  },
  actions: {
    async getParticipants({ commit }, data) {
      const participants = await get(GET_PARTICIPANTS, { ...data });
      if (participants.status) {
        commit('updateParticipants', participants.data);
      }
    },
  },
  mutations: {
    updateParticipants(state, participants) {
      state.participants = participants;
    },
  },
  getters: {
    getParticipants: state => state.participants,
  },
};
