let pageOffset = 0;
const app = document.body;

export const disableScroll = () => {
    let offset = window.pageYOffset;
    document.documentElement.style.overflow = 'hidden';
    pageOffset = offset;
    app.style.cssText = 'position: fixed;top: -' + offset + 'px';
};

export const enableScroll = () => {
    document.documentElement.style.overflow = 'initial';
    app.style.cssText = '';
    window.scrollTo(0, pageOffset);
};
