<template>
    <div class="profile">
        <!-- <div class="page-title">
            {{ $t('profile', 'My Profile') }}
        </div>
        <div class="app-topic-title"></div> -->
        <div
            v-if="profile"
            class="content"
        >
            <div class="main">
                <app-topic
                    v-if="user.message"
                    v-html="user.message"
                />
                <app-topic v-if="profile.activity">
                    <div class="app-topic__description">
                        {{ $t('profile_time', 'Your active participation time') }}:
                    </div>
                    <div class="app-topic__time">
                        {{ profile.activity.time }}
                    </div>
                </app-topic>
                <div class="messages">
                    <p class="title">
                        <b>
                            {{ $t('profile_messages', 'Messages') }}:
                        </b>
                    </p>
                    <div class="row">
                        <p>
                            <b>{{ $t('profile_msg_show', 'Show') }}</b>
                            <select
                                v-model="messagesPage.size"
                                class="app-input"
                                @change="getChats"
                            >
                                <option value="10">
                                    10
                                </option>
                                <option value="25">
                                    25
                                </option>
                                <option value="50">
                                    50
                                </option>
                                <option value="100">
                                    100
                                </option>
                            </select>
                            <b>{{ $t('profile_msg_entries', 'entries') }}</b>
                        </p>
                        <label>
                            <b>
                                {{ $t('profile_msg_search', 'Search') }}:
                            </b>
                            <input
                                v-model="searchString"
                                type="text"
                                class="app-input"
                                @input="searchChats"
                            />
                        </label>
                    </div>
                    <div class="row btns">
                        <div
                            class="btn"
                            @click="deleteMessages"
                        >
                            <svg
                                width="10"
                                height="12"
                                viewBox="0 0 10 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.714286 10.6667C0.714286 11.4 1.35714 12 2.14286 12H7.85714C8.64286 12 9.28571 11.4 9.28571 10.6667V2.66667H0.714286V10.6667ZM10 0.666667H7.5L6.78571 0H3.21429L2.5 0.666667H0V2H10V0.666667Z"
                                    fill="#7D7D7D"
                                />
                            </svg>
                            {{ $t('profile_msg_delete', 'Delete') }}
                        </div>
                        <div
                            class="btn"
                            @click="unreadMessages"
                        >
                            <svg
                                width="15"
                                height="12"
                                viewBox="0 0 15 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.5 0H1.5C0.675 0 0.00749999 0.675 0.00749999 1.5L0 10.5C0 11.325 0.675 12 1.5 12H13.5C14.325 12 15 11.325 15 10.5V1.5C15 0.675 14.325 0 13.5 0ZM13.5 3L7.5 6.75L1.5 3V1.5L7.5 5.25L13.5 1.5V3Z"
                                    fill="#7D7D7D"
                                />
                            </svg>
                            {{ $t('profile_msg_unread', 'Unread') }}
                        </div>
                    </div>
                    <div
                        v-if="profile"
                        class="messages__table"
                    >
                        <table>
                            <tr>
                                <th class="checkBox"></th>
                                <th>
                                    {{ $t('profile_from', 'Contact') }}
                                    <i
                                        class="top"
                                        :class="{active: activeSort===1}"
                                        @click="sortChats('name', 0, 1)"
                                    ></i>
                                    <i
                                        class="bottom"
                                        :class="{active: activeSort===2}"
                                        @click="sortChats('name', 1, 2)"
                                    ></i>
                                </th>
                                <th>
                                    {{ $t('profile_date', 'Date') }}
                                    <i
                                        class="top"
                                        :class="{active: activeSort===3}"
                                        @click="sortChats('date', 0, 3)"
                                    ></i>
                                    <i
                                        class="bottom"
                                        :class="{active: activeSort===4}"
                                        @click="sortChats('date', 1, 4)"
                                    ></i>
                                </th>
                                <th>
                                    {{ $t('profile_message', 'Message') }}
                                    <i
                                        class="top"
                                        :class="{active: activeSort===5}"
                                        @click="sortChats('text', 0, 5)"
                                    ></i>
                                    <i
                                        class="bottom"
                                        :class="{active: activeSort===6}"
                                        @click="sortChats('text', 1, 6)"
                                    ></i>
                                </th>
                            </tr>
                            <template v-if="profile.chats && profile.chats.chats.length">
                                <tr
                                    v-for="tr of profile.chats.chats"
                                    :key="tr.id"
                                    :class="{ new: tr.status == 0 }"
                                >
                                    <td class="checkBox">
                                        <label class="disposer-app-checkbox">
                                            <input
                                                type="checkbox"
                                                @change="checkDialog($event, tr)"
                                            />
                                            <div class="box"></div>
                                        </label>
                                    </td>
                                    <td @click="openPopup(tr)">
                                        {{ tr.name }}
                                    </td>
                                    <td
                                        class="date"
                                        @click="openPopup(tr)"
                                    >
                                        {{ tr.date }}
                                    </td>
                                    <td @click="openPopup(tr)">
                                        {{ tr.text }}
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr class="empty">
                                    <td colspan="4">
                                        {{ $t('profile_msg_empty', 'No messages') }}
                                    </td>
                                </tr>
                            </template>
                        </table>
                        <p
                            v-if="profile.chats && profile.chats.chats.length"
                            class="title"
                        >
                            {{ $t('profile_msg_showing', 'Showing') }}
                            {{ $t('from', 'from') }}
                            {{ showingFrom }}
                            {{ $t('to', 'to') }}
                            {{ showingTo }}
                            {{ $t('profile_msg_of', 'of') }}
                            {{ messagesPage.total }}
                            {{ $t('profile_msg_entries_s', 'entries') }}
                        </p>
                        <Paginate
                            v-if="profile.chats"
                            v-model="page"
                            :page-count="messagesPage.total_page"
                            :page-range="1"
                            :prev-text="$t('profile_msg_prev', 'Previos')"
                            :next-text="$t('profile_msg_next', 'Previos')"
                            :container-class="'pagination'"
                            :prev-class="'prev-btn'"
                            :next-class="'next-btn'"
                            :active-class="'active'"
                            :click-handler="changePage"
                        />
                    </div>
                </div>
            </div>
            <div>
                <div
                    v-if="profile.notification"
                    class="sidebar"
                >
                    <div class="title">
                        <BellIcon />
                        <p>
                            <b>{{ $t('profile_not_settings', 'Notifications Settings') }}:</b>
                        </p>
                    </div>
                    <label>
                        <input
                            v-model="updateMethod"
                            :value="2"
                            type="radio"
                            name="updateMethod"
                        />
                        <div class="box"></div>
                        <b>{{ $t('profile_sms', 'Update by sms') }}</b>
                    </label>
                    <label>
                        <input
                            v-model="updateMethod"
                            :value="1"
                            type="radio"
                            name="updateMethod"
                        />
                        <div class="box"></div>
                        <b>{{ $t('profile_email', 'Update by email') }}</b>
                    </label>
                    <template v-if="updateMethod===2">
                        <label class="column">
                            <p>{{ $t('profile_phone_code', 'Phone Code') }}</p>
                            <input
                                v-model="phoneCode"
                                name="phone_code"
                                class="form-control app-input"
                                type="text"
                            />
                        </label>
                        <label class="column">
                            <p>{{ $t('profile_phone', 'Phone number') }}</p>
                            <input
                                v-model="phoneNumber"
                                v-mask="'####################'"
                                type="text"
                                class="app-input"
                            />
                        </label>
                    </template>
                    <template v-if="updateMethod===1">
                        <label class="column">
                            <p>{{ $t('profile_mail', 'Mail address') }}</p>
                            <input
                                type="text"
                                class="app-input"
                                disabled
                                :value="profile.notification.email"
                            />
                        </label>
                    </template>
                    <app-button
                        class="narrow accent"
                        @click="updateNotification"
                    >
                        {{ $t('update', 'UPDATE') }}
                    </app-button>
                </div>
                <div
                    v-if="profile.report"
                    class="row"
                >
                    <app-button
                        v-if="user && user.role.id===5 && !reportLink"
                        class="purple print-report"
                        @click="printReport"
                    >
                        {{ $t('profile_report_dl', 'Print report') }}
                    </app-button>
                    <app-button
                        v-else-if="user && user.role.id===5"
                        class="purple print-report"
                        tag="a"
                        :href="reportLink"
                        target="_blank"
                    >
                        {{ $t('open_final_report', 'Open report') }}
                    </app-button>
                </div>
            </div>
        </div>
        <appPopup>
            <correspondence-popup
                v-if="chat.id"
                ref="correspondencePopup"
                :chat="chat"
                :socket="socket"
            />
        </appPopup>
    </div>
</template>

<script>
import BellIcon from '@/assets/img/icons/bell_icon.svg?inline';

import appButton from '@/components/user/app/app-button';
import appTopic from '@/components/user/app/app-topic';
import appPopup from '@/components/user/app-popup';
import correspondencePopup from '@/components/user/pages/profile/correspondence-popup';
import Paginate from 'vuejs-paginate';

const UPDATE_NOTIFICATION = '/my-profile/update-notification';
const NAME_SPACE = 'message';
const MESSAGE_CREATE = 'message-create';
const MESSAGE_DELETE = 'dialog-delete';
const MESSAGE_UNREAD = 'dialog-unread';
const GET_EVENT_REPORT = '/reports/get-event';

import { messageSocket } from '@/utils/user/message-socket.js';
import { post, get, generateFileUrl } from '@/utils/http-helper';
import { GP } from '@/utils/sockets-helper';

const generateSocketPath = new GP(NAME_SPACE);

export default {
    name: 'profile',

    components: {
        BellIcon,

        appTopic,
        Paginate,
        appButton,
        appPopup,
        correspondencePopup,
    },

    data: () => ({
        socket: null,
        updateMethod: 1,
        phoneCode: 0,
        phoneNumber: '',
        page: 1,
        searchString: '',
        messagesPage: {
            size: 10,
            total: 1,
            total_page: 1,
            current_page: 1,
        },
        activeSort: null,
        reportLink: '',
        checkedMessages: [],
    }),

    computed: {
        user() {
            return this.$store.getters['auth/userInfo'];
        },
        profile() {
            return this.$store.getters.getProfile;
        },
        chat() {
            return this.$store.getters.getChat;
        },
        showingFrom() {
            return (this.page - 1) * this.messagesPage?.size + 1;
        },
        showingTo() {
            let showingTo = +this.showingFrom + +this.messagesPage?.size - 1;
            if (this.messagesPage.total <= showingTo) {
                showingTo = this.messagesPage.total;
            }
            return showingTo;
        },
    },

    async created() {
        await this.$store.dispatch('getProfile');
        this.initialProfileInfo();
        this.initialSockets();
    },

    methods: {
        initialProfileInfo() {
            this.updateMethod = this.profile.notification?.method;
            this.phoneCode = this.profile.notification?.phone_code;
            this.phoneNumber = this.profile.notification?.phone;
            this.updateChats();
        },
        initialSockets() {
            this.socket = messageSocket;
            this.socket.on(generateSocketPath.generate(MESSAGE_CREATE), () => {
                this.$nextTick(() => {
                    this.$refs.correspondencePopup ? this.$refs.correspondencePopup.scrollDown() : null;
                });
            });
        },
        async openPopup(chat) {
            await this.$store.dispatch('viewChat', chat);
            await this.$store.dispatch('togglePopup', { popupName: 'correspondencePopup' });
        },
        updateNotification() {
            const obj = {
                method: this.updateMethod,
                phone_code: this.phoneCode,
                phone: this.phoneNumber,
            };
            if (this.profile.notification) {
                this.$awn.async(post(UPDATE_NOTIFICATION, obj), 'Success');
            }
        },
        changePage(page) {
            this.page = page;
            this.getChats();
        },
        async sortChats(name, order, activeSort) {
            await this.$store.dispatch('GET_CHATS', { name, order });
            this.activeSort = activeSort;
            this.searchString = '';
        },
        async getChats() {
            await this.$store.dispatch('GET_CHATS', {
                size: this.messagesPage.size,
                page: this.page,
            });
            this.updateChats();
        },
        async searchChats() {
            clearTimeout(window.searchChactT);
            window.searchChactT = setTimeout(async () => {
                await this.$store.dispatch('GET_CHATS', { search: this.searchString });
                this.updateChats();
            }, 300);
        },
        updateChats() {
            this.messagesPage = {
                size: this.profile?.chats?.size,
                total: this.profile?.chats?.total,
                total_page: this.profile?.chats?.total_page,
                current_page: this.profile?.chats?.current_page,
            };
        },
        checkDialog(e, dialog) {
            const status = e.target.checked;
            if (status) {
                this.checkedMessages.push(+dialog.id);
            } else {
                this.checkedMessages = this.checkedMessages.filter(d => +d !== +dialog.id);
            }
        },
        deleteMessages() {
            this.socket.emit(generateSocketPath.generate(MESSAGE_DELETE), { dialogs: this.checkedMessages }, data => {
                if (data.success) {
                    this.$store.dispatch('deleteChats', this.checkedMessages);
                    this.checkedMessages = [];
                    this.$store.dispatch('auth/getUserInfo');
                }
            });
        },
        unreadMessages() {
            this.socket.emit(generateSocketPath.generate(MESSAGE_UNREAD), { dialogs: this.checkedMessages }, data => {
                if (data.success) {
                    this.$store.dispatch('unreadChats', this.checkedMessages);
                    this.$store.dispatch('auth/changeUnreadCount', this.checkedMessages.length);
                    this.checkedMessages = [];
                }
                document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
                    checkbox.checked = false;
                });
            });
        },

        printReport() {
            this.$awn.async(get(GET_EVENT_REPORT, {
                event_id: this.user?.event.id,
            }), (response) => {
                this.reportLink = generateFileUrl(response.data.file);
                this.$awn.success('Success');
            });
        },
    },
};
</script>

<style scoped lang="scss">
.app-topic-title {
    margin-bottom: 1rem;
}

.app-topic {
    margin-bottom: .9rem;

    .app-progressBar {
        width: 100%;
    }
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.main {
    width: 100%;
}

.messages {
    padding: 1.35rem 1.7rem;

    font-size: to_rem(14px);

    background: white;
    border: 1px solid $gray;

    .row {
        display: flex;
        justify-content: space-between;

        margin-top: 1.5rem;

        &.btns {
            justify-content: flex-start;

            .btn {
                display: flex;
                align-items: center;

                margin-right: 1rem;
                padding: 0.4rem 1.2rem;

                color: $gray;

                border: 1px solid $gray;
                border-radius: 0.25rem;

                cursor: pointer;
                transition: .2s;

                svg {
                    margin-right: 0.4rem;

                    path {
                        transition: .2s;
                    }
                }

                &:hover {
                    color: #fff;

                    background-color: var(--platform-accent-color);
                    border-color: var(--platform-accent-color);

                    svg path {
                        fill: #fff;
                    }
                }
            }
        }
    }

    select {
        width: 3.9rem;
        height: 1.8rem;
        margin: 0 0.4rem;
        padding: 0 0.5rem;

        background: none;
        border: 1px solid $gray;
        border-radius: 0.3rem;
    }

    input {
        width: 12rem;
        margin-left: 0.3rem;
    }

    &__table {
        table {
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 0.5rem;

            border-collapse: collapse;
            border-spacing: 0;

            color: $copyGray;
        }

        td, th {
            border: 1px solid $lightGray;
        }

        th {
            position: relative;

            height: 2.3rem;

            color: var(--platform-accent-color);

            background: $mainBackgroundColor;

            &:nth-of-type(1) {
                width: 30.8%;
            }

            &:nth-of-type(2) {
                width: 29.6%;
            }

            &.checkBox {
                width: 1.5rem;
            }
        }

        tr {
            cursor: pointer;

            &.new {
                background: $lightYellow;
            }

            &.empty {
                background: transparent;
            }
        }

        td {
            padding: 0.7rem;

            line-height: 1.4;
            text-align: center;
            vertical-align: top;

            &.date {
                padding: 0.7rem 2rem;
            }

            &.checkBox {
                input {
                    display: none;
                }

                .box {
                    margin-right: 0;
                }
            }
        }

        i {
            position: absolute;
            top: 1.1rem;
            right: 0.3rem;

            width: 0.5rem;
            height: 0.7rem;

            background: url("~@/assets/img/icons/sort-both_icon.png") no-repeat center center/contain;

            &.active {
                filter: brightness(0.2);
            }

            &.top {
                top: 0.6rem;
                right: 0.31rem;

                transform: rotate(180deg);
            }
        }

        .pagination {
            display: flex;

            width: fit-content;
            margin-left: auto;

            list-style: none;

            color: $copyGray;

            &::v-deep {
                .prev-btn {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                .next-btn {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }

                .prev-btn, .next-btn {
                    display: block;

                    width: auto;
                    padding: 0.5rem 1rem;
                }

                li {
                    display: flex;
                    justify-content: center;

                    width: to_rem(34px);
                    margin: 0 0.1rem;
                    padding: 0.5rem;

                    border: 1px solid $lightGray;

                    &.active {
                        color: $mainTextColor;

                        background: var(--platform-accent-color);
                        border: 1px solid var(--platform-accent-color);
                    }
                }
            }
        }
    }
}

.sidebar {
    width: 25.7rem;
    min-width: 25.7rem;
    margin-left: 1rem;
    padding: 2.4em 1.2rem;

    font-size: to_rem(14px);

    background: white;
    border: 1px solid #a8a9ab;

    .title {
        display: flex;
        align-items: center;

        margin-bottom: 1.15rem;

        svg {
            width: 1.7rem;
            margin-right: 0.4rem;

            path {
                fill: var(--platform-accent-color);
            }
        }
    }

    label {
        display: flex;
        align-items: center;

        margin: 0 0 0.8rem 0.5rem;

        color: $copyGray;

        cursor: pointer;

        &.column {
            flex-direction: column;
            align-items: flex-start;

            margin-bottom: 1.1rem;
            padding: 0 1.3rem 0 0.8rem;

            p {
                margin-bottom: 0.5rem;
            }
        }

        select {
            width: 100%;

            font-size: to_rem(14px);
            color: $copyGray
        }

        input[type='radio'] {
            display: none;

            margin-top: 0;

            & + .box {
                display: flex;
                justify-content: center;
                align-items: center;

                box-sizing: content-box;

                width: 11px;
                height: 11px;
                margin: 0 0.2rem 0 0;

                border: 1px solid $gray;
                border-radius: 50%;

                &:after {
                    content: '';

                    width: 6px;
                    height: 6px;

                    background: black;
                    border-radius: 50%;

                    opacity: 0;
                    transition: opacity 0.3s;
                }
            }

            &:checked + .box {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    .app-button {
        margin-right: .7rem;
        margin-left: auto;
    }
}


.row {
    display: flex;
    align-items: center;

    margin-top: 1rem;

    .print-report {
        width: fit-content;
        margin-left: 1rem;
    }

    a {
        width: 2rem;
        margin-left: 2rem;
    }
}

@include mobile_or_P {
    .content {
        padding: .5rem 0.6rem;

        .app-topic-title {
            display: none;
        }
    }
    .messages {
        padding: 1rem 0;

        .title, .row, .pagination {
            padding: 0 .5rem;
        }
    }
    .sidebar {
        min-width: 17rem;
        margin-left: 0.6rem;
        padding: 2.4em 0.7rem;

        label {
            &.column {
                padding-right: 0;
            }
        }

        .app-button {
            margin-right: 0;
        }
    }
}

@include razr767 {
    .content {
        flex-direction: column;

        padding: 0 0.9rem;

        .app-topic-title {
            display: block;
        }

        select {
            height: 2.5rem;
        }
    }
    .messages {
        padding: 1.6rem .7rem;

        font-size: 12px;

        .title, .row, .pagination {
            padding: 0;
        }

        .row {
            flex-direction: column;

            margin-top: 1.7rem;

            &.btns {
                flex-direction: row;
            }

            select {
                width: 5.3rem;
                margin: 0 0.7rem;
            }

            label {
                margin-top: 2.2rem;
            }

            input {
                margin-left: 1rem;
            }
        }

        &__table {
            table {
                margin-top: 2rem;
            }

            th {
                height: 3.5rem;

                &:nth-of-type(1) {
                    width: 2rem;
                }

                &:nth-of-type(3) {
                    width: 7rem;
                }
            }

            tr {
                .disposer-app-checkbox {
                    justify-content: center;
                }
            }

            i {
                top: 1.7rem;

                &.top {
                    top: 1.1rem;
                }
            }

            td {
                padding: 1rem 0;

                &.date {
                    padding: 0.7rem 0.4rem;
                }
            }
        }

        .title {
            text-align: center;
        }

        .pagination {
            margin: .7rem auto;

            &::v-deep {
                .prev-btn, .next-btn, li {
                    align-items: center;

                    padding: 0.6rem;
                }

                li {
                    width: 2.7rem;
                }
            }
        }
    }
    .sidebar {
        margin-top: .9rem;
        margin-left: 0;
        padding: 2.4em 0.5rem;

        .title {
            margin-bottom: 1.6rem;

            font-size: 14px;

            svg {
                width: 2.2rem;
                margin-right: .6rem;
            }
        }

        label {
            margin: 0 0 1.1rem 0.5rem;

            font-size: 14px;

            input[type=radio] {
                & + .box {
                    width: 13px;
                    height: 13px;
                }
            }

            &.column {
                margin: 0 0 1.6rem;
                padding: 0 .6rem 0 1.6rem;
            }

            select {
                font-size: 14px;
            }
        }

        .app-button {
            margin-top: 2.7rem;
            margin-right: 0.7rem;
        }
    }
}
</style>
