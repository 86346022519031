<template>
    <div class="app-dates-row">
        <div>
            <slot name="startDate"></slot>
            <span
                v-if="startDateError"
                class="app-input-error"
            >
                {{ startDateError[0] }}
            </span>
        </div>
        <div>
            <slot name="endDate"></slot>
            <span
                v-if="endDateError"
                class="app-input-error"
            >
                {{ endDateError[0] }}
            </span>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'app-dates-row',
    props: {
      startDateError: {
        type: Array,
        default: null,
      },
      endDateError: {
        type: Array,
        default: null,
      },
    },
  };
</script>

<style scoped lang="scss">
.app-dates-row {
    display: flex;
    justify-content: space-between;

    width: to_rem(800px);
    padding-bottom: 20px;

    &>div{
        position: relative;

        display: flex;
        justify-content: space-between;

        width: 45.5%;
    }
    .app-date-picker {
        width: 150px;
    }

    @include razr1280 {
        &>div {
            width: 49%;
        }
    }
}
</style>
