<template>
    <div class="watermark-text">
        <div class="watermark-text__top">
            {{ watermarkParams.text[0] }}
        </div>
        <div class="watermark-text__user">
            {{ watermarkParams.text[1] }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'app-watermark',

    props: {
        watermarkParams: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style scoped lang="scss">
    .watermark-text {
        position: sticky;
        top: 50%;
        left: 50%;

        width: 100%;
        height: 0;

        font-family: Verdana;
        text-align: center;
        color: transparent;
        -webkit-text-stroke: 1px gray;

        transform: translateY(-60px);
        opacity: 0.5;

        pointer-events: none;

        &__top {
            font-size: 60px;
        }

        &__user {
            font-size: 50px;
        }
    }
</style>
