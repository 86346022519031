<template>
    <div class="participants">
        <!-- <div class="page-title">
            {{ $t('participants', 'List of participants') }}
        </div> -->
        <div
            v-if="participants"
            class="participants-list"
        >
            <form
                class="participants__section"
                @submit.prevent="searchParticipants"
            >
                <label class="flex justify-end">
                    <div class="participants__search">
                        <input
                            v-model="searchQuery"
                            type="text"
                            class="app-input"
                        />
                        <a
                            class="form-reset"
                            @click="resetParticipants"
                        >&times;</a>
                    </div>
                    &nbsp;&nbsp;
                    <app-button
                        tag="button"
                        type="submit"
                        class="accent"
                    >
                        {{ $t('profile_msg_search', 'Search') }}
                    </app-button>
                </label>
            </form>
            <div
                v-for="section of participants.sections"
                :key="section.id"
                class="participants__section"
            >
                <div class="app-topic-title" @click="section.collapsed = !section.collapsed">
                    {{ section.name }}
                    <span
                        class="icon-collapse"
                        :class="{'collapsed' : section.collapsed}"
                    ></span>
                </div>
                <template v-if="!section.collapsed">
                    <app-participants-information
                        v-for="(participant, index) of section.participants"
                        :key="index"
                        :participant="participant"
                    />
                </template>
            </div>

            <template v-if="participants.sections && !participants.sections.length">
                <div class="app-topic-title">
                    {{ $t('participants', 'Participants') }}
                </div>
                <app-participants-information
                    v-for="participant of participants.participants"
                    :id="`participant${participant.id}`"
                    :key="participant.id"
                    :participant="participant"
                />
            </template>
        </div>
    </div>
</template>

<script>
    import appParticipantsInformation from '@/components/user/app/app-participants-information';
    import appButton from '@/components/user/app/app-button';

    export default {
        name: 'participants',

        components: {
            appParticipantsInformation,
            appButton,
        },

        data(){
            return {
                searchQuery: '',
            };
        },
        computed: {
            participants() {
              return this.$store.getters.getParticipants;
            },
            eventId() {
              return this.$route.query.event_id;
            },
        },

        async created() {
            // !this.participants ? this.$store.dispatch('getParticipants', { event_id: this.eventId }) : null;
            await this.$store.dispatch('getParticipants', { event_id: this.eventId });
        },

        mounted() {
            this.scrollToParticipant();
        },

        methods: {
            async searchParticipants() {
                clearTimeout(window.searchPtsT);
                window.searchPtsT = setTimeout(async () => {
                    await this.$store.dispatch('getParticipants', { event_id: this.eventId, query: this.searchQuery});
                }, 300);
            },
            resetParticipants(){
                this.searchQuery = '';
                this.searchParticipants();
            },
            scrollToParticipant() {
                setTimeout(()=>{
                    const hashId = this.$route.hash;
                    if (hashId) {
                        const block = document.querySelector(`#participant${hashId.replace('#','')}`);
                        if (block) {
                            window.scrollTo({
                                top: block.getBoundingClientRect().top + pageYOffset,
                            });
                        }
                    }
                }, 200);
            },
        },
    };
</script>

<style scoped lang="scss">
    .flex{
        display: flex;
        align-items: center;
    }
    .justify-end{
        justify-content: flex-end;
    }
    .app-topic-title{
        justify-content: space-between;
        cursor: pointer;
    }
    .participants {
        &__section {
            margin-bottom: 2rem;
        }
        .app-topic-title {
            margin-bottom: 1rem;
        }
        &__search{
            position: relative;
            width: 100%;
            max-width: 420px;
            .app-input{
                padding-right: 30px;
            }
        }
    }
    .icon-collapse{
        position: relative;
        margin-left: 0;
        display: block;
        //width: 20px;
        //height: 10px;
        //background: url('../../../assets/img/icons/disposer/bottom-arrow_icon.svg') center center / contain no-repeat;
        transform: scale(1, -1);
        font-size: 150%;
        line-height: 0.5;
    }
    .icon-collapse:after{
        content: '−';
    }
    .icon-collapse.collapsed:after{
        //transform: scale(1, 1);
        content: '+'
    }
    .form-reset{
        text-decoration: none;
        font-size: 175%;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 50%;
        width: 30px;
        text-align: center;
        transform: translate(0, -50%);
    }
</style>
