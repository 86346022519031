<template>
    <div class="workshop-voting">
        <div
            v-if="surveys && surveys.length"
            class="workshop-voting__label"
            :class="{active: !allAnswered}"
            @click="toggleVotes"
        >
            <VotingIcon />
            <img
                class="gray"
                src="@/assets/img/icons/vote_icon__gray.svg"
            />
        </div>
        <div
            v-if="isOpen"
            class="workshop-voting__popup-wrapper"
        >
            <div class="workshop-voting__popup">
                <div class="head">
                    {{ $t('voting', 'Voting') }}

                    <app-language-select
                        v-if="showTranslating"
                        @change="translate"
                    />

                    <div
                        class="closer"
                        @click="toggleVotes"
                    >
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div class="votes">
                    <app-voting
                        v-for="survey in surveys"
                        :key="survey.id"
                        :type="survey.type"
                        :vote="survey"
                        @deleteSurvey="deleteSurvey"
                        @answer="answer"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VotingIcon from '@/assets/img/icons/vote_icon.svg?inline';

import appVoting from '@/components/user/app/app-voting';
import appLanguageSelect from '@/components/user/app/app-language-select';

import { disableScroll, enableScroll } from '@/assets/js/disableScroll';

export default {
    name: 'voting',

    components: {
        VotingIcon,

        appVoting,
        appLanguageSelect,
    },

    props: {
        surveys: {
            type: Array,
            default: () => ([]),
        },
        allAnswered: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isOpen: false,
    }),

    computed: {
        showTranslating() {
            return this.$store.getters['platform/platform'].settings.translating;
        },
    },

    methods: {
        toggleVotes() {
            this.isOpen = !this.isOpen;
            this.isOpen ? disableScroll() : enableScroll();
        },

        deleteSurvey(survey) {
            this.$emit('deleteSurvey', survey);
        },

        answer(answer) {
            this.$emit('answer', answer);
        },

        translate(lang) {
            this.$emit('translate', lang);
        },
    },
};
</script>

<style scoped lang="scss">
@keyframes bounce2 {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-8px);
    }
}

.workshop-voting {
    position: absolute;

    &__label {
        position: fixed;
        top: 25rem;
        left: 0;
        z-index: 100000;

        cursor: pointer;

        img {
            width: 3.5rem;
        }

        svg {
            display: none;

            width: 3.5rem;

            rect {
                fill: var(--platform-accent-color);
            }

            .cls-2 {
                fill: white;
            }
        }

        img.gray {
            display: block;
        }

        &.active {
            animation: bounce2 2s ease infinite;

            svg {
                display: block;
            }
            img.gray {
                display: none;
            }
        }
    }

    &__popup-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999999999;

        background: rgba(#000, 0.8);
    }

    &__popup {
        position: absolute;
        top: 50%;
        left: 50%;

        width: to_rem(1080px);

        background: white;

        transform: translate(-50%, -50%);

        .head {
            position: relative;

            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 1.2rem 5rem 1.2rem 3.3rem;

            font-size: to_rem(18px);
            font-weight: 700;
            color: white;

            background: var(--platform-main-color);


            .closer {
                position: absolute;
                top: 50%;
                right: 3rem;

                width: 1rem;
                height: 1rem;

                transform: translateY(-50%);
                cursor: pointer;

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    display: block;

                    width: 100%;
                    height: 2px;

                    background: #fff;
                    border-radius: 1px;

                    transform: translate(-50%, -50%) rotate(45deg);

                    &:nth-of-type(2) {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
            }
        }

        .votes {
            max-height: to_rem(760px);
            padding: 1.8rem;
            overflow: auto;

            .app-voting {
                margin-bottom: 1.7rem;
            }
        }
    }

    @media screen and (max-width: 1500px) {
        &__popup {
            width: 90%;

            .votes {
                height: to_rem(550px);
                max-height: 55vh;
            }
        }
    }
    @media screen and (max-width: 1023px) {
        &__label {
            top: 35rem;
            z-index: 20000000;
        }
        &__popup {
            width: 90%;

            .head {
                padding: 1.2rem 3rem 1.2rem 1.2rem;

                .closer {
                    right: 1.2rem;
                }
            }

            .votes {
                max-height: 36rem;
            }
        }
    }
    @media screen and (max-width: 767px) {
        &__popup {
            top: 50%;

            .votes {
                max-height: 50vh;
                padding: 1rem;
            }
        }
    }
}
</style>
