const notificationsTitle = [
    '{EventName} &mdash; Name of event',
    '{Title} &mdash; Title of participant',
    '{LastName} &mdash; Last Name of participant',
    '{FirstName} &mdash; First Name of participant',
    '{CompanyName} &mdash; Company name (equivalent to name of client)',
    '{LinkPlatform} &mdash; Link to platform',
    '{TopicName} &mdash; Name of topic',
    '{StartDate} &mdash; Start date of topic',
    '{StartTime} &mdash; Start time of topic',
    '{EndDate} &mdash; End time of topic',
    '{EndTime} &mdash; End time of topic',
];

export default notificationsTitle;
