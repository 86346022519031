<template>
    <div class="add-vote-popup">
        <div class="add-vote-popup__title">
            {{ $t('add_survey', 'Add survey') }}
        </div>
        <label class="question">
            <p>
                {{ $t('question', 'Question') }}
            </p>
            <input
                v-model="survey.question"
                class="app-input"
                type="text"
                title="Maximum 1000 characters"
                :class="{error: $v.survey.question.$dirty && $v.survey.question.$invalid}"
            />
        </label>
        <p>
            {{ $t('options', 'Options') }}
        </p>
        <div class="answers">
            <div
                v-for="option in $v.survey.options.$each.$iter"
                :key="option.id"
                class="answer"
            >
                <input
                    v-model="option.name.$model"
                    class="app-input"
                    type="text"
                    :class="{error: $v.survey.question.$dirty && !option.name.required}"
                />
                <i
                    class="fa fa-trash-o"
                    @click="deleteAnswer(option.$model.id)"
                ></i>
            </div>
            <i
                class="fa fa-plus"
                @click="addAnswer"
            ></i>
        </div>
        <label
            v-if="survey.isMultiple !== undefined"
            class="app-checkbox"
        >
            <input
                v-model="survey.isMultiple"
                type="checkbox"
                name="updateMethod"
            />
            <div class="box"></div>
            {{ $t('one_answer', 'One answer option') }}
        </label>
        <app-button
            class="accent"
            @click="saveSurvey"
        >
            OK
        </app-button>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';

import { GP } from '@/utils/sockets-helper';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
    name: 'add-vote-popup',
    components: {
        appButton,
    },
    props: {
        socket: {
            type: Object,
            default: null,
        },
        paths: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        survey: {
            id: null,
            question: '',
            options: [
                {
                    id: Math.random(),
                    name: '',
                },
            ],
            isMultiple: false,
        },
    }),

    validations: {
        survey: {
            question: {
                required,
                maxLength: maxLength(1000),
            },
            options: {
                required,
                $each: {
                    name: {
                        required,
                    },
                },
            },
        },
    },

    computed: {
        options() {
            return this.$store.getters.popupOptions;
        },
    },

    mounted() {
        this.options ? this.survey = this.options.survey : null;
        this.generateSocketPath = new GP(this.paths.NAME_SPACE);
    },

    methods: {
        addAnswer() {
            this.survey.options.push({
                id: Math.random(),
                name: '',
            });
        },
        deleteAnswer(id) {
            if (this.survey.options.length === 1) {
                this.survey.options[0].name = '';
            } else {
                this.survey.options = this.survey.options.filter((a) => a.id !== id);
            }
        },
        saveSurvey() {
            if (this.$v.survey.$invalid) {
                this.$v.survey.$touch();
                return;
            }

            let type = 2;
            if (this.survey.isMultiple) {
                type = 1;
            }
            if (!this.survey.id) {
                this.socket.emit(this.generateSocketPath.generate(this.paths.CREATE_SURVEY), {
                    survey_id: this.survey.id,
                    name: this.survey.question,
                    type: type,
                    options: this.survey.options.map(o => o.name),
                }, data => {
                    data.answers = {};
                    data.is_answered = 0;
                    this.$emit('addSurvey', data);
                });
            } else {
                this.socket.emit(this.generateSocketPath.generate(this.paths.UPDATE_SURVEY), {
                    survey_id: this.survey.id,
                    name: this.survey.question,
                    options: this.survey.options,
                }, data => {
                    this.$emit('editedSurvey', data, this.survey.id);
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.add-vote-popup {
    &__title {
        font-size: 1.125rem;
        color: var(--platform-accent-color);
    }

    .question {
        margin: 1rem 0;
    }

    label {
        p {
            margin-bottom: 1rem;
        }

        &:not(.app-checkbox) {
            display: block;
        }
    }

    .app-checkbox, .app-button {
        margin-top: 1rem;
    }

    .app-input {
        &.error {
            border-color: $red;
        }
    }

    input {
        margin: 3px;
    }

    .answers {
        margin-top: 1rem;

        .answer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin: 1rem 0;

            input {
                width: 70%;
            }
        }

        i {
            cursor: pointer;
        }
    }
}
</style>
