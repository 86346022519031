import Vue from 'vue';
import { get, post } from '@/utils/http-helper';

const GET_DASHBOARD = '/dashboard/get';
const GET_DASHBOARD_AGENDA = '/dashboard/get-agenda';
const TRANSLATE = '/dashboard/translate';
const GET_STAT = '/dashboard/get-stat';


const findComment = (arr, id, questionId) => {
    let comment;

    let container = arr.comments;

    if (questionId) {
        container = arr.questions.find((q) => {
            return +q.id === +questionId;
        }).comments;
    }

    container = container.reduce((total, c) => {
        return [...total, c, ...c.comments];
    }, []);

    comment = container.find((c) => {
        return +c.id === +id;
    });

    return comment;
};

const editDashboard = (state, { rowId, colId, questionId }, changeItem) => {
    const questions = state?.dashboard?.questions;
    const surveys = questionId && questions?.length ? questions.find(q => q.id === questionId)?.surveys : state?.dashboard?.surveys;
    if (surveys?.length) {
        surveys.forEach(tables => {
            if (!tables.columns) return;
            tables.columns.forEach(items => {
                if (items.id === colId) {
                    items.answers.forEach(item => {
                        if (item.row_id === rowId) {
                            changeItem(item);
                        }
                    });
                }
            });
        });
    }
};

export default {
    namespaced: true,

    state: {
        dashboard: null,
        agenda: null,
        lang: null,
    },

    actions: {
        async get({ state, commit, dispatch }, params) {
            const dashboard = await get(GET_DASHBOARD, { id: params.id, event_id: params.eventId });
            if (dashboard?.data) {
                commit('updateDashboard', dashboard.data);
            }
            if (dashboard?.data?.user_settings?.lang) {
                dispatch('translateTopic', {
                    topic_id: state.dashboard.id,
                    lang: dashboard.data.user_settings.lang,
                });
            }
        },
        async getAgenda({ commit }, eventId) {
            const agenda = await get(GET_DASHBOARD_AGENDA, { event_id: eventId });
            if (agenda?.data) {
                commit('updateAgenda', agenda.data);
            }
        },
        changeLikesCount({ commit }, id) {
            commit('changeLikesCount', id);
        },
        createComment({ commit, rootState }, data) {
            commit('createComment', data);
            if (data.question_id && rootState.auth.user.id !== data.user_id) {
                commit('readQuestion', { id: data.question_id, status: true });
            }
        },
        editComment({ commit, rootState }, data) {
            commit('editComment', data);
            if (data.question_id && rootState.auth.user.id !== data.user_id) {
                commit('readQuestion', { id: data.question_id, status: true });
            }
        },
        toggleCommentState({ commit }, data) {
            commit('toggleCommentState', data);
        },
        answerSurvey({ commit }, data) {
            commit('answerSurvey', data);
        },
        readQuestion({ commit }, data) {
            commit('readQuestion', data);
        },
        async translateTopic({ commit }, data) {
            const translations = await post(TRANSLATE, data);
            commit('translate', translations.data);
        },

        async getStat({ commit }, props) {
            const response = await get(GET_STAT, props);
            if (response.message !== 'No active topics') {
                commit('updStat', response.data);
            }
        },
    },

    mutations: {
        updateLang(state, lang) {
            state.lang = lang;
        },
        updateDashboard(state, dashboard) {
            if (dashboard.comments) {
                dashboard.comments.forEach((c) => c.showComments = false);
            } else if (dashboard.questions) {
                dashboard.questions.forEach((q) => {
                    q.comments.forEach((c) => {
                        c.showComments = false;
                    });
                    q.comments = q.comments.reverse();
                });
            }
            if (dashboard.surveys) {
                dashboard.surveys.forEach((s) => {
                    s.options?.forEach((o) => {
                        o.inputValue = null;
                    });
                });
            }
            Vue.set(state, 'dashboard', dashboard);
        },
        createComment(state, data) {
            const comment = {
                id: data.id,
                avatar: data.avatar,
                date: data.created_at,
                content: data.content,
                name: data.author,
                comments: [],
                count_likes: 0,
                liked: 0,
                count_comments: 0,
                deleted: 0,
                extra: data.extra,
                type: data.type,
                user_id: data.user_id,
                showComments: false,
            };

            let container = state.dashboard;

            if (data.question_id) {
                container = state.dashboard.questions.find((q) => {
                    return +q.id === +data.question_id;
                });
            }

            if (data.parent_id) {
                container = container.comments.find((c) => {
                    return +c.id === +data.parent_id;
                });
            }

            container.count_comments++;
            container.showComments = true;
            !data.parent_id ? container.comments.unshift(comment) : container.comments.push(comment);
        },
        editComment(state, data) {
            const comment = findComment(state.dashboard, data.comment_id, data.question_id);
            comment.content = data.content;
        },
        changeLikesCount(state, { id, up, self = false, questionId }) {
            const comment = findComment(state.dashboard, id, questionId);

            if (up) {
                comment.count_likes++;
                self ? comment.liked = 1 : null;
            } else {
                comment.count_likes--;
                self ? comment.liked = 0 : null;
            }
        },
        toggleCommentState(state, data) {
            const comment = findComment(state.dashboard, data.comment_id, data.question_id);
            comment.deleted = data.deleted;
            comment.content = data.content;
        },
        readQuestion(state, { id, status }) {
            const question = state.dashboard.questions.find(q => +q.id === +id);
            question.is_unread = status;
        },
        answerSurvey(state, data) {
            const dashboard = { ...state.dashboard };

            let survey = dashboard?.surveys?.find(s => +s.id === +data.survey_id);

            if (!survey && data.question_id) {
                const question = dashboard.questions.find(q => +q.id === +data.question_id);
                survey = question.surveys.find(s => +s.id === +data.survey_id);

                setTimeout(() => {
                    const unansweredSurvey = question.surveys.find(s => !s.answers);
                    if (!unansweredSurvey) {
                        question.is_answered = 1;
                        state.dashboard = { ...dashboard };
                    }
                });
            }

            // Код удаляющий из дашборда отвеченые вопросы

            // if (!data.answers) {
            //     let surveys = dashboard.surveys;
            //     if (!surveys) {
            //         const question = dashboard.questions.find(q => +q.id === +data.question_id);
            //         surveys = question.surveys;
            //         question.surveys = surveys.filter(s => +s.id !== +data.survey_id);
            //     } else {
            //         dashboard.surveys = surveys.filter(s => +s.id !== +data.survey_id);
            //     }
            //     state.dashboard = { ...dashboard };
            //     return;
            // }


            let answers = data.answers;
            Vue.set(survey, 'answers', answers);
            let advanced = null;
            if (survey.advanced) {
                advanced = { ...survey.advanced };
                advanced.comments = [...advanced.comments, ...data.advanced.comments];
            }
            if (survey.type === 4) {
                answers = survey.answers;
                answers.total += data.comments.values.length;
                data.comments.values.forEach((c) => {
                    const variant = answers.values.find(v => +v.id === +c.id);
                    variant.comments.push({
                        name: data.comments.author,
                        content: c.value,
                    });
                });
            }

            Vue.set(survey, 'answers', answers);
            Vue.set(survey, 'advanced', advanced);
            Vue.set(state, 'dashboard', dashboard);
        },
        updateAgenda(state, agenda) {
            state.agenda = agenda;
        },
        translate(state, translations) {
            state.dashboard.name = translations.name;
            state.dashboard.intro = translations.intro;
            state.dashboard.description = translations.description;
            translateSurveys(state.dashboard.surveys, translations.surveys);

            let comments = state.dashboard.comments;
            if (state.dashboard.questions) {
                const questions = state.dashboard.questions;
                translations.questions.forEach(q => {
                    const question = questions.find(c => +c.id === +q.id);
                    question.intro = q.intro;
                    question.name = q.name;
                    question.subtitle = q.subtitle;
                    translateSurveys(question.surveys, q.surveys);

                    q.comments.forEach(c => {
                        let comment = null;
                        question.comments.forEach(cc => {
                            if (+cc.id === +c.id) {
                                comment = cc;
                                Vue.set(cc, 'content', c.content);
                            } else if (cc.comments) {
                                const com = cc.comments.find(ccc => +ccc.id === +c.id);
                                if (com) {
                                    comment = com;
                                }
                            }
                        });
                        if (comment) {
                            Vue.set(comment, 'content', c.content);
                            comment.content = c.content;
                        }
                    });
                });
            } else {
                changeContent(comments);
            }

            function changeContent(comments) {
                translations.comments?.forEach(c => {
                    let comment = comments.find(item => item.id === c.id);
                    if (comment) {
                        if (comment.comments?.length) {
                            changeContent(comment.comments);
                        }
                        comment.content = c.content;
                    }
                });
            }

            function updateObject(mainObj, updateObj) {
                // Приводим updateObj.columns к массиву, если это объект
                if (updateObj.columns && typeof updateObj.columns === 'object' && !Array.isArray(updateObj.columns)) {
                    updateObj.columns = Object.values(updateObj.columns);
                }

                // Обновляем имена колонок
                if (Array.isArray(updateObj.columns)) {
                    updateObj.columns.forEach(updateColumn => {
                        let column = mainObj.columns.find(col => col.id === updateColumn.id);
                        if (column) {
                            column.name = updateColumn.name;

                            // Если есть options, обновляем их тоже
                            if (Array.isArray(updateColumn.options)) {
                                column.options = updateColumn.options.map(option => ({ ...option }));
                            }
                        }
                    });
                }

                // Обновляем значения строк
                if (Array.isArray(updateObj.rows)) {
                    updateObj.rows.forEach(updateRow => {
                        let row = mainObj.rows.find(r => r.id === updateRow.id);
                        if (row) {
                            row.val = updateRow.name;
                        }
                    });
                }

                return mainObj;
            }

            function translateSurveys(surveys, TObj) {
                surveys?.forEach(s => {
                    const TS = TObj?.find(ts => ts.id === s.id);
                    s = updateObject(s, TS);
                    s.name = TS?.name || s.name;
                    s.options = s.options?.map(o => {
                        const TO = TS?.options.find(to => to.id === o.id);
                        o.name = TO?.name || o.name;
                        return o;
                    });
                });
            }
        },
        updStat(state, data) {
            state.dashboard = { ...state.dashboard, ...data };
        },

        // Rate and Single select  and Single checkbox
        updateStateSingle(state, data) {
            editDashboard(state, data, item => {
                item.is_answered = true;
                item.value = data.value;
            });
        },
        editStateSingle(state, data) {
            editDashboard(state, data, item => {
                item.value = data.value;
            });
        },

        // Multi Checkbox
        updateStateMultiCheckbox(state, data) {
            editDashboard(state, data, item => {
                item.is_answered = true;
                item.value = data.value.map((e) => e.id);
            });
        },
        editStateMultiCheckbox(state, data) {
            editDashboard(state, data, item => {
                item.value = data.value.map((e) => e.id);
            });
        },
    },

    getters: {
        get: state => state.dashboard,
        topicId: state => state.dashboard?.id,
        getAgenda: state => state.agenda,
    },
};

