import { get } from '@/utils/http-helper.js';

const GET_PLATFORM = '/platform/get-settings';
const GET_LANG = '/lang/get';

export default {
    namespaced: true,

    state: {
        platform: null,
        platformName: '',
        lang: null,
    },

    mutations: {
        updatePlatform(state, platform) {
            state.platform = platform;
        },
        setPlatformName(state, domain) {
            state.platformName = domain;
        },
        updateLang(state, lang) {
            state.lang = lang;
        },
    },

    actions: {
        async getPlatform({ commit, dispatch }, domain) {
            const platform = await get(GET_PLATFORM, { domain: domain });
            commit('updatePlatform', platform.data);
            if (platform.data.lang) {
                dispatch('getLang');
            }
            return platform;
        },

        setPlatformName({ commit }, domain) {
            commit('setPlatformName', domain);
        },

        async getLang({ state, commit }) {
            const lang = await get(GET_LANG, { lang: state.platform.lang });
            commit('updateLang', lang.data.tokens);
        },
    },
    getters: {
        platform: state => state.platform,
        platformMainColor: state => state.platform?.interface?.buttons_color_main,
        platformAccentColor: state => state.platform?.interface?.buttons_color_accent,
        platformName: state => state.platformName,
        lang: state => state.lang,
        platformFavicon: state => state.platform?.interface?.favicon,
        platformTitle: state => state.platform?.interface?.platform_name,
    },
};
