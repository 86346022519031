<template>
    <div
        v-click-outside="close"
        class="app-language-select"
    >
        <div
            class="left"
            @click="translateDefault"
        >
            <img src="@/assets/img/icons/translate_icon.png" />
            <span>
                {{ $t('dash_translate', 'Translate') }}
            </span>
        </div>
        <div
            class="right"
            @click.stop="open"
        >
            <img src="@/assets/img/icons/translate-arrow_icon.svg" />
            <span>
                {{ language }}
            </span>
            <select
                id="language"
                v-model="language"
                :class="{isOpen}"
                size="7"
                @change="change"
            >
                <option
                    v-for="opt in $options.languages"
                    :key="opt.value"
                    :value="opt.value"
                >
                    {{ opt.label }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
import languages from '@/assets/js/languages';
import vClickOutside from 'v-click-outside';

export default {
    name: 'app-language-select',

    directives: {
        clickOutside: vClickOutside.directive,
    },

    languages: languages,

    data: () => ({
        isOpen: false,
        language: null,
        selectedLanguage: null,
    }),

    methods: {
        change() {
            this.$emit('change', this.language);
            this.selectedLanguage = this.language;
            
            setTimeout(() => {
                this.isOpen = false;
            });
        },

        reset() {
            this.language = null;
            this.selectedLanguage = null;
        },

        open() {
            this.isOpen = true;
        },

        close() {
            this.isOpen = false;
        },

        translateDefault() {
            if (!this.selectedLanguage) {
                let language = window.navigator ? (window.navigator.language ||
                    window.navigator.systemLanguage ||
                    window.navigator.userLanguage) : 'en';
                language = language.substr(0, 2).toLowerCase();
                this.$emit('change', language);
                this.selectedLanguage = language;
            } else {
                this.$emit('change', '');
                this.selectedLanguage = null;
                this.language = null;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.app-language-select {
    position: relative;
    z-index: 1;

    display: flex;

    width: fit-content;

    font-size: to_rem(14px);

    background: #1d2649;
    border: 2px solid #1d2649;
    border-radius: 0.2rem;

    cursor: pointer;
    transition: box-shadow .2s;

    &.small {
        margin-right: 5px;
        .left {
            width: auto;
            span {
                display: none;
            }
        }
    }

    &:hover {
        box-shadow: 3px 3px 3px rgb(0 0 0 / 15%)
    }

    .left {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 6rem;
        padding: 0.5rem;

        font-weight: 400;
        color: $mainTextColor;

        transition: color .3s;

        img {
            height: 1rem;
        }
    }

    .right {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 2.5rem;

        background: $lightBlue;
        border-radius: 0.2rem;

        img {
            height: 1rem;

            transform: rotate(90deg);
        }

        span {
            margin-left: 0.2rem;

            color: white;
        }

        select {
            position: absolute;
            top: calc(100% + 0.1rem);
            right: 0;
            z-index: 1;

            display: none;

            width: 8rem;

            &.isOpen {
                display: block;
            }
        }
    }
}

@include razr767 {
    .app-language-select {
        font-size: 12px;

        .left {
            width: 7.5rem;
            padding: 0.7rem 0;

            img {
                height: 15px;
                margin-right: 0.2rem;
            }
        }

        .right {
            width: 42px;

            img {
                height: 15px;
                margin-right: 3px;
            }
        }
    }
}
</style>
