import { socketInstance, GP } from '@/utils/sockets-helper';
import store from '@/store';

const NAME_SPACE = 'message';
const MESSAGE_CREATE = 'message-create';

const generateSocketPath = new GP(NAME_SPACE);

export let messageSocket = null;

export const initMessageSocket = () => {
    messageSocket = socketInstance(NAME_SPACE);
    messageSocket.on(generateSocketPath.generate(MESSAGE_CREATE), data=>{
        store.dispatch('messageCreate', data);
        store.dispatch('auth/changeUnreadCount', 1);
    });
};

export const disconnectMessageSocket = () => {
    if (messageSocket) {
        messageSocket.disconnect();
    }
};
