import { get } from '@/utils/http-helper';

const GET_GUIDANCE = '/user-guidance/get';

export default {
  state: {
    guidance: null,
  },
  actions: {
    async getGuidance({ commit }) {
      const guidance = await get(GET_GUIDANCE);
      if (guidance.status) {
        commit('updateGuidance', guidance.data);
      }
    },
  },
  mutations: {
    updateGuidance(state, guidance) {
      state.guidance = guidance;
    },
  },
  getters: {
    getGuidance: state => state.guidance,
  },
};
