export const managerCreated = 'Manager created';
export const managerChanged = 'Manager information changed';
export const managerDeleted = 'Manager deleted';

export const imageLoaded = 'Picture uploaded';
export const imageDeleted = 'Picture deleted';
export const changeData = 'Data changed';

export const createEvent = 'Event created';
export const changeEvent = 'Event information changed';
export const deletedEvent = 'Event deleted';
export const copyEvent = 'Event copied';

export const copyTopic = 'Topic copied';

export const changedTopicStatus = 'Topic status changed';
export const deletedTopic = 'Topic deleted';

export const createdTopic = 'Topic created';
export const updatedTopic = 'Topic update';
export const sendVideoRequest = 'Request has been sent';
export const deleteVideoRequest = 'Request has been sent';
export const saveVideoCallLink = 'The link is saved';
export const createdSurvey = 'Survey created';
export const updatedSurvey = 'Survey update';
export const deletedSurvey = 'Survey delete';
export const resetQuestion = 'Question was reset';

export const saveDocument = 'Document save';
export const deletedDocument = 'Document delete';

export const loadedVideo = 'Video uploaded';
export const deletedVideo = 'Video delete';

export const saveLink = 'Link save';
export const updateLink = 'Link update';
export const deleteLink = 'Link delete';

export const createQuestion = 'Question create';
export const updateQuestion = 'Question update';
export const deleteQuestion = 'Question delete';

export const saveObjectives = 'Objectives save';
export const changedParticipantStatus = 'Participant status changed';
export const saveLibraryInfo = 'Section save';
export const deleteSection = 'Section deleted';

export const saveCompanyInfo = 'Company info update';
export const saveAgendaTitle = 'Agenda title update';
export const updateRepresentativeStatus = 'Representative status update';

export const changeAllPartStatus = 'All participants status change';
export const changePartStatus = 'Participant status change';
export const changePartOrder = 'Participant order change';
export const deleteParticipant = 'Participant delete';
export const savePartSection = 'Participant section save';
export const deletePartSection = 'Participant section delete';
export const changePartTopicStatus = 'Topic status change';
export const changePartPhoto = 'Participant photo update';
export const deletePartPhoto = 'Participant photo delete';
export const createPart = 'Participant create';
export const updatePart = 'Participant info update';

export const loadedReport = 'Report loaded';

export const textUpdated = 'Text has been changed';

export const defaultConfirmSettings = {
    message: `Are you sure?`,
    button: {
        no: 'No',
        yes: 'Yes',
    },
};

