<template>
    <div
        class="login"
        :style="{backgroundImage: `url(${bgImg})`}"
    >
        <div
            v-if="platform.interface && platform.interface.title_lp"
            class="login__label"
            v-html="platform.interface.title_lp"
        ></div>

        <div
            class="login__wrapper"
            :style="{borderColor}"
        >
            <img
                v-if="logo"
                :src="logo"
            />
            <h2 v-if="!isForgot">
                {{ $t('login', 'Login') }}
            </h2>
            <template v-else>
                <h2>
                    {{ $t('forgot_title', 'FORGOT PASSWORD') }}
                </h2>
                <p class="forgotInfo">
                    {{ $t('forgot_info', 'FORGOT PASSWORD') }}:
                </p>
            </template>

            <form
                v-if="!isForgot"
                @submit.prevent="submitHandler"
            >
                <input
                    v-model="email"
                    type="text"
                    placeholder="E-mail"
                />

                <div class="login__password">
                    <input
                        v-model="password"
                        :type="passwordType"
                        :placeholder="$t('password', 'Password')"
                    />
                    <img
                        v-if="passwordType === 'password'"
                        src="@/assets/img/icons/view.svg"
                        @click="togglePasswordInputType"
                    />
                    <img
                        v-else
                        src="@/assets/img/icons/no-view.svg"
                        @click="togglePasswordInputType"
                    />
                </div>


                <p
                    v-if="errorMessage"
                    class="app-error"
                >
                    {{ errorMessage }}: <br />
                    <span class="row mt">
                        <img src="@/assets/img/icons/phone-icon-red.svg" />
                        <a :href="'tel:' + platform.interface.support_phone">
                            {{ platform.interface.support_phone }}
                        </a>
                    </span>
                    {{ $t('or', 'or') }}
                    <span class="row">
                        <img src="@/assets/img/icons/mail-red_icon.svg" />
                        <a :href="'mailto:' + platform.interface.support_email">
                            {{ platform.interface.support_email }}
                        </a>
                    </span>
                </p>

                <app-button
                    tag="button"
                    class="accent"
                >
                    {{ $t('login_submit', 'Login') }}
                </app-button>

                <a
                    class="forgot"
                    @click.prevent="toggleForgot"
                >
                    {{ $t('forgot_password', 'Forgot Password?') }}
                </a>
            </form>

            <form
                v-else
                @submit.prevent="rememberPassword"
            >
                <input
                    v-model="email"
                    type="text"
                    placeholder="E-mail"
                />

                <p
                    v-if="errorMessage"
                    class="app-error"
                >
                    {{ errorMessage }}
                </p>
                <p
                    v-if="successRemember"
                    class="forgotInfo mb20 position-relative"
                >
                    {{ $t('forgot_ok', 'New password has been sent to your email') }}<!--
                    --><a class="forgotInfo__icon"></a>
                    <span class="forgotInfo__tooltip">If you don't see the email in your inbox, please make sure you are registered to this event or check the spam folder</span>
                </p>

                <app-button tag="button">
                    {{ $t('send', 'Send') }}
                </app-button>

                <a
                    class="forgot"
                    @click.prevent="toggleForgot"
                >
                    {{ $t('back_login', 'Back to login') }}
                </a>
            </form>
        </div>
        <accept v-if="showAccept" />
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';
import accept from '@/components/user/pages/login/accept';

import { disableScroll } from '@/assets/js/disableScroll';
import { initTimeSocket } from '@/utils/user/time-socket';
import { initMessageSocket } from '@/utils/user/message-socket';
import generateUrl from '@/utils/mixins/generate_url';
import { post } from '@/utils/http-helper';

export default {
    name: 'login',

    components: {
        appButton,
        accept,
    },

    mixins: [generateUrl],

    data: () => ({
        passwordType: 'password',

        email: '',
        password: '',

        showAccept: false,
        errorMessage: '',

        isForgot: false,
        successRemember: false,
    }),

    computed: {
        platform() {
            return this.$store.getters['platform/platform'];
        },

        borderColor() {
            const loginFrame = this.platform.interface.login_frame;
            if (loginFrame === 1) {
                return this.platform.interface.buttons_color_main;
            } else if (loginFrame === 2) {
                return this.platform.interface.buttons_color_accent;
            } else {
                return 'transparent';
            }
        },

        bgImg() {
            return this.generateUrl(this.platform?.interface?.background_lp, require('@/assets/img/loginBg.jpg'));
        },

        logo() {
            return this.generateUrl(this.platform?.interface?.company_logo, ' ');
        },

        user() {
            return this.$store.getters['auth/userInfo'];
        },
    },

    mounted() {
        const accepted = this.$store.getters['auth/userInfo']?.accept;
        const roleId = this.$store.getters['auth/userInfo']?.role?.id;
        if (!accepted && (roleId === 6 || roleId === 5)) {
            this.openAcceptPopup();
        }
    },

    methods: {
        togglePasswordInputType() {
            this.passwordType === 'password' ? this.passwordType = 'text' : this.passwordType = 'password';
        },

        toggleForgot() {
            this.isForgot = !this.isForgot;
            this.errorMessage = '';
        },

        openAcceptPopup() {
            this.showAccept = true;
            disableScroll();
        },

        async submitHandler() {
            const data = {
                email: this.email.trim(),
                password: this.password.trim(),
                platform_id: this.platform.id,
            };
            const authData = await this.$store.dispatch('auth/login', data);
            if (Object.prototype.toString.call(authData?.data) === '[object Object]') {
                await this.$store.dispatch('auth/getUserInfo');

                const userRoleId = this.user?.role?.id;
                if ((userRoleId === 6 || userRoleId === 5) && !this.user.accept) {
                    this.openAcceptPopup();
                } else {
                    if (userRoleId === 3) {
                        this.$router
                            .push({ name: 'events' })
                            .then(() => {
                                location.reload();
                            });
                    } else {
                        this.$router
                            .push({ name: 'dashboard' })
                            .then(() => {
                                location.reload();
                            });
                    }
                }
                if (userRoleId === 6 || userRoleId === 5) {
                    initTimeSocket();
                    initMessageSocket();
                }
            }

            if (!authData.status) {
                this.errorMessage = authData.message;
            }
        },

        async rememberPassword() {
            const response = await post('/user/forgot-password', {
                platform_id: this.platform.id,
                email: this.email,
            }, false, true);
            if (response.status) {
                this.successRemember = true;
                this.errorMessage = '';
            } else {
                this.errorMessage = response.message;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.login {
    height: 92vh;
    padding-top: 0.5rem;

    background: url("~@/assets/img/loginBg.jpg") no-repeat center center/cover;

    &__label {
        width: 43.5rem;
        margin: 0 .5rem .5rem;
        padding: .8rem 1.4rem;

        font-family: Tahoma, Arial;
        font-size: to_rem(33px);
        font-weight: 700;

        background: rgba(255, 255, 255, 0.3);
    }

    &__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;

        display: flex;
        flex-direction: column;
        align-items: center;

        width: 25rem;
        padding: 2.45rem 1rem 1.5rem;

        background: white;
        border: 16px solid $mainBlue;
        border-radius: 0.3rem;

        transform: translate(-50%, -50%);
    }

    &__password {
        position: relative;

        width: 100%;
        margin-bottom: 1rem;

        input {
            margin: 0 !important;
        }

        img {
            position: absolute;
            top: 50%;
            right: 0.8rem;

            width: 1.3rem;

            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    .forgotInfo {
        margin: 1rem 0 0;

        font-size: to_rem(14px);
        text-align: center;

        &.mb20 {
            margin-bottom: 20px;
        }
        &.position-relative{
            position: relative;
        }
        &__icon{
            cursor: pointer;
            display: inline-block;
            width: 1em;
            height: 1em;
            vertical-align: bottom;
            margin-left: 0.25em;
            background: url('~@/assets/img/icons/info.svg') center center / contain no-repeat;
        }
        &__tooltip{
            position: absolute;
            bottom: 1.5em;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 250px;
            background: #fff;
            border: 3px solid #255C9B;
            font-size: 12px;
            padding: 0.5em;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s linear 0.1s, opacity 0.1s linear 0s;
        }
    }
    .forgotInfo__icon:hover + .forgotInfo__tooltip{
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 0.1s linear 0s;
    }


    .app-error {
        margin: 1rem 0;

        text-align: center;

        a {
            color: red;
        }

        .row {
            display: flex;
            justify-content: center;
            align-items: center;

            &.mt {
                margin: 0.5rem 0 0;
            }

            img {
                width: 1rem;
                height: 1rem;
                margin-right: 0.2rem;
            }
        }
    }

    img {
        max-width: 10rem;
        max-height: 5rem;
        object-fit: contain;
    }

    h2 {
        margin-top: 1.7rem;

        font-size: to_rem(24px);
        font-weight: 400;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        margin-top: 1.2rem;
    }

    input {
        width: 100%;
        padding: 0.7rem;

        font-size: to_rem(13px);

        background: $mainBackgroundColor;
        border: 1px solid $lightGray;

        &:nth-of-type(1) {
            margin: 1rem 0;
        }
    }

    .forgot {
        margin-top: .8rem;

        font-size: to_rem(10px);
        color: var(--platform-main-color);

        cursor: pointer;
    }
}

@include mobile_or_P {
    .login {
        &__label {
            width: 32.5rem;
            margin: 0;

            font-size: 24px;
        }

        img {
            height: 62px;
        }

        h2 {
            margin-top: 2.1rem;

            font-size: 28px;
        }

        &__wrapper {
            width: 21rem;
            padding: 2.45rem 0.6rem 1.5rem;
        }

        input {
            padding: 0.9rem 0.7rem;

            font-size: 15px;
        }

        .app-button {
            font-size: 20px;
        }

        .forgot {
            font-size: 12px;
        }
    }
}

@include razr767 {
    .login {
        padding-bottom: 5rem;

        &__label {
            width: 100%;

            font-size: 14px;
        }

        &__wrapper {
            width: 90%;
            max-width: 300px;
            padding: 2.45rem 1rem 1.5rem;
        }

        h2 {
            font-size: 18px;
        }

        input {
            font-size: 14px;
        }

        .app-button {
            font-size: 14px;
        }

        .forgot {
            font-size: 12px;
        }

        img {
            height: 50px;
        }
    }
}

</style>
