<template>
    <div class="disposer-edit-event-topic">
        <div class="main-modal">
            <app-modal
                v-if="isModalVisible"
                @closeModal="closeModal"
                @saveHandler="saveEditorData"
            >
                <app-editor
                    v-model="editorData"
                />
            </app-modal>
        </div>
        <div class="header">
            <div class="title-back">
                <div class="disposer-app-title">
                    Edit Event Topic
                </div>
                <div class="back">
                    <app-button
                        tag="router-link"
                        :to="{name: 'event-topics'}"
                    >
                        Back to topics
                    </app-button>
                </div>
            </div>
            <div class="disposer-app-topic-title row">
                Choose Participants
            </div>
            <label
                class="disposer-app-checkbox"
                :class="{ disabled: !topicId }"
            >
                <input
                    ref="switchParticipantsSelects"
                    v-model="checkedAllParts"
                    type="checkbox"
                    @change="toggleSelects"
                />
                <div class="box"></div>
                Select / Inselect
            </label>
            <form
                ref="participantsSelects"
                class="columns"
                :class="{ disabled: !topicId }"
            >
                <label
                    v-for="participant in participants"
                    :key="participant.id"
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="participant.status"
                        type="checkbox"
                        :data-id="participant.id"
                        @change="updateParticipant(participant)"
                    />
                    <div class="box"></div>
                    {{ participant.name }}
                </label>
                <app-button
                    class="big"
                    @click="loadMoreParticipant()"
                >
                    Load more
                </app-button>
            </form>
        </div>
        <div class="main">
            <app-section-column :save="saveTopic">
                <div class="row row-name-text">
                    <div class="topic-name">
                        <label
                            class="disposer-app-label"
                        >
                            Topic name
                            <input
                                v-model="topic.name"
                                type="text"
                                class="disposer-app-input title-input"
                            />
                            <span
                                v-if="$v.topic.name.$dirty && !$v.topic.name.required"
                                class="app-input-error"
                            >
                                Enter topic name
                            </span>
                        </label>
                    </div>
                    <div class="introductory-text">
                        <label
                            class="disposer-app-label"
                        >
                            Introductory text
                            <input
                                type="text"
                                class="disposer-app-input title-input"
                                :value="topic.introText | htmlToString"
                                :disabled="isModalVisible"
                                @focus="() => showModal('intro')"
                            />
                        </label>
                        <label
                            class="disposer-app-label"
                        >
                            Introductory text (after media)
                            <input
                                type="text"
                                class="disposer-app-input title-input"
                                :value="topic.introTextAfter | htmlToString"
                                :disabled="isModalVisible"
                                @focus="() => showModal('intro_after')"
                            />
                        </label>
                    </div>
                    <div class="copy-topic">
                        <label
                            class="disposer-app-label"
                        >
                            Copy this topic to
                        </label>
                        <label class="copy-label">Platform</label>
                        <app-select
                            v-model="platformCopyTo"
                            class="app-select--white"
                            :option-select="platforms"
                            label="name"
                        />
                        <label class="copy-label">Event</label>
                        <app-select
                            v-model="eventCopyTo"
                            class="app-select--white"
                            :disabled="!platformCopyTo"
                            :option-select="eventsPlatformCopyTo"
                            label="name"
                        />
                        <app-button
                            class="red"
                            :data-disable="!platformCopyTo && !eventCopyTo"
                            @click="copyTopic()"
                        >
                            Copy
                        </app-button>
                    </div>
                </div>
                <div class="row row-date-time">
                    <app-dates-row
                        :start-date-error="topicErrors.start_date"
                        :end-date-error="topicErrors.end_date"
                    >
                        <template v-slot:startDate>
                            <app-date-picker
                                v-model="topic.startDate"
                                title="Start date"
                                mode="date"
                                :required-error="$v.topic.startDate.$dirty && !$v.topic.startDate.required"
                            />
                            <app-date-picker
                                v-model="topic.startTime"
                                title="Start time"
                                mode="time"
                                :required-error="$v.topic.startTime.$dirty && !$v.topic.startTime.required"
                            />
                        </template>
                        <template v-slot:endDate>
                            <app-date-picker
                                v-model="topic.endDate"
                                title="End date"
                                mode="date"
                                :required-error="$v.topic.endDate.$dirty && !$v.topic.endDate.required"
                            />
                            <app-date-picker
                                v-model="topic.endTime"
                                title="End time"
                                mode="time"
                                :required-error="$v.topic.endTime.$dirty && !$v.topic.endTime.required"
                            />
                        </template>
                    </app-dates-row>
                </div>
                <div class="row row-description">
                    <div class="description">
                        <div class="wrap">
                            <label
                                class="disposer-app-label"
                            >
                                Description
                                <input
                                    type="text"
                                    class="disposer-app-input title-input"
                                    :value="topic.description | htmlToString"
                                    :disabled="isModalVisible"
                                    @focus="() => showModal('description')"
                                />
                            </label>
                        </div>
                        <div class="info-icons">
                            <img
                                v-tooltip.right-start="$options.texts.descriptionIconMsg"
                                src="@/assets/img/icons/disposer/info-icon.png"
                                alt=""
                                class="info-icon"
                            />
                        </div>
                    </div>
                </div>
                <div class="choose-topic-format">
                    <div class="disposer-app-topic-title">
                        Choose topic format
                    </div>
                    <app-select
                        v-model="topic.formatDiscussion"
                        :option-select="availableTopicFormatsNext"
                        :disabled="!!topicId"
                        :allow-empty="false"
                    />
                    <span
                        v-if="$v.topic.formatDiscussion.$dirty && !$v.topic.formatDiscussion.required"
                        class="app-input-error"
                    >
                        Choose topic format discussion
                    </span>
                </div>
                <br />
                <label
                    v-if="!topic.formatDiscussion || (topic.formatDiscussion && topic.formatDiscussion.id !== 3)"
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="topic.hideTool"
                        type="checkbox"
                    />
                    <div class="box"></div>
                    Hide the comments section
                </label>

                <br />
                <div class="choose-topic-format">
                    <div class="disposer-app-topic-title">
                        Functional
                    </div>
                    <app-select
                        v-model="topic.format"
                        :option-select="availableTopicFormats"
                        :disabled="!!topicId"
                    />
                    <span
                        v-if="$v.topic.format.$dirty && !$v.topic.format.required"
                        class="app-input-error"
                    >
                        Choose topic format
                    </span>
                </div>
            </app-section-column>
        </div>

        <div
            class="moreSettings"
            :class="{active: topicId}"
        >
            <app-section>
                <div class="disposer-app-topic-title">
                    Introduction Video
                </div>
                <app-upload-video
                    v-for="video in videosIntro"
                    :key="video.id"
                    :upload-type="1"
                    :video="video"
                    :topic-id="topicId"
                    :event-id="eventId"
                    :paths="$options.documentActionsPaths"
                    @delete="deleteIntroVideo"
                />
                <app-button
                    class="narrow"
                    @click="addIntroVideo"
                >
                    Add video
                </app-button>
            </app-section>
            <app-section>
                <div class="disposer-app-topic-title">
                    Introduction Audio
                </div>
                <app-upload-audio
                    v-for="audio in audiosIntro"
                    :key="audio.id"
                    :audio="audio"
                    :topic-id="topicId"
                    :event-id="eventId"
                    :paths="$options.documentActionsPaths"
                    @delete="deleteIntroAudios"
                />
                <app-button @click="addIntroAudio">
                    Add audio recording
                </app-button>
            </app-section>
            <app-section>
                <div class="disposer-app-topic-title">
                    Introduction Documents
                </div>
                <app-upload-documents
                    v-for="document in documentsIntro"
                    :key="document.id"
                    :document="document"
                    :topic-id="topicId"
                    :event-id="eventId"
                    :paths="$options.documentActionsPaths"
                    @delete="deleteIntroDocument"
                />
                <app-button
                    class="narrow"
                    @click="addIntroDocument"
                >
                    Add document
                </app-button>
            </app-section>

            <div
                v-if="checkSel1Sel3"
                class="sequence-section"
            >
                <app-sequence
                    :event-id="eventId"
                    :topic-id="topicId"
                    :available-questions.sync="editedTopic.questions"
                    @getEditedTopic="getEditedTopic"
                />
            </div>
            <div
                v-if="sectionsData.length"
                class=""
            >
                <div
                    v-for="(section, index) in sectionsData"
                    :key="section.id"
                    class="section"
                >
                    <label
                        class="disposer-app-label"
                    >
                        <span class="sectionHeader">
                            Section title
                            <label
                                class="disposer-app-checkbox"
                            >
                                <input
                                    v-model="section.is_hidden"
                                    type="checkbox"
                                />
                                <div class="box"></div>
                                Hide section
                            </label>
                        </span>
                        <input
                            v-model="section.title"
                            type="text"
                            class="disposer-app-input title-input"
                        />
                    </label>
                    <label
                        class="disposer-app-checkbox"
                    >
                        <input
                            :checked="section.questions.forEach((item) => item.status)"
                            type="checkbox"
                            @change="changeAllStatus(section)"
                        />
                        <div class="box"></div>
                        Select all
                    </label>
                    <div class="sectionQuestions">
                        <div class="">
                            <label
                                v-for="question in section.questions"
                                :key="question.id"
                                class="disposer-app-checkbox"
                            >
                                <input
                                    :checked="question.status === 1"
                                    type="checkbox"
                                    @change="changeStatus(question)"
                                />
                                <div class="box"></div>
                                {{ question.number || question.id }}
                            </label>
                        </div>
                        <app-button @click="updateSection(index)">
                            Save section
                        </app-button>
                    </div>
                </div>
            </div>
            <div
                v-if="showAddSection"
                class="section"
            >
                <label
                    class="disposer-app-label"
                >
                    <span class="sectionHeader">
                        Section title
                        <label
                            class="disposer-app-checkbox"
                        >
                            <input
                                v-model="sectionData.is_hidden"
                                type="checkbox"
                            />
                            <div class="box"></div>
                            Hide section
                        </label>
                    </span>
                    <input
                        v-model="sectionData.title"
                        type="text"
                        class="disposer-app-input title-input"
                    />
                </label>
                <div class="sectionQuestions">
                    <div class="">
                        <label
                            v-for="question in editedTopic.questions"
                            :key="question.id"
                            class="disposer-app-checkbox"
                        >
                            <input
                                type="checkbox"
                                :value="question.id"
                                @change="toggleQuestion(question.id)"
                            />
                            <div class="box"></div>
                            {{ question.number }}
                        </label>
                    </div>
                    <app-button @click="createSection">
                        Create section
                    </app-button>
                </div>
            </div>
            <app-button @click="addSection">
                Add section
            </app-button>
            <div
                v-if="checkSel1Sel2"
                class="discussion-forum-section"
            >
                <app-topic-surveys
                    v-for="survey in surveys"
                    :key="survey.id"
                    :survey="survey"
                    :topic-id="topicId"
                    @deleteSurvey="deleteSurvey"
                    @updateTopicInfo="getEditedTopic"
                />
                <app-button
                    class="add-polling-btn"
                    @click="addSurvey"
                >
                    Add survey
                </app-button>
            </div>
            <div
                v-if="checkSel2"
                class="video-call-section"
            >
                <app-section :save="updateVideoCallLink">
                    <div class="video-link">
                        <label
                            class="disposer-app-label"
                        >
                            Link to video call
                            <input
                                v-model="videoCallLink"
                                type="text"
                                class="disposer-app-input title-input"
                            />
                            <span
                                v-if="$v.videoCallLink.$dirty && !$v.videoCallLink.required"
                                class="app-input-error"
                            >
                                Link is required
                            </span>
                            <span
                                v-if="$v.videoCallLink.$dirty && !$v.videoCallLink.url"
                                class="app-input-error"
                            >
                                Invalid link
                            </span>
                            <label class="disposer-app-checkbox">
                                <input
                                    v-model="openOnPlatform"
                                    type="checkbox"
                                />
                                <div class="box"></div>
                                Open on the platform
                            </label>
                        </label>
                    </div>
                </app-section>
            </div>
            <div
                v-if="checkSel3_4"
                class="workshop-and-document-section"
            >
                <app-section
                    :save="saveMainDocument"
                    :delete-handler="deleteMainDocument"
                    class="workshop-upload-document"
                >
                    <div class="disposer-app-topic-title">
                        Upload document
                    </div>
                    <div class="upload-current-file">
                        <a
                            :href="generateUrl(mainDocument.file)"
                            target="_blank"
                        >
                            {{ mainDocument.name }}
                        </a>
                    </div>
                    <div class="flex">
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="mainDocument.secured"
                                type="checkbox"
                            />
                            <div class="box"></div>
                            Secured view-only format
                        </label>
                        <label
                            v-if="platform.settings.watermarks"
                            class="disposer-app-checkbox"
                        >
                            <input
                                v-model="mainDocument.watermarks"
                                type="checkbox"
                            />
                            <div class="box"></div>
                            Watermarks
                        </label>
                        <app-file-input
                            :load-file="loadMainDocument"
                            accept=".pdf"
                        >
                            Choose
                        </app-file-input>
                    </div>
                </app-section>
                <app-section
                    class="workshop-session"
                    :data-disabled="mainLinkRequested"
                >
                    <div class="disposer-app-topic-title">
                        Main Session
                    </div>

                    <app-dates-row
                        :start-date-error="videoMainErrors && videoMainErrors.start_date"
                        :end-date-error="videoMainErrors && videoMainErrors.end_date"
                    >
                        <template v-slot:startDate>
                            <app-date-picker
                                v-model="videoMain.startDate"
                                title="Start date"
                                mode="date"
                                :required-error="$v.videoMain.startDate.$dirty && !$v.videoMain.startDate.required"
                            />
                            <app-date-picker
                                v-model="videoMain.startTime"
                                title="Start time"
                                mode="time"
                                :required-error="$v.videoMain.startTime.$dirty && !$v.videoMain.startTime.required"
                            />
                        </template>
                        <template v-slot:endDate>
                            <app-date-picker
                                v-model="videoMain.endDate"
                                title="End date"
                                mode="date"
                                :required-error="$v.videoMain.endDate.$dirty && !$v.videoMain.endDate.required"
                            />
                            <app-date-picker
                                v-model="videoMain.endTime"
                                title="End time"
                                mode="time"
                                :required-error="$v.videoMain.endTime.$dirty && !$v.videoMain.endTime.required"
                            />
                        </template>
                    </app-dates-row>

                    <div class="link-button">
                        <div class="link">
                            <label class="disposer-app-label">
                                Link
                                <input
                                    v-model="videoMain.link"
                                    type="text"
                                    class="disposer-app-input title-input"
                                />
                                <span
                                    v-if="$v.videoMain.link.$dirty && !$v.videoMain.link.url"
                                    class="app-input-error"
                                >
                                    Invalid url
                                </span>
                            </label>
                        </div>
                        <app-button @click="sendVideoRequest(1)">
                            Send request to get link
                        </app-button>
                        <app-button @click="sendVideoRequest(1, true)">
                            Update link
                        </app-button>
                        <app-button @click="deleteVideoRequest(1)">
                            Delete link
                        </app-button>
                    </div>
                </app-section>
                <app-section
                    class="common-session"
                    :data-disabled="contextLinkRequested"
                >
                    <div class="disposer-app-topic-title">
                        Workshop Session
                    </div>

                    <app-dates-row
                        :start-date-error="videoContextErrors && videoContextErrors.start_date"
                        :end-date-error="videoContextErrors && videoContextErrors.end_date"
                    >
                        <template v-slot:startDate>
                            <app-date-picker
                                v-model="videoContext.startDate"
                                title="Start date"
                                mode="date"
                                :required-error="$v.videoContext.startDate.$dirty && !$v.videoContext.startDate.required"
                            />
                            <app-date-picker
                                v-model="videoContext.startTime"
                                title="Start time"
                                mode="time"
                                :required-error="$v.videoContext.startTime.$dirty && !$v.videoContext.startTime.required"
                            />
                        </template>
                        <template v-slot:endDate>
                            <app-date-picker
                                v-model="videoContext.endDate"
                                title="End date"
                                mode="date"
                                :required-error="$v.videoContext.endDate.$dirty && !$v.videoContext.endDate.required"
                            />
                            <app-date-picker
                                v-model="videoContext.endTime"
                                title="End time"
                                mode="time"
                                :required-error="$v.videoContext.endTime.$dirty && !$v.videoContext.endTime.required"
                            />
                        </template>
                    </app-dates-row>
                    <div class="link-button">
                        <div class="link">
                            <label class="disposer-app-label">
                                Link
                                <input
                                    v-model="videoContext.link"
                                    type="text"
                                    class="disposer-app-input title-input"
                                />
                                <span
                                    v-if="$v.videoContext.link.$dirty && !$v.videoContext.link.url"
                                    class="app-input-error"
                                >
                                    Invalid url
                                </span>
                            </label>
                        </div>

                        <app-button
                            @click="sendVideoRequest(2)"
                        >
                            Send request to get link
                        </app-button>
                        <app-button
                            @click="sendVideoRequest(2, true)"
                        >
                            Update link
                        </app-button>
                        <app-button
                            @click="deleteVideoRequest(2)"
                        >
                            Delete link
                        </app-button>
                    </div>
                </app-section>
            </div>
            <div
                v-if="checkSel5"
                class="workshop-and-document-section"
            >
                <app-section
                    class="workshop-session"
                    :data-disabled="mainLinkRequested"
                >
                    <div class="disposer-app-topic-title">
                        Activate video chat
                    </div>

                    <app-dates-row
                        :start-date-error="videoMainErrors && videoMainErrors.start_date"
                        :end-date-error="videoMainErrors && videoMainErrors.end_date"
                    >
                        <template v-slot:startDate>
                            <app-date-picker
                                v-model="videoMain.startDate"
                                title="Start date"
                                mode="date"
                                :required-error="$v.videoMain.startDate.$dirty && !$v.videoMain.startDate.required"
                            />
                            <app-date-picker
                                v-model="videoMain.startTime"
                                title="Start time"
                                mode="time"
                                :required-error="$v.videoMain.startTime.$dirty && !$v.videoMain.startTime.required"
                            />
                        </template>
                        <template v-slot:endDate>
                            <app-date-picker
                                v-model="videoMain.endDate"
                                title="End date"
                                mode="date"
                                :required-error="$v.videoMain.endDate.$dirty && !$v.videoMain.endDate.required"
                            />
                            <app-date-picker
                                v-model="videoMain.endTime"
                                title="End time"
                                mode="time"
                                :required-error="$v.videoMain.endTime.$dirty && !$v.videoMain.endTime.required"
                            />
                        </template>
                    </app-dates-row>

                    <div class="link-button">
                        <div class="link">
                            <label class="disposer-app-label">
                                Link
                                <input
                                    v-model="videoMain.link"
                                    type="text"
                                    class="disposer-app-input title-input"
                                />
                                <span
                                    v-if="$v.videoMain.link.$dirty && !$v.videoMain.link.url"
                                    class="app-input-error"
                                >
                                    Invalid url
                                </span>
                            </label>
                        </div>
                        <app-button @click="sendVideoRequest(1)">
                            Send request to get link
                        </app-button>
                        <app-button @click="sendVideoRequest(1, true)">
                            Update link
                        </app-button>
                    </div>
                </app-section>
            </div>

            <div class="footer">
                <app-section
                    :save="uploadPicture"
                    :delete-handler="deletePicture"
                >
                    <div>
                        <div class="disposer-app-topic-title">
                            Upload logo or any picture
                        </div>

                        <img
                            v-if="pictureSrc"
                            class="logo-img"
                            :src="pictureSrc"
                        />
                        <app-file-input
                            :load-file="loadPicture"
                            accept="image/*"
                        >
                            Choose
                        </app-file-input>
                    </div>
                </app-section>
                <app-section>
                    <div class="disposer-app-topic-title">
                        Upload Documents
                    </div>
                    <app-upload-documents
                        v-for="document in documents"
                        :key="document.id"
                        :document="document"
                        :topic-id="topicId"
                        :event-id="eventId"
                        :paths="$options.documentActionsPaths"
                        @delete="deleteDocument"
                    />
                    <app-button
                        class="narrow"
                        @click="addDocument"
                    >
                        Add document
                    </app-button>
                </app-section>
                <app-section>
                    <div class="disposer-app-topic-title">
                        Upload Video
                    </div>
                    <app-upload-video
                        v-for="video in videos"
                        :key="video.id"
                        :video="video"
                        :upload-type="1"
                        :topic-id="topicId"
                        :event-id="eventId"
                        :paths="$options.documentActionsPaths"
                        @delete="deleteVideo"
                    />
                    <app-button
                        class="narrow"
                        @click="addVideo"
                    >
                        Add video
                    </app-button>
                </app-section>
                <app-section>
                    <div class="disposer-app-topic-title">
                        Audios
                    </div>
                    <app-upload-audio
                        v-for="audio in audios"
                        :key="audio.id"
                        :audio="audio"
                        :topic-id="topicId"
                        :event-id="eventId"
                        :paths="$options.documentActionsPaths"
                        @delete="deleteAudios"
                    />
                    <app-button @click="addAudio">
                        Add audio recording
                    </app-button>
                </app-section>
            </div>
        </div>
    </div>
</template>

<script>
import appSectionColumn from '@/components/disposer/app/app-section-column';
import appSection from '@/components/disposer/app/app-section';
import appUploadDocuments from '@/components/disposer/app/app-upload-documents';
import appUploadVideo from '@/components/disposer/app/app-upload-video';
import appUploadAudio from '@/components/disposer/app/app-upload-audio';
import appTopicSurveys from '@/components/disposer/app/app-topic-surveys';
import appButton from '@/components/disposer/app/app-button';
import appSelect from '@/components/disposer/app/app-select';
import appModal from '@/components/disposer/app/app-modal';
import appEditor from '@/components/disposer/app/app-editor';
import appSequence from '@/components/disposer/app/app-sequence';
import appDatePicker from '@/components/disposer/app/app-date-picker';
import appDatesRow from '@/components/disposer/app/app-dates-row';
import appFileInput from '@/components/disposer/app/app-file-input';

import { generateFileUrl, get, post } from '@/utils/http-helper';
import { descriptionIconMsg } from '@/utils/disposer/icons-text-helper.js';
import { uniteTime } from '@/utils/disposer/time-format-helper';
import { parseImg } from '@/utils/disposer/parse-files-helper';
import {
    AUDIO_TEMPLATE,
    DOCUMENT_TEMPLATE,
    VIDEO_TEMPLATE,
} from '@/utils/disposer/objects-templates';
import {
    copyTopic,
    createdTopic,
    deletedDocument,
    deleteVideoRequest,
    imageDeleted,
    imageLoaded,
    saveDocument,
    saveVideoCallLink,
    sendVideoRequest,
    updatedTopic,
} from '@/utils/disposer/notifications-text-helper';
import { required, url } from 'vuelidate/lib/validators';
import generateUrl from '@/utils/mixins/generate_url';
import htmlToString from '@/utils/filters/html-to-string';

const UPLOAD_MAIN_DOCUMENT = '/topic/upload-document';
const DELETE_MAIN_DOCUMENT = '/topic/delete-document';
const TOPIC_CREATE = '/topic/create';
const TOPIC_UPDATE = '/topic/update';
const TOPIC_UPDATE_VIDEO_CALL = '/topic/update-videocall';
const VIDEO_REQUEST = '/topic/video-request';
const UPDATE_VIDEO_REQUEST = '/topic/update-video-request';
const REMOVE_VIDEO_REQUEST = '/topic/remove-video-request';
const UPDATE_PICTURE = '/topic/update-picture';
const DELETE_PICTURE = '/topic/delete-picture';
const UPDATE_PARTICIPANT = '/topic/update-participants';
const PLATFORM_LIST = '/platform/list';
const TOPIC_COPY = 'topic/copy';

export default {
    name: 'disposer-edit-event-topic',

    components: {
        appSectionColumn,
        appSection,
        appUploadDocuments,
        appUploadVideo,
        appUploadAudio,
        appTopicSurveys,
        appButton,
        appSelect,
        appDatePicker,
        appModal,
        appEditor,
        appSequence,
        appDatesRow,
        appFileInput,
    },

    filters: {
        htmlToString,
    },

    mixins: [generateUrl],

    texts: {
        descriptionIconMsg,
    },

    documentActionsPaths: {
        SAVE_OBJECT: '/topic/create-object',
        UPDATE_OBJECT: '/topic/update-object',
        DELETE_OBJECT: '/topic/delete-object',
    },

    selectOptions: {
        topicFormat: [
            {
                id: 1,
                label: 'None',
            },
            {
                id: 2,
                label: 'Video Call',
            },
            {
                id: 3,
                label: 'Document review',
                isActiveFlag: 'review',
            },
            {
                id: 4,
                label: 'Interactive workshop with white board',
                isActiveFlag: 'workshop',
            },
            {
                id: 5,
                label: 'Work on new document',
                isActiveFlag: 'document',
            },
            {
                id: 6,
                label: 'Stickers',
                isActiveFlag: 'sticker',
            },
        ],
        topicFormatNext: [
            {
                id: 1,
                label: 'Only one open discussion',
            },
            {
                id: 2,
                label: 'Only one open discussion with polling questions',
                isActiveFlag: 'survey',
            },
            {
                id: 3,
                label: 'Sequence of open/polling questions',
            },
        ],
    },

    data: () => ({
        selectedEvent: null,

        checkedAllParts: false,

        isModalVisible: false,
        isIntroTextModal: false,
        isIntroTextAfterModal: false,
        isDescriptionModal: false,

        editorData: '',

        platformCopyTo: null,
        eventCopyTo: null,
        platforms: [],

        countParticipant: 10,

        topic: {
            name: '',
            introText: '',
            introTextAfter: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            description: '',
            format: '',
            formatDiscussion: '',
            hideTool: false,
        },
        topicErrors: {},

        videoCallLink: '',
        openOnPlatform: false,

        surveys: [
            {
                id: Math.random(),
            },
        ],

        videoMain: {
            rstartDate: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            link: '',
        },
        videoContext: {
            rstartDate: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            link: '',
        },
        mainDocument: {
            secured: false,
            watermarks: false,
            name: '',
            file: null,
        },
        videoMainErrors: {},
        videoContextErrors: {},
        videoChatErrors: {},
        videoChatLinkRequested: false,

        picture: '',
        pictureSrc: '',
        videosIntro: [
            { ...VIDEO_TEMPLATE(Math.random(), 2) },
        ],
        audiosIntro: [
            { ...AUDIO_TEMPLATE(Math.random(), 2) },
        ],
        documentsIntro: [
            { ...DOCUMENT_TEMPLATE(Math.random(), 2) },
        ],

        documents: [
            { ...DOCUMENT_TEMPLATE(Math.random()) },
        ],
        documentsErrors: {},
        videos: [
            { ...VIDEO_TEMPLATE(Math.random()) },
        ],
        audios: [
            { ...AUDIO_TEMPLATE(Math.random()) },
        ],
        sectionData: {},
        sectionsData: [],
        showAddSection: false,
    }),

    validations: {
        topic: {
            name: {
                required,
            },
            startDate: {
                required,
            },
            startTime: {
                required,
            },
            endDate: {
                required,
            },
            endTime: {
                required,
            },
            format: {
                required,
            },
            formatDiscussion: {
                required,
            },
        },
        videoMain: {
            startDate: {
                required,
            },
            startTime: {
                required,
            },
            endDate: {
                required,
            },
            endTime: {
                required,
            },
            link: {
                url,
            },
        },
        videoContext: {
            startDate: {
                required,
            },
            startTime: {
                required,
            },
            endDate: {
                required,
            },
            endTime: {
                required,
            },
            link: {
                url,
            },
        },
        videoCallLink: {
            required,
            url,
        },
    },

    computed: {
        availableTopicFormats() {
            return this.$options.selectOptions.topicFormat.filter((f) => f.isActiveFlag ? this.platform.settings[f.isActiveFlag] : true);
        },
        availableTopicFormatsNext() {
            return this.$options.selectOptions.topicFormatNext.filter((f) => f.isActiveFlag ? this.platform.settings[f.isActiveFlag] : true);
        },
        platform() {
            return this.$store.getters['platform/platform'];
        },
        eventId() {
            return +this.$route.params.id;
        },
        topicId() {
            return +this.$route.params.topicId;
        },
        eventsPlatformCopyTo() {
            return this.platformCopyTo && this.platformCopyTo.events ? this.platformCopyTo.events : [];
        },
        editedTopic() {
            return this.$store.getters['disposerTopics/getTopic'] || {};
        },
        participants() {
            const participants = this.$store.getters['disposerTopics/getParticipants'];
            if (participants && Object.keys(participants).length) {
                return [...participants.external, ...participants.internal];
            }
            return [];
        },
        checkSel1Sel3() {
            return this.topic.formatDiscussion?.id === 3;
        },
        checkSel1Sel2() {
            return this.topic.formatDiscussion?.id === 2;
        },
        checkSel2() {
            return this.topic.format?.id === 2;
        },
        checkSel3_4() {
            return this.topic.format?.id === 4 || this.topic.format?.id === 3;
        },
        checkSel5() {
            return this.topic.format?.id === 5;
        },
        isRootManager() {
            return this.$store.getters['auth/userRole']?.id === 1 || this.$store.getters['auth/userRole']?.id === 2 || this.$store.getters['auth/userRole']?.id === 3;
        },
        mainLinkRequested() {
            return !this.isRootManager && !!this.videoMain.rstartDate;
        },
        contextLinkRequested() {
            return !this.isRootManager && !!this.videoContext.rstartDate;
        },
    },

    watch: {
        participants: {
            handler: function () {
                this.checkedAllParts = !!this.participants.find(p => p.status);
            },
            deep: true,
        },
    },

    async created() {
        await this.$awn.async(get('/topic/index-question-section', {
            event_id: this.eventId,
            topic_id: this.topicId,
        }), response => {
            this.sectionsData = response.data?.sections || [];  // Сохраняем данные в sectionsData
        });
        await this.$store.dispatch('disposerTopics/getParticipants', { id: this.eventId });
        if (this.topicId) {
            this.getEditedTopic();
        } else {
            this.$store.dispatch('disposerTopics/clearEditedTopic');
        }
        const platformInfo = await this.getPlatformList();
        if (platformInfo.platforms) {
            this.platforms = Object.keys(platformInfo.platforms).map((key) => platformInfo.platforms[key]);
        }
    },

    methods: {
        addSection() {
            this.showAddSection = true;
            this.sectionData = {
                title: '',
                is_hidden: 0,
                questions: [],
            };
        },
        toggleQuestion(id) {
            const index = this.sectionData.questions.indexOf(id);
            if (index === -1) {
                this.sectionData.questions.push(id);  // Добавляем, если нет
            } else {
                this.sectionData.questions.splice(index, 1);  // Удаляем, если есть
            }
        },
        changeStatus(question) {
            question.status = +!question.status;
        },
        changeAllStatus(section) {
            const newStatus = section.questions.some(question => question.status !== 1) ? 1 : 0;
            section.questions.forEach((question) => question.status = newStatus);
        },
        async createSection() {
            await this.$awn.async(post('topic/create-question-section', {
                topic_id: this.topicId,
                event_id: this.eventId,
                title: this.sectionData.title,
                is_hidden: +this.sectionData.is_hidden,
                questions: this.sectionData.questions,
            }), response => {
                if (response.status) {
                    this.$awn.success(copyTopic);
                    window.location.reload();
                }
            });
        },
        async updateSection(index) {
            await this.$awn.async(post('/topic/update-question-section', {
                topic_id: this.topicId,
                event_id: this.eventId,
                title: this.sectionsData[index].title,
                section_id: this.sectionsData[index].id,
                is_hidden: +this.sectionsData[index].is_hidden,
                questions: this.sectionsData[index].questions
                    .filter(item => item.status) // фильтруем по статусу
                    .map(item => item.id), // создаем массив только с id,
            }), response => {
                if (response.status) {
                    this.$awn.success(copyTopic);
                    window.location.reload();
                }
            });
        },
        async loadMoreParticipant() {
            this.countParticipant += 10;
            await this.$store.dispatch('disposerTopics/getTopic', {
                topicId: this.topicId,
                eventId: this.eventId,
                size: this.countParticipant,
            });
        },
        async getPlatformList() {
            const platformInfo = await get(PLATFORM_LIST, { withEvents: 1 });
            if (platformInfo.status) {
                return platformInfo.data;
            }
        },

        async copyTopic() {
            const data = {
                platform_id: this.platformCopyTo.id,
                event_id: this.eventCopyTo.id,
                topic_id: this.topicId,
            };
            this.$awn.async(post(TOPIC_COPY, data), response => {
                if (response.status) {
                    this.$awn.success(copyTopic);
                }
            });
        },

        generateFileUrl(file) {
            return generateFileUrl(file);
        },
        showModal(v) {
            this.isModalVisible = !this.isModalVisible;
            if (v === 'intro') {
                this.editorData = this.topic.introText;
                this.isIntroTextModal = true;
            }
            if (v === 'intro_after') {
                this.editorData = this.topic.introTextAfter;
                this.isIntroTextAfterModal = true;
            }
            if (v === 'description') {
                this.editorData = this.topic.description;
                this.isDescriptionModal = true;
            }
        },
        closeModal() {
            this.isModalVisible = !this.isModalVisible;
            this.isIntroTextModal = false;
            this.isIntroTextAfterModal = false;
            this.isDescriptionModal = false;
        },
        toggleSelects() {
            const state = this.$refs.switchParticipantsSelects.checked;
            this.participants = this.participants.map((s) => {
                s.status = state;
                return s;
            });
            this.updateParticipant(+state);
        },
        saveEditorData() {
            this.isModalVisible = !this.isModalVisible;
            if (this.isIntroTextModal) {
                this.topic.introText = this.editorData;
                this.isIntroTextModal = false;
            }
            if (this.isIntroTextAfterModal) {
                this.topic.introTextAfter = this.editorData;
                this.isIntroTextAfterModal = false;
            }
            if (this.isDescriptionModal) {
                this.topic.description = this.editorData;
                this.isDescriptionModal = false;
            }
        },

        addSurvey() {
            this.surveys.push({
                id: Math.random(),
            });
        },
        deleteSurvey(id) {
            this.surveys = this.surveys.filter(s => s.id !== id);
        },
        async saveTopic() {
            if (this.$v.topic.$invalid) {
                this.$v.topic.$touch();
                return;
            }
            const selectedParticipants = [];
            this.$refs.participantsSelects.elements.forEach((s) => {
                s.checked ? selectedParticipants.push({
                    user_id: +s.getAttribute('data-id'),
                    status: 1,
                }) : null;
            });
            const createTopicObj = {
                id: this.topic.id,
                event_id: this.eventId,
                name: this.topic.name,
                intro: this.topic.introText,
                intro_after: this.topic.introTextAfter,
                start_date: uniteTime(this.topic?.startDate, this.topic?.startTime),
                end_date: uniteTime(this.topic?.endDate, this.topic?.endTime),
                description: this.topic.description,
                format: this.topic.format.id,
                translating: 1,
                participants: selectedParticipants,
                hide_tool: +this.topic.hideTool,
            };
            this.topic.formatDiscussion ? createTopicObj.format_discussion = +this.topic.formatDiscussion.id : null;
            this.$awn.async(post(this.topic.id >= 1 ? TOPIC_UPDATE : TOPIC_CREATE, createTopicObj), response => {
                if (response.status) {
                    if (this.topicId) {
                        this.topic = {};
                        this.getEditedTopic();
                    } else {
                        this.$router.push({
                            name: 'edit-topic',
                            params: { topicId: response.data.id },
                        }, () => {
                            this.getEditedTopic();
                        });
                    }
                    this.topic.id = response.data.id;
                    this.topicErrors = {};
                    this.$awn.success(this.topic.id >= 1 ? createdTopic : updatedTopic);
                } else {
                    this.topicErrors = response.errors;
                }
            });
        },
        updateParticipant(participant) {
            let user_id = participant.id;
            let status = +participant.status;
            if (participant === 0 || participant === 1) {
                user_id = 0;
                status = participant;
            }
            this.$awn.async(post(UPDATE_PARTICIPANT, {
                topic_id: this.topicId,
                user_id,
                status,
            }), (response) => {
                if (response.status) {
                    this.$awn.success('Updated');
                }
            });
        },
        async updateVideoCallLink() {
            if (this.$v.videoCallLink.$invalid) {
                this.$v.videoCallLink.$touch();
                return;
            }
            this.$awn.async(post(TOPIC_UPDATE_VIDEO_CALL, {
                videocall: this.videoCallLink,
                topic_id: this.topicId,
                video_target: +this.openOnPlatform,
            }), response => {
                if (response.status) {
                    this.$awn.success(saveVideoCallLink);
                }
            });
        },
        async getEditedTopic() {
            await this.$store.dispatch('disposerTopics/getTopic', {
                topicId: this.topicId,
                eventId: this.eventId,
            });
            this.topic = {
                id: this.editedTopic.id,
                name: this.editedTopic.name,
                introText: this.editedTopic.intro,
                introTextAfter: this.editedTopic.intro_after,
                startDate: new Date(this.editedTopic.start_date),
                startTime: new Date(this.editedTopic.start_date),
                endDate: new Date(this.editedTopic.end_date),
                endTime: new Date(this.editedTopic.end_date),
                description: this.editedTopic.description,
                hideTool: this.editedTopic.hide_tool,
                format: this.$options.selectOptions.topicFormat.find(f => f.id === this.editedTopic.format),
                formatDiscussion: this.$options.selectOptions.topicFormatNext.find(f => f.id === this.editedTopic.format_discussion),
            };
            this.videoCallLink = this.editedTopic.videocall;
            this.openOnPlatform = this.editedTopic.video_target;
            this.surveys = this.editedTopic.surveys;
            this.mainDocument.name = this.editedTopic.file_name;
            this.mainDocument.file = this.editedTopic.file;
            this.mainDocument.watermarks = this.editedTopic.watermark;
            this.mainDocument.secured = this.editedTopic.secured;

            if (this.editedTopic.video_main_start) {
                this.videoMain.rstartDate = new Date(this.editedTopic.video_main_start);
                this.videoMain.startDate = new Date(this.editedTopic.video_main_start);
                this.videoMain.startTime = new Date(this.editedTopic.video_main_start);
                this.videoMain.endDate = new Date(this.editedTopic.video_main_end);
                this.videoMain.endTime = new Date(this.editedTopic.video_main_end);
                this.videoMain.link = this.editedTopic.video_main;
            }

            if (this.editedTopic.video_context_start) {
                this.videoContext.rstartDate = new Date(this.editedTopic.video_context_start);
                this.videoContext.startDate = new Date(this.editedTopic.video_context_start);
                this.videoContext.startTime = new Date(this.editedTopic.video_context_start);
                this.videoContext.endDate = new Date(this.editedTopic.video_context_end);
                this.videoContext.endTime = new Date(this.editedTopic.video_context_end);
                this.videoContext.link = this.editedTopic.video_context;
            }

            if (this.editedTopic.intro_content.videos) {
                this.videosIntro = this.editedTopic.intro_content.videos.map(v => {
                    return {
                        ...VIDEO_TEMPLATE(Math.random(), 2),
                        ...v,
                    };
                });
            }
            if (this.editedTopic.intro_content.documents) {
                this.documentsIntro = this.editedTopic.intro_content.documents;
            }
            if (this.editedTopic.intro_content.audios) {
                this.audiosIntro = this.editedTopic.intro_content.audios.map(v => {
                    return {
                        ...AUDIO_TEMPLATE(Math.random(), 2),
                        ...v,
                    };
                });
            }

            if (this.editedTopic.picture) {
                this.pictureSrc = this.generateFileUrl(this.editedTopic.picture);
            }

            if (this.editedTopic.documents) {
                this.documents = this.editedTopic.documents;
            }
            if (this.editedTopic.videos) {
                this.videos = this.editedTopic.videos.map(v => {
                    return {
                        ...VIDEO_TEMPLATE(Math.random()),
                        ...v,
                    };
                });
            }
            if (this.editedTopic.audios) {
                this.audios = this.editedTopic.audios.map(v => {
                    return {
                        ...AUDIO_TEMPLATE(Math.random()),
                        ...v,
                    };
                });
            }
        },

        async sendVideoRequest(type, isUpdate) {
            let workshop = 'videoMain';
            if (type === 2) {
                workshop = 'videoContext';
            }
            if (this.$v[workshop].$invalid) {
                this.$v[workshop].$touch();
                return;
            }
            const requestObj = {
                event_id: this.eventId,
                topic_id: this.topicId,
                start_date: uniteTime(this[workshop]?.startDate, this[workshop]?.startTime),
                end_date: uniteTime(this[workshop]?.endDate, this[workshop]?.endTime),
                type: type,
            };

            let url = VIDEO_REQUEST;

            if (isUpdate) {
                url = UPDATE_VIDEO_REQUEST;
                requestObj.link = this[workshop]?.link;
            }

            this.$awn.async(post(url, requestObj), response => {
                if (response.status) {
                    this[workshop].rstartDate = requestObj.start_date;
                    if (type === 1) {
                        this.videoMain.link = requestObj.link;
                    } else if (type === 2) {
                        this.videoContext.link = requestObj.link;
                    }
                    this.$awn.success(sendVideoRequest);
                }
            });
        },

        deleteVideoRequest(type) {
            const requestObj = {
                event_id: this.eventId,
                topic_id: this.topicId,
                type: type,
            };
            this.$awn.async(post(REMOVE_VIDEO_REQUEST, requestObj), response => {
                if (response.status) {
                    if (type === 1) {
                        this.videoMain = {
                            rstartDate: '',
                            startDate: '',
                            startTime: '',
                            endDate: '',
                            endTime: '',
                            link: '',
                        };
                    } else if (type === 2) {
                        this.videoContext = {
                            rstartDate: '',
                            startDate: '',
                            startTime: '',
                            endDate: '',
                            endTime: '',
                            link: '',
                        };
                    }
                    this.$awn.success(deleteVideoRequest);
                }
            });
        },

        loadMainDocument(document) {
            this.mainDocument.name = document.name;
            this.mainDocument.file = document;
        },
        async saveMainDocument() {
            const fd = new FormData();
            fd.append('topic_id', this.topicId);
            fd.append('watermark', +this.mainDocument.watermarks || 0);
            fd.append('secured', +this.mainDocument.secured || 0);
            if (typeof this.mainDocument.file !== 'string') {
                fd.append('file', this.mainDocument.file);
            }

            this.$awn.async(post(UPLOAD_MAIN_DOCUMENT, fd, true), saveDocument);
        },
        async deleteMainDocument() {
            this.$awn.async(post(DELETE_MAIN_DOCUMENT, {
                topic_id: this.topicId,
                event_id: this.eventId,
            }), response => {
                if (response.status) {
                    this.mainDocument = {
                        secured: false,
                        watermarks: false,
                        name: '',
                        file: null,
                    };
                    this.$awn.success(deletedDocument);
                }
            });
        },

        async loadPicture(img) {
            this.picture = img;
            this.pictureSrc = await parseImg(img);
        },
        uploadPicture() {
            const fd = new FormData;
            fd.append('topic_id', this.topicId);
            fd.append('picture', this.picture);
            this.$awn.async(post(UPDATE_PICTURE, fd, true), response => {
                if (response.status) {
                    this.$awn.success(imageLoaded);
                }
            });
        },
        async deletePicture() {
            this.$awn.async(post(DELETE_PICTURE, {
                topic_id: this.topicId,
            }), response => {
                if (response.status) {
                    this.pictureSrc = '';
                    this.picture = null;
                    this.$awn.success(imageDeleted);
                }
            });
        },

        addDocument() {
            this.documents.push(DOCUMENT_TEMPLATE(Math.random()));
        },
        deleteDocument(id) {
            this.documents = this.documents.filter(d => d.id !== id);
        },
        addVideo() {
            this.videos.push(VIDEO_TEMPLATE(Math.random()));
        },
        deleteVideo(id) {
            this.videos = this.videos.filter(d => d.id !== id);
        },
        addAudio() {
            this.audios.push(VIDEO_TEMPLATE(Math.random()));
        },
        deleteAudios(id) {
            this.audios = this.audios.filter(d => d.id !== id);
        },

        addIntroDocument() {
            this.documentsIntro.push(DOCUMENT_TEMPLATE(Math.random(), 2));
        },
        deleteIntroDocument(id) {
            this.documentsIntro = this.documentsIntro.filter(d => d.id !== id);
        },
        addIntroVideo() {
            this.videosIntro.push(VIDEO_TEMPLATE(Math.random(), 2));
        },
        deleteIntroVideo(id) {
            this.videosIntro = this.videosIntro.filter(d => d.id !== id);
        },
        addIntroAudio() {
            this.audiosIntro.push(VIDEO_TEMPLATE(Math.random(), 2));
        },
        deleteIntroAudios(id) {
            this.audiosIntro = this.audiosIntro.filter(d => d.id !== id);
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-edit-event-topic {
    .disabled {
        opacity: 0.5;

        pointer-events: none;
    }

    .row p {
        padding-top: 5px;
        padding-left: 10px;

        font-size: 14px;
        color: red;
    }

    .header {
        .title-back {
            display: flex;
            justify-content: space-between;

            width: to_rem(450px);

            .app-button {
                padding: 15px;
            }
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
    }

    .main {
        .row-name-text {
            width: 100%;

            .topic-name {
                width: to_rem(260px);
            }

            .introductory-text {
                width: to_rem(530px);
            }

            .copy-topic {
                width: to_rem(320px);

                .disposer-app-label {
                    margin-bottom: .5rem;
                }

                .app-button {
                    height: 30px;
                }

                .copy-label {
                    font-size: .7rem;
                }
            }
        }

        .row-description {
            .description {
                display: flex;
            }

            .disposer-app-input {
                width: to_rem(740px);
            }

            .info-icons {
                margin-top: to_rem(45px);
                margin-left: to_rem(10px);
            }
        }

        .app-select {
            margin-bottom: to_rem(10px);
        }
    }

    .choose-topic-format {
        position: relative;
    }

    .columns {
        display: flex;
        flex-wrap: wrap;

        width: to_rem(390px);
        margin: to_rem(20px) 0;

        label {
            margin-right: to_rem(10px);
            margin-bottom: to_rem(10px);
        }
    }

    .video-call-section {
        .video-link {
            width: to_rem(740px);
        }

        .disposer-app-checkbox {
            margin-top: 20px;
        }
    }

    .workshop-session, .common-session {
        &[data-disabled] {
            opacity: 0.7;

            pointer-events: none;
        }
    }

    .workshop-and-document-section {
        .upload-current-file {
            padding: 0;

            color: grey;
        }

        .workshop-upload-document {
            .flex {
                display: flex;
                justify-content: space-between;

                width: to_rem(550px);
                padding: to_rem(20px) 0;
            }
        }

        .link-button {
            display: flex;
            align-items: flex-end;

            & > div {
                margin-right: 2rem;
            }

            .link {
                width: to_rem(375px);
            }

            .app-button {
                height: to_rem(35px);
            }

            .disposer-app-label {
                margin-bottom: 0;
            }
        }
    }

    .discussion-forum-section {
        .add-polling-btn {
            margin-top: to_rem(20px);
        }
    }

    .moreSettings {
        opacity: 0.5;

        pointer-events: none;

        &.active {
            opacity: 1;

            pointer-events: all;
        }
    }

    .logo-img {
        display: block;

        width: 27.5rem;
        margin-bottom: 1rem;

        border: 1px solid $gray;
    }

    .footer {
        padding: to_rem(100px) 0 to_rem(20px);
    }

    .upload-btns {
        justify-content: flex-start;
    }

    .upload-video-btn {
        position: relative;

        .name {
            position: absolute;
            top: 120%;
            left: 0;

            width: 250px;

            color: black;
        }
    }
}

.sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sectionQuestions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section {
    margin-bottom: 2rem;
}
</style>
