<template>
    <div class="error404">
        Page not found
    </div>
</template>

<script>
    export default {
        name: 'error404',
    };
</script>

<style scoped lang="scss">
.error404 {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;

    font-size: to_rem(50px);

    background: $lightGray;
}
</style>
