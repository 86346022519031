<template>
    <div class="app-videos-gallery">
        <div
            v-for="video of videosArr"
            :key="video.id"
            class="video-wrapper"
        >
            <div class="title">
                <YouTubeIcon />
                {{ video.name }}
            </div>
            <div
                class="video"
                @click.stop="openVideo(video)"
            >
                <video
                    ref="videoElements"
                    :src="generateFileUrl(video.file)"
                    controls
                    class="video-with-subtitles"
                    crossorigin="use-credentials"
                    @click.prevent
                >
                    <track
                        v-for="subtitle in video.subtitles"
                        :key="subtitle.id"
                        kind="subtitles"
                        :src="generateUrl(subtitle.file)"
                        :srclang="subtitle.lang"
                        :label="getSubtitleTile(subtitle.lang)"
                        :default="subtitle.default || false"
                    />
                </video>
                <img
                    v-if="video.preview"
                    class="preview"
                    :src="generateFileUrl(video.preview)"
                />
                <div class="play">
                    <YouTubeIcon />
                </div>
            </div>
            <p v-if="video.description">
                {{ video.description }}
            </p>
            <p v-if="video.duration">
                Длительность видео: {{ video.duration }} секунд
            </p>
        </div>
        <app-popup
            name="appVideoPopup"
            @close="closePopup"
        >
            <app-video-popup
                @playVideo="startTimer"
                @pauseVideo="stopTimer"
                @endedVideo="stopTimer"
            />
        </app-popup>
    </div>
</template>

<script>
import YouTubeIcon from '@/assets/img/icons/youTube_icon.svg?inline';
import { generateFileUrl, generateUrlSubtitle } from '@/utils/http-helper';
import { sendClick } from '@/utils/user/clicks-analytic';
import appPopup from '@/components/user/app-popup';
import appVideoPopup from '@/components/user/app/app-video-popup';
import languages from '@/assets/js/languages';

export default {
    name: 'app-videos-gallery',

    components: {
        YouTubeIcon,
        appPopup,
        appVideoPopup,
    },

    props: {
        videos: {
            type: Array,
            default: null,
        },
    },

    data: () => ({
        videosArr: [],
        activeVideo: null,
        viewVideoDuration: null,
        startTime: null,
    }),

    computed: {
        topicId() {
            if (this.$route.name === 'dashboard') {
                return this.$store.getters['dashboard/topicId'];
            } else {
                return null;
            }
        },
        partId() {
            if (this.$route.name === 'dashboard') {
                return 5;
            } else if (this.$route.name === 'e-library') {
                return 3;
            } else if (this.$route.name === 'company') {
                return 1;
            } else {
                return null;
            }
        },
    },

    mounted() {
        this.videosArr = this.videos?.map((video) => {
            video.played = false;
            video.duration = null;
            return video;
        });
        this.setActiveVideo();
    },

    methods: {
        setActiveVideo() {
            const activeVideoId = sessionStorage.getItem('activeVideo');
            if (activeVideoId) {
                const activeVideo = this.videos.find(video => video.id === parseInt(activeVideoId));
                if (activeVideo) {
                    this.activeVideo = activeVideo;
                }
            }
        },
        getSubtitleTile(lang) {
            return languages.find((item) => item.value === lang).label;
        },
        generateFileUrl(file) {
            return generateFileUrl(file);
        },
        generateUrl(file) {
            return generateUrlSubtitle(file);
        },

        sendAnalytic(type, id) {
            sendClick(this.partId, type, id, this.topicId);
        },

        openVideo(video) {
            this.activeVideo = video;
            this.getVideoDuration(video);
            sessionStorage.setItem('activeVideo', video.id);
            this.$store.dispatch('togglePopup', {
                popupName: 'appVideoPopup',
                options: { video },
            });
            this.sendAnalytic(2, video.id);
        },

        getVideoDuration(video) {
            const videoElements = this.$refs.videoElements; // Массив элементов видео

            if (Array.isArray(videoElements)) {
                const videoElement = videoElements.find(v => v.src === generateFileUrl(video.file)); // Проверка src
                if (videoElement) {
                    const duration = Math.floor(videoElement.duration);
                    this.viewVideoDuration = duration;
                    video.duration = duration;
                    sessionStorage.setItem('viewVideoDuration', duration.toString());
                } else {
                    console.warn('Video element not found for video:', video);
                }
            } else {
                console.warn('videoElements is not an array or is undefined');
            }
        },

        startTimer(video) {
            this.activeVideo = video;
            this.startTime = new Date();
            sessionStorage.setItem(`startTime_${video.id}`, this.startTime.toISOString());
            video.played = true;
        },

        stopTimer(video, isEnded = false, closeVideo = false) {
            const startTimeStr = sessionStorage.getItem(`startTime_${video.id}`);
            this.activeVideo = video;
            if (startTimeStr) {
                const startTime = new Date(startTimeStr);
                const endTime = new Date();
                const timeSpent = Math.floor((endTime - startTime) / 1000);

                let totalWatchTime = sessionStorage.getItem(`watchTime_${video.id}`);
                totalWatchTime = totalWatchTime ? parseInt(totalWatchTime) : 0;
                totalWatchTime += timeSpent;
                sessionStorage.setItem(`watchTime_${video.id}`, totalWatchTime.toString());

                if (closeVideo || isEnded) {
                    sendClick(this.partId, 2, video.id, this.topicId, parseInt(sessionStorage.getItem('viewVideoDuration')), totalWatchTime);
                    sessionStorage.removeItem(`viewVideoDuration`); // Удаляем длину видео
                }

                this.startTime = null;
                sessionStorage.removeItem(`startTime_${video.id}`);
            }
        },

        closePopup() {
            this.setActiveVideo();
            if (this.activeVideo) {
                this.stopTimer(this.activeVideo, false, true);
                this.activeVideo.played = false;
                sessionStorage.removeItem(`watchTime_${this.activeVideo.id}`);
            }
            sessionStorage.removeItem('activeVideo');
            this.activeVideo = null;
        },
    },
};
</script>


<style scoped lang="scss">
.video-with-subtitles::cue {
    background: rgba(255, 255, 255, 0.75); /* Полупрозрачная белая подложка */
    color: black; /* Черный цвет текста */
    padding: 2px 4px; /* Отступы вокруг текста */
    border-radius: 4px; /* Сглаженные углы */
}

.app-videos-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 1rem 1.75rem;

    font-size: to_rem(16px);
    color: $copyGray;

    background: white;

    &.column {
        flex-direction: column;

        padding: 0.5rem;

        .video-wrapper {
            width: 100%;
        }
    }

    &.single {
        padding: 0.5rem 0 0;

        .video-wrapper {
            width: 100%;
        }

        .title {
            display: none;
        }
    }

    .video-wrapper {
        width: 49%;

        &:not(:last-of-type) {
            margin-bottom: 1.4rem;
        }
    }

    img, video {
        display: block;

        width: 100%;
    }

    .video {
        position: relative;

        height: 22rem;

        cursor: pointer;
    }

    video {
        position: relative;
        z-index: 0;

        height: 100%;
        object-fit: contain;

        background: black;
    }

    .preview {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;

        height: 100%;

        object-fit: contain;

        background: black;
    }

    .play {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;

        width: 5.5rem;

        transform: translate(-50%, -50%);
    }

    .title {
        display: flex;
        align-items: center;

        margin-bottom: 1.3rem;

        color: var(--platform-accent-color);

        svg {
            width: 2rem;
            margin-right: 0.4rem;
        }
    }

    svg {
        * {
            fill: var(--platform-accent-color);
        }
    }

    p {
        margin-top: 1.1rem;
        padding-left: 0.2rem;
    }
}

@include mobile_or_P {
    .app-videos-gallery {
        padding: 1.85rem 1.3rem;

        p {
            margin-top: 0.6rem;

            line-height: 1.25;
        }

        .video {
            margin-bottom: 1rem;

            & > img {
                width: 100%;
            }
        }
    }
}

@include razr767 {
    .app-videos-gallery {
        flex-direction: column;

        padding: 1.45rem 1.9rem;

        .video-wrapper {
            width: 100%;
        }

        .video {
            width: 100%;
            max-height: 13rem;
            margin-bottom: 1.5rem;
            object-fit: contain;

            video {
                max-height: 13rem;
            }
        }

        .title {
            font-size: 14px;

            img {
                width: 2.8rem;
                margin-right: 0.5rem;
            }
        }

        p {
            margin-top: 0.4rem;
            padding-left: 0;

            font-size: 12px;
            line-height: 1.2;
        }
    }
}
</style>
