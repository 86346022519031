<template>
    <div class="app-calendar">
        <Calendar
            :masks="masks"
            :attributes="days"
            is-expanded
            :locale="locale"
            :first-day-of-week="2"
            class="calendar-bord"
            @update:to-page="updateDate"
        >
            <template v-slot:day-content="{ day, attributes }">
                <div
                    class="day-wrapper overflow-hidden"
                    @click="openAllEvents(day, attributes[0].customData.all)"
                    @mouseover="toggleAllEvents($event, attributes[0].customData.all, true)"
                    @mouseleave="toggleAllEvents($event, null, false)"
                >
                    <div class="day-content flex-grow overflow-y-auto overflow-x-auto">
                        <div class="day-label text-sm text-gray-900">
                            {{ day.day }}
                        </div>
                        <template v-if="calendar && attributes && attributes[0]">
                            <p
                                v-for="event in attributes[0].customData.calendar"
                                :key="event.id"
                            >
                                <format-icons
                                    class="min"
                                    :format="event.format"
                                    :is-conf="event.conf"
                                />
                                {{ event.time }}
                            </p>
                        </template>
                    </div>
                </div>
            </template>
        </Calendar>
        <template v-if="isMobile">
            <div class="app-topic-title">
                {{ activeDate }}
            </div>
            <div class="events-schedule">
                <div
                    v-for="event of activeEvents"
                    :key="event.id"
                    class="events-schedule__event"
                >
                    <format-icons
                        :format="event.format"
                        :is-conf="event.conf"
                    />
                    <div class="right">
                        <router-link
                            :to="{name: 'dashboard', query: {id: event.id}}"
                            class="name"
                        >
                            {{ event.name }}
                        </router-link>
                        <div class="time">
                            {{ event.time }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div
            v-if="allEventsList && !isMobile"
            class="all-events"
            :style="{top: `${listY}px`, left: `${listX}px`}"
        >
            <div
                v-for="event in allEventsList"
                :key="event.id"
            >
                <format-icons
                    class="min"
                    :format="event.format"
                    :is-conf="event.conf"
                />
                <p class="name">
                    {{ event.name }}
                </p>
                <p class="time">
                    {{ event.time }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import formatIcons from '@/components/user/pages/agenda/format-icons';
    import Calendar from 'v-calendar/lib/components/calendar.umd';
    import moment from 'moment';

    export default {
        name: 'app-calendar',
        components: {
            Calendar,
            formatIcons,
        },
        data() {
            return {
                selectMonth: null,
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                masks: {
                    weekdays: window.innerWidth<1024? 'WWW' : 'WWWW',
                    title: 'MMMM / YYYY',
                },
                activeDay: undefined,
                activeEvents: null,
                allEventsList: null,
                listX: 0,
                listY: 0,
            };
        },
        computed: {
            calendar() {
                return this.$store.getters.getAgendaCalendar;
            },
            days() {
              if (!this.calendar) {
                return ;
              }
              const days = [];
              Object.keys(this.calendar).forEach(index => {
                const dayInfo = this.calendar[index].slice(0, 4);
                const newDay = {
                  key: index,
                  dates: new Date(this.year, this.month, index),
                  customData: {
                    calendar: dayInfo,
                    all: this.calendar[index],
                  },
                };
                days.push(newDay);
              });
              return days;
            },
            activeDate() {
                return moment(this.activeDay).format('DD, dddd , MMMM / YYYY');
            },
            today() {
              return this.$store.getters.getToday;
            },
            isMobile() {
              return window.innerWidth < 1024;
            },
            eventId() {
                return this.$route.query.event_id;
            },
            locale() {
                return this.$store.getters['platform/platform'].lang;
            },
        },
        mounted() {
            if (!this.calendar) { return; }
            this.activeEvents = this.today.topics;

            window.addEventListener('scroll', this.toggleAllEvents.bind(this, null, null, false));
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.toggleAllEvents);
        },
        methods: {
          openAllEvents(day, events) {
            this.activeDay = `${day.month}/${day.day}/${day.year}`;
            this.activeEvents = events;
          },
          getCalendar() {
            this.$store.dispatch('getCalendar');
          },
          toggleAllEvents(e, events, status) {
            if (!events?.length) {
              this.allEventsList = null;
              return;
            }
            this.listX = e.clientX;
            this.listY = e.clientY;
            status ? this.allEventsList = events : this.allEventsList = null;
          },
          updateDate(e) {
            this.year = new Date(moment(`${e.month}-${e.year}`, 'MM-YYYY')).getFullYear();
            this.month = new Date(moment(`${e.month}-${e.year}`, 'MM-YYYY')).getMonth();
            const period = moment(`${e.month}-${e.year}`, 'MM-YYYY').format('YYYY-MM');
            this.$store.dispatch('getPeriod', { eventId: this.eventId, period });
          },
        },
    };
</script>

<style scoped lang="scss">
    .app-calendar {
        .vc-container {
            border-radius: 0;
            box-shadow: none;
        }
    }
    .calendar-bord{
        margin-bottom: 1.5rem;
    }
    .all-events {
        position: fixed;
        z-index: 10000;

        width: 20rem;
        padding: 1rem;

        background: white;
        border: 1px solid $gray;
        border-radius: 0.5rem;
        div {
            display: flex;

            margin-bottom: 0.5rem;
            padding-bottom: 0.3rem;

            border-bottom: 1px solid $lightGray;

            .name {
                width: 11rem;
            }

            .time {
                width: 5rem;
                margin-left: auto;
            }
        }
    }
    .vc-container.vc-is-expanded {
        width: 100%;

        background: transparent;
        border: none;

        &::v-deep {
            .vc-header {
                margin-bottom: 2.2rem;
                padding: 0;
            }

            .vc-arrows-container {
                padding: 0;
            }

            .vc-title {
                font-size: to_rem(40px);
                font-weight: 400;
                text-transform: uppercase;
            }

            .vc-svg-icon {
                display: none;
            }

            .vc-arrow {
                width: 1.1rem;
                height: 1.5rem;

                background: url("~@/assets/img/icons/arrow-right_icon.svg") no-repeat center center/contain;
                border: none;

                &.is-left {
                    transform: rotate(180deg);
                }
            }

            .vc-weeks {
                grid-column-gap: .4rem;
                grid-row-gap: .4rem;
                grid-template-columns: repeat(7, 1fr);

                padding: 0 2px 0 0;
            }

            .vc-weekday {
                padding: 1.2rem 0;

                font-size: to_rem(21px);
                font-weight: 400;
                color: $mainTextColor;

                background: $gray;

                &:first-letter {
                    text-transform: uppercase;
                }

                &:nth-of-type(1) {
                    margin-left: 0;
                }

                &:nth-of-type(5) {
                    margin-right: 0;
                }

            }

            .vc-day.is-not-in-month * {
                opacity: 1;
            }

            .vc-day {
                height: 10.4rem;

                background: white;

            }

            .day-label {
                padding: 0 0.3rem;

                font-size: to_rem(24px);
            }

            .is-not-in-month {
                opacity: 0.6;
                .view {
                    display: none;
                }
            }

        }

        .day-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            height: 100%;

            border: 1px solid transparent;

            &:hover {
                border: 1px solid var(--platform-accent-color);
            }
        }

        .day-content {
            width: 100%;

            p {
                display: flex;
                justify-content: space-between;
                align-items: center;


                margin: 0 .5rem .2rem;
                padding: 0 .5rem .2rem;

                font-size: to_rem(12px);

                border-bottom: 2px solid $mainBackgroundColor;
            }
        }

        .view {
            margin-bottom: 0.5rem;

            font-size: to_rem(14px);
            color: $gray;

            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .events-schedule{
        background: white;
        &__event{
            position: relative;

            display: flex;

            margin-left: 2.3rem;
            &:not(:last-of-type){
                &:after{
                    content: '';
                    position: absolute;
                    top: 100%;
                    right: 1rem;
                    left: 0;
                    z-index: 1;

                    height: 2px;

                    background: $mainBackgroundColor;
                }
            }
        }
        .app-labels-imgs{
            min-width: 10rem;

            font-size: to_rem(33px);

            b{
                margin: 0 0.6rem;
            }

            img{
                width: 2rem;
                height: 2rem;
            }
        }
        .right{
            display: flex;
            justify-content: space-between;

            width: 100%;
        }
        .name{
            padding: 1.4rem 0;

            font-size: to_rem(24px);
            color: $mainPurple;
            font-style: italic;
        }
        .time{
            display: flex;
            justify-content: center;
            align-items: center;

            width: 17rem;
            margin-left: auto;

            font-size: to_rem(24px);

            background: $heavyLightGray;
        }
    }

    @include mobile_or_P{
        .vc-container.vc-is-expanded{
            padding: 0 .5rem;
            &::v-deep{
                .vc-header{
                    margin-bottom: 1.4rem;
                }
                .vc-weekday{
                    padding: 0.8rem 0;

                    font-size: 0.9rem;
                }
                .day-label{
                    font-size: 1rem;
                }
                .vc-day{
                    height: 7.3rem;
                }
                .day-content{
                    p{
                        padding: 0 0.4rem 0 0.4rem;

                        font-size: .6rem;
                    }
                    .app-labels-imgs{
                        font-size: 0.725rem;
                        img{
                            width: 0.7rem;
                            height: 0.8rem;
                        }
                    }
                }
            }
            .view{
                margin-bottom: 0.35rem;

                font-size: 0.675rem;
            }
        }
        .events-schedule{
            &__event{
                align-items: center;
            }
            .app-labels-imgs{
                min-width: 8.2rem;
                margin-left: 1.8rem;
                padding: 1.25rem 0;

            }
            .name{
                padding: 1.25rem 0;

                font-size: 1.25rem;
            }
            .time{
                display: flex;
                align-items: center;

                width: 14.9rem;
                min-height: 100%;
                padding: 0 2.5rem;

                font-size: 1.25rem;
            }
        }
    }
    @include razr767{
        .vc-container.vc-is-expanded{
            padding: 0 0.85rem;
            &::v-deep{
                .vc-title{
                    font-size: 1.53rem;
                }
                .vc-arrows-container{
                    top: 0.8%;
                    left: 50%;

                    width: 69%;

                    transform: translateX(-50%);
                }
                .vc-weeks{
                    grid-column-gap: 0.2rem;
                    grid-row-gap: 0.2rem;
                }
                .vc-weekday{
                    padding: 0.1rem 0;
                }
                .vc-header{
                    margin-bottom: 0.5rem;
                }
                .vc-day{
                    height: 2.5rem;
                }
                .day-content{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    height: 100%;
                    p{
                        display: none;
                    }
                }
                .is-today{
                    border: none;
                    .day-label{
                        position: relative;

                        font-size: 1.6rem;
                        &:before{
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            z-index: -1;

                            width: 2.5rem;
                            height: 2.5rem;

                            background: $lightYellow;
                            border-radius: 50%;

                            transform: translate(-50%, -50%);
                        }
                    }
                }
                .view{
                    display: none;
                }
            }
        }
        .events-schedule{
            &__event{
                padding: 1.4rem 0.5rem 1.4rem 0;
            }
            .right{
                flex-direction: column;
                align-items: flex-start;

                width: auto;
            }
            .app-labels-imgs{
                min-width: 3rem;
                margin-left: 0;
                padding: 0;
            }
            .name{
                margin-bottom: 0.1rem;
                padding: 0;

                font-size: 1.2rem;
            }
            .time{
                justify-content: flex-start;

                margin-left: 0;
                padding: 0;

                font-size: 1rem;
                font-style: italic;

                background: transparent;
            }
        }
    }

</style>
