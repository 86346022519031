import { get } from '@/utils/http-helper';

const GET_COMPANY = '/company/get';

export default {
  state: {
    company: null,
  },
  actions: {
    async getCompany({ commit }, eventId) {
      const company = await get(GET_COMPANY, { event_id: eventId });
      if (company.status) {
        commit('updateCompany', company.data);
      }
    },
  },
  mutations: {
    updateCompany(state, company) {
      state.company = company;
    },
  },
  getters: {
    getCompany: state => state.company,
  },
};
