<template>
    <div class="disposer-app-section-column">
        <div class="section-main">
            <slot></slot>
        </div>

        <div class="section-footer">
            <div class="section-btns">
                <app-button
                    class="section-btn red"
                    @click="save"
                >
                    Save
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
    import appButton from '@/components/disposer/app/app-button';

    export default {
        name: 'app-section-column',

        components: {
            appButton,
        },

        props: {
            save: {
                type: Function,
                default: ()=>{},
            },
        },
    };
</script>

<style scoper lang="scss">
    .disposer-app-section-column {
        margin-bottom: 10px;
        padding: 20px;

        background-color: #fdfcfc;

        .section-footer{
            display: flex;
            justify-content: flex-end;

            .section-btns{
                .section-btn {
                    width: to_rem(100px);
                    height: 35px;
                    margin-top: 20px;
                }
            }
        }
    }
</style>
