<template>
    <div class="login-layout">
        <slot></slot>
        <layout-footer />
    </div>
</template>

<script>
    import LayoutFooter from '@/components/user/layouts/footer';

    export default {
        name: 'login-layout',
        components: {
            LayoutFooter,
        },
    };
</script>

<style scoped lang="scss">

</style>
