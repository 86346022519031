<template>
    <div class="app-actions">
        <img
            v-if="newTabs"
            src="@/assets/img/icons/disposer/new-tab.png"
            title="Edit in new tab"
            @click="newTabs"
        />
        <img
            v-if="edit"
            src="@/assets/img/icons/disposer/edit_icon.png"
            title="Edit"
            @click="edit"
        />
        <img
            v-if="back"
            src="@/assets/img/icons/disposer/back_icon.png"
            title="Back"
            @click="back"
        />
        <img
            v-if="check"
            src="@/assets/img/icons/disposer/check_icon.svg"
            title="Save"
            @click="check"
        />
        <img
            v-if="save"
            src="@/assets/img/icons/disposer/save_icon.png"
            title="Save"
            @click="save"
        />
        <img
            v-if="trash"
            src="@/assets/img/icons/disposer/trash_icon.png"
            title="Delete"
            @click="deleteHandler"
        />
    </div>
</template>

<script>
    import { defaultConfirmSettings } from '@/utils/disposer/notifications-text-helper';

    export default {
        name: 'app-actions',

        props: {
            newTabs: {
                type: Function,
                default: null,
            },
            check: {
                type: Function,
                default: null,
            },
            edit: {
                type: Function,
                default: null,
            },
            back: {
                type: Function,
                default: null,
            },
            trash: {
                type: Function,
                default: null,
            },
            save: {
                type: Function,
                default: null,
            },
        },

        methods: {
            deleteHandler() {
                this.$confirm({
                  ...defaultConfirmSettings,
                  callback: confirm => {
                    if (confirm) {
                      this.trash();
                    }
                  },
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .app-actions{
        display: flex;
        align-items: center;
        img{
            display: block;

            width: 1rem;

            cursor: pointer;
            &:not(:last-of-type){
                margin-right: .5rem;
            }
        }
    }
</style>
