import { get } from '@/utils/http-helper';

const GET = '/participant/index';
const GET_COUNTRIES = '/country/index';
const GET_PARTICIPANT = '/participant/view';
const GET_SECTIONS = '/section-participant/index';
const GET_SECTIONS_COMPACT = '/section-participant/index-short';

export default {
    namespaced: true,
    state: {
        participants: null,
        editParticipant: null,
        countries: [],
        sections: [],
        sectionsCompact: [],
        scrollTo: 0,
    },
    actions: {
        async get({ commit }, { eventId, role, size = 0, page = 1, query = '' }) {
            const participants = await get(GET, { event_id: eventId, role, size, page, query });
            commit('updateParticipants', participants.data);
        },
        async getParticipant({ commit }, id) {
            const participant = await get(GET_PARTICIPANT, { id: id });
            commit('updateEditParticipant', participant.data);
        },
        async getCountries({ commit }) {
            const countries = await get(GET_COUNTRIES);
            commit('updateCountries', countries.data);
        },
        async getSections({ commit }, { eventId, type }) {
            const sections = await get(GET_SECTIONS, { event_id: eventId, type: type });
            commit('updateSections', sections.data);
        },
        async getSectionsCompact({ commit }, { eventId, type }) {
            const sectionsCompact = await get(GET_SECTIONS_COMPACT, {
                event_id: eventId,
                type: type,
            });
            commit('updateSectionsCompact', sectionsCompact.data);
        },
    },
    mutations: {
        updateParticipants(state, participants) {
            state.participants = participants;
        },
        updateEditParticipant(state, participant) {
            state.editParticipant = participant;
        },
        updateCountries(state, countries) {
            state.countries = countries.countries;
        },
        updateSections(state, sections) {
            state.sections = sections.sections;
        },
        updateSectionsCompact(state, sections) {
            state.sectionsCompact = sections.sections;
        },
        updateScrollTo(state, scroll) {
            state.scrollTo = scroll;
        },
    },
    getters: {
        get: state => state.participants,
        getCountries: state => state.countries,
        getEditParticipant: state => state.editParticipant,
        getSections: state => state.sections,
        getSectionsCompact: state => state.sectionsCompact,
        getUpdateScrollTo: state => state.scrollTo,
    },
};
