import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import routes from './routes';

import { changeTopicTimer, initTimeSocket } from '@/utils/user/time-socket';
import { initMessageSocket } from '@/utils/user/message-socket.js';
import { get } from '@/utils/http-helper';

Vue.use(VueRouter);

const GET_PLATFORM = '/platform/get-settings';

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from) {
        if (to.name !== from.name) {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach(async (to, from, next) => {
    if ((!localStorage.platform || to.params.platform !== localStorage.platform) && !(['%2F', '', '/', undefined].includes(to.params.platform))) {
        localStorage.platform = to.params.platform;
    }
    if (to.params.platform === '%2F' || to.params.platform === '/') {
        to.params.platform = localStorage.platform;
        next({ name: 'login', params: { platform: localStorage.platform } });
    }

    const pl = store.getters['platform/platform'];
    const domain = to.params.platform || localStorage.platform;
    let platform = {};
    if (to.name !== 'home') {
        platform = await get(GET_PLATFORM, { domain });
    }

    if (store.getters['platform/platform']?.name !== store.getters['platform/platformName'] && to.name !== 'error404') {
        if (domain) {
            if (!pl) {
                const platform = await store.dispatch('platform/getPlatform', domain);
                if (!platform.status) {
                    next({ name: 'error404' });
                    return;
                } else {
                    store.dispatch('platform/setPlatformName', domain);
                }
            }
        }
    }

    if (to.name === 'error404All' || !to.name) {
        let domain = to.fullPath.replaceAll('/', '');
        if (to.params.platform) {
            domain = to.params.platform;
        }
        if (!pl) {
            const platform = await store.dispatch('platform/getPlatform', domain);
            if (platform.status) {
                store.dispatch('platform/setPlatformName', domain);
                next({ name: 'login', params: { platform: domain } });
                return;
            } else {
                next({ name: 'errorPage' });
            }
        }
    }

    if (to.query.access_token) {
        const tokens = {
            access_token: to.query.access_token,
            refresh_token: to.query.refresh_token,
            platform: {
                platform: platform.data,
                platformName: domain,
            },
        };

        store.dispatch('auth/setTokens', tokens);
        router.replace({ name: to.name, query: {}, params: { platform: to.params.platform } });
    }

    if (!store.getters['auth/userInfo'] && store.getters['auth/accessToken'] && to.name !== 'error404') {
        const eventId = to.query.event_id;
        const user = await store.dispatch('auth/getUserInfo', { event_id: eventId });

        const accepted = user.data.accept;
        if (!accepted && to?.name !== 'login' && (user.data.role?.id === 5 || user.data.role?.id === 6)) {
            next({ name: 'login', params: { platform: store.getters['platform/platformName'] } });
            return;
        }
        initTimeSocket(user.data?.role?.id);
        if (user.data?.role?.id === 6 || user.data?.role?.id === 5) {
            initMessageSocket();
        }
        store.dispatch('auth/setPageLoadedStatus', true);
    } else {
        store.dispatch('auth/setPageLoadedStatus', true);
    }

    if (to.name !== 'dashboard') {
        changeTopicTimer(0);
    }

    if (to.meta.requiresAuthManager) {
        const role = store.getters['auth/userInfo']?.role?.id;
        if (store.getters['auth/accessToken'] && role === 3 || role === 1 || role === 2 || role === 4) {
            next();
        } else {
            next({ name: 'login', params: { platform: store.getters['platform/platformName'] } });
        }
    } else if (to.meta.requiresAuth) {
        if (store.getters['auth/accessToken']) {
            next();
        } else {
            next({ name: 'login', params: { platform: store.getters['platform/platformName'] } });
        }
    } else {
        next();
    }
});

export default router;
