<template>
    <div class="guidance">
        <!-- <div class="page-title">
            {{ $t('guidance', 'User Guidance') }}
        </div> -->
        <div
            v-if="guidance"
            class="row"
        >
            <router-link
                v-if="guidanceDocs.overview"
                :to="{ name:'view', query:{ type: 'overview', part: 'guidance' } }"
                class="card"
                target="_blank"
                @click.native="sendAnalytic(1 , 2)"
            >
                <div class="title">
                    {{ $t('guid_overview', 'Virtual Platform Overview') }}
                </div>
                <DisplayIcon />
                <p>
                    {{ $t('guid_view', 'view document, pdf') }}
                </p>
            </router-link>
            <router-link
                v-if="guidanceDocs.dashboard"
                :to="{ name:'view', query:{ type: 'dashboard', part: 'guidance' } }"
                class="card"
                target="_blank"
                @click.native="sendAnalytic(1 , 1)"
            >
                <div class="title">
                    {{ $t('guid_dashboard', 'Dashboard – Discussion Forum') }}
                </div>
                <DiscussionIcon />
                <p>
                    {{ $t('guid_view', 'view document, pdf') }}
                </p>
            </router-link>
            <router-link
                v-if="guidanceDocs.videocall"
                :to="{ name:'view', query:{ type: 'videocall', part: 'guidance' } }"
                class="card"
                target="_blank"
                @click.native="sendAnalytic(1 , 4)"
            >
                <div class="title">
                    {{ $t('guid_videocall', 'Video Call') }}
                </div>
                <VideoCallIcon />
                <p>
                    {{ $t('guid_view', 'view document, pdf') }}
                </p>
            </router-link>
            <router-link
                v-if="guidanceDocs.review"
                :to="{ name:'view', query:{ type: 'review', part: 'guidance' } }"
                class="card"
                target="_blank"
                @click.native="sendAnalytic(1 , 3)"
            >
                <div class="title">
                    {{ $t('guid_review', 'Document Review') }}
                </div>
                <DocumentReviewIcon />
                <p>
                    {{ $t('guid_view', 'view document, pdf') }}
                </p>
            </router-link>
        </div>
        <guidance-videos
            v-if="guidanceVideos"
            :guidance-videos="guidanceVideos"
            @openVideo="openVideo"
        />
        <app-popup
            v-if="guidanceVideos"
            name="appVideoPopup"
        >
            <app-video-popup />
        </app-popup>
    </div>
</template>

<script>
import DisplayIcon from '@/assets/img/icons/display_icon.svg?inline';
import DiscussionIcon from '@/assets/img/icons/discussion_icon.svg?inline';
import VideoCallIcon from '@/assets/img/icons/video-call_icon.svg?inline';
import DocumentReviewIcon from '@/assets/img/icons/document-review_icon.svg?inline';

import appPopup from '@/components/user/app-popup';
import appVideoPopup from '@/components/user/app/app-video-popup';
import guidanceVideos from './guidanceVideos';

import { sendClick } from '@/utils/user/clicks-analytic';

export default {
    name: 'guidance',

    components: {
        DisplayIcon,
        DiscussionIcon,
        VideoCallIcon,
        DocumentReviewIcon,
        appPopup,
        appVideoPopup,
        guidanceVideos,
    },

    computed: {
        guidance() {
            return this.$store.getters.getGuidance;
        },

        guidanceDocs() {
            return this.guidance.docs;
        },

        guidanceVideos() {
            return this.guidance?.videos;
        },
    },

    created() {
        !this.guidance ? this.$store.dispatch('getGuidance') : null;
    },

    methods: {
        sendAnalytic(type, id) {
            sendClick(4, type, id);
        },

        openVideo(video) {
            this.$store.dispatch('togglePopup', {
                popupName: 'appVideoPopup',
                options: { video },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.row::v-deep {
    display: flex;
    flex-wrap: wrap;

    width: 40rem;
    margin: 2rem 1.7rem;

    .title {
        align-self: flex-start;

        width: 90%;
        margin-bottom: 1.8rem;

        font-size: to_rem(20px);
        text-align: center;
        color: var(--platform-accent-color);
        font-style: italic;
    }
}

.card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;

    width: 13rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 0.5rem 0;

    text-decoration: none;

    border: 1px solid $gray;

    &:hover {
        svg {
            transform: scale(1.1);
        }
    }

    svg {
        height: 3.8rem;
        margin-bottom: 1.9rem;

        transition: transform .3s;

        .cls-1 {
            stroke: var(--platform-main-color);
        }
        .cls-1, .st1, .cls-3 {
            fill: var(--platform-main-color);
        }
        .cls-2 {
            fill: white;
        }
    }

    p {
        width: 100%;

        font-size: to_rem(14px);
        text-align: center;
        color: #000;
    }
}

@include mobile_or_P {
    .row::v-deep {
        justify-content: center;

        margin: 1.5rem 2rem 2.1rem;

        .card {
            margin: 0 1rem 2rem;
        }
    }
}

@include razr767 {
    .row::v-deep {
        flex-direction: column;
        align-items: center;

        width: 100%;
        margin: 0;

        .card {
            width: 205px;
            margin: 0 0 2.8rem 0;
            padding: 0.6rem 0;

            &:hover {
                img {
                    transform: none;
                }
            }

            svg {
                height: 5rem;
                margin-bottom: 2.5rem;
            }

            p {
                font-size: 14px;
            }
        }

        .title {
            width: 100%;
            margin-bottom: 2.7rem;

            font-size: 23px;
        }
    }
}

</style>
