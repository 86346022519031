import { get } from '@/utils/http-helper';

const GET_LIBRARY = '/library/get';

export default {
  state: {
    library: null,
  },
  actions: {
    async getLibrary({ commit }, data) {
      const library = await get(GET_LIBRARY, { ...data });

      if (library.status) {
        commit('updateLibrary', library.data);
      }
    },
  },
  mutations: {
    updateLibrary(state, library) {
      state.library = library;
    },
  },
  getters: {
    getLibrary: state => state.library,
  },
};
