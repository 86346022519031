<template>
    <div class="disposer-app-modal">
        <div class="header">
            <div class="edit-text disposer-app-topic-title">
                Edit Text
            </div>
            <div
                class="close-btn"
                @click="closeModal"
            >
                &times;
            </div>
        </div>
        <div class="main">
            <slot></slot>
        </div>
        <hr />
        <div class="footer">
            <div class="save-btn">
                <app-button
                    class="red"
                    @click="saveHandler"
                >
                    Save
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
    import appButton from '@/components/disposer/app/app-button';

    export default {
        name: 'disposer-app-modal',

        components: {
            appButton,
        },

        methods: {
            closeModal() {
                this.$emit('closeModal');
            },
            saveHandler() {
                this.$emit('saveHandler');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .disposer-app-modal {
        position: fixed;
        top: 100px;
        z-index: 10;

        display: flex;
        flex-direction: column;

        width: auto;
        height: auto;
        margin: 0 auto;

        text-align: center;

        background-color: white;
        box-shadow: 0 0 30px rgb(151, 148, 148, 10);

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 100%;
            padding: 5px;
            .edit-text {
                padding: 10px;

                font-size: 1.5rem;
                color: $mainDarkBlue;
            }
            .close-btn {
                font-size: 2rem;
                color: $gray;

                &:hover {
                    cursor: pointer;
                }
            }
        }
        .main {
            .disposer-app-modal {
                margin: 0;
            }
        }
        .footer {
            display: flex;
            justify-content: flex-end;
            .app-button {
                margin: 10px;

                background-color: $green;
            }
        }
    }
</style>>
