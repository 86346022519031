<template>
    <div class="disposer-reports">
        <eventNav />
        <div class="disposer-app-title">
            Reports
        </div>
        <div class="row flex-wrapp">
            <app-button
                :class="{ active: activeSettings === 1}"
                @click="openSettings(1)"
            >
                Event
            </app-button>
            <app-button
                :class="{ active: activeSettings === 2}"
                @click="openSettings(2)"
            >
                Review
            </app-button>
            <app-button
                :class="{ active: activeSettings === 3}"
                @click="openSettings(3)"
            >
                Document
            </app-button>
            <app-button
                :class="{ active: activeSettings === 4}"
                @click="openSettings(4)"
            >
                Sticker
            </app-button>
            <app-button
                :class="{ active: activeSettings === 5}"
                @click="openSettings(5)"
            >
                Clicks
            </app-button>
            <app-button
                :class="{ active: activeSettings === 6}"
                @click="openSettings(6)"
            >
                Polling Results
            </app-button>
            <app-button
                :class="{ active: activeSettings === 7}"
                @click="openSettings(7)"
            >
                Table surveys
            </app-button>
            <app-button
                :class="{ active: activeSettings === 8}"
                @click="openSettings(8)"
            >
                Participants' platform activity
            </app-button>
            <app-button
                :class="{ active: activeSettings === 9}"
                @click="openSettings(9)"
            >
                Summary text
            </app-button>
            <app-button
                :class="{ active: activeSettings === 10}"
                @click="openSettings(10)"
            >
                Activity Status
            </app-button>
        </div>
        <div
            v-if="activeSettings"
            class="settings"
        >
            <template v-if="activeSettings===1">
                <template v-if="!loading">
                    <p>!To download the full report, click on the "Get report" button.</p>
                    <p>
                        !To upload comments one or more users, select the participants and click on
                        the "Get report" button.
                    </p>
                    <div class="participants-container">
                        <div class="participants-wrapper">
                            <h3>External</h3>
                            <label
                                v-for="setting in settings.external"
                                :key="setting.id"
                                class="disposer-app-checkbox"
                            >
                                <input
                                    v-model="setting.status"
                                    type="checkbox"
                                />
                                <div class="box"></div>
                                {{ setting.name }}
                            </label>
                            <app-button
                                v-if="!hideLoadMoreButton"
                                class="big get-report"
                                @click="loadMoreParticipant()"
                            >
                                Load more
                            </app-button>
                            <app-reports-lang
                                :languages-list="languagesList"
                                @getReport="getReport"
                            />
                        </div>
                        <div class="participants-wrapper">
                            <h3>Internal</h3>
                            <label
                                v-for="setting in settings.internal"
                                :key="setting.id"
                                class="disposer-app-checkbox"
                            >
                                <input
                                    v-model="setting.status"
                                    type="checkbox"
                                />
                                <div class="box"></div>
                                {{ setting.name }}
                            </label>
                            <app-reports-lang
                                :languages-list="languagesList"
                                @getReport="getReport"
                            />
                        </div>
                    </div>
                </template>
                <app-loader v-else />
            </template>

            <template
                v-else-if="activeSettings!==5 && activeSettings!==6 && activeSettings!==7 && activeSettings!==9 && activeSettings!==10"
            >
                <label
                    v-for="setting in settings"
                    :key="setting.id"
                    class="disposer-app-checkbox"
                >
                    <input
                        v-model="radioSetting"
                        type="radio"
                        :value="setting.id"
                        @click="checkOverlap(setting.id)"
                    />
                    <div class="box"></div>
                    {{ setting.name }}
                </label>
            </template>

            <template v-if="activeSettings===2">
                <p>Choose language</p>
                <app-select
                    v-model="language"
                    class="disposer-app-select app-select--white"
                    :option-select="languagesList"
                    label="label"
                    :placeholder="'Default'"
                />
            </template>

            <template v-if="activeSettings===3">
                <p>Choose format</p>
                <div class="row start mt">
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="docType"
                            type="radio"
                            value="pdf"
                        />
                        <div class="box"></div>
                        PDF
                    </label>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="docType"
                            type="radio"
                            value="doc"
                        />
                        <div class="box"></div>
                        DOC
                    </label>
                </div>

                <template v-if="radioSetting">
                    <p>Choose version</p>
                    <div class="row start mt">
                        <label
                            v-for="version in activeDoc.version"
                            :key="version"
                            class="disposer-app-checkbox"
                        >
                            <input
                                v-model="docVersion"
                                type="radio"
                                :value="version"
                            />
                            <div class="box"></div>
                            {{ version }}
                        </label>
                    </div>
                </template>
            </template>

            <template v-if="activeSettings===6 || activeSettings===7">
                <template v-if="!loading">
                    <div class="row start">
                        <div>
                            <p>Choose topic</p>
                            <app-select
                                v-model="selectedTopic"
                                class="app-select--white select-reports"
                                :option-select="topicsAndAllTopics"
                                label="name"
                                searchable
                                :placeholder="'Сhoose topic'"
                            />
                        </div>
                        <div v-if="activeSettings===7">
                            <p>Choose tables report format</p>
                            <app-select
                                v-model="viewMode"
                                class="app-select--white select-reports"
                                :option-select="viewModesReports"
                                label="name"
                                searchable
                                :placeholder="'Сhoose mode'"
                            />
                        </div>
                    </div>
                </template>
                <app-loader v-else />
            </template>

            <template v-if="activeSettings === 9 || activeSettings === 10">
                <template v-if="!loading">
                    <div class="row start">
                        <div>
                            <p>Choose topic</p>
                            <app-select
                                v-model="selectedTopic"
                                class="app-select--white select-reports"
                                :option-select="topicsAndAllTopics"
                                label="name"
                                searchable
                                :placeholder="'Сhoose topic'"
                            />
                        </div>
                    </div>
                </template>
                <app-loader v-else />
            </template>

            <div class="row start">
                <app-button
                    v-if="activeSettings && activeSettings !== 1"
                    class="get-report"
                    :class="{ disabled: getReportButtonDisabled}"
                    @click="getReport"
                >
                    Get report
                </app-button>
                <app-button
                    v-if="reportLink"
                    tag="a"
                    :href="reportLink"
                    target="_blank"
                    class="get-report"
                >
                    Open report
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
import eventNav from '@/components/disposer/pages/event/event-nav.vue';
import appLoader from '@/components/disposer/app/app-loader';
import appSelect from '@/components/disposer/app/app-select';
import appButton from '@/components/disposer/app/app-button';
import appReportsLang from '@/components/disposer/app/app-reports/lang.vue';

import { generateFileUrl, get } from '@/utils/http-helper';
import { loadedReport } from '@/utils/disposer/notifications-text-helper';

import languages from '@/assets/js/languages';

const GET_EVENT = '/reports/get-event';
const GET_REVIEW = '/reports/get-review';
const GET_DOCUMENT = '/reports/get-document';
const GET_STICKERS = '/reports/get-sticker';
const GET_CLICKS = '/reports/get-clicks';
const GET_SURVEYS = '/reports/get-survey';
const GET_TABLE = '/reports/get-table';
const GET_ANALYTIC = '/reports/get-analytic';
const GET_SUMMARY = '/reports/get-summary';
const GET_QUESTION = '/reports/get-question';

export default {
    components: {
        eventNav,
        appLoader,
        appSelect,
        appButton,
        appReportsLang,
    },

    data: () => ({
        activeSettings: null,
        settings: null,
        reportLink: '',
        radioSetting: null,
        docType: 'pdf',
        docVersion: null,
        activeRole: 6,
        languages: languages,
        language: null,
        selectedTopic: null,
        countParticipant: 10,
        page: 1,
        loading: false,
        viewModesReports: [{ id: 1, name: 'Platform tables view' }, {
            id: 2,
            name: 'Tables data sheet',
        }],
        viewMode: null,
    }),

    computed: {
        languagesList() {
            return [{ label: 'Default', value: null }, ...this.languages];
        },
        eventId() {
            return +this.$route.params.id;
        },
        participants() {
            return this.$store.getters['disposerTopics/getParticipants'];
        },
        hideLoadMoreButton() {
            return this.$store.getters['disposerTopics/getTotalPage'] < this.page;
        },
        topics() {
            return this.$store.getters['disposerTopics/getTopicsShort'];
        },
        topicsAndAllTopics() {
            return this.topics ? [...this.topics] : [];
        },
        activeDoc() {
            return this.settings.find(s => s.id === this.radioSetting);
        },

        getReportButtonDisabled() {
            if (this.activeSettings === 1) {
                //return !this.settings?.some(s => s.status);
                return false;
            }
            if (this.activeSettings === 2 || this.activeSettings === 3 || this.activeSettings === 4 || this.activeSettings === 8) {
                return !this.radioSetting;
            }
            return this.activeSettings !== 5 && this.activeSettings !== 6 && this.activeSettings !== 7 && this.activeSettings !== 9 && this.activeSettings !== 10;
        },
    },
    watch: {
        topics() {
            if (this.activeSettings === 6 || this.activeSettings === 7 || this.activeSettings === 9 || this.activeSettings === 10) {
                this.selectedTopic = this.topicsAndAllTopics[0];
                this.viewMode = this.viewModesReports[0];
            }
        },
    },

    methods: {
        async openSettings(num) {
            this.activeSettings = num;
            this.settings = null;
            this.reportLink = null;
            this.radioSetting = null;
            if (num === 1) {
                this.getParticipants();
            } else if (num === 2) {
                this.getTopics(3);
            } else if (num === 3) {
                this.getTopics(5);
            } else if (num === 4) {
                this.getTopics(6);
            } else if (num === 6 || num === 7 || num === 9) {
                this.selectedTopic = null;
                this.getTopics();
            } else if (num === 8) {
                this.getRoles();
            } else if (num === 10) {
                this.selectedTopic = null;
                this.getTopics(null, 3);
            }
        },
        async getParticipants() {
            this.loading = true;
            await this.$store.dispatch('disposerTopics/getParticipants', { id: this.eventId });
            this.settings = {
                external: this.participants.external,
                internal: this.participants.internal,
            };
            this.loading = false;
        },
        async loadMoreParticipant() {
            this.page += 1;
            await this.$store.dispatch('disposerTopics/getParticipantsMore', {
                id: this.eventId,
                size: this.countParticipant,
                page: this.page,
            });
            this.settings = {
                external: this.participants.external,
                internal: this.participants.internal,
            };
        },
        async getTopics(type, type_discussion) {
            this.loading = true;
            await this.$store.dispatch('disposerTopics/getTopicsShort', {
                id: this.eventId,
                type: type,
                type_discussion: type_discussion,
            });
            this.settings = this.topics;
            this.loading = false;
        },
        async getReport(extension) {
            this.reportLink = '';
            let url = '';
            let obj = {};

            switch (this.activeSettings) {
                case 1 : {
                    url = GET_EVENT;
                    const users = [];
                    obj.event_id = this.eventId;
                    obj.role = extension.role;
                    this.settings[extension.roleName].forEach((p) => {
                        p.status ? users.push(p.id) : null;
                    });
                    if (users.length) {
                        obj.users = users.join(',');
                    }
                    extension.language ? obj.lang = extension.language : null;
                    break;
                }
                case 2 : {
                    url = GET_REVIEW;
                    obj.topic_id = this.radioSetting;
                    this.language ? obj.lang = this.language.value : null;
                    break;
                }
                case 3 : {
                    url = GET_DOCUMENT;
                    obj.topic_id = this.radioSetting;
                    obj.format = this.docType;
                    this.docVersion ? obj.version = this.docVersion : null;
                    break;
                }
                case 4 : {
                    url = GET_STICKERS;
                    obj.topic_id = this.radioSetting;
                    break;
                }
                case 5 : {
                    url = GET_CLICKS;
                    obj.event_id = this.eventId;
                    break;
                }
                case 6 : {
                    url = GET_SURVEYS;
                    obj.event_id = this.eventId;
                    if (this.selectedTopic) {
                        obj.topic_id = this.selectedTopic.id;
                    }
                    break;
                }
                case 7 : {
                    url = GET_TABLE;
                    obj.event_id = this.eventId;
                    if (this.selectedTopic) {
                        obj.topic_id = this.selectedTopic.id;
                        obj.view = this.viewMode.id;
                    }
                    break;
                }
                case 8 : {
                    url = GET_ANALYTIC;
                    obj.role = this.radioSetting;
                    obj.event_id = this.eventId;
                    break;
                }
                case 9 : {
                    url = GET_SUMMARY;
                    obj.event_id = this.eventId;
                    if (this.selectedTopic) {
                        obj.topic_id = this.selectedTopic.id;
                    }
                    break;
                }
                case 10 : {
                    url = GET_QUESTION;
                    obj.event_id = this.eventId;
                    if (this.selectedTopic) {
                        obj.topic_id = this.selectedTopic.id;
                    }
                    break;
                }
            }
            this.$awn.async(get(url, obj), response => {
                if (response.status) {
                    this.reportLink = generateFileUrl(response.data.file);
                    this.$awn.success(loadedReport);
                }
            });
        },
        getRoles() {
            this.settings = [
                {
                    id: 5,
                    name: 'Internal',
                },
                {
                    id: 6,
                    name: 'External',
                },
            ];
            this.radioSetting = 6;
        },
        checkOverlap(num) {
            +this.radioSetting === +num ? this.radioSetting = null : null;
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-reports {
    .row {
        display: flex;
        align-items: center;

        &.flex-wrapp {
            flex-wrap: wrap;
            justify-content: center;

            & > div {
                margin: 10px;
            }

        }

        .app-button {
            margin-right: 20px;
        }

        .app-button.active {
            color: #000;

            background: rgba(#222d30, 0.4);
        }

        &.start {
            justify-content: flex-start;

            width: 27rem;

            .app-button {
                margin-right: 1rem;
            }
        }

        &.mt {
            margin-top: 0.2rem;
        }
    }

    p {
        margin-top: 1rem;
    }

    .settings {
        margin-top: 2rem;

        &::v-deep {
            .disposer-app-checkbox {
                margin-top: 0.5rem;
            }

            .disposer-app-select {
                margin-top: 0.4rem;
            }

            .app-select {
                width: 18rem;
                margin-top: 0.4rem;

                .multiselect {
                    width: 18rem;
                    max-width: 18rem;
                }
            }

            .get-report {
                margin-top: 2rem;

                &.disabled {
                    opacity: 0.3;

                    pointer-events: none;
                }
            }

            .select-reports {
                margin-right: 1.5rem;
            }
        }
    }

    .participants {
        &-container {
            display: flex;

            margin-top: 1rem;
        }

        &-wrapper {
            margin-right: 2rem;
        }
    }

    .big-btn {
        padding: 0.36rem 2.5rem;

        text-align: center;
    }
}

</style>
