<template>
    <div class="disposer-company">
        <eventNav />
        <div class="disposer-app-title">
            Company Info/Contacts
            <label class="disposer-app-title--checkbox">
                <input
                    v-model="isCheckActiveDashdord"
                    :class="{ active : isCheckActiveDashdord}"
                    type="checkbox"
                    @click="activeDashbord"
                />
            </label>
            <span class="title-info">Display on the dashboard</span>
        </div>

        <template v-if="company">
            <div
                v-for="section in company.sections"
                :key="section.id"
                class="company-section"
            >
                <app-section
                    delete-warning="Are you sure to delete section?"
                    :save="() => saveSection(section)"
                    :delete-handler="() => deleteSection(section)"
                >
                    <div class="input-title">
                        <label class="disposer-app-label">
                            Title
                            <input
                                v-model="section.title"
                                type="text"
                                class="disposer-app-input"
                            />
                        </label>
                        <label class="disposer-app-label">
                            Order
                            <div class="disposer-app-label--order">
                                <vue-numeric-input
                                    v-model="section.number"
                                    :min="1"
                                    controls-type="updown"
                                />
                            </div>
                        </label>
                    </div>
                    <app-editor
                        v-model="section.content"
                        title="Contents"
                        class="section-editor"
                    />
                </app-section>

                <div
                    class="section-content"
                    :class="{active: section.id>=1}"
                >
                    <app-section>
                        <div class="disposer-app-topic-title">
                            Documents
                        </div>
                        <app-upload-documents
                            v-for="document in section.documents"
                            :key="document.id"
                            :document="document"
                            :section="section"
                            :event-id="eventId"
                            :paths="$options.objectActionsPaths"
                            @delete="deleteDocument"
                        />
                        <app-button @click="addDocument(section)">
                            Add document
                        </app-button>
                    </app-section>

                    <app-section>
                        <div class="disposer-app-topic-title">
                            Videos
                        </div>
                        <app-upload-video
                            v-for="video in section.videos"
                            :key="video.id"
                            :upload-type="2"
                            :video="video"
                            :section="section"
                            :event-id="eventId"
                            :paths="$options.objectActionsPaths"
                            @delete="deleteVideo"
                        />
                        <app-button @click="addVideo(section)">
                            Add video
                        </app-button>
                    </app-section>

                    <app-section>
                        <div class="disposer-app-topic-title">
                            Audios
                        </div>
                        <app-upload-audio
                            v-for="audios in section.audios"
                            :key="audios.id"
                            :audio="audios"
                            :section="section"
                            :event-id="eventId"
                            :paths="$options.objectActionsPaths"
                            @delete="deleteAudios"
                        />
                        <app-button @click="addAudio(section)">
                            Add audio recording
                        </app-button>
                    </app-section>

                    <app-section>
                        <div class="disposer-app-topic-title">
                            Links
                        </div>
                        <app-link
                            v-for="link in section.links"
                            :key="link.id"
                            :paths="$options.objectActionsPaths"
                            :section="section"
                            :link="link"
                            :event-id="eventId"
                            @delete="deleteLink"
                        />
                        <app-button @click="addLink(section)">
                            Add link
                        </app-button>
                    </app-section>
                </div>
            </div>
        </template>

        <div class="add-section-wrap">
            <app-button @click="addSection">
                Add section
            </app-button>
        </div>

        <app-section>
            <div class="disposer-app-topic-title flex company-representative">
                <span v-if="!isEditRepresentativeTitle">
                    {{ company && company.customize.info_contact }}
                </span>
                <input
                    v-else
                    v-model="company.customize.info_contact"
                    class="disposer-app-input"
                    type="text"
                />
                <app-actions
                    :edit="toggleEditRepresentativeTitle"
                    :back="removeRepresentativeTitle"
                    :save="saveRepresentativeTitle"
                />
            </div>
            <label
                v-for="representative in representatives"
                :key="representative.id"
                class="disposer-app-checkbox"
            >
                <input
                    v-model="representative.status"
                    type="checkbox"
                    @change="changeRepresentativeStatus(representative)"
                />
                <div class="box"></div>
                {{ representative.name }}
            </label>
        </app-section>
    </div>
</template>

<script>
import eventNav from '@/components/disposer/pages/event/event-nav.vue';
import appSection from '@/components/disposer/app/app-section';
import appEditor from '@/components/disposer/app/app-editor';
import appUploadDocuments from '@/components/disposer/app/app-upload-documents';
import appUploadVideo from '@/components/disposer/app/app-upload-video';
import appUploadAudio from '@/components/disposer/app/app-upload-audio';
import appLink from '@/components/disposer/app/app-link';
import appButton from '@/components/disposer/app/app-button';
import appActions from '@/components/disposer/app/app-actions';
import VueNumericInput from 'vue-numeric-input';

import { post } from '@/utils/http-helper';
import {
    AUDIO_TEMPLATE,
    DOCUMENT_TEMPLATE,
    LINK_TEMPLATE,
    VIDEO_TEMPLATE,
} from '@/utils/disposer/objects-templates';
import {
    deleteSection,
    saveCompanyInfo,
    updateRepresentativeStatus,
} from '@/utils/disposer/notifications-text-helper';

const CREATE_SECTION = '/company/create-section';
const UPDATE_SECTION = '/company/update-section';
const DELETE_SECTION = '/company/delete-section';

const defaultRepresentativeTitle = 'Company representative(s)';

const UPDATE_REPRESENTATIVE_STATUS = '/company/update-representative';
const SECTION_TEMPLATE = id => ({
    id,
    title: '',
    content: '',
    documents: [],
    videos: [],
    links: [],
});

export default {
    name: 'disposer-company',

    components: {
        eventNav,
        appSection,
        appEditor,
        appUploadDocuments,
        appUploadVideo,
        appLink,
        appButton,
        appActions,
        VueNumericInput,
        appUploadAudio,
    },

    objectActionsPaths: {
        SAVE_OBJECT: '/company/create-object',
        UPDATE_OBJECT: '/company/update-object',
        DELETE_OBJECT: '/company/delete-object',
    },

    data: () => ({
        title: '',
        content: '',
        documents: [],
        videos: [],
        links: [],
        representatives: [],

        isEditRepresentativeTitle: false,
        isCheckActiveDashdord: false,
    }),

    computed: {
        company() {
            return this.$store.getters['disposerCompany/get'];
        },

        eventId() {
            return +this.$route.params.id;
        },
    },

    async created() {
        await this.$store.dispatch('disposerCompany/get', this.eventId);
        this.sections = this.company.sections;
        this.sections.forEach(s => {
            if (s.videos) {
                s.videos = s.videos.map(v => {
                    return {
                        ...VIDEO_TEMPLATE(Math.random()),
                        ...v,
                    };
                });
            }
            if (s.audios) {
                s.audios = s.audios.map(v => {
                    return {
                        ...AUDIO_TEMPLATE(Math.random()),
                        ...v,
                    };
                });
            }
        });
        this.representatives = this.company.representatives;

        !this.company.customize.info_contact ? this.company.customize.info_contact = defaultRepresentativeTitle : '';
        if (this.company?.display_dash === 2) {
            this.isCheckActiveDashdord = true;
        } else {
            this.isCheckActiveDashdord = false;
        }
    },

    methods: {
        addSection() {
            this.sections.push(SECTION_TEMPLATE(Math.random()));
        },

        async saveSection(section) {
            this.$awn.async(post(section.id >= 1 ? UPDATE_SECTION : CREATE_SECTION, {
                event_id: this.eventId,
                title: section.title,
                number: section.number,
                content: section.content,
                section_id: section.id,
            }), response => {
                if (response.status) {
                    section.id = response.data.id;
                    this.$awn.success(saveCompanyInfo);
                }
            });
        },
        async deleteSection(section) {
            this.$awn.async(post(DELETE_SECTION, {
                event_id: this.eventId,
                section_id: section.id,
            }), response => {
                if (response.status) {
                    this.sections = this.sections.filter(s => s.id !== section.id);
                    this.$awn.success(deleteSection);
                }
            });
        },
        addDocument(section) {
            section.documents.push(DOCUMENT_TEMPLATE(Math.random()));
        },
        deleteDocument(id, section) {
            section.documents = section.documents.filter(d => d.id !== id);
        },
        addVideo(section) {
            section.videos.push(VIDEO_TEMPLATE(Math.random()));
        },
        addAudio(section) {
            section.audios.push(AUDIO_TEMPLATE(Math.random()));
        },
        deleteVideo(id, section) {
            section.videos = section.videos.filter(d => d.id !== id);
        },
        deleteAudios(id, section) {
            section.audios = section.audios.filter(d => d.id !== id);
        },
        addLink(section) {
            section.links.push(LINK_TEMPLATE(Math.random()));
        },
        deleteLink(id, section) {
            section.links = section.links.filter(d => d.id !== id);
        },
        changeRepresentativeStatus(representative) {
            this.$awn.async(post(UPDATE_REPRESENTATIVE_STATUS, {
                event_id: this.eventId,
                user_id: representative.id,
                status: representative.status,
            }), response => {
                if (response.status) {
                    this.$awn.success(updateRepresentativeStatus);
                }
            });
        },

        toggleEditRepresentativeTitle() {
            this.isEditRepresentativeTitle = !this.isEditRepresentativeTitle;
        },
        removeRepresentativeTitle() {
            this.company.customize.info_contact = defaultRepresentativeTitle;
            this.saveRepresentativeTitle();
        },
        saveRepresentativeTitle() {
            const fd = new FormData();
            fd.append('event_id', this.eventId);
            fd.append('field', 'info_contact');
            fd.append('value', this.company.customize.info_contact);
            this.$awn.async(post('interface/update', fd, true), response => {
                if (response.status) {
                    this.$awn.success(saveCompanyInfo);
                }
            });
            this.isEditRepresentativeTitle = false;
        },
        activeDashbord() {
            this.$awn.async(post('/event/change-display-dash', {
                event_id: this.eventId,
                status: this.isCheckActiveDashdord ? 0 : 2,
            }));
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-company {
    .input-title {
        margin-bottom: 50px;

        .disposer-app-input {
            width: to_rem(740px);
        }
    }

    .company-section {
        margin-bottom: 5rem;
    }

    .company-representative {
        .app-actions {
            margin-left: 10px;
        }

        .disposer-app-input {
            width: 250px;
            margin-top: 0;
        }
    }

    .disposer-app-checkbox {
        width: fit-content;
        margin-bottom: 0.5rem;
    }

    .add-section-wrap {
        display: flex;
        justify-content: flex-end;

        .app-button {
            width: 120px;
            height: 40px;
            margin: 20px;
            padding: 5px;
        }
    }

    .section-content {
        opacity: 0.5;

        pointer-events: none;

        &.active {
            opacity: 1;

            pointer-events: all;
        }
    }

    .title-info {
        font-size: 17px;
        color: #7f7f7f;
        padding-left: 12px;
    }
}
</style>
