<template>
    <div class="disposer-event-topics">
        <event-nav />

        <div class="head">
            <div class="disposer-app-title">
                Event Topics
            </div>
        </div>

        <div class="event-name">
            {{ eventName }}
        </div>

        <div class="event-topics-table">
            <div class="row-tr row-tr-head">
                <div class="number">
                    Number
                </div>

                <div class="topic-name">
                    Topic name
                </div>

                <div class="start-date">
                    Start date
                </div>

                <div class="end-date">
                    End date
                </div>

                <div class="active-inactive">
                    Active / Inactive
                </div>

                <div class="edit-delete">
                    Edit / Delete
                </div>
            </div>

            <div
                v-for="(topic, index) in topics"
                :key="topic.id"
                class="row-tr"
            >
                <div class="number">
                    {{ index + 1 }}
                </div>

                <div class="topic-name">
                    {{ topic.name }}
                </div>

                <div class="start-date">
                    {{ topic.start_date }}
                </div>

                <div class="end-date">
                    {{ topic.end_date }}
                </div>

                <div class="active-inactive active-inactive-btns">
                    <app-button
                        :class="{inactive: +topic.status===0}"
                        @click="toggleTopicStatus(topic.id, 1)"
                    >
                        Active
                    </app-button>

                    <app-button
                        :class="{inactive: +topic.status===1}"
                        @click="toggleTopicStatus(topic.id, 0)"
                    >
                        Inactive
                    </app-button>
                </div>

                <div class="edit-delete edit-delete-align">
                    <app-actions
                        :edit="()=>editTopic(topic.id)"
                        :trash="()=>deleteTopic(topic.id)"
                    />
                </div>
            </div>
        </div>

        <app-button
            class="add-topic-btn"
            @click="editMode"
        >
            Add Topic
        </app-button>
        <app-button
            class="add-topic-btn"
            @click="addSection"
        >
            Add Section
        </app-button>

        <div
            v-for="(section, index) in sectionsList"
            :key="section.id"
            class="mb-20"
        >
            <div
                class="app-topic-title"
                @click="toggleShowSection(section)"
            >
                <span v-if="section.title">{{ section.title }}</span>
                <span v-else></span>
                <span
                    class="icon-collapse"
                    :class="{'collapsed' : section.show_section}"
                ></span>
            </div>
            <template>
                <div
                    v-if="!section.show_section"
                    class=""
                >
                    <app-section
                        :save="() => saveSection(section)"
                        :delete-handler="() => deleteSection(section)"
                        delete-warning="Are you sure?"
                    >
                        <div
                            class="section"
                        >
                            <div class="section-name">
                                <label class="disposer-app-label">
                                    Name Section
                                    <input
                                        v-model="section.title"
                                        type="text"
                                        class="disposer-app-input title-input"
                                    />
                                </label>
                                <label class="disposer-app-label flex">
                                    <span>Collapsed</span>
                                    <label class="disposer-app-title--checkbox">
                                        <input
                                            v-model="section.collapsed"
                                            :class="{ active : section.collapsed}"
                                            type="checkbox"
                                        />
                                    </label>
                                </label>
                            </div>

                            <div class="select-participants">
                                <div>
                                    <label class="disposer-app-checkbox">
                                        <input
                                            type="checkbox"
                                            name="updateAllParticipants"
                                            :checked="checkedAll[index]"
                                            @change="updateAllTopics(index)"
                                        />
                                        <div class="box"></div>
                                        <b>Check All</b>
                                    </label>
                                </div>
                                <label
                                    v-for="topic in section.topics"
                                    :key="topic.id"
                                    class="disposer-app-checkbox"
                                >
                                    <input
                                        v-model="topic.status"
                                        type="checkbox"
                                        name="updateMethod"
                                        @change="updateTopic(section, topic, index)"
                                    />
                                    <div class="box"></div>
                                    {{ topic.name }}
                                </label>
                            </div>
                        </div>
                    </app-section>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import eventNav from '@/components/disposer/pages/event/event-nav.vue';
import appButton from '@/components/disposer/app/app-button';
import appActions from '@/components/disposer/app/app-actions';

import { post } from '@/utils/http-helper';
import {
    changedTopicStatus,
    deletedTopic,
    deletePartSection,
    savePartSection,
} from '@/utils/disposer/notifications-text-helper';
import appSection from '@/components/disposer/app/app-section/index.vue';

const TOPIC_DELETE = '/topic/delete';
const TOPIC_CHANGE_STATUS = '/topic/change-status';
const CREATE_SECTION = '/topic/create-section';
const UPDATE_SECTION = '/topic/update-section';
const DELETE_SECTION = '/topic/delete-section';
const sectionTemplate = (id) => ({
    id,
    title: '',
    collapsed: 0,
});

export default {
    name: 'event-topics',

    components: {
        appSection,
        eventNav,
        appButton,
        appActions,
    },

    data: () => ({
        topicsList: [],
        sectionsList: [],
        checkedAll: [],
    }),
    computed: {
        topics() {
            return this.$store.getters['disposerTopics/get'];
        },
        topicsInfo() {
            return this.$store.getters['disposerTopics/getTopicsInfo'];
        },
        eventId() {
            return +this.$route.params.id;
        },
        eventName() {
            return this.$store.getters['disposerEvents/getEventInfo']?.name;
        },
        role() {
            return this.$route.meta.role;
        },
        sections() {
            return this.$store.getters['disposerTopics/getSections'];
        },
    },

    watch: {
        role() {
            this.getParticipantsInfo();
        },
    },

    created() {
        this.fetchTopics();
        if (this.eventId) {
            this.$store.dispatch('disposerEvents/getEventInfo', this.eventId);
        }
    },

    methods: {
        editMode() {
            this.$router.push({ name: 'add-topic' });
        },

        editTopic(id) {
            this.$router.push({ name: 'edit-topic', params: { topicId: id } });
        },

        async fetchTopics() {
            await this.$store.dispatch('disposerTopics/get', { id: this.eventId });
            await this.$store.dispatch('disposerTopics/getSections', {
                eventId: this.eventId,
                type: this.role,
            });
            this.topicsList = this.topics;
            this.sectionsList = this.sections;
        },

        updateSection(section) {
            this.$awn.async(post(UPDATE_SECTION, {
                section_id: section.id,
                title: section.title,
                collapsed: +section.collapsed,
                event_id: this.eventId,
                topics: this.setActiveTopics(section.topics),
                show_section: +section.show_section,
            }), response => {
                if (response.status) {
                    this.$awn.success(savePartSection);
                }
            });
        },

        setActiveTopics(topics) {
            return topics.filter((item) => item.status === 1 || item.status === true).map((item) => {
                return {
                    id: +item.id,
                    status: +item.status,
                    name: item.name,
                };
            });
        },

        async saveSection(section) {
            if (section.id >= 1) {
                this.updateSection(section);
                return;
            }
            const obj = {
                event_id: this.eventId,
                title: section.title,
                section_id: section.id,
                collapsed: +section.collapsed,
            };
            obj.topics = this.setActiveTopics(section.topics);
            this.$awn.async(post(CREATE_SECTION, obj), response => {
                if (response.status) {
                    section.id = response.data.id;
                    this.$awn.success(savePartSection);
                }
            });
        },

        async deleteSection(section) {
            if (section.id >= 1) {
                this.$awn.async(post(DELETE_SECTION, {
                    section_id: section.id,
                    event_id: this.eventId,
                }), response => {
                    if (response.status) {
                        this.sectionsList = this.sectionsList.filter(s => s.id !== section.id);
                        this.$awn.success(deletePartSection);
                    }
                });
            }
            this.sectionsList = this.sectionsList.filter(s => s.id !== section.id);
        },

        updateTopic(section, topic, index) {
            if (section.id < 1) {
                return;
            }
            const activeTopics = this.setActiveTopics(this.sectionsList[index].topics);
            this.$awn.async(post(UPDATE_SECTION, {
                event_id: this.eventId,
                section_id: section.id,
                topics: activeTopics,
            }), response => {
                if (response.status) {
                    this.$awn.success(savePartSection);
                } else {
                    topic.status = false;
                }
            });
        },

        toggleShowSection(section) {
            section.show_section = !section.show_section;
            if (section.id < 1) {
                return;
            }
            this.updateSection(section);
        },

        async toggleTopicStatus(id, status) {
            this.$awn.async(post(TOPIC_CHANGE_STATUS, {
                topic_id: id,
                status: status,
                event_id: this.eventId,
            }), response => {
                if (response.status) {
                    this.$awn.success(changedTopicStatus);
                    this.fetchTopics();
                }
            });
        },

        async deleteTopic(id) {
            this.$awn.async(post(TOPIC_DELETE, {
                id: id,
                event_id: this.eventId,
            }), response => {
                if (response.status) {
                    this.$awn.success(deletedTopic);
                    this.fetchTopics();
                }
            });
        },

        addSection() {
            const newSection = { ...sectionTemplate(Math.random()) };
            newSection.topics = this.topicsList.map(topic => {
                return {
                    id: topic.id,
                    status: +topic.status,
                    name: topic.name,
                };
            });
            this.sectionsList.push(newSection);
        },
        updateAllTopics(index) {
            this.sectionsList[index].topics.forEach((topic) => {
                topic.status = true;
            });
            this.$awn.async(post(UPDATE_SECTION, {
                event_id: this.eventId,
                section_id: this.sectionsList[index].id,
                topics: this.sectionsList[index].topics,
            }), response => {
                if (response.status) {
                    this.$awn.success(savePartSection);
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-event-topics {
    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .event-name {
        margin-bottom: 1rem;

        font-size: to_rem(20px);
        font-weight: 700;
    }

    .event-topics-table {
        padding-bottom: 20px;

        .row-tr.row-tr-head {
            background-color: #d0d1d2;
        }

        .row-tr {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: to_rem(1300px);
            //max-width: 78vw;
            height: auto;
            padding: 10px;

            background-color: white;
            border: 1px solid #d0d1d2;

            .number {
                width: to_rem(50px);
            }

            .event {
                width: to_rem(210px);
            }

            .topic-name {
                width: to_rem(210px);
            }

            .start-date,
            .end-date {
                width: to_rem(150px);
            }

            .active-inactive {
                width: to_rem(150px);
            }

            .edit-delete {
                width: to_rem(90px);
            }

            .active-inactive-btns {
                display: flex;

                padding-top: 5px;

                pointer-events: none;

                .app-button {
                    width: 70px;
                    height: 35px;
                }

                .inactive {
                    background-color: #d0d1d2;

                    pointer-events: all;
                }
            }

            .edit-delete-align {
                display: flex;
                justify-content: flex-end;

                padding-right: 10px;
            }
        }
    }

    .add-topic-btn {
        width: 200px;
        height: 35px;
        margin-bottom: 20px;
    }
}

.icon-collapse {
    position: relative;
    margin-left: 0;
    display: block;
    //width: 20px;
    //height: 10px;
    //background: url('../../../assets/img/icons/disposer/bottom-arrow_icon.svg') center center / contain no-repeat;
    transform: scale(1, -1);
    font-size: 150%;
    line-height: 0.5;
}

.icon-collapse:after {
    content: '−';
}

.icon-collapse.collapsed:after {
    //transform: scale(1, 1);
    content: '+'
}

.app-topic-title {
    justify-content: space-between;
    cursor: pointer;
}

.mb-20 {
    margin-bottom: 20px;

    .app-topic-title {
        background: rgba(34, 45, 48, 0.4);
    }
}
</style>
