<template>
    <div class="green-initiative-popup">
        <h2 class="green-initiative-popup__title">
            Thank you for saving our planet!
        </h2>
        <p>While using our platform for one week one participant reduces</p>
        <div class="green-initiative-popup__subtitle">
            <img
                src="@/assets/img/icons/thunderbolt.png"
                alt="thunderbolt icon"
            />
            25,0 kg(s) of carbon offset
        </div>
        <div class="green-initiative-popup__description">
            <p>By using digital tools and participating in virtual events you avoid travelling and decrease CO₂ emissions into the atmosphere.</p>
            <p>MphaR is developing different digital platforms to be used by Pharmaceutical Companies to keep the commitment to reduce unnecessary traveling for employees and HCPs.</p>
            <div class="link">
                <p>Visit</p>
                <a
                    href="https://m-phar.com/green-initiative/"
                    target="_blank"
                >the Green Initiative page</a>
                <p>to learn more</p>
            </div>
        </div>
        <a
            class="green-initiative-popup__logo"
            href="https://m-phar.com/green-initiative/"
            target="_blank"
        >
            <img src="@/assets/img/icons/greenInitiative.svg" />
        </a>
    </div>
</template>

<script>
export default {
    name: 'green-initiative-popup',
};
</script>

<style scoped lang="scss">
    .green-initiative-popup {
        &__title {
            font-family: 'Montserrat';
            font-size: to_rem(22px);
            line-height: to_rem(27px);
            font-weight: 600;
            text-align: center;
            color: $lightGreen;

            &~p {
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }

        &__subtitle {
            display: flex;
            align-items: center;

            margin-bottom: 2rem;

            font-family: 'Montserrat';
            font-size: to_rem(18px);
            line-height: to_rem(22px);
            font-weight: 600;
            color: $lightGreen;

            img {
                margin-right: to_rem(25px);
            }
        }

        &__description {
            p {
                margin-top: to_rem(12px);
            }
            .link {
                margin-top: to_rem(12px);
                p, a {
                    display: inline;
                }
                a {
                    margin: 0 4px;

                    color: $lightGreen;
                    text-decoration: underline;

                    transition: color .3s;

                    &:hover {
                        color: $darkGreen;
                    }
                }
            }
        }

        &__logo {
            display: block;

            width: fit-content;
            margin-top: to_rem(11px);
            padding: 1rem 1rem 1rem 0;
        }
    }
</style>
