<template>
    <div class="sidebar">
        <div
            v-if="dashboard.description"
            class="sidebar__information"
        >
            <div class="head">
                <InfoIcon />
                {{ $t('dash_disc_ques', 'Key Information') }}:
            </div>
            <p v-html="dashboard.description"></p>
        </div>
        <img
            v-if="dashboard.picture"
            :src="generateFileUrl(dashboard.picture)"
            class="sidebar__img"
        />
        <hr v-if="dashboard.picture" />
        <template v-if="showDocuments">
            <h4>
                {{ $t('dash_docs', 'Documents') }}:
            </h4>
            <app-resources
                v-for="document of dashboard.documents"
                :key="document.id"
                class="resource"
            >
                <a
                    v-if="!document.secured"
                    :href="generateFileUrl(document.file)"
                    target="_blank"
                    @click="sendAnalytic(1 ,document.id)"
                >
                    <PDFIcon />
                    {{ document.name }}
                </a>
                <router-link
                    v-else
                    :to="{ name:'view', query:{ document_id: document.id, part: 'dashboard' } }"
                    target="_blank"
                    @click.native="sendAnalytic(1 ,document.id)"
                >
                    <PDFIcon />
                    {{ document.name }}
                </router-link>
                <p>
                    {{ document.description }}
                </p>
            </app-resources>
        </template>
        <template v-if="showVideos">
            <h4>
                {{ $t('dash_videos', 'Videos') }}:
            </h4>
            <app-videos-gallery
                :key="dashboard.id"
                class="column"
                :videos="dashboard.videos"
            />
        </template>
        <template v-if="showAudios">
            <h4>
                Audio recording:
            </h4>
            <app-audios-gallery
                :key="dashboard.id + 1"
                class="column"
                :audios="dashboard.audios"
            />
        </template>
        <hr v-if="showDocuments || showVideos" />
        <div class="statistics">
            <h4>
                {{ $t('dash_statistics', 'Statistics') }}:
            </h4>
            <!--
            <div class="statistic">
                <app-progress-bar :progress="dashboard.progress_my" />
                <div class="statistic__time">
                    {{ dashboard.activity_my }}
                    ({{ $t('dash_my_activity', 'My active participation time') }})
                </div>
            </div>
            <div class="statistic">
                <app-progress-bar :progress="dashboard.progress_max" />
                <div class="statistic__time">
                    {{ dashboard.activity_max }}
                    ({{ $t('dash_top_activity', 'Maximum active participation time') }})
                </div>
            </div>
            -->
            <div class="statistic">
                <app-progress-bar :progress="dashboard.progress_action" />
                <div class="statistic__time">
                    {{ dashboard.progress_action }}%
                    {{ $t('dash_part_activity', 'Participants already commented') }}
                </div>
            </div>
        </div>
        <hr />
        <div class="rating">
            <h4>
                {{ $t('dash_top5_activity', 'Top 5 Active Participants') }}
            </h4>
            <div
                v-for="participant in dashboard.top5"
                :key="participant.id"
                class="item"
            >
                <img :src="generateUrl(participant.photo)" />
                <router-link :to="{name: 'participants', hash: `#${participant.id}`}">
                    {{ participant.name }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import InfoIcon from '@/assets/img/icons/info.svg?inline';
import PDFIcon from '@/assets/img/icons/pdf_icon.svg?inline';
import appProgressBar from '@/components/user/app/app-progressBar';
import appResources from '@/components/user/app/app-resources';
import appVideosGallery from '@/components/user/app/app-videos-gallery';
import appAudiosGallery from '@/components/user/app/app-audios-gallery';

import generateUrl from '@/utils/mixins/generate_url';
import { generateFileUrl } from '@/utils/http-helper';
import { sendClick } from '@/utils/user/clicks-analytic';

export default {
    name: 'sidebar',

    components: {
        InfoIcon,
        PDFIcon,
        appProgressBar,
        appResources,
        appVideosGallery,
        appAudiosGallery,
    },

    mixins: [generateUrl],

    props: {
        dashboard: {
            type: Object,
            default: null,
        },
    },

    computed: {
        showDocuments() {
            return this.dashboard.documents?.length;
        },
        showVideos() {
            return this.dashboard.videos?.length;
        },
        showAudios() {
            return this.dashboard.audios?.length;
        },
        topicId() {
            return this.$store.getters['dashboard/topicId'];
        },
    },

    methods: {
        sendAnalytic(type, id) {
            sendClick(5, type, id, this.topicId);
        },

        generateFileUrl(file) {
            return generateFileUrl(file);
        },
    },
};
</script>

<style scoped lang="scss">
.sidebar {
    width: 18.4rem;
    min-width: 18.4rem;
    padding: 1.45rem 1.15rem;

    background: white;
    border: 1px solid $gray;

    &::v-deep {
        .video {
            height: 8rem;

            video {
                height: 100%;
            }

            img {
                height: 100%;
            }

            .play {
                width: 5rem;
            }
        }

    }

    &__img {
        display: block;

        width: 82%;
        margin: 0 auto;
    }

    &__information {
        margin-bottom: 1.2rem;
        padding: 1rem;

        font-size: to_rem(14px);
        line-height: 1.43;
        color: $copyGray;

        border: 5px solid $lightGray;

        &::v-deep {
            img {
                max-width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

        .head {
            display: flex;
            align-items: center;

            font-size: to_rem(14px);
            font-weight: 700;
        }

        svg {
            width: 1.7rem;
            height: 1.7rem;
            margin-right: 0.5rem;

            circle {
                fill: var(--platform-accent-color);
            }
        }

        p {
            margin-top: 0.8rem;

            font-size: to_rem(14px);
            line-height: 1.43;
            color: $copyGray;
        }
    }

    .app-resources {
        padding: 0.5rem;
    }
}

hr {
    margin: 1.8rem 0 1.2rem;
}

h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;

    font-size: to_rem(14px);
}

.statistics {
    .statistic {
        margin-bottom: 1.5rem;

        &__time {
            margin-top: 0.7rem;

            font-size: to_rem(14px);
            line-height: 1.4;
        }
    }
}

.rating {
    .item {
        display: flex;
        align-items: center;

        margin-bottom: 0.7rem;
        padding-left: 0.3rem;

        font-size: to_rem(14px);
        font-weight: 700;

        img {
            width: 2rem;
            margin-right: 0.7rem;
        }

        a {
            color: var(--platform-accent-color);
        }
    }
}


@include mobile_or_P {
    .sidebar {
        width: 16.3rem;
        min-width: 16.3rem;
        padding: 1.55rem 0.65rem;

        &__information {
            margin-bottom: 1.3rem;
            padding: 1.1rem 0.6rem;

            p {
                margin-top: .7rem;
            }
        }

        &__img {
            width: 88%;
        }

        .statistics {
            margin-top: 1.2rem;
        }

        .rating {
            margin-top: 1.2rem;
        }
    }
    hr {
        margin: 1.8rem 0 0;
    }
}

@include razr767 {
    .sidebar {
        width: 94.5%;
        margin: .6rem auto;
        padding: 2.15rem 2rem;

        &__information {
            margin-bottom: 2.1rem;
            padding: 1.4rem 1.2rem 2rem;

            img {
                width: 2.2rem;
                height: 2.2rem;
                margin-right: 0.3rem;
            }

            .head {
                font-size: 1.18rem;
            }

            p {
                margin-top: 1.2rem;

                font-size: 1.18rem;
                line-height: 1.5;
            }
        }

        &__img {
            width: 82%;
        }

        hr {
            margin-top: 2.6rem;
        }

        h4 {
            margin-bottom: 2rem;

            font-size: 1.25rem;
        }

        .statistics {
            margin-top: 1.5rem;

            .statistic {
                margin-bottom: 2rem;

                &__time {
                    margin-top: 0.9rem;

                    font-size: 1.175rem;
                }
            }
        }

        .rating {
            margin-top: 2.2rem;

            .item {
                margin-bottom: 1.3rem;

                font-size: 1.2rem;

                img {
                    width: 2.6rem;
                    margin-right: 1rem;
                }
            }
        }
    }
}
</style>
