<template>
    <div class="app-topic">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'app-topic',
    };
</script>

<style scoped lang="scss">
    .app-topic{
        position: relative;

        padding: 1.7rem;

        background: white;
        border: 1px solid $gray;
        &__description{
            margin-bottom: 0.44rem;

            font-size: to_rem(12px);
            font-weight: 700;
        }
        &__question{
            margin-bottom: 0.7rem;

            font-size: to_rem(18px);
            color: var(--platform-accent-color);
            font-style: italic;
        }
        .app-progressBar{
            width: 18rem;
            margin-bottom: 0.4rem;
        }
        &__time{
            font-size: to_rem(16px);
            color: var(--platform-accent-color);
            font-style: italic;
        }
        p{
            margin-top: 0.5rem;

            font-size: to_rem(16px);
            line-height: 1.2;
        }
    }

    @include mobile_or_P {
        .app-topic {
            padding: 1.8rem 0.6rem;

            &__question {
                margin-bottom: 0.7rem
            }
        }
    }

    @include razr767{
        .app-topic{
            padding: 1.05rem 0.9rem;
            &__description{
                margin-bottom: .54rem;

                font-size: 12px;
            }
            &__question{
                margin-bottom: 0.8rem;

                font-size: 12px;
            }
            &__time{
                font-size: 14px;
            }
        }
    }
</style>
