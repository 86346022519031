import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import platform from './modules/platform';
import disposerManagers from './modules/disposer/managers';
import disposerEvents from './modules/disposer/events';
import disposerTopics from './modules/disposer/topics';
import disposerInterface from './modules/disposer/interface';
import disposerAgenda from './modules/disposer/agenga';
import disposerCompany from './modules/disposer/company';
import disposerLibrary from './modules/disposer/library';
import disposerParticipants from './modules/disposer/participants';
import dashboard from './modules/user/dashboard';
import guidance from './modules/user/guidance';
import profile from './modules/user/profile';
import agenda from './modules/user/agenda';
import participants from './modules/user/participants';
import library from './modules/user/e-library';
import company from './modules/user/company';
import workshop from './modules/user/workshop';
import stickers from './modules/user/stickers';
import popup from './modules/popup';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        platform,
        disposerManagers,
        disposerEvents,
        disposerInterface,
        disposerTopics,
        disposerAgenda,
        disposerCompany,
        disposerLibrary,
        disposerParticipants,
        dashboard,
        guidance,
        profile,
        agenda,
        participants,
        library,
        company,
        workshop,
        stickers,
        popup,
    },
});
