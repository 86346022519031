<template>
    <div class="app-progressBar">
        <span :style="{width: progress + '%'}"></span>
    </div>
</template>

<script>
    export default {
        name: 'app-progress-bar',
        props: {
            progress: {
                type: Number,
                default: 0,
            },
        },
    };
</script>

<style scoped lang="scss">
    .app-progressBar {
        height: .8rem;
        overflow: hidden;

        border-radius: .5rem;
        box-shadow: inset 0 3px 7px 3px #e5e5e5;


        span {
            display: block;

            height: 100%;

            background: var(--platform-accent-color);
        }
    }
    @include razr767{
        .app-progressBar{
            height: 1.2rem;
        }
    }
</style>
