import io from 'socket.io-client';
import { get_cookie } from '@/utils/cookie-helper';

const SOCKETS_URL = process.env.VUE_APP_BASE_SOCKETS_URL;

export const socketInstance = (namespace, extension={}, callBack) => {
  const instance = io(`${SOCKETS_URL}/${namespace}`);
    instance.on('connect', () => {
        instance.emit(`${namespace}:auth`, {
          access_token: `${get_cookie('access-token').trim()}`,
          ...extension,
        }, () => {
            if (typeof callBack === 'function') {
                callBack();
            }
        });
    });
    return instance;
};

export class GP {
  constructor(nameSpace) {
    this.nameSpace = nameSpace;
  }
  generate(method){
    return  `${this.nameSpace}:${method}`;
  }
}

