<template>
    <header :class="{workshop: type==='workshop' || type==='review', editor: type==='editor'}">
        <div class="main-container">
            <div
                v-if="type==='stickers'"
                class="actions"
            >
                <app-button
                    class="small accent"
                    @click="openPopup('stickersFunctionalityPopup')"
                >
                    {{ $t('h_sticker_func', 'Board Functionality') }}
                </app-button>
                <app-button
                    class="small accent"
                    @click="closeWindow"
                >
                    {{ $t('h_sticker_close', 'Finish') }}
                </app-button>
            </div>

            <div
                v-else-if="type==='workshop' || type==='review'"
                :class="{workshop: type==='workshop' || type==='review'}"
            >
                <div class="actions">
                    <app-button
                        class="small accent"
                        @click="openPopup('workshopFunctionalityPopup')"
                    >
                        <template v-if="type==='review'">
                            {{ $t('h_review_func', 'Workshop Functionality') }}
                        </template>
                        <template v-else>
                            {{ $t('h_workshop_func', 'Workshop Functionality') }}
                        </template>
                    </app-button>
                    <app-button
                        class="small accent"
                        @click="closeWindow"
                    >
                        <template v-if="type==='review'">
                            {{ $t('h_review_close', 'Workshop Functionality') }}
                        </template>
                        <template v-else>
                            {{ $t('h_workshop_close', 'Finish Workshop') }}
                        </template>
                    </app-button>
                </div>

                <div
                    v-if="type==='workshop' || type==='review'"
                    class="video-call"
                >
                    <app-button
                        v-if="activeContextVideo"
                        class="small accent"
                        @click="startVideoCall(false)"
                    >
                        {{ $t('h_topic_context_video', 'Join Workshop Session') }}
                    </app-button>
                    <img
                        v-if="activeContextVideo || activeMainVideo"
                        src="@/assets/img/icons/camera-black_icon.svg"
                    />
                    <app-button
                        v-if="activeMainVideo"
                        class="small accent"
                        @click="startVideoCall(true)"
                    >
                        {{ $t('h_topic_main_video', 'Join Main Session') }}
                    </app-button>
                </div>
            </div>

            <div
                v-else-if="type==='editor'"
                class="actions"
            >
                <app-button
                    class="small accent"
                    @click="openPopup('workshopFunctionalityPopup')"
                >
                    {{ $t('h_doc_func', 'Editor Functionality') }}
                </app-button>
                <app-button
                    class="small accent"
                    @click="closeWindow"
                >
                    {{ $t('h_doc_close', 'Finish') }}
                </app-button>
            </div>

            <div
                v-else
                class="img"
            >
                <img
                    v-if="logo"
                    :src="logo"
                    class="logo"
                />
            </div>

            <div
                v-if="type==='view'"
                class="actions view"
            >
                <app-button
                    class="small accent"
                    @click="closeWindow"
                >
                    {{ $t('close', 'Finish') }}
                </app-button>
            </div>

            <div class="right">
                <div class="time">
                    {{ $t('h_server_time', 'Servеr time') }}:
                    <span>
                        {{ currentTime }}
                    </span>
                    <span
                        v-if="type"
                        class="description"
                    >
                        <br />
                        {{ $t('h_protection', 'Protected with RSA encryption') }}
                    </span>
                </div>
                <div class="profile">
                    <img src="@/assets/img/icons/avatar_icon.svg" />
                    <span
                        is="router-link"
                        :to="{ name: 'profile' }"
                    >
                        {{ $t('h_profile', 'MY PROFILE') }}
                    </span>
                    <div
                        v-if="unreadMessages"
                        class="messages-count"
                    >
                        {{ unreadMessages }}
                    </div>
                </div>
                <div
                    class="logout"
                    @click="logout"
                >
                    <img src="@/assets/img/icons/logout_icon.svg" />
                    <span>
                        {{ $t('h_logout', 'LOGOUT') }}
                    </span>
                </div>
                <div
                    v-if="!type"
                    class="burger"
                    :class="{open: menuIsOpen}"
                    @click="$emit('toggleNav')"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        <div
            v-if="menuIsOpen"
            class="shadowBlock"
        ></div>
    </header>
</template>

<script>
import appButton from '@/components/user/app/app-button';
import { bus } from '@/main';
import { logoutTopicTimer } from '@/utils/user/time-socket';
import generateUrl from '@/utils/mixins/generate_url';

export default {
    name: 'layout-header',

    components: {
        appButton,
    },

    mixins: [generateUrl],

    props: {
        type: {
            type: String,
            default: '',
        },

        menuIsOpen: {
            type: Boolean,
        },
    },

    data: () => ({
        isOpenVideo: false,
    }),

    computed: {
        currentTime() {
            return this.$store.getters['auth/currentTime'];
        },
        platform() {
            return this.$store.getters['platform/platform'];
        },
        logo() {
            return this.generateUrl(this.platform?.interface?.company_logo, ' ');
        },
        unreadMessages() {
            return +this.$store.getters['auth/userInfo']?.messages?.unread;
        },
        workshop() {
            return this.$store.getters['workshop/get'];
        },
        activeMainVideo() {
            return this.workshop?.info?.video_main;
        },
        activeContextVideo() {
            return this.workshop?.info?.video_context;
        },
    },

    created() {
        bus.$on('endVideoCall', () => {
            this.isOpenVideo = false;
        });
    },

    methods: {
        startVideoCall(isMain) {
            if (!this.isOpenVideo) {
                bus.$emit('startVideoCall', { status: true, isMain });
                this.isOpenVideo = true;
            } else {
                bus.$emit('startVideoCall', { status: false, isMain });
                this.isOpenVideo = false;
            }
        },
        async logout() {
            logoutTopicTimer();
            await this.$store.dispatch('auth/logout');
            this.$router.push({ name: 'login' });
        },
        openPopup(popupName) {
            this.$store.dispatch('togglePopup', { popupName });
        },
        closeWindow() {
            window.close();
        },
    },
};
</script>

<style scoped lang="scss">
header {
    display: flex;
    align-items: center;

    min-height: to_rem(78px);
    padding: 0.6rem 0 0.8rem;

    background: white;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);

}

.main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;

    padding-right: 0.7rem;

    .logo {
        max-width: 13.4rem;
        height: 3rem;

        object-fit: contain;
    }

    .actions {
        display: flex;

        .app-button {
            margin-right: .5rem;
        }

        &.view {
            margin-right: auto;
            margin-left: 1rem;
        }
    }

    .right {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: to_rem(14px);

        & > * {
            margin-left: 0.5rem;
        }
    }

    .time {
        font-size: to_rem(18px);
        line-height: 1;
        color: #7d7d7d;

        .description {
            font-size: to_rem(11px);
        }
    }

    .logout, .profile {
        display: flex;
        align-items: center;

        cursor: pointer;

        img {
            width: 1.2rem;
            margin-right: 0.4rem;
        }

        a {
            color: black;
        }
    }

    .profile {
        position: relative;

        margin-right: 1rem;
        margin-left: 1.5rem;

        img {
            width: 1.3rem;
        }

        .messages-count {
            position: absolute;
            top: 0;
            right: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 1.2rem;
            height: 1.2rem;

            font-size: to_rem(14px);
            color: white;

            background: $red;
            border-radius: 50%;

            transform: translate(50%, -70%);
        }
    }

    .video-call {
        display: flex;
        align-items: center;

        margin-left: 8rem;

        img {
            width: 1.5rem;
            margin: 0 0.5rem;
        }
    }

    .workshop {
        display: flex;
    }

    .burger {
        position: relative;

        display: none;

        width: 1.5rem;
        height: 1.5rem;
        margin-left: 1rem;

        &.open {
            span {
                &:nth-of-type(1) {
                    top: 50%;

                    transform: translate(-50%, -50%) rotate(45deg);
                }

                &:nth-of-type(2) {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }

                &:nth-of-type(3) {
                    opacity: 0;
                }
            }
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;

            display: block;

            width: 100%;
            height: 2px;

            background: black;

            transform: translate(-50%, -50%);

            &:nth-of-type(1) {
                top: 25%;
            }

            &:nth-of-type(3) {
                top: 75%;
            }
        }

    }
}

@media screen and (max-width: 1023px) {
    .description {
        line-height: 0.5;
    }
}

@include mobile_or_P {
    header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1000000;

        height: 5rem;
        padding: 1rem 1.2rem 0.6rem;

        &.editor {
            min-height: 5.5rem !important;
            padding-bottom: 0 !important;
        }

        &.workshop {
            height: 7.8rem;

            .actions {
                margin-bottom: 1rem;
            }

            .right {
                flex-wrap: wrap;

                width: 42%;

                .time {
                    order: 1;

                    margin-top: 1rem;
                    margin-right: 0;

                    span {
                        display: inline;
                    }
                }
            }
        }
    }

    .logo {
        max-width: 9rem;
    }

    .main-container {
        position: relative;
        z-index: 10;

        padding-right: 2.5rem;

        .workshop {
            flex-direction: column;
        }

        .right {
            margin-bottom: 0.3rem;

            .time {
                margin-right: 0.3rem;

                line-height: 1.2;

                span {
                    display: block;
                }
            }
        }

        .video-call {
            margin-left: 0;
        }

        .burger {
            display: block;
        }
    }

    .shadowBlock {
        position: fixed;
        top: 80px;
        right: 0;
        bottom: 0;
        left: 0;

        background: rgba(#000, .5);
    }
}

@include razr767 {
    header {
        z-index: 2;

        min-height: 5.775rem;
        padding: .8rem;

        box-shadow: 0 0 7px 3px rgb(0 0 0 / 50%);

        &.editor {
            min-height: 11.5rem !important;
        }

        .logo {
            height: 4rem;
        }

        .main-container {
            .burger {
                margin-left: -0.5rem;
            }

            .actions {
                justify-content: space-between;

                .app-button {
                    &:nth-of-type(2) {
                        margin-right: 0;
                    }
                }
            }

            .video-call {
                justify-content: space-between;

                .app-button {
                    padding: 0 .5rem;
                }

                img {
                    margin: 0 .4rem;
                }
            }
        }

        .right {
            flex-wrap: wrap;
            justify-content: space-between;

            .profile {
                display: none;
            }

            .logout {
                margin-right: 1.5rem;
                margin-left: 0;

                font-size: 1.1rem;

                img {
                    width: 1.2rem;
                }
            }

            .time {
                margin: 0.5rem 0.8rem 0.3rem 0 !important;

                font-size: 0.9rem;
            }

            .burger {
                position: absolute;
                right: 0;
                z-index: 10;

                width: 2rem;
                height: 2rem;
            }
        }

        &.all-info {
            align-items: flex-start;

            min-height: 12.5rem;
            padding: 1.5rem 0.9rem;

            .main-container {
                flex-direction: column;
                align-items: flex-start;
            }

            .right {
                .time {
                    display: block;

                    width: 100%;
                    margin: 0.7rem 0 0.8rem;

                    span {
                        display: inline;
                    }
                }

                .profile, .logout {
                    display: flex;

                    margin: 0 1.8rem;

                    font-size: 12px;
                }
            }
        }

        &.workshop {
            width: 100%;
            height: 12.7rem;

            .right {
                width: 100%;

                .time {
                    order: 0;
                }
            }
        }
    }
    .shadowBlock {
        top: 5.775rem;

        display: none;
    }
}
</style>
