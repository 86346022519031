export const delete_cookie = ( name, path, domain ) => {
    document.cookie = name + '=' +
        ((path) ? ';path='+path:'')+
        ((domain)?';domain='+domain:'') +
        ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
};

export const get_cookie = (name) => {
  const c = document.cookie.split(';').find(c => c.trim().startsWith(name + '='));
  if (c) {
    return c.replace(`${name}=`, '');
  } else {
    return null;
  }
};

export const set_cookie = (name, value, path = '', domain= '') => {
  document.cookie=`${name}=${value};path=/${path};domain=${domain}`;
};
