<template>
    <label class="app-button app-file-input">
        <input
            type="file"
            accept=".pdf,.ptt,.pptx,.doc,.docx,image/*"
            @change="uploadFile"
        />
        <div
            v-if="!isLoading"
            class="button"
        >
            <slot></slot>
        </div>
        <line-loader v-else />
    </label>
</template>

<script>
    import LineLoader from '@/components/user/app/app-loader/line-loader';

    export default {
        name: 'app-file-input',
        components: {
          LineLoader,
        },
        props: {
            refName: {
              type: String,
              default: '',
            },
            loadFile: {
              type: Function,
              default: null,
            },
        },
        data: () => ({
            isLoading: false,
        }),
        methods: {
            async uploadFile(e) {
                this.isLoading = true;
                await this.loadFile(e.target.files[0]);
                this.isLoading = false;
            },
            fileIsLoad() {
                this.isLoading = false;
            },
        },
    };
</script>

<style scoped lang="scss">
    .app-file-input{
        display: flex;
        justify-content: center;
        align-items: center;

        width: fit-content;
        min-width: 9.3rem;
        padding: 0.86rem 2.5rem;

        font-size: to_rem(14px);
        color: $mainTextColor;

        background: var(--platform-main-color);
        border: none;
        border-radius: 0.2rem;

        cursor: pointer;
        transition: box-shadow .2s;


        &[data-disable] {
            filter: grayscale(1);

            pointer-events: none;
        }

        .button {
            display: flex;
            align-items: center;
        }

        &.narrow{
            padding: 0.55rem 0.4rem;
            img{
                height: 1rem;
                margin-right: 0.2rem;
            }
        }

        input {
            display: none;
        }

        &:hover{
            box-shadow: 3px 3px 3px rgb(0 0 0 / 40%)
        }
    }

    img, i{
        width: 0.9rem;
        margin-right: 0.5rem;
    }

    @include mobile_or_P{
        .app-button{
            &:hover{
                box-shadow: none;
            }
        }
    }
    @include razr767{
        .app-button{
            font-size: 13px;

            img{
                width: 1.2rem;
            }
        }
    };
</style>
