<template>
    <div class="default-layout">
        <layout-header
            :menu-is-open="menuIsOpen"
            @toggleNav="toggleNav"
        />
        <div class="main-container">
            <layout-sidebar
                :menu-is-open="menuIsOpen"
                @toggleNav="toggleNav"
            />
            <div class="content">
                <slot></slot>
            </div>
        </div>
        <layout-footer />
    </div>
</template>

<script>
    import LayoutHeader from '@/components/user/layouts/header';
    import LayoutSidebar from '@/components/user/layouts/sidebar';
    import LayoutFooter from '@/components/user/layouts/footer';

    export default {
        name: 'default-layout',
        components: {
            LayoutHeader,
            LayoutSidebar,
            LayoutFooter,
        },
        data: ()=>({
          menuIsOpen: !window.isMobileOrTablet,
        }),
        methods: {
            toggleNav(state){
                state!==undefined ? this.menuIsOpen = state: this.menuIsOpen = !this.menuIsOpen;
            },
        },
    };
</script>

<style scoped lang="scss">
    .default-layout {
        display: flex;
        flex-direction: column;

        min-height: 100vh;
    }
    .main-container{
        display: flex;
        justify-content: space-between;

        margin-top: 1.3rem;
        margin-bottom: 1.3rem;
    }
    .content {
        width: 83%;
    }
    @include mobile_or_P{
        .main-container{
            margin-top: 1.15rem;
            padding-top: 5rem;
        }
        .content {
            width: 100%;
        }
    }
    @include razr767{
        .main-container{
            padding-top: 6rem;
        }
    }
</style>
