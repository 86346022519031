<template>
    <div class="timer-warning">
        <div class="timer-warning__wrapper">
            <div class="title">
                Session About To Timeout
            </div>
            <p>
                You will be automatically logged out in 1 minute. <br />
                To remain logged in move your mouse over this window.
            </p>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'timer-warning',
  };
</script>

<style scoped lang="scss">
    .timer-warning {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000000;

        background: rgba(0,0,0,0.2);
        &__wrapper {
            position: absolute;
            top: 50%;
            left: 50%;

            padding: 3rem;

            background: $lightGray;

            transform: translate(-50%, -50%);
        }
        .title {
            margin-bottom: 1rem;

            font-size: to_rem(20px);
        }
        @media screen and (max-width: 1023px){
            &__wrapper {
                width: 90%;
            }
        }
    }
</style>
