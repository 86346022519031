<template>
    <div class="comment-popup">
        <div class="comment-popup_title">
            {{ $t('your_message', 'Your Message') }}
        </div>
        <form @submit.prevent="sendComment">
            <label class="textarea-label">
                <textarea
                    v-model="message"
                    class="app-input"
                ></textarea>
            </label>
            <app-button
                tag="button"
                type="submit"
                class="accent"
            >
                {{ $t('send', 'Send') }}
            </app-button>
        </form>
    </div>
</template>

<script>
  import appButton from '@/components/user/app/app-button';

  import { messageSocket, initMessageSocket } from '@/utils/user/message-socket.js';
  import { GP } from '@/utils/sockets-helper';

  const NAME_SPACE = 'message';
  const CREATE_MESSAGE = 'message-create';

  const generateSocketPath = new GP(NAME_SPACE);

  export default {
    name: 'app-message-popup',
    components: {
      appButton,
    },
    props: {
        socket: {
            type: Object,
            default: null,
        },
        path: {
            type: String,
            default: null,
        },
    },
    data: ()=>({
      message: '',
    }),
    computed: {
        options() {
            return this.$store.getters.popupOptions;
        },
    },
    methods: {
        sendComment() {
          if (this.message.trim()) {
            if (messageSocket) {
              messageSocket.emit(generateSocketPath.generate(CREATE_MESSAGE), {
                user_id: this.options.userId,
                text: this.message.trim(),
              }, () => {
                this.$store.dispatch('togglePopup', { popupName: null });
              });
            } else {
              initMessageSocket();
              this.sendComment();
            }
          }
        },
    },
  };
</script>

<style scoped lang="scss">
.comment-popup {
    .comment-popup_title {
        margin-bottom: 2rem;
    }
    .textarea-label {
        font-size: 0.8rem;
        font-weight: 700;
        color: $copyGray;
    }
    form {
        padding: 2px;
    }
    textarea {
        height: 8rem;
        margin-top: 1rem;

        resize: none;
    }
    .app-button {
        margin-top: 1rem;
        margin-left: auto;
    }
}
</style>
