<template>
    <div class="home">
        <section class="header">
            <div class="home__container">
                <a href="https://m-phar.com/">
                    <img src="@/assets/img/logo.svg" />
                </a>
            </div>
        </section>
        <section class="home__section">
            <div class="home__fon-opacity">
                <div class="home__container">
                    <div class="text">
                        <h2>WELCOME TO</h2>
                        <p class="sub-title">
                            Virtual Platform for Scientific Events
                        </p>
                        <h1>Virtual Advisory Board Platform</h1>
                        <p class="text">
                            Innovative digital solution that creates new ways <br />
                            how healthcare professionals and pharmaceutical companies collaborate
                        </p>
                        <div class="start_pointer"></div>
                        <p class="desc">
                            <b>Developed together with doctors</b> for life science industry
                        </p>
                    </div>
                    <div class="action">
                        <a
                            href="https://getvirtualadvisory.com/"
                            class="btn_request"
                        >
                            Explore
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'home',
};
</script>

<style scoped lang="scss">
.home {
    width: 100%;
    height: 100vh;
    min-height: 822px;
    overflow-x: hidden;


    background: url("~@/assets/img/home-fon.png") no-repeat center/cover;
    .header {
        width: 100%;
        height: 72px;

        background: #fff;
        a {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 154px;
            height: 49px;
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 100%;
        max-width: 1180px;
        height: 100%;
        margin: 0 auto;
        padding-right: 16px;
        padding-left: 16px;
    }
    &__section {
        text-align: center;

        h1 {
            margin-top: 1rem;
            margin-bottom: 1rem;

            font-family: "Eras", sans-serif;
            font-size: 52px;
            line-height: 1.325;
            font-weight: bold;
            color: #f8b38c;
            letter-spacing: -0.025em;
        }
        h2 {
            margin-top: 3rem;
            margin-bottom: 2rem;

            font-family: "Eras", sans-serif;
            font-size: 45px;
            line-height: 1.325;
            font-weight: bold;
            color: #f8b38c;
            letter-spacing: -0.025em;
        }
        p {
            margin-bottom: 0;

            font-family: "Eras", sans-serif;
            font-size: 26px;
            color: #FFFFFF;
            letter-spacing: 0;
        }
        b {
            font-family: "Eras", sans-serif;
            color: #f8b38c;
        }
        .start_pointer {
            width: 0;
            height: 0;
            margin: 2.4rem auto 1.3rem;

            border: 10px solid transparent;
            border-top: 15px solid #fff;
            border-bottom: 0;
        }

        .action {
            display: flex;
            justify-content: center;
        }

        .btn_request {
            display: flex;

            margin: 1.5rem 0;
            padding: 0.7rem 5rem;

            font-size: 22px;
            font-weight: 600;
            color: #582780;
            text-decoration: none;

            background: white;
            border: 1px solid transparent;
            border-radius: 6px;
            outline: none;

            cursor: pointer;
            transition: all 0.25s ease;
            &:hover {
                color: white;

                background: transparent;
                border: 1px solid white;
            }
        }
    }
    &__fon-opacity {
        position: relative;

        background: rgba(73, 17, 111, 0.9);
        &::after, &::before{
            content: '';
            position: absolute;
            top: 100%;

            width: 0;
            height: 0;

            border-style: solid;
        }
        &::after{
            left: 50%;

            border-width: 0 0 47px 50vw;
            border-color: transparent  transparent transparent rgba(73, 17, 111, 0.9) ;
        }
        &::before{
            right: 50%;

            border-width: 0 50vw 47px 0;
            border-color: transparent rgba(73, 17, 111, 0.9) transparent transparent ;

        }
    }
    @media (max-width: 767px) {
        &__section {
            h1 {
                font-size: 35px;
                line-height: 1;
            }

            h2{
                margin-top: 20px;
                margin-bottom: 5px;

                font-size: 28px;
            }

            p {
                font-size: 20px;
            }

            .desc {
                max-width: 275px;
                margin: 0 auto;

                font-size: 16px;
            }

            .sub-title {

            }

            .start_pointer {
                margin: 12px auto 20px;
            }
            .btn_request {
                padding: 14px 75px;

                font-size: 20px;
            }
        }
    }

    @media (max-width: 500px) {
        &__section {
            h1 {
                margin-top: 20px;
                margin-bottom: 25px;

                font-size: 35px;
                line-height: 1;
            }

            h2{
                margin-top: 50px;
                margin-bottom: 5px;

                font-size: 28px;
            }



            p {
                font-size: 26px;
            }

            .sub-title {
                font-size: 20px;
            }
        }
    }
    @media (max-width: 375px) {
        &__section {
            h1 {
                font-size: 32px;
                line-height: 1;
            }

            h2{
                margin-top: 40px;
                margin-bottom: 5px;

                font-size: 28px;
            }

            p {
                font-size: 18px;

            }

            .text {
                max-width: 315px;
                margin: 0 auto;
            }

            .sub-title {
                font-size: 16px;
            }

            .desc {
                max-width: 250px;

                font-size: 14px;
            }

            .btn_request{
                padding: 12px 60px;

                font-size: 16px;
            }
        }
    }
}

</style>
