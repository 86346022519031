<template>
    <div
        class="app-participants-information"
        :class="{short: !participant.quick_info && !participant.photo}"
    >
        <div
            v-if="userInfo"
            class="left"
        >
            <div class="short-info forMobile">
                <div class="app-participants-information__title">
                    {{ participantName }}
                </div>
                <div
                    class="app-participants-information__description"
                    v-html="participant.description"
                ></div>
            </div>
            <img
                :src="generateUrl(participant.photo)"
                class="app-participants-information__avatar"
            />
            <app-button
                v-if="participant.allow_messages && userInfo.messages && participant.id !== userInfo.id"
                class="responsive accent"
                @click="openMessagePopup"
            >
                <img src="@/assets/img/icons/mail_icon.svg" />
                {{ $t('send_message', 'Send Message') }}
            </app-button>
        </div>
        <div class="right">
            <div class="forDesktop">
                <div class="app-participants-information__title">
                    {{ participantName }}
                </div>
                <div
                    class="app-participants-information__description"
                    v-html="participant.description"
                ></div>
            </div>
            <div
                v-if="participant.quick_info"
                class="app-participants-information__info"
            >
                <div class="app-participants-information__info-title">
                    <InfoIcon />
                    {{ $t('agenda_quick_info', 'Quick Info') }}:
                </div>
                <p v-html="participant.quick_info"></p>
                <p
                    v-for="link in participant.links"
                    :key="link.id"
                >
                    <a
                        :href="link.link"
                        target="_blank"
                    >
                        {{ link.description }}
                    </a>
                </p>
            </div>
        </div>
        <app-popup :name="`appMessagePopup${participant.id}`">
            <appMessagePopup />
        </app-popup>
    </div>
</template>

<script>
import InfoIcon from '@/assets/img/icons/info.svg?inline';

import appButton from '@/components/user/app/app-button';
import appPopup from '@/components/user/app-popup';
import appMessagePopup from '@/components/user/app/app-message-popup';

import generateUrl from '@/utils/mixins/generate_url';

export default {
    name: 'app-participants-information',

    components: {
        InfoIcon,

        appButton,
        appPopup,
        appMessagePopup,
    },

    mixins: [generateUrl],

    props: {
        participant: {
            type: Object,
            default: null,
        },
    },

    computed: {
        participantName() {
            return `${this.participant.title} ${this.participant.firstName} ${this.participant.lastName}`;
        },

        userInfo() {
            return this.$store.getters['auth/userInfo'];
        },
    },

    methods: {
        openMessagePopup() {
            const options = {
                userId: this.participant.id,
            };
            this.$store.dispatch('togglePopup', { popupName: `appMessagePopup${this.participant.id}`, options });
        },
    },
};
</script>

<style scoped lang="scss">
    .app-participants-information {
        display: flex;
        justify-content: space-between;

        padding: 2.6rem 2.3rem 2.8rem 2.8rem;

        background: linear-gradient(to bottom, var(--platform-main-color), var(--platform-main-color) 9rem, white 9rem, white);

        &.short{
            background: var(--platform-main-color);

            .left{
                justify-content: center;
            }
        }

        .left {
            display: flex;
            flex-direction: column;

            width: 15rem;
        }

        &__avatar {
            width: 10em;
            height: 10rem;
            margin: 2.3rem auto 2rem;

            border-radius: 50%;
            object-fit: cover;
        }

        .right {
            width: 73.3%;
        }

        &__title {
            margin-bottom: 0.3rem;

            font-size: to_rem(24px);
            font-weight: 700;
            color: $mainTextColor;
            white-space: nowrap;
        }

        &__description {
            font-size: to_rem(18px);
            color: $mainTextColor;
        }

        &__info {
            margin-top: 4.8rem;
            padding: 1.2rem;

            word-break: break-word;

            border: 3px solid $lightGray;

            svg {
                width: 25px;
                margin-right: 0.3rem;

                circle {
                    fill: var(--platform-accent-color);
                }
            }

            &-title {
                display: flex;
                align-items: center;

                margin-bottom: 1.07rem;

                font-size: to_rem(14px);
                font-weight: 700;
            }

            p {
                font-size: to_rem(14px);
                line-height: 1.4;
                color: $gray;
            }

            a {
                display: block;

                margin-top: 0.7rem;

                font-size: to_rem(14px);
                color: var(--platform-accent-color);
            }
        }

        &.short{
            margin-bottom: 1rem;
            .app-participants-information__avatar {
                display: none;
            }
        }
    }

    @include mobile_or_P {
        .app-participants-information{
            padding: 2.5rem 1.2rem 2.5rem 1.2rem;
            .left{
                width: 25%;
            }
            &__avatar{
                width: 100%;
                height: auto;
                max-height: 12rem;
                margin-top: 2.6rem;
                object-fit: contain;
            }
            &__info{
                margin-top: 8rem;
                padding: 0.6rem;
            }
            &__info-title{
                margin-bottom: 0.67rem;
            }
        }
    }

    @include razr767{
        .app-participants-information{
            position: relative;

            flex-direction: column;

            padding: 0;

            background: none;
            &__title{
                margin-bottom: 0.6rem;

                font-size: 1.2rem;
            }
            &__description{
                font-size: 1.02rem;
            }
            &__avatar{
                width: 34%;
                margin-top: 1.5rem;
                margin-bottom: 1.3rem;
            }
            &__info{
                margin-top: 2rem;
                padding: 1.6rem 0.8rem;

                border-width: 5px;
                img{
                    width: 2.4rem;
                }
                p{
                    font-size: 1.175rem;
                    line-height: 1.5;
                }
                a{
                    margin-top: 0.9rem;

                    font-size: 1.275rem;
                }
            }
            &__info-title{
                margin-bottom: 1.5rem;

                font-size: 1.175rem;
            }
            .left{
                align-items: center;

                width: 100%;
            }
            .right{
                width: 100%;
                padding: 0 .5rem 1rem;
            }
            .short-info{
                width: 100%;
                margin-bottom: 1rem;
                padding: 1.1rem 1.6rem 1.9rem;

                background: var(--platform-accent-color);
            }
            .app-button{
                width: 17rem;
            }

            &.short{
                flex-direction: column-reverse;

                padding: 1.3rem;
                .left{
                    margin-top: 1.6rem;
                }
                .right{
                    padding-bottom: 0;
                }
            }
        }
    }
</style>
