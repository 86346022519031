<template>
    <div class="disposer-elibrary">
        <eventNav />

        <div class="disposer-app-title">
            E-Library
            <label class="disposer-app-title--checkbox">
                <input
                    v-model="isCheckActiveDashdord"
                    :class="{ active : isCheckActiveDashdord}"
                    type="checkbox"
                    @click="activeDashbord"
                />
            </label>
            <span class="title-info">Display on the dashboard</span>
        </div>

        <div
            v-for="section in sections"
            :key="section.id"
            class="library-section"
        >
            <app-section
                delete-warning="Are you sure to delete section?"
                :save="() => saveSection(section)"
                :delete-handler="() => deleteSection(section)"
            >
                <div class="input-title">
                    <label class="disposer-app-label">
                        Title
                        <input
                            v-model="section.title"
                            type="text"
                            class="disposer-app-input"
                        />
                    </label>
                    <label class="disposer-app-label flex">
                        <span>Collapsed</span>
                        <label class="disposer-app-title--checkbox">
                            <input
                                v-model="section.collapsed"
                                :class="{ active : section.collapsed}"
                                type="checkbox"
                            />
                        </label>
                    </label>
                    <label class="disposer-app-label">
                        Order
                        <div class="disposer-app-label--order">
                            <vue-numeric-input
                                v-model="section.number"
                                :min="1"
                                controls-type="updown"
                            />
                        </div>
                    </label>
                </div>
                <app-editor
                    v-model="section.content"
                    title="Contents"
                    class="section-editor"
                />
            </app-section>

            <div
                class="section-content"
                :class="{active: section.id>=1}"
            >
                <app-section>
                    <div class="disposer-app-topic-title">
                        Documents
                    </div>
                    <app-upload-documents
                        v-for="document in section.documents"
                        :key="document.id"
                        :document="document"
                        :event-id="eventId"
                        :section="section"
                        :paths="$options.objectActionsPaths"
                        @delete="deleteDocument"
                    />
                    <app-button @click="addDocument(section)">
                        Add document
                    </app-button>
                </app-section>

                <app-section>
                    <div class="disposer-app-topic-title">
                        Videos
                    </div>
                    <app-upload-video
                        v-for="video in section.videos"
                        :key="video.id"
                        :upload-type="3"
                        :video="video"
                        :event-id="eventId"
                        :section="section"
                        :paths="$options.objectActionsPaths"
                        @delete="deleteVideo"
                    />
                    <app-button @click="addVideo(section)">
                        Add video
                    </app-button>
                </app-section>

                <app-section>
                    <div class="disposer-app-topic-title">
                        Audios
                    </div>
                    <app-upload-audio
                        v-for="audios in section.audios"
                        :key="audios.id"
                        :audio="audios"
                        :section="section"
                        :event-id="eventId"
                        :paths="$options.objectActionsPaths"
                        @delete="deleteAudios"
                    />
                    <app-button @click="addAudio(section)">
                        Add audio recording
                    </app-button>
                </app-section>

                <app-section>
                    <div class="disposer-app-topic-title">
                        Links
                    </div>
                    <app-link
                        v-for="link in section.links"
                        :key="link.id"
                        :paths="$options.objectActionsPaths"
                        :link="link"
                        :event-id="eventId"
                        :section="section"
                        @delete="deleteLink"
                    />
                    <app-button @click="addLink(section)">
                        Add link
                    </app-button>
                </app-section>
            </div>
        </div>
        <div class="add-section-wrap">
            <app-button @click="addSection">
                Add section
            </app-button>
        </div>
    </div>
</template>

<script>
import eventNav from '@/components/disposer/pages/event/event-nav.vue';
import appSection from '@/components/disposer/app/app-section';
import appEditor from '@/components/disposer/app/app-editor';
import appUploadDocuments from '@/components/disposer/app/app-upload-documents';
import appUploadVideo from '@/components/disposer/app/app-upload-video';
import appUploadAudio from '@/components/disposer/app/app-upload-audio';
import appLink from '@/components/disposer/app/app-link';
import appButton from '@/components/disposer/app/app-button';
import VueNumericInput from 'vue-numeric-input';

import {
    AUDIO_TEMPLATE,
    DOCUMENT_TEMPLATE,
    LINK_TEMPLATE,
    VIDEO_TEMPLATE,
} from '@/utils/disposer/objects-templates';
import { deleteSection, saveLibraryInfo } from '@/utils/disposer/notifications-text-helper';
import { post } from '@/utils/http-helper';

const CREATE_SECTION = '/library/create-section';
const UPDATE_SECTION = '/library/update-section';
const DELETE_SECTION = '/library/delete-section';
const SECTION_TEMPLATE = id => ({
    id,
    title: '',
    content: '',
    documents: [],
    videos: [],
    links: [],
});

export default {
    name: 'disposer-elibrary',

    components: {
        eventNav,
        appSection,
        appEditor,
        appUploadDocuments,
        appUploadVideo,
        appLink,
        appButton,
        VueNumericInput,
        appUploadAudio,
    },

    data: () => ({
        sections: [],
        isCheckActiveDashdord: false,
    }),

    objectActionsPaths: {
        SAVE_OBJECT: '/library/create-object',
        UPDATE_OBJECT: '/library/update-object',
        DELETE_OBJECT: '/library/delete-object',
    },

    computed: {
        library() {
            return this.$store.getters['disposerLibrary/get'];
        },
        eventId() {
            return +this.$route.params.id;
        },
    },

    async created() {
        await this.$store.dispatch('disposerLibrary/get', this.eventId);
        this.sections = this.library.sections;
        this.sections.forEach(s => {
            if (s.videos) {
                s.videos = s.videos.map(v => {
                    return {
                        ...VIDEO_TEMPLATE(Math.random()),
                        ...v,
                    };
                });
            }
            if (s.audios) {
                s.audios = s.audios.map(v => {
                    return {
                        ...AUDIO_TEMPLATE(Math.random()),
                        ...v,
                    };
                });
            }
        });
        if (this.library?.display_dash === 1) {
            this.isCheckActiveDashdord = true;
        } else {
            this.isCheckActiveDashdord = false;
        }
    },

    methods: {
        addSection() {
            this.sections.push(SECTION_TEMPLATE(Math.random()));
        },
        async saveSection(section) {
            this.$awn.async(post(section.id >= 1 ? UPDATE_SECTION : CREATE_SECTION, {
                event_id: this.eventId,
                title: section.title,
                number: section.number,
                content: section.content,
                section_id: section.id,
                collapsed: section.collapsed,
            }), response => {
                if (response.status) {
                    section.id = response.data.id;
                    this.$awn.success(saveLibraryInfo);
                }
            });
        },
        async deleteSection(section) {
            this.$awn.async(post(DELETE_SECTION, {
                event_id: this.eventId,
                section_id: section.id,
            }), response => {
                if (response.status) {
                    this.sections = this.sections.filter(s => s.id !== section.id);
                    this.$awn.success(deleteSection);
                }
            });
        },
        addDocument(section) {
            section.documents.push(DOCUMENT_TEMPLATE(Math.random()));
        },
        deleteDocument(id, section) {
            section.documents = section.documents.filter(d => d.id !== id);
        },
        addVideo(section) {
            section.videos.push(VIDEO_TEMPLATE(Math.random()));
        },
        addAudio(section) {
            section.audios.push(AUDIO_TEMPLATE(Math.random()));
        },
        deleteVideo(id, section) {
            section.videos = section.videos.filter(d => d.id !== id);
        },
        deleteAudios(id, section) {
            section.audios = section.audios.filter(d => d.id !== id);
        },
        addLink(section) {
            section.links.push(LINK_TEMPLATE(Math.random()));
        },
        deleteLink(id, section) {
            section.links = section.links.filter(d => d.id !== id);
        },
        activeDashbord() {
            this.$awn.async(post('/event/change-display-dash', {
                event_id: this.eventId,
                status: this.isCheckActiveDashdord ? 0 : 1,
            }));
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-app-label.flex {
    display: flex;
    align-items: center;
}

.disposer-elibrary {
    .library-section {
        margin-bottom: 5rem;

        background: white;
    }

    .input-title {
        margin-bottom: 50px;

        .disposer-app-input {
            width: to_rem(740px);
        }
    }

    .add-section-wrap {
        display: flex;
        justify-content: flex-end;

        .app-button {
            width: 120px;
            height: 40px;
            margin: 20px;
            padding: 5px;
        }
    }

    .section-content {
        opacity: 0.5;

        pointer-events: none;

        &.active {
            opacity: 1;

            pointer-events: all;
        }
    }

    .title-info {
        font-size: 17px;
        color: #7f7f7f;
        padding-left: 12px;
    }
}
</style>

