<template>
    <div class="disposer-app-upload-video">
        <div class="upload-header">
            <div class="upload-current-file">
                <span
                    v-if="$v.video.file.$dirty && !$v.video.file.required"
                    class="file-error app-input-error"
                >
                    Choose a file
                </span>
                <span
                    v-if="errors.file"
                    class="file-error app-input-error"
                >
                    {{ errors.file[0] }}
                </span>
            </div>
        </div>

        <div class="upload-header">
            <label
                class="disposer-app-label"
            >
                Sorting order &nbsp;
                <vue-numeric-input
                    v-model="video.number"
                    :min="0"
                />
            </label>
        </div>

        <div class="upload-main">
            <div class="choose-video-cover">
                Choose video cover
            </div>

            <div class="video-cover-wrap">
                <img
                    v-if="video.coverUrl || preview"
                    class="video-cover"
                    :src="video.coverUrl || preview"
                />
                <app-file-input
                    class="mini"
                    :load-file="loadCover"
                    accept="image/*"
                >
                    Choose
                </app-file-input>
            </div>
        </div>

        <div class="upload-footer">
            <div class="upload-checkboxes">
                <div>
                    <label class="disposer-app-checkbox">
                        <input
                            v-model="video.secured"
                            type="checkbox"
                        />
                        <div class="box"></div>
                        Secured view-only format
                    </label>
                </div>
            </div>

            <div class="upload-name">
                <label
                    class="disposer-app-label"
                >
                    Name of video
                    <a
                        v-if="video.openLink || generateUrl(video.file, true)"
                        target="_blank"
                        :href="video.openLink || generateUrl(video.file, true)"
                    >
                        <i
                            class="fa fa-external-link"
                            title="Open video"
                        ></i>
                    </a>
                    <input
                        v-model="video.name"
                        type="text"
                        class="disposer-app-input"
                    />
                </label>
                <label
                    class="disposer-app-label"
                >
                    Description of video
                    <input
                        v-model="video.description"
                        type="text"
                        class="disposer-app-input"
                    />
                </label>
            </div>

            <div class="upload-btns">
                <div class="upload-choose">
                    <div class="title">
                        Choose file
                    </div>

                    <div class="info-icons">
                        <img
                            v-tooltip.right-start="$options.texts.chooseVideoIconMsg"
                            src="@/assets/img/icons/disposer/info-icon.png"
                            class="info-icon"
                        />
                    </div>
                </div>

                <div class="row mb-20">
                    <app-file-input
                        class="upload-btn mini"
                        :load-file="loadVideo"
                        accept="video/mp4,video/webm,video/mov"
                    >
                        Choose
                        <div class="name">
                            {{ video.fileName || video.native }}
                        </div>
                    </app-file-input>
                    <app-button
                        class="upload-btn mini red"
                        @click="saveVideo"
                    >
                        Save
                    </app-button>
                    <app-button
                        class="upload-btn mini"
                        @click="deleteVideo"
                    >
                        Delete
                    </app-button>
                </div>
            </div>
        </div>
        <div
            v-if="video.id > 1"
            class="subtitle-container"
        >
            <div
                v-for="(subtitle, index) in video.subtitles"
                :key="index"
                class="row subtitle-row"
            >
                <div class="file-input-container">
                    <app-file-input
                        class="upload-btn"
                        :file-index="index"
                        :load-file="addSubtitleFile"
                        accept=".vtt"
                    >
                        Choose subtitle file
                    </app-file-input>
                </div>
                <div
                    v-if="subtitle.file"
                    class="input-group"
                >
                    <label>Name</label>
                    <input
                        v-model="subtitle.native"
                        type="text"
                        class="disposer-app-input"
                    />
                </div>
                <div
                    v-if="subtitle.file"
                    class="input-group"
                >
                    <label>Subtitle language</label>
                    <select
                        id="language"
                        v-model="subtitle.lang"
                        size="7"
                        class="disposer-app-select"
                    >
                        <option
                            v-for="opt in $options.languages"
                            :key="opt.value"
                            :value="opt.value"
                        >
                            {{ opt.label }}
                        </option>
                    </select>
                </div>
                <div class="button-container">
                    <button
                        class="upload-btn"
                        @click="deleteSubtitle(index)"
                    >
                        Delete subtitle
                    </button>
                    <button
                        class="upload-btn red"
                        :disabled="!checkSubtitlesUpload(subtitle)"
                        @click="uploadSubtitle(index)"
                    >
                        Upload subtitle
                    </button>
                </div>
            </div>
            <app-button
                class="upload-btn red"
                @click="addSubtitle"
            >
                Add subtitle
            </app-button>
        </div>
    </div>
</template>

<script>
import appButton from '@/components/disposer/app/app-button';
import appFileInput from '@/components/disposer/app/app-file-input';

import { chooseVideoIconMsg } from '@/utils/disposer/icons-text-helper.js';
import {
    defaultConfirmSettings,
    deletedVideo,
    loadedVideo,
} from '@/utils/disposer/notifications-text-helper';
import { post } from '@/utils/http-helper';
import { parseImg } from '@/utils/disposer/parse-files-helper';
import { required } from 'vuelidate/lib/validators';
import generateUrl from '@/utils/mixins/generate_url';

import VueNumericInput from 'vue-numeric-input';
import languages from '@/assets/js/languages';

const CREATE_SUBTITLE = '/subtitle/create';
const DELETE_SUBTITLE = '/subtitle/delete';

export default {
    name: 'app-upload-video',
    languages: languages,
    components: {
        appButton,
        appFileInput,
        VueNumericInput,
    },
    mixins: [generateUrl],
    texts: {
        chooseVideoIconMsg,
    },
    props: {
        video: {
            type: Object,
            default: () => {
            },
        },
        paths: {
            type: Object,
            default: () => {
            },
        },
        topicId: {
            type: Number,
            default: null,
        },
        eventId: {
            type: Number,
            default: null,
        },
        uploadType: {
            type: Number,
            default: null,
        },
        section: {
            type: Object,
            default: null,
        },
        questionId: {
            type: Number,
            default: null,
        },
        type: {
            type: Number,
            default: null,
        },
    },

    data: () => ({
        errors: {},
        subtitles: [],
        subtitleName: '',
    }),

    computed: {
        preview() {
            return this.generateUrl(this.video.preview, ' ');
        },
    },

    validations: {
        video: {
            file: {
                required,
            },
        },
    },

    mounted() {
        if (!this.video.subtitles) {
            this.$set(this.video, 'subtitles', []);
        }
    },

    methods: {
        checkSubtitlesUpload(subtitle) {
            return !!(subtitle.file && subtitle.native && subtitle.lang);
        },
        loadVideo(video) {
            this.video.file = video;
            this.video.fileName = video.name;
            if (!this.video.name) {
                this.video.name = video.name;
            }
            this.video.openLink = window.URL.createObjectURL(video);
        },
        addSubtitle() {
            const pushItem = {
                event_id: this.eventId,
                subtype: this.type || this.video.type,
                type: this.uploadType || 1,
                video_id: this.video.id,
                lang: '',
                file: '',
                native: '',
            };
            this.video.subtitles = [...this.video.subtitles, pushItem];
        },
        deleteSubtitle(index) {
            this.video.subtitles.splice(index, 1);
            if (this.video.subtitles[index]?.id) {
                this.$awn.async(post(DELETE_SUBTITLE, {
                    subtitle_id: this.video.subtitles[index].id,
                    event_id: this.video.subtitles[index].event_id,
                    type: this.video.subtitles[index].type,
                }), response => {
                    if (response.status) {
                        this.$awn.success(deletedVideo);
                    }
                });
            }
        },
        addSubtitleFile(subtitle, index) {
            this.video.subtitles = this.video.subtitles.map((item, i) =>
                i === index ? { ...item, file: subtitle, native: subtitle.name } : item,
            );
        },
        async uploadSubtitle(index) {
            const fd = new FormData();
            fd.append('event_id', this.eventId.toString());
            fd.append('subtype', this.video.subtitles[index].subtype);
            fd.append('type', this.video.subtitles[index].type);
            fd.append('video_id', this.video.id);
            fd.append('lang', this.video.subtitles[index].lang);
            fd.append('file', this.video.subtitles[index].file);
            fd.append('native', this.video.subtitles[index].native);
            this.$awn.async(post(CREATE_SUBTITLE, fd, true), response => {
                if (response.status) {
                    this.$awn.success(loadedVideo);
                } else {
                    this.errors = response.errors;
                }
            });
        },
        async loadCover(img) {
            this.video.cover = img;
            this.video.coverUrl = await parseImg(img);
        },
        async saveVideo() {
            if (this.$v.video.$invalid) {
                this.$v.video.$touch();
                return;
            }
            const fd = new FormData();
            if (this.video.number === undefined) {
                this.video.number = 0;
            }
            fd.append('object', 'video');
            fd.append('name', this.video.name);
            fd.append('description', this.video.description);
            fd.append('secured', +this.video.secured);
            fd.append('file', this.video.file);
            fd.append('preview', this.video.cover);
            fd.append('id', this.video.id);
            this.type ? fd.append('type', this.type) : fd.append('type', this.video.type);
            fd.append('number', this.video.number);
            this.topicId ? fd.append('topic_id', this.topicId) : fd.append('event_id', this.eventId);
            this.section ? fd.append('section_id', this.section.id) : null;
            this.questionId ? fd.append('question_id', this.questionId) : null;

            this.$awn.async(post(this.video.id >= 1 ? this.paths.UPDATE_OBJECT : this.paths.SAVE_OBJECT, fd, true), response => {
                if (response.status) {
                    this.video.id = response.data.id;
                    if (!this.video.subtitles) {
                        this.video.subtitles = [];
                    }
                    this.$awn.success(loadedVideo);
                } else {
                    this.errors = response.errors;
                }
            });

        },
        async deleteVideo() {
            this.$confirm({
                ...defaultConfirmSettings,
                callback: confirm => {
                    if (confirm) {
                        if (this.video.id >= 1) {
                            this.$awn.async(post(this.paths.DELETE_OBJECT, {
                                topic_id: this.topicId,
                                event_id: this.eventId,
                                id: this.video.id,
                                object: 'video',
                            }), response => {
                                if (response.status) {
                                    this.$emit('delete', this.video.id, this.section);
                                    this.$awn.success(deletedVideo);
                                }
                            });
                        } else {
                            this.$emit('delete', this.video.id, this.section);
                        }
                    }
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.disposer-app-upload-video {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .disposer-app-input {
        width: to_rem(270px);
    }

    .upload-main {
        .choose-video-cover {
            margin-bottom: 20px;
        }

        .video-cover-wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            width: to_rem(440px);
            margin-bottom: 2rem;

            img {
                width: 100%;
                margin-right: 1rem;
            }

            .upload-btn {
                margin: 0;
            }

            .video-cover {
                border: 1px solid $gray;
            }
        }
    }

    .upload-footer {
        display: flex;
        justify-content: space-between;

        .upload-checkboxes {
            margin-right: 1rem;
        }

        .upload-name {
            margin-right: 1rem;
        }
    }

    .upload-current-file {
        position: relative;

        margin-bottom: 1rem;

        color: grey;
    }

    .row {
        display: flex;
    }

    .upload-btns {
        .upload-choose {
            display: flex;

            .info-icons {
                margin-left: 10px;
            }
        }

        label {
            position: relative;
        }

        .name {
            position: absolute;
            top: 120%;
            left: 0;

            width: 250px;

            color: black;
        }
    }

    .upload-btn {
        margin-top: 0.75rem;
        margin-right: 1rem;
    }
}

.subtitle-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: #f5f5f5;
    border-radius: 0.5rem;


    .upload-btn {
        background-color: #222d30;
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.red {
            background-color: #dc3545;
        }

        &:hover {
            background-color: darken(#007bff, 10%);
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
    }

    .subtitle-row {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: center;
        background-color: #fff;
        padding: 1rem;
        border-radius: 0.25rem;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }

    .file-input-container {
        flex: 1;
    }

    .input-group {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        label {
            font-weight: bold;
        }

        .disposer-app-input {
            padding: 0.5rem;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        .disposer-app-select {
            padding: 0.5rem;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        .disposer-app-input:focus,
        .disposer-app-select:focus {
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    .button-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
