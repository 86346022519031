import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import store from './store';

import VTooltip from 'v-tooltip';
import Vuelidate from 'vuelidate';
import ScrollLoader from 'vue-scroll-loader';
import { VueMaskDirective } from 'v-mask';
import TextareaAutosize from 'vue-textarea-autosize';
import Paginate from 'vuejs-paginate';
import '@/assets/scss/main.scss';

import '@/assets/js/deviceDetecter.js';
import '@/plugins/translate.js';
import '@/plugins/awn.js';
import '@/plugins/confirm.js';
import './ckeditor';

Vue.component('paginations', Paginate);

window.$ = window.jQuery = require('jquery');

export const bus = new Vue();

Vue.use(Vuex);
Vue.use(VTooltip, {
    defaultHtml: false,
});
Vue.use(Vuelidate);
Vue.use(ScrollLoader);
Vue.use(TextareaAutosize);
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;

window.APP = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
