<template>
    <div class="app-resources">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'app-resources',
};
</script>

<style scoped lang="scss">
.app-resources {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 0.5rem 1.75rem;

    font-size: to_rem(16px);
    color: $copyGray;

    background: white;

    .resource {
        width: 49%;
        margin-bottom: 0.5rem;
    }

    a {
        display: flex;
        align-items: center;

        margin-bottom: 0.6rem;

        color: var(--platform-accent-color);
        word-break: break-word;

        span {
            margin-left: 0.3rem;

            color: $copyGray;
            font-style: italic;
        }
    }

    p {
        width: 100%;
    }

    &::v-deep {
        img, svg {
            width: 2rem;
            height: 1rem;
            margin-right: 0.6rem;

            path {
                fill: var(--platform-accent-color);
            }
        }
    }
}

@include mobile_or_P {
    .app-resources {
        padding: 1.8rem 1.25rem;

        img {
            width: 0.7rem;
        }

        .resource {
            margin-bottom: 1.1rem;
        }
    }
}

@include razr767 {
    .app-resources {
        flex-direction: column;

        padding: 1.7rem 1.75rem;

        font-size: 12px;

        a {
            margin-bottom: 0.9rem;

            font-size: 12px;
        }

        img {
            width: 1rem;
            margin-right: 0.9rem;
        }

        .resource {
            width: 100%;
            margin-bottom: 1.5rem;
        }
    }
}
</style>
