<template>
    <div class="home-layout">
        <slot></slot>
    </div>
</template>

<script>

    export default {
        name: 'home-layout',
    };
</script>

<style scoped lang="scss">
</style>
