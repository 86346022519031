<template>
    <div
        v-if="library"
        class="e-library"
    >
        <!-- <div class="page-title">
            {{ $t('library', 'E-Library') }}
        </div> -->

        <template v-if="library">
            <form
                class="section"
                @submit.prevent="searchLibrary"
            >
                <label class="flex justify-end">
                    <div class="e-library__search">
                        <input
                            v-model="searchQuery"
                            type="text"
                            class="app-input"
                        />
                        <a
                            class="form-reset"
                            @click="resetLibrary"
                        >&times;</a>
                    </div>
                    &nbsp;&nbsp;
                    <app-button
                        tag="button"
                        type="submit"
                        class="accent"
                    >
                        {{ $t('profile_msg_search', 'Search') }}
                    </app-button>
                </label>
            </form>
            <div
                v-for="section in library"
                :key="section.id"
                class="section"
            >
                <div
                    class="app-topic-title"
                    @click="section.collapsed = !section.collapsed"
                >
                    <span v-if="section.title">{{ section.title }}</span>
                    <span v-else></span>
                    <span
                        class="icon-collapse"
                        :class="{'collapsed' : section.collapsed}"
                    ></span>
                </div>
                <template v-if="!section.collapsed">
                    <app-information v-if="section.content && !(searchQuery !== '' && (!section.links.length && !section.documents.length && !section.videos.length && !section.audios.length))">
                        <div class="head">
                            <InfoIcon />
                            {{ $t('library_quick_info', 'Quick Info') }}:
                        </div>
                        <p
                            class="view"
                            v-html="section.content"
                        ></p>
                    </app-information>

                    <app-resources v-if="section.links.length">
                        <div
                            v-for="link of section.links"
                            :key="link.id"
                            class="resource"
                        >
                            <a
                                :href="link.link"
                                target="_blank"
                                @click="sendAnalytic(3, link.id)"
                            >
                                <LinkIcon />
                                {{ link.link }}
                            </a>
                            <p>
                                {{ link.description }}
                            </p>
                        </div>
                    </app-resources>

                    <app-resources v-if="section.documents.length">
                        <div
                            v-for="document of section.documents"
                            :key="document.id"
                            class="resource"
                        >
                            <a
                                v-if="!document.secured"
                                :href="generateFileUrl(document.file)"
                                target="_blank"
                                @click="sendAnalytic(1, document.id)"
                            >
                                <PDFIcon />
                                {{ document.name }}
                            </a>
                            <router-link
                                v-else
                                :to="{ name:'view', query: { document_id: document.id, part: 'library' } }"
                                target="_blank"
                                @click.native="sendAnalytic(1, document.id)"
                            >
                                <PDFIcon />
                                {{ document.name }}
                            </router-link>
                            <p>
                                {{ document.description }}
                            </p>
                        </div>
                    </app-resources>

                    <app-videos-gallery
                        v-if="section.videos.length"
                        :videos="section.videos"
                    />
                    <app-audios-gallery
                        v-if="section.audios.length"
                        :audios="section.audios"
                    />
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import InfoIcon from '@/assets/img/icons/info.svg?inline';
import LinkIcon from '@/assets/img/icons/link_icon.svg?inline';
import PDFIcon from '@/assets/img/icons/pdf_icon.svg?inline';

import appInformation from '@/components/user/app/app-information';
import appResources from '@/components/user/app/app-resources';
import appVideosGallery from '@/components/user/app/app-videos-gallery';
import appAudiosGallery from '@/components/user/app/app-audios-gallery';
import appButton from '@/components/user/app/app-button';


import { sendClick } from '@/utils/user/clicks-analytic';
import { generateFileUrl } from '@/utils/http-helper';

export default {
    name: 'e-library',

    components: {
        InfoIcon,
        LinkIcon,
        PDFIcon,

        appButton,
        appInformation,
        appResources,
        appVideosGallery,
        appAudiosGallery,
    },
    data(){
        return {
            searchQuery: '',
        };
    },
    computed: {
        library() {
            return this.$store.getters.getLibrary?.sections;
        },
        eventId() {
            return this.$route.query.event_id;
        },
    },
    async created() {
        await this.$store.dispatch('getLibrary', { event_id: this.eventId});
    },

    methods: {
        async searchLibrary() {
            clearTimeout(window.searchLibT);
            window.searchLibT = setTimeout(async () => {
                await this.$store.dispatch('getLibrary', { event_id: this.eventId, query: this.searchQuery});
            }, 300);
        },
        resetLibrary(){
            this.searchQuery = '';
            this.searchLibrary();
        },
        sendAnalytic(type, id) {
            sendClick(3, type, id, this.topicId);
        },
        generateFileUrl(file) {
            return generateFileUrl(file);
        },
    },
};
</script>

<style scoped lang="scss">
.section {
    margin-bottom: 1.2rem;
}
.flex{
    display: flex;
    align-items: center;
}
.justify-end{
    justify-content: flex-end;
}
.app-topic-title{
    justify-content: space-between;
    cursor: pointer;
}
.icon-collapse{
    position: relative;
    margin-left: 0;
    display: block;
    //width: 20px;
    //height: 10px;
    //background: url('../../../assets/img/icons/disposer/bottom-arrow_icon.svg') center center / contain no-repeat;
    transform: scale(1, -1);
    font-size: 150%;
    line-height: 0.5;
}
.icon-collapse:after{
    content: '−';
}
.icon-collapse.collapsed:after{
    //transform: scale(1, 1);
    content: '+'
}
.e-library__search{
    position: relative;
    width: 100%;
    max-width: 420px;
}
.e-library__search .app-input{
    padding-right: 30px;
}
.form-reset{
    text-decoration: none;
    font-size: 175%;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    width: 30px;
    text-align: center;
    transform: translate(0, -50%);
}
</style>
