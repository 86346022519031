import { post } from '@/utils/http-helper';

const CLICK = '/analytic/click';

export const sendClick = (partId, typeId, fileId, topicId = null, total_length = null, view_length = null) => {
    const obj = {
        topic_id: topicId,
        part_id: partId,
        type_id: typeId,
        resource_id: fileId,
        total_length,
        view_length,
    };
    post(CLICK, obj);
};
