<template>
    <div class="plug">
        Please turn the device over
    </div>
</template>

<script>
  export default {
    name: 'plug',
  };
</script>

<style lang="scss">
    .plug {
        display: none;
    }
    @media screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape){
        html {
            overflow: hidden;
        }
        .plug {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 999999999999999;

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: to_rem(50px);
            color: white;

            background: $lightBlue;
        }
    }
</style>
