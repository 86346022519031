<template>
    <div class="app-date-picker">
        <label
            class="disposer-app-label"
            :class="{date: mode==='date'}"
        >
            {{ title }}
            <v-date-picker
                v-model="state"
                :mode="mode"
                locale="en"
                :popover="{
                    visibility: 'focus',
                }"
                :is24hr="true"
                :masks="{ input: 'DD.MM.YYYY' }"
            >
                <template v-slot="{ inputValue, inputEvents }">
                    <input
                        class="disposer-app-input px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="inputValue"
                        v-on="inputEvents"
                    />
                </template>
            </v-date-picker>
            <span
                v-if="requiredError"
                class="app-input-error"
            >
                Select {{ mode }}
            </span>
        </label>
    </div>
</template>

<script>
    import VDatePicker from 'v-calendar/lib/components/date-picker.umd';

    export default {
        name: 'app-date-picker',
        components: {
          VDatePicker,
        },
        props: {
            value: {
                type: null,
                required: true,
                default: null,
            },
            title: {
                type: String,
                default: '',
            },
            mode: {
                type: String,
                default: '',
            },
            requiredError: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            state: {
                  get() {
                    return this.value;
                  },

                  set(value) {
                    this.$emit('input', value);
                  },
            },
        },
    };
</script>

<style scoped lang="scss">
    .app-date-picker{
        position: relative;
        .disposer-app-label {
            margin-bottom: 0;
        }
        &::v-deep {
            .date {
                .vc-popover-content-wrapper {
                    width: 252px;
                }
            }
            .vc-container {
                width: auto;

                border-radius: 0.2rem;
            }
            .vc-time-picker {
                .vc-date {
                    display: none;
                }
            }
        }
    }
</style>
