<template>
    <div class="disposer-managers">
        <div class="disposer-app-title">
            Project Manager
        </div>

        <app-button @click="createManagerMode">
            Create
        </app-button>

        <div class="managers-table">
            <div v-if="createMode || editMode">
                <tr>
                    <th>
                        First name
                    </th>
                    <th>
                        Last name
                    </th>
                    <th>
                        E-mail
                    </th>
                    <th>
                        Password
                    </th>
                    <th>
                        Confirm password
                    </th>
                    <th></th>
                </tr>

                <tr class="inputs">
                    <td>
                        <label>
                            <input
                                v-model="firstName"
                                class="disposer-app-input"
                                type="text"
                            />
                            <p
                                v-if="$v.firstName.$dirty && !$v.firstName.required"
                                class="app-input-error"
                            >
                                This field is required
                            </p>
                        </label>
                    </td>
                    <td>
                        <label>
                            <input
                                v-model="lastName"
                                class="disposer-app-input"
                                type="text"
                            />
                            <p
                                v-if="$v.lastName.$dirty && !$v.lastName.required"
                                class="app-input-error"
                            >
                                This field is required
                            </p>
                        </label>
                    </td>
                    <td>
                        <label>
                            <input
                                v-model="email"
                                class="disposer-app-input"
                                type="text"
                            />
                            <p
                                v-if="$v.email.$dirty && !$v.email.required"
                                class="app-input-error"
                            >
                                This field is required
                            </p>
                            <p
                                v-else-if="$v.email.$dirty && !$v.email.email"
                                class="app-input-error"
                            >
                                Invalid value
                            </p>
                            <p
                                v-if="errors.email"
                                class="app-input-error"
                            >
                                {{ errors.email[0] }}
                            </p>
                        </label>
                    </td>
                    <td>
                        <div class="pass">
                            <input
                                v-model="password"
                                class="disposer-app-input"
                                :type="passInputIsShow? 'text': 'password'"
                                placeholder="********"
                            />
                            <i
                                class="fa fa-eye show-password"
                                @mousedown="toggleShowPass(true)"
                                @mouseup="toggleShowPass(false)"
                            ></i>
                            <p
                                v-if="$v.password.$dirty && !$v.password.required"
                                class="app-input-error"
                            >
                                This field is required
                            </p>
                            <p
                                v-else-if="$v.password.$dirty && !$v.password.minLength"
                                class="app-input-error"
                            >
                                Password must be more 8 characters
                            </p>
                        </div>
                    </td>
                    <td>
                        <label>
                            <input
                                v-model="confirmPassword"
                                class="disposer-app-input"
                                type="password"
                                placeholder="********"
                            />
                            <p
                                v-if="$v.confirmPassword.$dirty && !$v.confirmPassword.isConfirm"
                                class="app-input-error"
                            >
                                Confirm password must be equal
                            </p>
                        </label>
                    </td>
                    <td>
                        <div v-if="editCurrentManager">
                            <app-actions
                                :save="updateManager"
                                :trash="endAddManagerHandler"
                            />
                        </div>

                        <div v-else>
                            <app-actions
                                :save="addManager"
                                :trash="endAddManagerHandler"
                            />
                        </div>
                    </td>
                </tr>
            </div>

            <table class="body">
                <tr
                    v-for="(manager, idx) of managersList.managers"
                    :key="manager.id"
                    :class="{main: manager.main}"
                >
                    <td>
                        {{ ++idx }}
                    </td>
                    <td>
                        {{ manager.firstName + ' ' + manager.lastName }}
                    </td>
                    <td>
                        {{ manager.email }}
                    </td>
                    <td>
                        ********
                    </td>
                    <td>
                        ********
                    </td>
                    <td>
                        {{ manager.created_at }}
                    </td>
                    <td>
                        <div
                            v-if="userInfo && userInfo.role.main"
                            class="main"
                        >
                            <app-actions
                                v-if="manager.firstName === userInfo.firstName"
                                :edit="() => editManager(manager)"
                            />
                            <app-actions
                                v-else
                                :edit="() => editManager(manager)"
                                :trash="() => deleteManager(manager)"
                            />
                        </div>
                        <div
                            v-else
                            class="others"
                        >
                            <app-actions
                                v-if="manager.firstName === userInfo.firstName"
                                :edit="() => editManager(manager)"
                            />
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import appButton from '@/components/disposer/app/app-button';
    import appActions from '@/components/disposer/app/app-actions';

    import { required, email, minLength } from 'vuelidate/lib/validators';
    import { post } from '@/utils/http-helper';
    import { managerCreated, defaultConfirmSettings } from '@/utils/disposer/notifications-text-helper';

    const MANAGER_CREATE = '/manager/create';

    export default {
        name: 'disposer-managers',

        components: {
            appButton,
            appActions,
        },

        data: () => ({
            createMode: false,
            editMode: false,
            editCurrentManager: false,

            currentEditManager: {},

            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            passInputIsShow: false,
            errors: {},
        }),

        validations: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          email: {
            required,
            email,
          },
          password: {
            required,
            minLength: minLength(8),
          },
          confirmPassword: {
            required,
            isConfirm() {
              return this.password === this.confirmPassword;
            },
          },
        },

        computed: {
            managersList() {
                return this.$store.getters.managersList;
            },

            userInfo() {
                return this.$store.getters['auth/userInfo'];
            },
        },

        async mounted() {
            await this.$store.dispatch('getManagersList');
        },

        methods: {
            toggleShowPass(status) {
                this.passInputIsShow = status;
            },

            createManagerMode() {
                this.createMode = !this.createMode;
                this.editMode = false;
                this.cleanInputs();
            },

            endAddManagerHandler() {
              this.$confirm({
                ...defaultConfirmSettings,
                callback: confirm => {
                  if (confirm) {
                    this.endAddManager();
                  }
                },
              });
            },

            endAddManager() {
              this.editMode = false;
              this.createMode = false;
              this.cleanInputs();
              this.$v.$reset();
            },

            cleanInputs() {
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.password = '';
                this.confirmPassword = '';
            },

            async addManager() {
                if (this.$v.$invalid) {
                    this.$v.$touch();
                    return;
                }

                const newManager = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    password_confirm: this.confirmPassword,
                };

                this.$awn.async(post(MANAGER_CREATE, newManager),
                  response => {
                    if (response.status) {
                      this.$store.dispatch('getManagersList');
                      this.endAddManager();
                      this.errors = {};
                      this.$awn.success(managerCreated);
                    } else  {
                      this.errors = response.errors;
                    }
                  });
            },

            async updateManager() {
                const updatedManager = {
                    id: this.currentEditManager.id,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    password_confirm: this.confirmPassword,
                };
                await this.$store.dispatch('updateManager', updatedManager);
                this.cleanInputs();
                this.editMode = !this.editMode;
            },

            editManager(manager) {
                this.createMode = false;
                this.editMode = !this.editMode;
                this.editCurrentManager = true;

                this.firstName = manager.firstName;
                this.lastName = manager.lastName;
                this.email = manager.email;

                this.password = '';
                this.confirmPassword = '';

                this.currentEditManager = Object.assign({}, manager);
            },

            deleteManager(manager) {
                this.$confirm({
                    ...defaultConfirmSettings,
                    callback: confirm => {
                      if (confirm) {
                        this.$store.dispatch('deleteManager', manager);
                      }
                    },
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .disposer-managers{
        .input-wrap {
            position: relative;

            display: flex;
        }

        .app-button{
            margin-top: 1.4rem;
            padding: 0.65rem 1.9rem;
        }
        label {
            position: relative;

            display: block;
        }
        .pass {
            position: relative;
            .show-password {
                position: absolute;
                top: 50%;
                right: 25px;

                font-size: to_rem(18px);

                transform: translateY(-50%);
                cursor: pointer;
            }
        }
        .managers-table{
            margin-top: 1.6rem;
            margin-left: 0.2rem;

            font-size: to_rem(14px);
            text-align: left;
            .main {
                font-weight: 700;
            }
            tr{
                &.inputs{
                    td{
                        padding-top: 1rem;
                        padding-bottom: 1.8rem;
                        &:nth-of-type(1){
                            padding-right: 4.2rem;
                            input {
                                width: 7rem;
                            }
                        }
                        &:nth-of-type(2){
                            padding-right: 4.35rem;
                            input {
                                width: 7rem;
                            }
                        }
                        &:nth-of-type(3){
                            padding-right: 4.35rem;
                            input {
                                width: 17rem;
                            }
                        }
                        &:nth-of-type(4){
                            padding-right: 2.1rem;
                            input {
                                width: 8rem;
                            }
                        }
                        &:nth-of-type(5){
                            padding-right: 2.1rem;
                            input {
                                width: 8rem;
                            }
                        }
                        &:nth-of-type(6){
                            padding-right: 1rem;
                        }
                    }
                }
            }
            th{
                &:nth-of-type(1){
                    width: 20.5rem;
                }
                &:nth-of-type(2){
                    width: 20.7rem;
                }
                &:nth-of-type(3){
                    width: 13.85rem;
                }
                &:nth-of-type(4){
                    width: 11.55rem;
                }
            }
            .body{
                margin-top: 2rem;
                tr{
                    &:nth-of-type(2n + 1){
                        background: white;
                    }
                }
                td{
                    padding: .7rem .5rem;
                    &:nth-of-type(1){
                        width: 3rem;
                    }
                    &:nth-of-type(2){
                        width: 17.3rem;
                    }
                    &:nth-of-type(3){
                        width: 20.6rem;
                    }
                    &:nth-of-type(4){
                        width: 6.8rem;
                    }
                    &:nth-of-type(5){
                        width: 6.8rem;
                    }
                    &:nth-of-type(6){
                        width: 10.9rem;
                    }
                }
            }
        }
    }

</style>
