<template>
    <div
        v-if="content"
        class="stickers-board"
        :class="{disable: disableAll}"
    >
        <input
            v-model="content.title"
            class="title"
            contenteditable="true"
            placeholder="Page title"
            @input="renamePage"
        />
        <div class="board">
            <div class="board__head">
                <div
                    class="app-tool-btn pre accent"
                    :title="$t('previous', 'Previews')"
                    :class="{disabled: activePage === 1}"
                    @click="switchPage(activePage-1)"
                >
                    <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                </div>
                <div class="moderation forDesktop">
                    <div
                        class="app-tool-btn start-moderation-btn"
                        title="Moderator Board"
                        :class="{red: isModerator, disabled: otherModerator}"
                        @click="startModerator"
                    >
                        <img src="@/assets/img/icons/tools/start-moderation_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn"
                        :class="{disabled: !isModerator}"
                        @click="clearArrows(true)"
                    >
                        <img src="@/assets/img/icons/tools/clear_icon.svg" />
                    </div>
                </div>
                <div class="user-actions">
                    <div
                        class="app-tool-btn app-page-btn"
                        title="New page"
                        :class="{disabled: allowPrintArrow}"
                        @click="openPopup"
                    >
                        <img src="@/assets/img/icons/tools/add-page_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn add-sticker-btn"
                        title="New sticker"
                        :class="{active: createStickerStart, disabled: allowPrintArrow}"
                        @click="createSticker"
                    >
                        <img src="@/assets/img/icons/tools/add-sticker_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn"
                        title="Draw arrow"
                        :class="{active: allowPrintArrow}"
                        @click="toggleAllowPrintArrow"
                    >
                        <img src="@/assets/img/icons/tools/draw-arrow_icon.svg" />
                    </div>
                    <div
                        v-if="allowPrintArrow"
                        class="arrow-settings"
                    >
                        <input
                            v-model="arrowWeight"
                            title="The width of the line - 5"
                            type="range"
                            min="3"
                            max="15"
                        />

                        <appColorPicker v-model="arrowColor" />
                    </div>
                    <div
                        class="app-tool-btn accent"
                        title="Clear all my arrows"
                        :class="{disabled: allowPrintArrow}"
                        @click="clearArrows(false)"
                    >
                        <img src="@/assets/img/icons/tools/clear_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn accent"
                        title="New voting"
                        :class="{disabled: allowPrintArrow}"
                        @click="openVotePopup"
                    >
                        <img src="@/assets/img/icons/tools/add-voting_icon.svg" />
                    </div>
                </div>
                <app-language-select
                    v-if="showTranslating"
                    @change="translate"
                />
                <div
                    class="app-tool-btn next accent"
                    :title="$t('next', 'Next')"
                    :class="{disabled: activePage === content.total_page}"
                    @click="switchPage(activePage+1)"
                >
                    <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                </div>
            </div>
            <div
                ref="board"
                class="board__view"
                @touchstart="startPrintArrow($event)"
                @mousedown="startPrintArrow($event)"
            >
                <div
                    ref="boardContent"
                    class="board__content"
                >
                    <div
                        class="arrows"
                        :class="{edited: allowPrintArrow}"
                    >
                        <div
                            v-for="(arrow, index) of arrows"
                            :key="arrow.id"
                            class="arrow"
                            :class="{disabled: allowPrintArrow}"
                            @mouseover="toggleDelete(arrow.id)"
                            @mouseleave="toggleDelete(false)"
                        >
                            <svg
                                width="100%"
                                height="100%"
                            >
                                <defs>
                                    <marker
                                        :id="`arrowhead${index}`"
                                        :refX="arrow.width*5"
                                        :refY="arrow.width*2.9"
                                        markerUnits="userSpaceOnUse"
                                        orient="auto"
                                        :markerWidth="arrow.width*10"
                                        :markerHeight="arrow.width*10"
                                    >
                                        <polyline
                                            :id="`markerPoly${index}`"
                                            :points="`0,0 ${arrow.width*6}, ${arrow.width*3} 0,${arrow.width*5.5} ${arrow.width*2.5}, ${arrow.width*3} 0,0`"
                                            :stroke="arrow.color"
                                            :fill="arrow.color"
                                            :stroke-width="arrow.width/5"
                                        />
                                    </marker>
                                </defs>
                                <line
                                    :x1="arrow.b.x"
                                    :y1="arrow.b.y"
                                    :x2="arrow.e.x"
                                    :y2="arrow.e.y"
                                    :stroke="arrow.color"
                                    :stroke-width="arrow.width"
                                    :marker-end="`url(#arrowhead${index})`"
                                />
                            </svg>
                            <div
                                v-if="arrow.showDelete && (arrow.user_id === userInfo.id || isModerator)"
                                class="delete"
                                :style="{top: `${(arrow.e.y-arrow.b.y)/2 + arrow.b.y}px`, left: `${(arrow.e.x-arrow.b.x)/2 + arrow.b.x}px`}"
                                @click="deleteArrow(arrow.id)"
                            ></div>
                        </div>
                    </div>

                    <vue-drag-resize
                        v-for="sticker of stickers"
                        :key="sticker.id"
                        :parent-limitation="true"
                        :x="sticker.x"
                        :y="sticker.y"
                        :z="sticker.z"
                        :w="sticker.width"
                        :h="sticker.height"
                        :minw="280"
                        :minh="140"
                        :style="{background: sticker.color}"
                        :is-active="sticker.isActive"
                        :class="{disabled: openedSticker || allowPrintArrow}"
                        :prevent-active-behavior="allowPrintArrow"
                        drag-cancel=".likes"
                        @clicked="clickToSticker($event, sticker)"
                        @activated="activateSticker(sticker.id)"
                        @deactivated="deactivatedSticker(sticker.id)"
                        @resizestop="(e) => {resizeMoveSticker(e, sticker.id);}"
                        @dragstop="(e) => {resizeMoveSticker(e, sticker.id);}"
                    >
                        <div
                            class="sticker-content"
                            @dblclick="openMore(sticker.id)"
                            @mousedown="openStickerSettings($event, sticker.id)"
                            @contextmenu.prevent="()=>{return false}"
                        >
                            <div class="top">
                                <div class="left">
                                    <h3>
                                        {{ sticker.author }}
                                    </h3>
                                    <p>
                                        {{ $t('of_comments', 'Comments') }}
                                        {{ sticker.count_comments }}
                                    </p>
                                </div>
                                <div
                                    class="likes"
                                    @click.stop="likeSticker(sticker)"
                                >
                                    {{ sticker.count_likes }}
                                    <img
                                        v-if="!sticker.self_like"
                                        src="@/assets/img/icons/tools/like_icon.svg"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/img/icons/tools/like-active.svg"
                                    />
                                </div>
                            </div>
                            <p>
                                <span>
                                    {{ sticker.content }}
                                </span>
                            </p>
                            <div class="settings">
                                <img src="@/assets/img/icons/tools/settings_icon.svg" />
                            </div>
                            <div
                                v-if="sticker.settingsIsOpen"
                                v-click-outside="closeStickerSettings"
                                :style="{top: sticker.settingsY + 'px', left: sticker.settingsX + 'px'}"
                                class="colors"
                                :data-id="sticker.id"
                            >
                                <div
                                    class="to-front"
                                    @click="stickerToFront(sticker.id)"
                                >
                                    <i class="fa fa-angle-double-up"></i>
                                    {{ $t('to_front', 'To front') }}
                                </div>
                                <div
                                    v-for="(color, colorIndex) of colors"
                                    :key="colorIndex"
                                    class="color"
                                    :data-color="color.color"
                                    @click.stop="changeStickerColor(sticker.id, color.color)"
                                >
                                    <i
                                        class="fa fa-paint-brush"
                                        :style="{color: color.color}"
                                    ></i>
                                    {{ color.name }}
                                </div>
                                <div
                                    class="delete"
                                    @click="deleteSticker(sticker.id)"
                                >
                                    <i class="fa fa-trash-o"></i>
                                    {{ $t('delete', 'Delete') }}
                                </div>
                            </div>
                            <div class="open forMobile">
                                <i class="fa fa-search"></i>
                            </div>
                        </div>
                    </vue-drag-resize>

                    <div
                        v-if="openedSticker"
                        class="sticker-all-info sticker-content"
                        :style="{background: openedSticker.color}"
                    >
                        <div class="top">
                            <div class="left">
                                <h3>
                                    {{ openedSticker.author }}
                                </h3>
                                <p>
                                    {{ openedSticker.created_at }} |
                                    {{ $t('of_comments', 'Comments') }}
                                    {{ openedSticker.count_comments }}
                                </p>
                            </div>
                            <div class="right">
                                <app-language-select
                                    v-if="showTranslating"
                                    class="small"
                                    @change="translateSticker"
                                />
                                <div
                                    class="likes"
                                    @click="likeSticker(openedSticker)"
                                >
                                    {{ openedSticker.count_likes }}
                                    <img
                                        v-if="!openedSticker.self_like"
                                        src="@/assets/img/icons/tools/like_icon.svg"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/img/icons/tools/like-active.svg"
                                    />
                                </div>
                                <div
                                    class="closer"
                                    @click="closeMore"
                                >
                                    <i class="fa fa-times"></i>
                                </div>
                            </div>
                        </div>
                        <p class="main-comment">
                            <span
                                id="commentText"
                                :placeholder="$t('default_text_sticker', 'The creator can enter the text here')"
                                :contenteditable="+userInfo.id === +openedSticker.user_id"
                                @input="changeStickerText"
                                v-html="openedSticker.content"
                            ></span>
                        </p>
                        <div class="comments">
                            <div
                                v-for="comment in openedSticker.comments"
                                :key="comment.id"
                                class="comment"
                            >
                                <div class="name">
                                    {{ comment.author }}
                                </div>
                                <div class="text">
                                    {{ comment.content }}
                                </div>
                            </div>
                        </div>
                        <form @submit.prevent="sendStickerComment">
                            <textarea
                                v-model="stickerComment"
                                class="app-input"
                                maxlength="1000"
                                :placeholder="`${$t('enter_comment', 'Please, enter comment')}:`"
                                @keyup="checkSend"
                            ></textarea>
                            <app-button
                                tag="button"
                                class="narrow accent"
                                type="submit"
                            >
                                {{ $t('send', 'Send') }}
                            </app-button>
                        </form>
                    </div>

                    <div
                        v-if="isModerator || (otherModerator && +moderatorPage===+activePage)"
                        class="moderator"
                        :style="{top: `${moderItemTop}px`, left: `${moderItemLeft}px`}"
                    ></div>
                </div>
            </div>
            <div class="board__bottom">
                <div
                    class="app-tool-btn pre accent"
                    :title="$t('previous', 'Previews')"
                    :class="{disabled: activePage === 1}"
                    @click="switchPage(activePage-1)"
                >
                    <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                </div>
                <form
                    class="pages"
                    @submit.prevent="switchPage(pageInputValue)"
                >
                    <span>
                        {{ activePage }}/ {{ content.total_page }}
                    </span>
                    <input
                        v-model="pageInputValue"
                        class="app-input white"
                        type="number"
                        :placeholder="$t('go_to', 'Go to')"
                    />
                    <button
                        type="submit"
                        class="app-tool-btn go accent"
                        title="Go to"
                    >
                        {{ $t('go', 'Go') }}
                    </button>
                </form>
                <div
                    class="app-tool-btn next accent"
                    :title="$t('next', 'Next')"
                    :class="{disabled: activePage === content.total_page}"
                    @click="switchPage(activePage+1)"
                >
                    <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                </div>
            </div>
        </div>
        <app-popup name="add-new-page">
            <add-new-page
                :socket="socket"
                :name-space="$options.surveysPaths.NAME_SPACE"
                @createPage="createPage"
            />
        </app-popup>
        <voting
            :surveys="surveys"
            :all-answered="allAnswered"
            @deleteSurvey="deleteSurvey"
            @answer="answerSurvey"
            @translate="translate($event, true)"
        />
        <app-popup name="add-vote-popup">
            <add-vote-popup
                :socket="socket"
                :paths="$options.surveysPaths"
                @addSurvey="addSurvey"
                @editedSurvey="editedSurvey"
            />
        </app-popup>
        <app-popup name="stickersFunctionalityPopup">
            <functionalityPopup />
        </app-popup>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import VueDragResize from 'vue-drag-resize';
import appButton from '@/components/user/app/app-button';
import appColorPicker from '@/components/user/app/app-color-picker';
import addNewPage from '@/components/user/pages/editor/add-new-page';
import appPopup from '@/components/user/app-popup';
import addVotePopup from '@/components/user/pages/workshop/add-vote-popup';
import voting from '@/components/user/pages/workshop/voting';
import functionalityPopup from '@/components/user/pages/stickers/functionality-popup';
import appLanguageSelect from '@/components/user/app/app-language-select';

import { socketInstance, GP } from '@/utils/sockets-helper';
import { get, post } from '@/utils/http-helper';
import surveys from '@/utils/mixins/surveys';

import { disableScroll, enableScroll } from '@/assets/js/disableScroll';

const NAME_SPACE = 'sticker';
const SWITCH_PAGE = 'page-switch';
const RENAME_PAGE = 'page-rename';
const CREATE_STICKER = 'sticker-create';
const DELETE_STICKER = 'sticker-remove';
const CREATE_NEW_PAGE = 'page-create';
const RESIZE_STICKER = 'sticker-resize';
const MOVE_STICKER = 'sticker-move';
const CHANGE_STICKER_COLOR = 'sticker-color';
const FRONT_STICKER = 'sticker-front';
const LIKE_STICKER = 'sticker-like';
const UNLIKE_STICKER = 'sticker-unlike';
const UPDATE_TEXT = 'sticker-text';
const CREATE_STICKER_COMMENT = 'sticker-comment';
const CREATE_ARROW = 'arrow-create';
const DELETE_ARROW = 'arrow-remove';
const CLEAR_ARROWS = 'arrow-clear';
const START_MODERATOR = 'moderator-start';
const MODERATOR_PAGE = 'moderator-page';
const MODERATOR_MOUSE = 'moderator-mouse';
const FINISH_MODERATOR = 'moderator-stop';
const GET_COMMENTS = '/sticker/get-comments';
const TRANSLATE = '/sticker/translate';
const TRANSLATE_STICKER = '/sticker/translate-sticker';
const generateSocketPath = new GP(NAME_SPACE);

export default {
    name: 'stickers-board',

    components: {
        VueDragResize,
        appButton,
        appColorPicker,
        addNewPage,
        appPopup,
        addVotePopup,
        voting,
        functionalityPopup,
        appLanguageSelect,
    },

    surveysPaths: {
        NAME_SPACE: 'sticker',
        CREATE_SURVEY: 'survey-create',
        UPDATE_SURVEY: 'survey-update',
    },

    directives: {
        clickOutside: vClickOutside.directive,
    },

    mixins: [surveys(NAME_SPACE)],

    data: () => ({
        stickers: [],
        openedSticker: null,
        createStickerStart: false,

        activePage: 1,
        pageInputValue: '',
        stickerComment: '',

        arrows: [],
        allowPrintArrow: false,
        arrowColor: '#000000',
        arrowWeight: 5,

        socket: null,
        isModerator: false,
        otherModerator: false,
        moderItemTop: 0,
        moderItemLeft: 0,
        moderatorPage: 1,

        surveys: null,
        allAnswered: false,
    }),

    computed: {
        topicId() {
            return +this.$route.query.id;
        },
        content() {
            return this.$store.getters['stickers/get'];
        },
        userInfo() {
            return this.$store.getters['auth/userInfo'];
        },
        disableAll() {
            const mode = +this.$store.getters['auth/userInfo'].role.mode;
            return this.userInfo?.role.id !== 5 && this.userInfo?.role.id !== 6 || mode === 0;
        },
        colors() {
            return [
                {
                    name: `${this.$t('yellow', 'Yellow')}`,
                    color: '#fefedb',
                },
                {
                    name: `${this.$t('green', 'Green')}`,
                    color: '#d5eed5',
                },
                {
                    name: `${this.$t('pink', 'Pink')}`,
                    color: '#ef9ee5',
                },
                {
                    name: `${this.$t('gray', 'Gray')}`,
                    color: '#c7c5c5',
                },
                {
                    name: `${this.$t('cyan', 'Cyan')}`,
                    color: '#87c3f5',
                },
                {
                    name: `${this.$t('violet', 'Violet')}`,
                    color: '#b67de8',
                },
                {
                    name: `${this.$t('blue', 'Blue')}`,
                    color: '#8186ff',
                },
                {
                    name: `${this.$t('red', 'Red')}`,
                    color: '#ff8181',
                },
            ];
        },
        showTranslating() {
            return this.$store.getters['platform/platform'].settings.translating;
        },
    },

    async mounted() {
        await this.$store.dispatch('stickers/get', this.topicId);
        this.allAnswered = !!this.content?.is_all_answered;
        this.writeInfo();
        this.initSockets();
        this.initBoard();
        // функция из миксина
        this.subscribeSocketEvents();
    },

    methods: {
        initBoard() {
            if (window.innerWidth < 1024) return;
            const maxW = this.$refs.board?.offsetWidth;
            const boardW = this.$refs.boardContent?.offsetWidth;
            this.scalingFactor = maxW / boardW;
            this.$refs.boardContent.style.transform = `scale(${this.scalingFactor})`;
            this.$refs.board.style.height = `${this.$refs.boardContent?.offsetHeight * this.scalingFactor}px`;
        },
        writeInfo() {
            this.surveys = this.content.surveys;
            this.arrows = this.content.arrows.map(a => {
                a.showDelete = false;
                return a;
            });
            this.stickers = this.content.stickers.map(s => {
                s.isActive = false;
                s.settingsIsOpen = false;
                s.settingsX = 0;
                s.settingsY = 0;
                s.initialZ = s.z;
                return s;
            });
            this.allAnswered = !!this.content?.is_all_answered;
        },
        initSockets() {
            this.socket = socketInstance(NAME_SPACE, { room: `room-${ this.topicId }` }, async () => {
                if (this.$route.query.page) {
                    this.switchPage(+this.$route.query.page);
                }
            });
            this.socket.on(generateSocketPath.generate(CREATE_NEW_PAGE), data => {
                if (data.success) {
                    this.createPage();
                }
            });
            this.socket.on(generateSocketPath.generate(RENAME_PAGE), data => {
                if (data.success) {
                    this.$store.dispatch('stickers/renamePage', data.title);
                }
            });
            this.socket.on(generateSocketPath.generate(CREATE_STICKER), data => {
                if (data.success) {
                    data.count_likes = 0;
                    data.count_comments = 0;
                    this.stickers.push(data);
                }
            });
            this.socket.on(generateSocketPath.generate(RESIZE_STICKER), data => {
                if (data.success) {
                    const sticker = this.stickers.find(s => +s.id === +data.id);
                    sticker.width = data.width;
                    sticker.height = data.height;
                }
            });
            this.socket.on(generateSocketPath.generate(MOVE_STICKER), data => {
                if (data.success) {
                    const sticker = this.stickers.find(s => +s.id === +data.id);
                    sticker.x = data.x;
                    sticker.y = data.y;
                }
            });
            this.socket.on(generateSocketPath.generate(CHANGE_STICKER_COLOR), data => {
                if (data.success) {
                    const sticker = this.stickers.find(s => +s.id === +data.id);
                    sticker.color = data.color;
                }
            });
            this.socket.on(generateSocketPath.generate(FRONT_STICKER), data => {
                if (data.success) {
                    this.stickerToFront(data.id, data);
                }
            });
            this.socket.on(generateSocketPath.generate(LIKE_STICKER), data => {
                if (data.success) {
                    const sticker = this.stickers.find(s => +s.id === +data.id);
                    sticker.count_likes++;
                }
            });
            this.socket.on(generateSocketPath.generate(UNLIKE_STICKER), data => {
                if (data.success) {
                    const sticker = this.stickers.find(s => +s.id === +data.id);
                    sticker.count_likes--;
                }
            });
            this.socket.on(generateSocketPath.generate(UPDATE_TEXT), data => {
                if (data.success) {
                    const sticker = this.stickers.find(s => +s.id === +data.id);
                    sticker.content = data.content;
                }
            });
            this.socket.on(generateSocketPath.generate(CREATE_STICKER_COMMENT), data => {
                if (data.success) {
                    this.addStickerComment(data.sticker_id, data);
                }
            });
            this.socket.on(generateSocketPath.generate(DELETE_STICKER), data => {
                if (data.success) {
                    this.stickers = this.stickers.filter(s => +s.id !== +data.id);
                }
            });
            this.socket.on(generateSocketPath.generate(CREATE_ARROW), data => {
                if (data.success) {
                    this.arrows.push(data);
                }
            });
            this.socket.on(generateSocketPath.generate(DELETE_ARROW), data => {
                if (data.success) {
                    this.arrows = this.arrows.filter(a => +a.id !== data.id);
                }
            });
            this.socket.on(generateSocketPath.generate(CLEAR_ARROWS), data => {
                if (data.success) {
                    if (+data.user_id) {
                        this.arrows = this.arrows.filter(a => +a.user_id !== +data.user_id);
                    } else {
                        this.arrows = [];
                    }
                }
            });
            this.socket.on(generateSocketPath.generate(START_MODERATOR), data => {
                if (data.success) {
                    this.switchPage(data.page);
                    this.moderatorPage = data.page;
                    this.otherModerator = true;
                }
            });
            this.socket.on(this.generateSocketPath.generate(MODERATOR_PAGE), data => {
                if (data.success) {
                    this.switchPage(data.page);
                    this.moderatorPage = data.page;
                }
            });
            this.socket.on(generateSocketPath.generate(MODERATOR_MOUSE), data => {
                if (data.success) {
                    this.otherModerator = true;
                    this.moderItemTop = data.position.y;
                    this.moderItemLeft = data.position.x;
                }
            });
            this.socket.on(generateSocketPath.generate(FINISH_MODERATOR), data => {
                if (data.success) {
                    this.otherModerator = false;
                }
            });
        },
        openPopup() {
            this.$store.dispatch('togglePopup', { popupName: 'add-new-page' });
        },
        createPage() {
            this.content.total_page++;
        },
        renamePage() {
            clearTimeout(window.renamePageT);
            window.renamePageT = setTimeout(() => {
                this.socket.emit(generateSocketPath.generate(RENAME_PAGE), {
                    title: this.content.title,
                });
            }, 500);
        },
        switchPage(num) {
            if (!num) return;
            num < 1 ? num = this.pageInputValue = 1 : null;
            if (num > this.content?.total_page) {
                num = this.pageInputValue = this.content?.total_page;
            }
            this.socketSwitchPage(num);
            this.pageInputValue = '';

            if (+this.$route.query.page !== +num) {
                this.$router.replace({ query: { ...this.$route.query, page: num } });
            }
        },
        socketSwitchPage(page) {
            this.socket.emit(generateSocketPath.generate(SWITCH_PAGE), { page: page }, data => {
                if (data.success) {
                    this.activePage = page;
                    this.getPage(page);
                }
            });
        },
        async getPage(page) {
            await this.$store.dispatch('stickers/getPage', { topicId: this.topicId, page });
            this.writeInfo();
        },

        createSticker() {
            const initialW = 300;
            const initialH = 300;

            this.createStickerStart = true;
            const board = this.$refs.board;
            board.onclick = (e) => {
                let x = e.offsetX;
                let y = e.offsetY;
                const dX = e.offsetX + initialW - board.offsetWidth;
                const dY = e.offsetY + initialH - board.offsetHeight;
                dX > 0 ? x -= dX : null;
                dY > 0 ? y -= dY : null;
                this.socket.emit(generateSocketPath.generate(CREATE_STICKER), {
                    content: '',
                    width: 300,
                    height: 300,
                    x: x,
                    y: y,
                    color: '#d7efd7',
                }, data => {
                    if (data.success) {
                        data.count_likes = 0;
                        data.count_comments = 0;
                        this.stickers.push(data);
                    }
                });
                this.createStickerStart = false;
                board.onclick = null;
            };
        },
        deleteSticker(id) {
            this.socket.emit(generateSocketPath.generate(DELETE_STICKER), {
                id,
            }, data => {
                if (data.success) {
                    this.stickers = this.stickers.filter(s => +s.id !== +id);
                }
            });
        },
        resizeMoveSticker(e, id) {
            this.socket.emit(generateSocketPath.generate(RESIZE_STICKER), {
                id,
                width: e.width,
                height: e.height,
            });
            this.socket.emit(generateSocketPath.generate(MOVE_STICKER), {
                id,
                x: e.left,
                y: e.top,
            });
        },
        likeSticker(sticker) {
            if (sticker.self_like) {
                this.socket.emit(generateSocketPath.generate(UNLIKE_STICKER), { id: sticker.id });
                sticker.count_likes--;
                sticker.self_like = 0;
            } else {
                this.socket.emit(generateSocketPath.generate(LIKE_STICKER), { id: sticker.id });
                sticker.count_likes++;
                sticker.self_like = 1;
            }
        },
        changeStickerText(e) {
            clearTimeout(window.changeTextT);
            window.changeTextT = setTimeout(() => {
                const text = e.target.innerText.substr(0, 1000);
                if (text.length > 999) {
                    e.target.innerText = e.target.innerText.substr(0, 1000);
                }

                this.socket.emit(generateSocketPath.generate(UPDATE_TEXT), {
                    id: this.openedSticker.id,
                    content: text,
                });
            }, 300);
        },
        checkSend(e) {
            if (e.which == 13 && !e.shiftKey) {
                this.sendStickerComment();
            }
        },
        sendStickerComment() {
            if (!this.stickerComment.trim()) return;
            this.socket.emit(generateSocketPath.generate(CREATE_STICKER_COMMENT), {
                id: this.openedSticker.id,
                content: this.stickerComment,
            }, data => {
                if (data.success) {
                    this.addStickerComment(this.openedSticker.id, data);
                    this.stickerComment = '';
                }
            });
        },
        addStickerComment(id, data) {
            const stickers = [...this.stickers];
            const sticker = stickers.find((s) => +s.id === +id);
            if (sticker.comments) {
                sticker.comments.push(data);
                sticker.count_comments++;
            }
            this.stickers = stickers;
        },

        toggleDelete(id) {
            const arrows = [...this.arrows];
            if (id) {
                const arrow = arrows.find(a => +a.id === +id);
                arrow.showDelete = true;
                this.arrows = [...arrows];
            } else {
                this.arrows = this.arrows.map(a => {
                    a.showDelete = false;
                    return a;
                });
            }
        },
        toggleAllowPrintArrow() {
            this.allowPrintArrow = !this.allowPrintArrow;
        },
        startPrintArrow(e) {
            if (!this.allowPrintArrow) return;
            if (window.isMobileOrTablet) {
                disableScroll();
                this.$refs.board.ontouchmove = this.printedArrow;
                this.$refs.board.ontouchend = this.endPrintArrow;
            } else {
                this.$refs.board.onmousemove = this.printedArrow;
                this.$refs.board.onmouseup = this.endPrintArrow;
            }

            let x = e.offsetX;
            let y = e.offsetY;
            if (window.isMobileOrTablet) {
                const rect = e.target.getBoundingClientRect();
                x = e.targetTouches[0].clientX - rect.left;
                y = e.targetTouches[0].clientY - rect.top;
            }

            const arrowProps = {
                b: {
                    x,
                    y,
                },
                e: {
                    x,
                    y,
                },
                width: this.arrowWeight,
                color: this.arrowColor,
            };

            this.arrows.push(arrowProps);
        },
        printedArrow(e) {
            const arrowProps = this.arrows.pop();
            if (window.isMobileOrTablet) {
                const rect = e.target.getBoundingClientRect();
                const x = e.targetTouches[0].pageX - rect.left;
                const y = e.targetTouches[0].pageY - rect.top;
                arrowProps.e.x = x;
                arrowProps.e.y = y;
            } else {
                arrowProps.e.x = e.offsetX;
                arrowProps.e.y = e.offsetY;
            }
            arrowProps.showDelete = false;
            this.arrows.push(arrowProps);
        },
        endPrintArrow() {
            const arrow = this.arrows.pop();
            const arrowLength = ((arrow.b.x - arrow.e.x) ^ 2 + (arrow.b.y - arrow.e.y) ^ 2) ^ 0.5;
            if (Math.abs(arrowLength) >= 25) {
                this.socket.emit(generateSocketPath.generate(CREATE_ARROW), arrow, data => {
                    arrow.showDelete = false;
                    arrow.id = data.id;
                    arrow.user_id = +this.userInfo.id;
                    this.arrows.push(arrow);
                });
            }
            if (window.isMobileOrTablet) {
                this.$refs.board.ontouchmove = null;
                this.$refs.board.ontouchend = null;
            } else {
                this.$refs.board.onmousemove = null;
                this.$refs.board.onmouseup = null;
            }
            if (window.isMobileOrTablet) {
                enableScroll();
            }
        },
        deleteArrow(id) {
            this.socket.emit(generateSocketPath.generate(DELETE_ARROW), { id }, data => {
                if (data.success) {
                    this.arrows = this.arrows.filter(a => +a.id !== +id);
                }
            });
        },
        clearArrows(isAll) {
            let message = `Are you sure?`;
            if (isAll) {
                message = 'Are you sure you want to remove all arrows';
            }
            this.$confirm({
                message: message,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: confirm => {
                    if (confirm) {
                        this.socket.emit(generateSocketPath.generate(CLEAR_ARROWS), { all: isAll }, data => {
                            if (data.success) {
                                if (!this.isModerator) {
                                    this.arrows = this.arrows.filter(a => +a.user_id !== +this.userInfo.id);
                                } else {
                                    this.arrows = [];
                                }
                            }
                        });
                    }
                },
            });
        },

        clickToSticker(e, sticker) {
            if (!window.isMobileOrTablet) return;
            if (e.target.closest('.likes')) {
                this.likeSticker(sticker);
            }
            const color = e.target.closest('.colors .color');
            if (color) {
                this.changeStickerColor(sticker.id, color.getAttribute('data-color'));
            }
            if (e.target.closest('.to-front')) {
                this.stickerToFront(sticker.id);
            }
            if (e.target.closest('.delete')) {
                this.deleteSticker(sticker.id);
            }
            if (e.target.closest('.settings')) {
                this.openStickerSettings(e, sticker.id);
            }
            if (e.target.closest('.open')) {
                this.openMore(sticker.id);
            }
        },
        activateSticker(id) {
            const stickers = [...this.stickers];
            stickers.forEach(sticker => {
                if (+sticker.id === +id) {
                    sticker.isActive = true;
                    sticker.z = 999;
                    return;
                }
                sticker.isActive = false;
                sticker.z = sticker.initialZ;
            });
            this.stickers = stickers;
        },
        deactivatedSticker(id) {
            const sticker = this.stickers.find(s => +s.id === +id);
            sticker.isActive = false;
            sticker.z = sticker.initialZ;
        },
        stickerToFront(id, data) {
            let stickers = [...this.stickers];
            const sticker = stickers.find(s => +s.id === +id);
            stickers.forEach(sticker => {
                sticker.z = sticker.initialZ;
            });
            this.stickers = stickers;
            if (!data) {
                this.socket.emit(generateSocketPath.generate(FRONT_STICKER), { id }, data => {
                    if (data.success) {
                        sticker.initialZ = +data.z;
                        sticker.z = +data.z;
                        sticker.settingsIsOpen = false;
                    }
                });
            } else {
                sticker.initialZ = +data.z;
                sticker.z = +data.z;
                sticker.settingsIsOpen = false;
            }
        },
        async openMore(id) {
            const comments = await get(GET_COMMENTS, { sticker_id: id });
            const sticker = this.stickers.find(s => +s.id === +id);
            this.openedSticker = sticker;
            this.openedSticker.content = comments.data.content;
            this.$set(this.openedSticker, 'comments', comments.data.comments || []);
        },
        closeMore() {
            this.openedSticker.content = document.querySelector('#commentText').innerText;
            this.openedSticker = null;
        },
        closeStickerSettings() {
            const stickers = [...this.stickers];
            stickers.forEach((sticker) => {
                sticker.settingsIsOpen = false;
            });
            this.stickers = stickers;
        },
        openStickerSettings(e, id) {
            const stickers = [...this.stickers];
            const sticker = stickers.find(s => +s.id === +id);
            if (e.button === 2 || e.target.closest('.settings')) {
                setTimeout(() => {
                    this.activateSticker(sticker.id);
                    sticker.settingsX = e.clientX - e.target.closest('.sticker-content').getBoundingClientRect().left;
                    sticker.settingsY = e.clientY - e.target.closest('.sticker-content').getBoundingClientRect().top;
                    sticker.settingsIsOpen = true;
                    this.stickers = stickers;
                }, 200);
            }
        },
        changeStickerColor(id, color) {
            this.socket.emit(generateSocketPath.generate(CHANGE_STICKER_COLOR), {
                id,
                color: color,
            }, data => {
                if (data.success) {
                    const sticker = this.stickers.find(s => +s.id === +id);
                    sticker.color = color;
                    sticker.settingsIsOpen = false;
                }
            });
        },

        openVotePopup() {
            this.$store.dispatch('togglePopup', { popupName: 'add-vote-popup' });
        },
        startModerator() {
            if (this.isModerator) {
                this.finishModerator();
                return;
            }
            this.$confirm({
                message: `Are you sure to start moderation?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: confirm => {
                    if (confirm) {
                        this.socket.emit(generateSocketPath.generate(START_MODERATOR), {}, data => {
                            if (data.success) {
                                this.isModerator = true;
                                this.$refs.boardContent.onmousemove = (e) => {
                                    if (e.target.closest('.board__content')) {
                                        const LX = this.$refs.boardContent.getBoundingClientRect().left;
                                        const LY = this.$refs.boardContent.getBoundingClientRect().top;
                                        this.moderItemTop = (e.clientY - LY) / this.scalingFactor;
                                        this.moderItemLeft = (e.clientX - LX) / this.scalingFactor;
                                    }
                                };
                                window.moderatorMouseI = setInterval(() => {
                                    this.socket.emit(generateSocketPath.generate(MODERATOR_MOUSE), {
                                        position: {
                                            x: this.moderItemLeft,
                                            y: this.moderItemTop,
                                        },
                                    });
                                }, 10);
                            }
                        });
                    }
                },
            });
        },
        finishModerator() {
            this.$confirm({
                message: `Are you sure to stop moderation?`,
                button: {
                    no: 'No',
                    yes: 'Yes',
                },
                callback: confirm => {
                    if (confirm) {
                        this.socket.emit(generateSocketPath.generate(FINISH_MODERATOR), {}, data => {
                            if (data.success) {
                                this.isModerator = false;
                                clearInterval(window.moderatorMouseI);
                                this.$refs.boardContent.onmousemove = null;
                            }
                        });
                    }
                },
            });
        },

        async translate(lang, surveys) {
            if (lang) {
                const response = await post(TRANSLATE, {
                    topic_id: +this.topicId,
                    page: +this.activePage,
                    lang,
                });
                if (response.status) {
                    if (!surveys) {
                        this.stickers = this.stickers.map(s => {
                            const sticker = response.data.stickers.find(ts => ts.id === s.id);
                            s.content = sticker?.content || s.content;
                            return s;
                        });
                    } else {
                        this.surveys = this.surveys.map(c => {
                            const survey = response.data.surveys.find(t => t.id === c.id);
                            c.name = survey?.name || c.name;
                            c.options = survey?.options || c.options;
                            return c;
                        });
                    }
                }
            } else {
                this.switchPage(+this.activePage);
            }
        },
        async translateSticker(lang) {
            if (lang) {
                const response = await post(TRANSLATE_STICKER, {
                    topic_id: +this.topicId,
                    sticker_id: +this.openedSticker.id,
                    lang,
                });
                if (response.status) {
                    this.openedSticker.content = response.data.content;
                    this.openedSticker.comments = this.openedSticker.comments.map(c => {
                        const comment = response.data.comments.find(tc => +tc.id === +c.id);
                        c.content =  comment.content;
                        return c;
                    });
                }
            } else {
                this.openMore(this.openedSticker.id);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.stickers-board {
    margin-bottom: 4rem;

    &.disable {
        pointer-events: none;

        .app-popup, .pre, .next, .pages {
            pointer-events: all;
        }
    }
}

.go {
    width: auto;
    min-width: 2.5rem;
}

.title {
    width: 100%;
    margin-top: 2.55rem;

    font-size: to_rem(30px);
    font-weight: 700;
    letter-spacing: 0.023rem;

    background: none;
    border: none;
}

.moderator {
    position: absolute;
    z-index: 1000000;

    width: 10px;
    height: 10px;

    background: $red;
    border-radius: 50%;

    transform: translate(-50%, -50%);

    pointer-events: none;
}

.board {
    position: relative;

    margin-top: 1.6rem;
    padding: 1rem 1rem 0;

    background: var(--platform-main-color);

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0.8rem 0.7rem;

        background: $mainBackgroundColor;
    }

    &__view {
        position: relative;
        z-index: 10000;

        background: white;

        .arrows {
            pointer-events: none;

            svg {
                pointer-events: none;

                * {
                    cursor: pointer;

                    pointer-events: all;
                }
            }

            &.edited {
                pointer-events: all;
            }

            .arrow {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 998;

                &.disabled {
                    user-select: none;
                    pointer-events: none;

                    * {
                        pointer-events: none;
                    }
                }

                .delete {
                    position: absolute;
                    z-index: 10;

                    width: 3%;
                    height: 3%;

                    background: url("~@/assets/img/icons/delete.svg") no-repeat center center/contain;

                    transform: translate(-50%, -50%);
                    cursor: pointer;

                    pointer-events: all;
                }
            }
        }
    }

    &__content {
        width: 1400px;
        height: 700px;

        transform-origin: top left;

    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        height: to_rem(55px);

        background: var(--platform-main-color);

        .pages {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: to_rem(14px);
            color: $mainTextColor;

            input {
                width: 4rem;
                margin: 0 0 0 0.5rem;

                text-align: center;
            }

            button {
                border: none;
            }
        }
    }

    .user-actions {
        display: flex;

        margin-right: 25.8rem;
    }

    .moderation {
        display: flex;

        margin-right: 14rem;
    }

    .vdr {
        border: 1px solid $gray;
        border-radius: .5rem;

        &.disabled {
            user-select: none;
            pointer-events: none;
        }
    }

    .arrow-settings {
        display: flex;
        align-items: center;
    }

    .sticker-content {
        position: relative;

        height: 100%;
        padding: 6px;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            height: 3rem;
            margin-bottom: 3px;
            padding-bottom: 4px;

            border-bottom: 3px solid black;

            .left {
                max-width: 60%;
            }
        }

        h3 {
            overflow: hidden;

            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.6px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        p {
            max-height: calc(100% - 5.1rem);
            overflow: hidden;

            font-size: 12px;
            line-height: 1.45;
            letter-spacing: .5px;

            span {
                word-break: break-word;
            }
        }

        .likes {
            display: flex;
            align-items: flex-end;

            font-size: 18px;

            cursor: pointer;

            img {
                display: block;

                width: 24px;
                margin-bottom: 3px;
                margin-left: 3px;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .settings {
            position: absolute;
            right: 10px;
            bottom: 10px;
            z-index: 1;

            img {
                width: 16px;
                height: 16px;

                cursor: pointer;
            }

        }

        .open {
            position: absolute;
            bottom: 10px;
            left: 10px;
            z-index: 10;
        }

        .to-front {
            border-bottom: 1px solid $gray;
        }

        .delete {
            border-top: 1px solid $gray;
        }

        .colors {
            position: absolute;
            top: 100%;
            z-index: 1000000000;

            width: 120px;

            font-size: 14px;

            background: white;
            border: 1px solid black;

            & > div {
                padding: 3px 8px;

                cursor: pointer;

                &:hover {
                    color: $mainTextColor;

                    background: $mainBlue;
                }
            }
        }
    }

    .sticker-all-info {
        position: absolute;
        top: 1.5rem;
        left: 50%;
        z-index: 1000;

        width: 450px;
        height: auto;

        border: 1px solid $gray;
        border-radius: .5rem;

        transform: translateX(-50%);

        .right {
            display: flex;
            align-items: center;
        }

        .closer {
            margin-left: 0.5rem;

            font-size: to_rem(20px);

            cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }
        }

        p.main-comment {
            max-height: 150px;
            margin-bottom: 1rem;
            overflow: auto;

            border-bottom: 1px solid $darkGray;

            span {
                display: block;

                min-height: 10px;
                margin: 0.5rem 0;
                padding: 0.3rem;

                outline: none;

                &:empty:not(:focus):before {
                    content: attr(placeholder);

                    color: $darkGray;

                    pointer-events: none;
                }
            }
        }

        .comments {
            float: right;

            height: 15rem;
            margin-bottom: 0.5rem;
            padding-right: .5rem;
            overflow: auto;

            text-align: right;
        }

        .name {
            padding: 0.2rem 0;

            font-size: to_rem(14px);
            font-weight: 700;
        }

        .text {
            padding: 0.2rem 0;

            font-size: to_rem(12.5px);
        }

        textarea {
            height: 4rem;
            padding: .5rem;

            background: white;

            resize: none;
        }

        .app-button {
            margin-top: .5rem;
            margin-left: auto;
        }
    }
}

.votes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 2.5rem 0;

    .app-voting {
        width: 49%;
    }
}

@include razr1100 {
    .board {
        &__view {
            overflow: auto;
        }
    }
}

@include mobile_or_P {
    .stickers-board {
        padding: 0 0.7rem;
    }
    h1 {
        margin-top: 1.15rem;
        padding-left: .4rem;

        font-size: 1.49rem;
    }
    .board {
        margin-top: 1.5rem;
        padding: 3.4375rem 0.5125rem 0;

        &__head {
            align-items: flex-start;

            padding: 0.8rem 0.3rem;
        }

        .user-actions {
            flex-wrap: wrap;

            width: 64%;
            margin-right: 0;

            input {
                width: 9rem;
            }
        }

        .moderation {
            margin-right: 1rem;
        }

        .sticker-content {
            .colors {
                top: auto;
                right: 0;
                bottom: 0;
                z-index: 100000000000;
            }
        }

        .arrow-settings {
            order: 10;

            margin-top: 1rem;
        }
    }
    .votes {
        flex-direction: column;

        margin: 1.2rem 0;
        padding: 0 0.5rem;

        .app-voting {
            width: 100%;
            margin-bottom: 1.2rem;
        }
    }
}

@include razr767 {
    .stickers-board {
        padding: 0;
    }
    h1 {
        padding: 0 1rem;

        font-size: 18px;
        line-height: 1.25;
    }
    .board {
        margin-top: 4.9rem;
        padding: 1rem 1rem 0;

        &__head {
            flex-wrap: wrap;

            padding: 1.65rem .4rem;

            .app-language-select, .app-tool-btn.next {
                margin-top: 10px;
            }
        }

        &__bottom {
            align-items: center;

            padding: 0 1.3rem;

            .pages {
                font-size: 12px;
            }
        }

        .moderation {
            display: none;
        }

        .user-actions {
            margin-right: 0;
        }
    }
    .votes {
        padding: 0 .8rem;
    }
}
</style>
