<template>
    <span class="app-labels-imgs">
        <i
            v-if="format === 1"
            class="fa fa-comments"
        ></i>
        <i
            v-if="format === 2"
            class="fa fa-video-camera"
        ></i>
        <i
            v-if="format === 3"
            class="fa fa-eye"
        ></i>
        <i
            v-if="format === 4"
            class="fa fa-pencil-square-o"
        ></i>
        <i
            v-if="format === 5"
            class="fa fa-file-text"
        ></i>
        <i
            v-if="format === 6"
            class="fa fa-sticky-note"
        ></i>
        <template v-if="isConf">
            <i class="fa fa-plus"></i>
            <i class="fa fa-video-camera"></i>
        </template>
    </span>
</template>

<script>
  export default {
    name: 'format-icons',
    props: {
      format: {
        type: Number,
        default: null,
      },
      isConf: {
        type: Number,
        default: null,
      },
    },
  };
</script>

<style scoped lang="scss">
    .app-labels-imgs {
        font-size: to_rem(32px);
        i {
            margin: 0 0.5rem 0 0;
        }

        &.min {
            font-size: to_rem(18px);
            b {
                margin: 0 0.35rem;
            }

            img {
                width: 1.1rem;
                height: 1.2rem;
            }

            i {
                margin-right: 0.2rem;
            }
        }

    }
</style>
