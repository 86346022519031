<template>
    <div class="video">
        <video
            :src="video"
            controls
        ></video>
    </div>
</template>

<script>
import { get } from '@/utils/http-helper';

const GET_GUIDANCE_VIDEO = '/user-guidance/get-for-managers';

export default {
    name: 'video',

    data: () => ({
        video: null,
    }),

    mounted() {
        this.getVideoGuidance();
    },

    methods: {
        async getVideoGuidance() {
            const video = await get(GET_GUIDANCE_VIDEO);
            this.video = video.data.video;
        },
    },
};
</script>

<style scoped lang="scss">
    .video {
        width: 60rem;
        video {
            width: 100%;
            max-height: 36rem;
            object-fit: contain;
        }
    }
</style>
