<template>
    <div class="lds-dual-ring"></div>
</template>

<script>
export default {
    name: 'index',
};
</script>

<style scoped lang="scss">
.lds-dual-ring {
    position: absolute;
    top: 50%;
    left: 50%;

    display: inline-block;

    width: 80px;
    height: 80px;

    transform: translate(-50%, -50%);
}
.lds-dual-ring:after {
    content: " ";

    display: block;

    width: 64px;
    height: 64px;
    margin: 8px;

    border: 6px solid var(--platform-accent-color);
    border-color: var(--platform-accent-color) transparent var(--platform-accent-color) transparent;
    border-radius: 50%;

    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


</style>
