<template>
    <div
        v-if="company"
        class="company"
    >
        <!-- <div class="page-title">
            {{ $t('company', 'Company Info/Contacts') }}
        </div> -->

        <template v-if="company">
            <div
                v-for="section in company.sections"
                :key="section.id"
                class="section"
            >
                <div
                    v-if="section.title"
                    class="app-topic-title"
                >
                    {{ section.title }}
                </div>
                <app-information v-if="section.content">
                    <p
                        class="view"
                        v-html="section.content"
                    ></p>
                </app-information>

                <template v-if="section.links.length">
                    <app-resources>
                        <div
                            v-for="link of section.links"
                            :key="link.id"
                            class="resource"
                        >
                            <a
                                :href="link.link"
                                target="_blank"
                                @click="sendAnalytic(3, link.id)"
                            >
                                <LinkIcon />
                                {{ link.link }}
                            </a>
                            <p>
                                {{ link.description }}
                            </p>
                        </div>
                    </app-resources>
                </template>

                <template v-if=" section.documents.length">
                    <app-resources>
                        <div
                            v-for="document of section.documents"
                            :key="document.id"
                            class="resource"
                        >
                            <a
                                v-if="!document.secured"
                                :href="generateFileUrl(document.file)"
                                target="_blank"
                                @click="sendAnalytic(1, document.id)"
                            >
                                <PDFIcon />
                                {{ document.name }}
                            </a>
                            <router-link
                                v-else
                                :to="{ name:'view', query:{ document_id: document.id, part: 'company' } }"
                                target="_blank"
                                @click.native="sendAnalytic(1, document.id)"
                            >
                                <PDFIcon />
                                {{ document.name }}
                            </router-link>
                            <p>
                                {{ document.description }}
                            </p>
                        </div>
                    </app-resources>
                </template>

                <template v-if="section.videos.length">
                    <app-videos-gallery :videos="section.videos" />
                </template>
                <template v-if="section.audios.length">
                    <app-audios-gallery :audios="section.audios" />
                </template>
            </div>
        </template>

        <template v-if="company.representatives.length">
            <div class="app-topic-title company_participants">
                {{ companyRepresentativeTitle || $t('company_participants', 'Company Representative') }}
            </div>
            <app-participants-information
                v-for="representative of company.representatives"
                :key="representative.id"
                :participant="representative"
            />
        </template>
    </div>
</template>

<script>
import LinkIcon from '@/assets/img/icons/link_icon.svg?inline';
import PDFIcon from '@/assets/img/icons/pdf_icon.svg?inline';

import appInformation from '@/components/user/app/app-information';
import appResources from '@/components/user/app/app-resources';
import appVideosGallery from '@/components/user/app/app-videos-gallery';
import appAudiosGallery from '@/components/user/app/app-audios-gallery';
import appParticipantsInformation from '@/components/user/app/app-participants-information';

import { sendClick } from '@/utils/user/clicks-analytic';
import { generateFileUrl } from '@/utils/http-helper';

export default {
    name: 'company',

    components: {
        LinkIcon,
        PDFIcon,

        appInformation,
        appResources,
        appVideosGallery,
        appParticipantsInformation,
        appAudiosGallery,
    },

    computed: {
        company() {
            return this.$store.getters.getCompany;
        },
        eventId() {
            return this.$route.query.event_id;
        },
        companyRepresentativeTitle() {
            return this.$store.getters['platform/platform']?.customize.company.info_contact;
        },
    },

    async created() {
        await this.$store.dispatch('getCompany', this.eventId);
    },

    methods: {
        sendAnalytic(type, id) {
            sendClick(1, type, id);
        },

        generateFileUrl(file) {
            return generateFileUrl(file);
        },
    },
};
</script>

<style scoped lang="scss">
.app-topic-title{
    &.white{
        margin: 1rem 0;
    }

    &.company_participants {
        margin-bottom: 0.5rem;
    }
}

</style>
