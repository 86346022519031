<template>
    <div class="accept">
        <div class="accept__wrapper">
            <div
                v-if="logo"
                class="logo"
            >
                <img :src="logo" />
            </div>
            <div class="accept__items">
                <label
                    v-for="checkbox in $v.accept.$each.$iter"
                    :key="checkbox.$model.id"
                    class="app-checkbox"
                >
                    <input
                        v-model="checkbox.status.$model"
                        type="checkbox"
                    />
                    <div class="box"></div>
                    <span v-html="checkbox.$model.value"></span>
                    <div
                        v-if="$v.accept.$dirty && !checkbox.status.required"
                        class="app-error"
                    >
                        {{ $t('need_consent', 'Need consent') }}
                    </div>
                </label>
                <div class="accept__green-initiative">
                    <span class="green">Thank you for saving our planet! 🌱</span><br />
                    By&nbsp;participating in&nbsp;virtual events you reduce carbon emissions by&nbsp;25&nbsp;kg(s) per week<br />
                    Learn more about
                    <a
                        :href="greenInitiativeLink"
                        target="_blank"
                        class="green"
                    >
                        the Green Initiative
                    </a>
                </div>
                <app-button @click="join">
                    {{ $t('join_event', 'Join Event') }}
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>
import appButton from '@/components/user/app/app-button';

import generateUrl from '@/utils/mixins/generate_url';
import { post } from '@/utils/http-helper';
import { required } from 'vuelidate/lib/validators';
import { enableScroll } from '@/assets/js/disableScroll';

import { greenInitiativeLink } from '@/utils/settings';

const ACCEPT = '/user/accept';

export default {
    name: 'accept',

    components: {
        appButton,
    },

    mixins: [generateUrl],

    data: () => ({
        accept: [],
        greenInitiativeLink,
    }),

    validations: {
        accept: {
            required,
            $each: {
                status: {
                    required(value) {
                        return !!value;
                    },
                },
            },
        },
    },

    computed: {
        platform() {
            return this.$store.getters['platform/platform'];
        },
        logo() {
            return this.platform?.interface?.company_logo ? this.generateUrl(this.platform?.interface?.company_logo, '') : '';
        },
    },

    async created() {
        await this.$store.dispatch('auth/accept');
        const accept = this.$store.getters['auth/accept']?.disclaimers.map((d) => {
            const disclaimer = { ...d, status: false };
            return disclaimer;
        });
        this.accept = [...accept];
    },

    methods: {
        async join() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const agreements = this.accept.map(a => a.id);
            const response = await post(ACCEPT, { agreements });
            if (response.status) {
                enableScroll();
                if (this.$store.getters['auth/userInfo']?.role?.id === 3) {
                    this.$router.push({ name: 'events' });
                } else {
                    this.$router.push({ name: 'dashboard' });
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.accept {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.5);

    img {
        max-width: 150px;
    }

    .app-checkbox {
        display: block;

        margin-bottom: 1rem;

        font-size: to_rem(14px);

        .box {
            position: relative;

            display: inline-block;

            min-width: 10px;
            min-height: 10px;
            margin-right: 0;
            margin-bottom: -0.1rem;

            &:after {
                position: absolute;
                top: 50%;
                left: 50%;

                width: 9px;
                height: 9px;

                transform: translate(-50%, -50%);
            }
        }
    }

    .app-button {
        margin: 1rem auto 0;
    }

    .logo {
        margin-bottom: 1rem;

        img {
            display: block;

            margin: 0 auto;
        }
    }

    &__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 42rem;
        max-width: 90%;
        padding: 1rem;

        background: white;
        border-radius: 0.5rem;

        transform: translate(-50%, -50%);
    }

    &__items {
        padding: 1rem 1.5rem;

        background: $lightGray;
        border: 1px solid $gray;
        border-radius: 0.5rem;
    }

    span {
        margin-left: 5px;
    }

    &__green-initiative {
        font-size: 10px;
        line-height: 1.15;
        font-weight: 700;

        span {
            margin-left: 0;
        }

        .green {
            color: $lightGreen;
        }

        &>span.green {
            font-size: to_rem(14px);
        }

        a {
            text-decoration: underline;
            
            transition: color .3s;
            &:hover {
                color: $darkGreen;
            }
        }
    }
}
</style>
