<template>
    <div class="view-layout">
        <layout-header
            type="view"
            class="all-info"
        />
        <div class="container">
            <slot></slot>
        </div>
        <layout-footer />
    </div>
</template>

<script>
    import LayoutHeader from '@/components/user/layouts/header';
    import LayoutFooter from '@/components/user/layouts/footer';

    export default {
        name: 'view-layout',
        components: {
            LayoutHeader,
            LayoutFooter,
        },
    };
</script>

<style scoped lang="scss">
    .view-layout {
        position: relative;

        display: flex;
        flex-direction: column;

        min-height: 100vh;

        footer {
            margin-top: auto;
        }
    }
    .container{
        width: to_rem(1440px);
        max-width: 100%;
        margin: 0 auto;
    }
    @include mobile_or_P{
        .container{
            width: 100%;
            padding-top: 7.3rem;
        }
    }

    @include razr767{
        .container{
            padding-top: 16rem;
        }
    }
</style>
