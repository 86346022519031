<template>
    <div class="error-layout">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'error-layout',
    };
</script>

<style scoped lang="scss">

</style>
