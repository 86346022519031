<template>
    <div class="sidebar">
        <nav v-if="menuIsOpen">
            <router-link
                v-for="(link, index) of activeLinks"
                :key="index"
                :to="{name: link.to, query: {event_id: eventId}}"
                :class="{'router-link-active': $route.name === link.to}"
                @click.native="closeNav"
            >
                <span>
                    {{ link.label }}
                </span>
                <div class="bg"></div>
            </router-link>
        </nav>
        <img
            src="@/assets/img/icons/dashboard_icon.svg"
            class="burger"
            @click="$emit('toggleNav')"
        />
    </div>
</template>

<script>

export default {
    name: 'layout-sidebar',

    props: {
        menuIsOpen: {
            type: Boolean,
        },
    },

    data: () => ({
        links: [
            {
                label: 'Dashboard',
                to: 'dashboard',
                activeFlag: 'dashboard_v',
                langToken: 'm_dashboard',
            },
            {
                label: 'Agenda',
                to: 'agenda',
                activeFlag: 'agenda_v',
                langToken: 'm_agenda',
            },
            {
                label: 'Participants',
                to: 'participants',
                activeFlag: 'participants_v',
                langToken: 'm_participants',
            },
            {
                label: 'E- Library',
                to: 'e-library',
                activeFlag: 'library_v',
                langToken: 'm_library',
            },
            {
                label: 'Company Info/Contacts',
                to: 'company',
                activeFlag: 'company_v',
                langToken: 'm_company',
            },
            {
                label: 'User Guidance',
                to: 'user-guidance',
                activeFlag: 'guidance_v',
                langToken: 'm_guidance',
            },
            {
                label: 'My Profile',
                to: 'profile',
                activeFlag: 'profile_v',
                langToken: 'm_profile',
            },
            {
                label: 'IT Support',
                to: 'it-support',
                activeFlag: 'support_v',
                langToken: 'm_support',
            },
        ],
    }),

    computed: {
        platform() {
            return this.$store.getters['platform/platform'];
        },

        menu() {
            return this.platform.menu;
        },

        activeLinks() {
            const links = this.links.filter(l => this.menu[l.activeFlag]);
            return links.map(l => {
                l.label = this.menu[l.activeFlag.slice(0, -1) + 't'] || this.$t(l.langToken, l.label);
                return l;
            });
        },

        eventId() {
            return this.$route.query.event_id;
        },
    },

    methods: {
        closeNav() {
            if (window.isMobileOrTablet) {
                this.$emit('toggleNav', false);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.sidebar {
    position: relative;

    nav {
        width: 14.3rem;
        padding-right: 1rem;
    }

    .burger {
        position: absolute;
        top: 0;
        right: -1.85rem;
        // закоментил не стал удалять, так как могу сказать вернуть
        // поэтому просто скрыл 
        display: none;

        width: 1.8rem;

        cursor: pointer;
    }
}

a {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;

    height: 3.6rem;
    padding-left: 0.9rem;

    color: $mainTextColor;
    text-decoration: none !important;

    border-radius: 0.2rem;

    transition: background 0.2s;

    &.router-link-active, &:hover {
        .bg {
            background-color: var(--platform-accent-color);
        }
    }

    &:not(:last-of-type) {
        margin-bottom: 0.53rem;
    }

    .bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;

        background: var(--platform-main-color);
    }
}

@include mobile_or_P {
    .sidebar {
        position: fixed;
        top: 5rem;
        right: 0;
        z-index: 120000000;

        width: 15em;

        background: white;
        box-shadow: -9px 21px 47px -9px rgba(0, 0, 0, .2);

        nav {
            width: 100%;
            padding: 0 1rem 1rem;
        }
    }
}

@include razr767 {
    .sidebar {
        top: 5.775rem;
        left: 0;

        width: 100%;

        nav {
            padding-top: 1rem;
        }
    }
}
</style>
