import { get } from '@/utils/http-helper';

const GET_EVENTS = '/event/index';
const GET_TIME_ZONES = '/timezone/index';
const GET_EVENT_INFO = '/event/view';
const GET_EVENTS_SHORT = '/event/index-short';

export default {
    namespaced: true,
    state: {
        eventsInfo: null,
        eventsShort: null,
        events: null,
        event: null,
        timeZones: null,
    },
    actions: {
        async get({ commit }, page, size=0) {
            const events = await get(GET_EVENTS, { page, size });
            commit('updateEvents', events.data);
            return events;
        },
        async getShort({ commit }) {
            const events = await get(GET_EVENTS_SHORT );
            commit('updateEventsShort', events.data);
        },
        async getTimeZones({ commit }) {
            const timeZones = await get(GET_TIME_ZONES);
            commit('updateTimeZones', timeZones.data);
        },
        async getEventInfo({ commit }, id) {
            const event = await get(GET_EVENT_INFO, { id: id });
            commit('updateEventInfo', event.data);
        },
    },
    mutations: {
        updateEvents(state, events) {
            state.eventsInfo = events;
            state.events = events.events;
        },
        updateEventsShort(state, events) {
            state.eventsShort = events.events;
        },
        updateTimeZones(state, timeZones) {
            state.timeZones = timeZones.timezones;
        },
        updateEventInfo(state, event) {
            state.event = event;
        },
    },
    getters: {
        get: state => state.events,
        getEventsInfo: state => state.eventsInfo,
        getTimeZones: state => state.timeZones,
        getEventInfo: state => state.event,
        getEventsShort: state => state.eventsShort,
    },
};
