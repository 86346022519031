import { get } from '@/utils/http-helper';

const GET = '/company/view';

export default {
  namespaced: true,
  state: {
    company: null,
  },
  actions: {
    async get({ commit }, eventId) {
      const company = await get(GET, { event_id: eventId });
      commit('updateCompany', company.data);
    },
  },
  mutations: {
    updateCompany(state, company) {
      state.company = company;
    },
  },
  getters: {
    get: state => state.company,
  },
};
