<template>
    <div class="table-survey">
        <div class="table-survey__row">
            <label class="disposer-app-label">
                Table Name
                <input
                    v-model="table.name"
                    type="text"
                    class="disposer-app-input title-input"
                />
            </label>
            <label class="disposer-app-checkbox">
                <input
                    v-model="table.freeze_row"
                    type="checkbox"
                />
                <div class="box"></div>
                Freeze 1 row
            </label>
            <label class="disposer-app-checkbox">
                <input
                    v-model="table.freeze_column"
                    type="checkbox"
                />
                <div class="box"></div>
                Freeze 1 column
            </label>
        </div>

        <div
            ref="tableWrapper"
            class="table-survey__table-wrapper"
        >
            <div
                ref="table"
                class="table-survey__table"
            >
                <div
                    ref="tableHead"
                    class="table-survey__table-head"
                >
                    <div
                        v-for="(col, colIndex) in table.columns"
                        :key="col.id"
                        class="table-survey__table-cell table-survey__table-th"
                        :class="{error: $v.table.$dirty && !$v.table.columns.$each[colIndex].name.required}"
                    >
                        {{ col.name }}
                        <img
                            src="@/assets/img/icons/disposer/table-survey/settings_icon.svg"
                            @click="openDropdown($event, col.id)"
                        />
                    </div>
                    <img
                        src="@/assets/img/icons/disposer/table-survey/plus-call.svg"
                        class="table-survey__action"
                        @click="addColumn"
                    />
                </div>

                <div class="table-survey__table-body">
                    <div
                        v-for="(string, stringIndex) in table.rows"
                        :key="string.id"
                        class="table-survey__table-tr"
                    >
                        <div
                            v-for="(col, colIndex) in table.columns"
                            :key="col.id"
                            class="table-survey__table-cell table-survey__table-td"
                            :class="{error: $v.table.$dirty && !$v.table.rows.$each[stringIndex].val.required && colIndex===0}"
                        >
                            <input
                                v-if="col.type===0"
                                v-model="string.val"
                                type="text"
                            />
                            <div v-else-if="col.type===3">
                                <span>1 &mdash; {{ col.settings.max_scale }}</span>
                            </div>
                            <div
                                v-else-if="col.type===5"
                                class="square"
                            ></div>
                            <span
                                v-else
                                :title="colValuesString(col.options)"
                            >
                                {{ colValuesString(col.options) }}
                            </span>
                        </div>
                        <img
                            src="@/assets/img/icons/disposer/table-survey/remove-string.svg"
                            class="table-survey__action"
                            @click="removeString(string.id)"
                        />
                    </div>
                </div>
                <img
                    src="@/assets/img/icons/disposer/table-survey/new.svg"
                    class="table-survey__new-string"
                    @click="addString"
                />
            </div>
            <div
                v-if="activeDropdownCol"
                v-click-outside="openDropdown.bind(null)"
                class="table-survey__dropdown"
                :style="{top: `${dropdownTop}px`, left: `${dropdownLeft}px`}"
            >
                <input
                    v-model="activeDropdownCol.name"
                    type="text"
                />
                <div class="table-survey__dropdown-section">
                    <h5>TYPE</h5>
                    <div
                        v-if="activeDropdownCol.type === 0"
                        class="table-survey__dropdown-item"
                    >
                        <img src="@/assets/img/icons/disposer/table-survey/Aa.svg" />
                        Title
                    </div>
                    <template v-else>
                        <label class="disposer-app-checkbox table-survey__dropdown-item">
                            <img src="@/assets/img/icons/disposer/table-survey/select.svg" />
                            Select
                            <input
                                v-model="activeDropdownCol.type"
                                :value="1"
                                type="radio"
                                name="type"
                            />
                            <div class="box"></div>
                        </label>
                        <label class="disposer-app-checkbox table-survey__dropdown-item">
                            <img src="@/assets/img/icons/disposer/table-survey/multi-select.svg" />
                            Multi-select
                            <input
                                v-model="activeDropdownCol.type"
                                :value="2"
                                type="radio"
                                name="type"
                            />
                            <div class="box"></div>
                        </label>
                        <label class="disposer-app-checkbox table-survey__dropdown-item">
                            <img src="@/assets/img/icons/disposer/table-survey/rating.svg" />
                            Rating
                            <input
                                v-model="activeDropdownCol.type"
                                :value="3"
                                type="radio"
                                name="type"
                            />
                            <div class="box"></div>
                        </label>
                        <label class="disposer-app-checkbox table-survey__dropdown-item">
                            <img src="@/assets/img/icons/disposer/table-survey/checkbox.svg" />
                            Checkbox
                            <input
                                v-model="activeDropdownCol.type"
                                :value="5"
                                type="radio"
                                name="type"
                            />
                            <div class="box"></div>
                        </label>
                        <label class="disposer-app-checkbox table-survey__dropdown-item">
                            <img src="@/assets/img/icons/disposer/table-survey/text.svg" />
                            Text
                            <input
                                v-model="activeDropdownCol.type"
                                :value="4"
                                type="radio"
                                name="type"
                            />
                            <div class="box"></div>
                        </label>
                    </template>
                </div>
                <div
                    v-if="activeDropdownCol.type!==5 && activeDropdownCol.type!==4 && activeDropdownCol.type!==3 && activeDropdownCol.type!==0"
                    class="table-survey__dropdown-section"
                >
                    <h5>VALUES</h5>
                    <div class="table-survey__add-value">
                        <img
                            v-if="!isAddValue"
                            src="@/assets/img/icons/disposer/table-survey/add-value.svg"
                            @click="startAddValue"
                        />
                        <template v-else>
                            <input
                                v-model="newValue"
                                type="text"
                            />
                            <img
                                src="@/assets/img/icons/disposer/table-survey/check.svg"
                                class="check"
                                @click="saveNewValue"
                            />
                            <img
                                src="@/assets/img/icons/disposer/table-survey/remove.svg"
                                @click="removeAddValue"
                            />
                        </template>
                    </div>
                    <draggable
                        v-model="activeDropdownCol.options"
                        group="options"
                        @end="sortOptions"
                    >
                        <div
                            v-for="(value, index) in activeDropdownCol.options"
                            :key="index"
                            class="table-survey__dropdown-value"
                        >
                            <span>{{ value.name }}</span>
                            <img
                                src="@/assets/img/icons/disposer/table-survey/remove-value.svg"
                                @click="removeValue(index)"
                            />
                        </div>
                    </draggable>
                </div>
                <template v-if="activeDropdownCol.type===3">
                    <div class="table-survey__dropdown-section">
                        <h5>MAX RATING</h5>
                        <vue-numeric-input
                            v-model="activeDropdownCol.settings.max_scale"
                            :min="1"
                        />
                    </div>
                    <div class="table-survey__dropdown-section">
                        <label class="disposer-app-checkbox">
                            <input
                                v-model="activeDropdownCol.settings.is_rate_unique"
                                type="checkbox"
                            />
                            <div class="box"></div>
                            Value can be used only once
                        </label>
                    </div>
                </template>
                <div class="table-survey__dropdown-section">
                    <div
                        v-if="activeDropdownCol.type!==0"
                        class="table-survey__dropdown-item"
                        @click="insertCol('left')"
                    >
                        <img src="@/assets/img/icons/disposer/table-survey/to-left.svg" />
                        Insert left
                    </div>
                    <div
                        class="table-survey__dropdown-item"
                        @click="insertCol('right')"
                    >
                        <img src="@/assets/img/icons/disposer/table-survey/to-right.svg" />
                        Insert right
                    </div>
                    <div
                        v-if="activeDropdownCol.type!==0"
                        class="table-survey__dropdown-item"
                        @click="deleteCol"
                    >
                        <img src="@/assets/img/icons/disposer/table-survey/delete.svg" />
                        Delete
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueNumericInput from 'vue-numeric-input';
import draggable from 'vuedraggable';

import vClickOutside from 'v-click-outside';
import { post } from '@/utils/http-helper';
import { createdSurvey } from '@/utils/disposer/notifications-text-helper';
import { required } from 'vuelidate/lib/validators';


const stringObj = () => ({
    id: Math.random(),
    val: '',
});
const columnObj = () => ({
    id: Math.random(),
    name: '',
    type: '1',
    options: [],
    settings: {},
});

export default {
    name: 'table-survey',

    components: {
        VueNumericInput,
        draggable,
    },

    directives: {
        clickOutside: vClickOutside.directive,
    },

    props: {
        survey: {
            type: Object,
            default: () => ({}),
        },
    },

    data: () => ({
        table: {
            name: '',
            freeze_row: false,
            freeze_column: false,
            rows: [stringObj()],
            columns: [
                {
                    id: Math.random(),
                    name: 'Name',
                    type: 0,
                },
                columnObj(),
            ],
        },

        activeDropdownCol: null,
        dropdownLeft: 0,
        dropdownTop: 0,
        isAddValue: false,
        newValue: '',
    }),

    validations: {
        table: {
            rows: {
                $each: {
                    val: {
                        required,
                    },
                },
            },
            columns: {
                $each: {
                    name: {
                        required,
                    },
                },
            },
        },
    },

    mounted() {
        this.$refs.table.addEventListener('scroll', () => {
            this.openDropdown(null, false);
        });
        this.setTable();
    },

    methods: {
        setTable() {
            if (this.survey.id >= 1) {
                this.table = {
                    ...this.survey,
                    columns: this.survey.columns.sort((a, b) => a.position - b.position),
                };
            }
        },

        addString() {
            this.table.rows.push(stringObj());
        },

        removeString(id) {
            this.table.rows = this.table.rows.filter(s => s.id !== id);
        },

        addColumn() {
            this.table.columns.push(columnObj());
        },

        openDropdown(e, id) {
            this.activeDropdownCol = this.table.columns.find(c => c.id === id);

            const parent = e?.target.closest('.table-survey__table-th');
            this.dropdownLeft = parent?.getBoundingClientRect().left - this.$refs.tableWrapper.getBoundingClientRect().left;
            this.dropdownTop = this.$refs.tableHead.offsetHeight;
        },

        startAddValue() {
            this.isAddValue = true;
        },

        removeAddValue() {
            this.isAddValue = false;
            this.newValue = '';
        },

        saveNewValue() {
            if (this.newValue) {
                this.activeDropdownCol.options.push({
                    name: this.newValue,
                });
                this.newValue = '';
            } else {
                this.removeAddValue();
            }
        },

        sortOptions() {
            this.activeDropdownCol.options = this.activeDropdownCol.options.map(item => {
                return {
                    name: item.name,
                };
            });
        },

        colValuesString(options) {
            if (options?.length) {
                return options.reduce((res, value) => {
                    return res += value.name + '; ';
                }, '');
            }
        },

        removeValue(id) {
            this.activeDropdownCol.options = this.activeDropdownCol.options.filter(
                (v, index) => index !== id);
        },

        insertCol(direction) {
            let colIndex = this.table.columns.findIndex(c => c.id === this.activeDropdownCol.id);
            direction === 'right' ?  colIndex ++ : null;
            this.table.columns.splice(colIndex, 0, columnObj());
            this.openDropdown(null, false);
        },

        deleteCol() {
            this.table.columns = this.table.columns.filter(c => c.id !== this.activeDropdownCol.id);
            this.openDropdown(null, false);
        },

        async submit(data) {
            if (this.$v.$invalid) {
                this.$v.table.$touch();
                return;
            }

            const survey = {
                ...data,
                name: this.table.name,
                rows: this.table.rows.map(s => {
                    if (s.id >= 1) {
                        return {
                            id: s.id,
                            val: s.val,
                        };
                    }
                    return { val: s.val };
                }),
                freeze_row: +this.table.freeze_row,
                freeze_column: +this.table.freeze_column,
                columns: this.table.columns.map((c, index) => {
                    const obj = {
                        ...c,
                        type: +c.type,
                        position: index,
                        settings: {
                            is_rate_unique: +c.settings?.is_rate_unique || 0,
                            max_scale: c.settings?.max_scale || 1,
                        },
                        options: c.options?.map(o => {
                            o.id < 1 ? delete o.id: '';
                            return o;
                        }),
                    };
                    obj.id < 1 ? delete obj.id: '';
                    obj.type === 2? obj.settings.max_response = c.options?.length : '';

                    return obj;
                }),
            };

            let url = '/topic/create-survey';
            if (this.table.id >= 1) {
                url = '/topic/update-survey';
                survey.id = this.table.id;
            }

            let questionErrors = {};
            return await new Promise((resolve) => {
                this.$awn.async(post(url, survey), response => {
                    if (response.status === 1) {
                        this.$emit('updateTopicInfo');
                        this.$awn.success(createdSurvey);
                    } else {
                        questionErrors = response.errors;
                    }
                    resolve();
                });
            }).then(() => questionErrors);
        },
    },
};
</script>

<style scoped lang="scss">
@mixin error {
    position: relative;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        border: 1px solid red;

        pointer-events: none;
    }
}

.table-survey {
    font-size: 14px;

    $root: &;

    &__row {
        display: flex;

        .disposer-app-label {
            margin-right: 30px;
        }

        .disposer-app-checkbox {
            margin-top: 11px;
            margin-left: 15px;
        }
    }

    &__table {
        overflow: auto;

        @include custom_scroll;

        &-wrapper {
            position: relative;
        }

        &-cell {
            display: flex;
            flex-shrink: 0;
            justify-content: space-between;
            align-items: center;

            width: 322px;
            min-height: 31px;

            background: #fff;
            border: 1px solid $nobel;

            &:not(:last-of-type) {
                border-right: none;
            }

            &.error {
                @include error;
            }

            .square {
                width: 15px;
                height: 15px;
                margin: 0 auto;

                border: 1px solid #000000;
            }
        }

        &-head, &-tr {
            display: flex;
        }

        &-tr {
            #{$root}__table-td {
                border-top: none;
            }
        }

        &-th {
            padding: 8px;

            font-weight: 700;
            color: $copyGray;

            &.error {
                @include error;
            }

            img {
                cursor: pointer;
            }
        }

        &-td {
            input[type='text'] {
                width: 100%;
                height: 100%;
                padding: 8px;

                background: transparent;
                border: none;
            }

            span {
                padding: 0 8px;
                overflow: hidden;

                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &__new-string {
        margin: 10px 0;

        cursor: pointer;
    }

    &__action {
        margin-left: 10px;

        cursor: pointer;
    }

    &__dropdown {
        position: absolute;
        top: 31px;
        z-index: 1;

        width: 322px;

        background: #fff;
        border: 1px solid $nobel;
        border-top: none;
        box-shadow: 0 10px 12px rgba(0, 0, 0, 0.15);

        input[type='text'] {
            width: calc(100% - 24px);
            height: 28px;
            margin: 12px 12px 0;
            padding: 0 5px;

            background: #fff;
            border: 1px solid $nobel;
            border-radius: 3px;
        }

        &-section {
            padding: 10px 6px;

            font-size: 14px;
            color: #000;

            &:not(:last-of-type) {
                border-bottom: 1px solid $nobel;
            }

            h5 {
                margin-bottom: 10px;
                padding: 0 5px;

                font-weight: 700;
            }
        }
        &-item {
            display: flex;
            align-items: center;

            width: 150px;
            padding: 6px;

            font-weight: 400;
            color: #000;

            border-radius: 10px;

            cursor: pointer;

            &:hover {
                background: rgba($nobel, 0.3);
            }

            img {
                width: 14px;
                margin-right: 10px;
            }

            .box {
                box-sizing: border-box;

                width: 16px;
                min-width: initial;
                height: 16px;
                min-height: initial;
                margin-right: 0;
                margin-left: auto;

                border-color: #000;
                border-radius: 50%;

                &:after {
                    transition: none;
                }
            }

            input {
                &:checked {
                    & + {
                        .box {
                            border-color: #000;
                            border-radius: 50%;

                            &:after {
                                top: 3px;
                                left: 3px;

                                width: 8px;
                                height: 8px;

                                background: black;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }

        &-value {
            display: flex;
            align-items: center;

            padding: 6px;

            word-break: break-all;

            &:not(:last-of-type) {
                margin-bottom: 4px;
            }

            img {
                margin-left: 5px;

                cursor: pointer;
            }

            span {
                cursor: move;
            }
        }
    }

    &__add-value {
        display: flex;
        align-items: center;

        margin-left: 6px;

        img {
            cursor: pointer;
        }

        input[type='text'] {
            width: 140px;
            margin: 0;
            padding-right: 20px;
        }

        .check {
            margin-right: 5px;
            margin-left: -20px;
        }
    }
}
</style>
