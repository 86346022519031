import { disableScroll, enableScroll } from '@/assets/js/disableScroll';

export default {
    state: {
        activePopup: null,
        options: null,
    },

    mutations: {
        setActivePopup(state, { popupName, options }) {
            state.activePopup = popupName;
            state.options = options;
        },
    },

    actions: {
        togglePopup({ commit }, { popupName, options }) {
            let activePopup;
            if (!popupName) {
                enableScroll();
                activePopup = null;
                options = null;
            } else {
                disableScroll();
                activePopup = popupName;
            }
            commit('setActivePopup', { popupName: activePopup, options });
        },
    },

    getters: {
        activePopup: state => state.activePopup,
        popupOptions: state => state.options,
    },
};
