<template>
    <component
        :is="tag"
        class="app-button"
        v-bind="attributes"
        v-on="listeners"
    >
        <slot></slot>
    </component>
</template>

<script>
export default {
    name: 'app-button',
    props: {
        tag: {
            type: String,
            default: 'div',
        },
    },
    computed: {
        attributes() {
            return this.$attrs;
        },
        listeners() {
            return Object.assign(
                {},
                this.$listeners,
            );
        },
    },
};
</script>

<style scoped lang="scss">
.app-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: fit-content;
    padding: 0.86rem 2.5rem;

    font-size: to_rem(14px);
    text-align: center;
    color: $mainTextColor;
    text-decoration: none;

    background: var(--platform-main-color);
    border: none;
    border-radius: 0.2rem;

    cursor: pointer;
    transition: box-shadow .2s;

    &::v-deep {
        .fa {
            margin-right: 0.5rem;
        }
    }

    &:hover {
        box-shadow: 3px 3px 3px rgb(0 0 0 / 40%)
    }

    &[data-disable] {
        filter: grayscale(1);

        pointer-events: none;
    }

    &.responsive {
        width: 100%;
        padding: 0.86rem 0;
    }

    &.narrow {
        padding: 0.55rem 0.4rem;

        img, i {
            height: 1rem;
            margin-right: 0.2rem;
        }
    }

    &.small {
        height: 2.3rem;
        padding: 0.2rem 0.5rem;

        img, i {
            height: 1rem;
            margin-right: 0.2rem;
        }
    }

    &.accent {
        background: var(--platform-accent-color);
    }

    &.white {
        color: $mainDarkBlue;

        background: white;
    }

    &.big {
        width: 14rem;
        padding: 1.1rem 0;

        font-size: to_rem(20px);
    }
}

img, i {
    display: block;

    width: 0.9rem;
    margin-right: 0.5rem;
}

@include mobile_or_P {
    .app-button {
        &.narrow {
            height: 2.2rem;
            padding: 0 .5rem;
        }

        &:hover {
            box-shadow: none;
        }
    }
}

@include razr767 {
    .app-button {
        font-size: 13px;

        &.narrow {
            height: 3.1rem;
            margin: 0 auto;
            padding: 0 0.8rem;

            img {
                width: 1.1rem;
                height: 1.2rem;
            }
        }

        &.big {
            width: 16.5rem;
            padding: 0.8rem 0;
        }

        &.small {
            height: 2.4rem;
            padding: 0 0.8rem;
        }

        img {
            width: 1.2rem;
        }
    }
}
.event-none {
    pointer-events: none;
    .table-survey__save > button {
        background-color: #c5c0c0;
    }
}
;
</style>
